import React, {useContext, useState} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import Context from "../../../Utils/Context";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const OrderRatingModal = ({open, toggle, order}) => {
    const { t } = useTranslation()
    const ModalData = t('myOrders.OrderRating', { returnObjects: true })
    const pageData = {
        'order-rating': ModalData['custom-content'].find(x => x.name === 'order-rating')['value-single'],
        'order-number':ModalData['custom-content'].find(x => x.name === 'order-number')['value-single'],
        'order-point': ModalData['custom-content'].find(x => x.name === 'order-point')['value-single'],
        'rating-error': ModalData['custom-content'].find(x => x.name === 'rating-error')['value-single'],
        'rating-send':ModalData['buttons'].find(x => x.name === 'rating-send')['label'],
        'order-time': ModalData['custom-content'].find(x => x.name === 'order-time')['value-single'],
    }
    const context = useContext(Context);
    const history = useHistory();
    const [rating, setRating] = useState(0);
    const [comment, setComment] = useState("");
    const [formData, setFormData] = useState({
        product_transaction_buyer_sales_comment: null,
        product_transaction_buyer_sales_like_score: null,
    });

    const onRateOrderButtonPressed = () => {
        if (rating) {
            formData.product_transaction_buyer_sales_like_score = rating;
            formData.product_transaction_buyer_sales_comment = comment;
            context.update(
                'product_transaction',
                formData,
                order.product_transaction_secret_key,
                (r) => {
                    //this.props.navigation.goBack();
                    toggle();
                },
            );
        } else {
            const { t } = this.props
            const dialogTData = t("showDialogs", { returnObjects: true })
            const dialogData = dialogTData.find((x) => x.name === "order-rating")
            context.setGlobalState('showDialog', {
                title: dialogData.title,
                message: dialogData.message,
                buttons: [
                    {
                        text: dialogData.buttons[0].label,
                        onPress: () => {
                            context.setGlobalState('showDialog', false);
                        },
                    },
                ],
            });
        }
    };

    return (<Modal show={open} id="myFeedback" centered>
        <div className="close-modal" onClick={()=>toggle()} data-dismiss="modal"><i/></div>
        <ModalBody>
            <div className="row">
                <div className="col-10 m-auto">
                    <h1>{pageData['order-rating']}</h1>
                </div>
                <div className="col-10 auto green-box">
                    <div className="row no-gutters">
                        <p className="col text-left">{pageData['order-number']}</p>
                        <p className="col text-right">{order.product_transaction_id}</p>
                    </div>
                    <div className="row">
                        <p className="col-4 text-left">{pageData['order-time']}</p>
                        <p className="col-8 text-right">{_.replace(
                            order.product_transaction_delivery_start_datetime, ' 00:00:00','',)}{' '}{order.product_transaction_delivery_time_interval}</p>
                    </div>
                </div>
                <div id="feedback-form" className="col-12 m-auto needs-validation" noValidate>
                    <div className="row">
                        <div className="col-10 auto green-box">
                            <p className="col-12 text-center">{pageData['order-point']}</p>
                            <div id="like" className="rating">
                                <input type="radio" checked={rating === 5} onChange={()=>setRating(5)} id="heart_5" name="like" value={5}/>
                                <label htmlFor="heart_5" title="Five"/>
                                <input type="radio" checked={rating === 4} onChange={()=>setRating(4)} id="heart_4" name="like" value={4}/>
                                <label htmlFor="heart_4" title="Four"/>
                                <input type="radio" checked={rating === 3} onChange={()=>setRating(3)} id="heart_3" name="like" value={3}/>
                                <label htmlFor="heart_3" title="Three"/>
                                <input type="radio" checked={rating === 2} onChange={()=>setRating(2)} id="heart_2" name="like" value={2}/>
                                <label htmlFor="heart_2" title="Two"/>
                                <input type="radio" checked={rating === 1} onChange={()=>setRating(1)} id="heart_1" name="like" value={1} required/>
                                <label htmlFor="heart_1" title="One"/>
                                <div className="invalid-feedback">{pageData['rating-error']}</div>
                            </div>
                        </div>
                        <div className="form-group col-10 auto green-box">
                            <textarea id="feedback-message" value={comment} onChange={(e)=>setComment(e.target.value)} name="feedback-message" placeholder="Siparişe Yorum Yazın"
                                      required/>
                            <div className="invalid-feedback">{pageData['comment-error']}</div>
                        </div>
                        <div className="col-lg-8 m-auto">
                            <button onClick={()=>onRateOrderButtonPressed()}
                                    className="btn btn-secondary w-100 text-uppercase font-weight-bold">{pageData['rating-send']}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>);
}
export default OrderRatingModal;
