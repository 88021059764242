import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import langEn from "./translation/en/translation";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import homeTr from './content/contentrain/home/tr.json'
import stallsTr from './content/contentrain/stalls/tr.json'
import myOrdersTr from './content/contentrain/my-orders/tr.json'
import orderTr from './content/contentrain/order/tr.json'
import profileTr from './content/contentrain/profile/tr.json'
import addAddressTr from './content/contentrain/add-address/tr.json'
import addressesTr from './content/contentrain/addresses/tr.json'
import clarificationTextTr from './content/contentrain/clarification-text/tr.json'
import becomeSellerTr from './content/contentrain/become-seller/tr.json'
import faqTr from './content/contentrain/faq/tr.json'
import kvkkTr from './content/contentrain/kvkk/tr.json'
import passwordSettingsTr from './content/contentrain/password-settings/tr.json'
import privacyCokiesPolicyTr from './content/contentrain/privacy-cokies-policy/tr.json'
import sssFooterTr from './content/contentrain/sss-footer/tr.json'
import userContractTr from './content/contentrain/user-contract/tr.json'
import showDialogsTr from './content/contentrain/show-dialogs/tr.json'
import supportTr from './content/contentrain/support/tr.json'
import modalsTr from './content/contentrain/layout-modals/tr.json'
import toastsTr from './content/contentrain/toasts/tr.json'
import contactSettingsTr from './content/contentrain/contact-settings/tr.json'
import globalDataTr from './content/contentrain/global-data/tr.json'
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init


const resources = {
  en: {
    translation: langEn,
  },
  tr: {
    translation: {
      home:  homeTr[0],
      stalls: {
        Stall: stallsTr.find(x => x.name === 'stall'),
        StallsColumn: stallsTr[0].components.find(x => x.name === 'stalls-column'),
        CommentsModal: stallsTr[0].components.find(x => x.name === 'comments-modal'),
        ProductDetails: stallsTr[0].components.find(x => x.name === 'product-details'),
        Product: stallsTr[0].components.find(x => x.name === 'product'),
        Products: stallsTr[0].components.find(x => x.name === 'products'),
        Stalls: stallsTr.find(x => x.name === 'stalls'),
        MarketPlacesModal: stallsTr[0].components.find(x => x.name === 'marketplaces-modal'),
        ClerkDetails: stallsTr[0].components.find(x => x.name === 'clerk-detail'),
        Basket: stallsTr[0].components.find(x => x.name === 'basket'),
      },
      myOrders: {
        OrderDetails: myOrdersTr[0].components.find(x => x.name === 'order-details'),
        OrderRating: myOrdersTr[0].components.find(x => x.name === 'order-rating'),
        MyOrders: myOrdersTr[0],
      },
      order: {
        fail: orderTr.find(x => x.name === 'fail'),
        orderSummary: orderTr.find(x => x.name === 'order-summary'),
        thanks: orderTr.find(x => x.name === 'thanks'),
      },
      profile: {
        ProfileForm: profileTr[0].components.find(x => x.name === 'profile-form'),
        Sidebar: profileTr[0].components.find(x => x.name === 'sidebar'),
        SubHeader: profileTr[0].components.find(x => x.name === 'subheader'),
      },
      addAddress: addAddressTr[0],
      addresses: addressesTr[0],
      clarificationText: clarificationTextTr[0]["custom-content"][0]["value-rich"],
      becomeSeller: {
        EvulationForm: becomeSellerTr[0].components.find(x => x.name === 'evaluation-form'),
        FirstBanner: becomeSellerTr[0].components.find(x => x.name === 'first-banner'),
        SellerInformation: becomeSellerTr[0].components.find(x => x.name === 'seller-information')["custom-content"][0]["value-rich"],
      },
      faq: faqTr[0],
      kvkkText: kvkkTr[0]["custom-content"][0]["value-rich-try"],
      passwordSettings: passwordSettingsTr[0].components.find(x => x.name === 'password-form'),
      privacyCokiesPolicy: privacyCokiesPolicyTr[0]["custom-content"][0]["value-rich"],
      sssFooter: sssFooterTr[0]["custom-content"][0]["value-rich"],
      userContract: userContractTr[0]["custom-content"][0]["value-rich"],
      showDialogs: showDialogsTr,
      kvkk: kvkkTr[0]['custom-content'][0]['value-rich'],
      support: {
        supportForm: supportTr[0].components.find(x => x.name === 'support-form')
      },
      modals: {
        addAddressModal: modalsTr[0].components.find(x => x.name === 'add-address-modal'),
        addressSelectModal: modalsTr[0].components.find(x => x.name === 'address-select-modal'),
        agreementDialog: modalsTr[0].components.find(x => x.name === 'agreement-dialog'),
        confirmSignup: modalsTr[0].components.find(x => x.name === 'confirm-signup'),
        dialog: modalsTr[0].components.find(x => x.name === 'dialog'),
        footer: modalsTr[0].components.find(x => x.name === 'footer'),
        forgotPass: modalsTr[0].components.find(x => x.name === 'forgot-pass'),
        layout: modalsTr[0].components.find(x => x.name === 'layout'),
        loading: modalsTr[0].components.find(x => x.name === 'loading'),
        login: modalsTr[0].components.find(x => x.name === 'login'),
        loginAlert: modalsTr[0].components.find(x => x.name === 'loginalert'),
        myOrdersDrawer: modalsTr[0].components.find(x => x.name === 'my-orders-drawer'),
        navbar: modalsTr[0].components.find(x => x.name === 'navbar'),
        newPassConfirm: modalsTr[0].components.find(x => x.name === 'new-pass-confirm'),
        newToolbar: modalsTr[0].components.find(x => x.name === 'new-toolbar'),
        signup: modalsTr[0].components.find(x => x.name === 'signup'),
        storyModal: modalsTr[0].components.find(x => x.name === 'story-modal'),
        toolbar: modalsTr[0].components.find(x => x.name === 'toolbar'),
      },
      toasts: toastsTr,
      contactSettings: {
        settingsContent : contactSettingsTr[0].components.find(x => x.name === 'settings-content'),
      },
      global: globalDataTr[0],
    },
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: "tr",
    fallbackLng: "tr",
    debug: true,
    // whiteList: Languages,
    ns: ["translation"],
    defaultNS: "translation",
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      formatSeparator: ",",
    },
    react: {
      wait: true,
    },
  });

export default i18n;
