import React from "react";
import * as Actions from "../store/actions/index";
import {Modal} from "react-bootstrap";
// import {useDispatch, useSelector} from "react-redux";
import ValidationComponent from "../components/Validator/Validator";
import {closeSignupModal, openConfirmSignupModal} from "../store/actions/index";
import { connect } from "react-redux";
import Context from "../Utils/Context";
import * as _ from 'lodash';
import {setItem} from "../Utils/LocalStorage";
import { withTranslation } from "react-i18next";


class SignUp extends ValidationComponent {

    static contextType = Context;
    deviceLocale = 'tr';
    labels = {
        formData: {
            user_name: 'Ad',
            user_surname: 'Soyadı',
            user_active_gsm: 'Telefon',
            user_active_email: 'E-mail',
            user_password: 'Şifre',
            // user_birthdate: 'Doğum Tarihi',
        },
    };
    state = {
        showDatePicker: false,
        aggrement1: false,
        aggrement2: false,
        // birthday: null,
        formData: {
            user_name: "",
            user_surname: "",
            user_active_gsm: "",
            user_active_email: "",
            user_username: "",
            user_password: "",
            // user_birthdate: null,
            usernametype: 'gsm',
            loginusertype: 'user',
            roletype: 'enduser',
        },
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    signUpButtonPressed = async () => {
        this.state.formData.user_active_gsm = _.replace(
            this.state.formData.user_active_gsm,
            new RegExp(/\s/, 'g'),
            '',
        );

        console.log("formData1", this.state.formData);
        this.validate({
            formData: {
                user_name: {required: true},
                user_surname: {required: true},
                user_active_gsm: {required: true, exactlength: 10},
                user_active_email: {required: true, email: true},
                user_password: {required: true},
                // user_birthdate: {birthday: 'DD/MM/YYYY'},
            },
        });
        if (this.isFormValid()) {
            if (!this.state.aggrement1) {
                const { t } = this.props
                const dialogTData = t('showDialogs', { returnObjects: true })
                const dialogData = dialogTData.find(x => x.name === 'signup-contracts')
                this.context.setGlobalState('showDialog', {
                    title: dialogData.title,
                    message: dialogData.message,
                    buttons: [
                        {
                            text: dialogData.buttons[0].label,
                            onPress: () => {
                                this.context.setGlobalState('showDialog', false);
                            },
                        },
                    ],
                });
                return;
            }
            this.state.formData.user_active_gsm = _.replace(
                this.state.formData.user_active_gsm,
                new RegExp(/\s/, 'g'),
                '',
            );
            this.state.formData.user_username = _.replace(
                this.state.formData.user_username,
                new RegExp(/\s/, 'g'),
                '',
            );
            this.state.formData.user_general_sms_permission = this.state.aggrement2
                ? '1'
                : '0';
            this.state.formData.user_general_email_permission = this.state.aggrement2
                ? '1'
                : '0';
            this.state.formData.user_general_mobile_permission = this.state.aggrement2
                ? '1'
                : '0';
            this.state.formData.user_general_phone_permission = this.state.aggrement2
                ? '1'
                : '0';
            //--console.warn("this.state.formData >> ", this.state.formData);
            await this.context.newmember(this.state.formData, (response) => {
                //--console.warn("newmember >> response >> ", response);
                console.log({response})
                if (response.statuscode === '401') {
                    const { t } = this.props
                    const dialogTData = t('showDialogs', { returnObjects: true })
                    const dialogData = dialogTData.find(x => x.name === 'signup-phone')
                    if (response.message === 'registeredbefore') {
                        this.context.setGlobalState('showDialog', {
                            title:  dialogData.title,
                            message: dialogData.message,
                            buttons: [
                                {
                                    text: dialogData.buttons[0].label,
                                    onPress: () => {
                                        this.context.setLoading(false);
                                        this.context.setGlobalState('showDialog', false);
                                    },
                                },
                            ],
                        });
                        return;
                    }
                } else {
                     setItem("user_active_gsm", this.state.formData.user_active_gsm);
                    this.props.closeSignupModal();
                    this.props.openConfirmSignupModal();
                    // this.props.navigation.navigate('Activation', {
                    //     formData: this.state.formData,
                    //     response: response,
                    //     usernotactive: false,
                    // });
                }
            });
        }
    };

    updateFormData(key, value) {
        const f = this.state.formData;
        f[key] = value;
        this.setState(f);
    }


    render() {
        const {t} = this.props
        const signupData = t('modals.signup', { returnObjects: true })
        const pageData = {
            "title": signupData['custom-content'].find(x => x.name === 'register')['value-single'],
            "user": signupData['custom-content'].find(x => x.name === 'user-contract')['value-single'],
            "personal": signupData['custom-content'].find(x => x.name === 'personal-info')['value-single'],
            "protect": signupData['custom-content'].find(x => x.name === 'protect-info')['value-single'],
            "contract": signupData['custom-content'].find(x => x.name === 'contract')['value-single'],
            "cookies": signupData['custom-content'].find(x => x.name === 'cookies')['value-single'],
            "accept": signupData['custom-content'].find(x => x.name === 'accept-above')['value-single'],
            "and": signupData['custom-content'].find(x => x.name === 'and')['value-single'],
            "name": signupData.inputs.find(x => x.name === 'signup-name'),
            "surname": signupData.inputs.find(x => x.name === 'signup-surname'),
            "phone": signupData.inputs.find(x => x.name === 'signup-phone'),
            "email": signupData.inputs.find(x => x.name === 'signup-email'),
            "pass": signupData.inputs.find(x => x.name === 'signup-pass'),
            "agree": signupData.inputs.find(x => x.name === 'contact-agreement'),
            }
    return (
        <Modal show={this.props.signupModal} id={"signup"} centered>
            <div onClick={() => this.props.closeSignupModal()} className="close-modal" data-dismiss="modal"><i/>
            </div>
            <div className="modal-body">
                <h1>{ pageData['title']}</h1>
                <div id="signup-form" className=" needs-validation">
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sign_name"
                                    placeholder={pageData['name'].placeholder}
                                    onChange={(event) => {
                                        this.updateFormData('user_name', event.target.value);
                                    }}
                                    value={this.state.formData.user_name}
                                    required/>
                                <label htmlFor="sign_name">{pageData['name'].label}</label>
                                <div className="invalid-feedback">
                                    {pageData['name'].error}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="sign_surname"
                                    placeholder={pageData['surname'].placeholder}
                                    value={this.state.formData.user_surname}
                                    onChange={(event) => {
                                        this.updateFormData('user_surname', event.target.value);
                                    }}
                                    required
                                />
                                <label htmlFor="sign_surname">{pageData['surname'].label}</label>
                                <div className="invalid-feedback">
                                    {pageData['surname'].error}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group" style={{display: "flex", justifyContent:"center", alignItems:"center", paddingLeft:"0"}}>
                                <p className="mb-0" style={{color: "#90B52A", fontSize: "14px", paddingLeft: "20px", fontWeight: 500}}>+90 </p>
                                <input
                                    type="number"
                                    className="form-control"
                                    style={{paddingLeft: 2, height: "auto"}}
                                    id="sign_phone"
                                    placeholder={pageData['phone'].placeholder}
                                    value={this.state.formData.user_active_gsm}
                                    onChange={(event) => {
                                        this.updateFormData('user_active_gsm', event.target.value);
                                        this.updateFormData('user_username', event.target.value);
                                    }}
                                    required/>
                                <label htmlFor="sign_phone">{pageData['phone'].label}</label>
                                <div className="invalid-feedback">
                                    {pageData['phone'].error}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="email"
                                    className="form-control"
                                    id="sign_email"
                                   placeholder={pageData['email'].placeholder}
                                    onChange={(event) => {
                                        this.updateFormData('user_active_email', event.target.value);
                                    }}
                                    value={this.state.formData.user_active_email}
                                    required/>
                                <label htmlFor="sign_email">{pageData['email'].label}</label>
                                <div className="invalid-feedback">
                                    {pageData['email'].error}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input
                                    type="password"
                                    className="form-control"
                                    id="sign_pass"
                                    onChange={(event) => {
                                        this.updateFormData('user_password', event.target.value);
                                    }}
                                   placeholder={pageData['pass'].placeholder}
                                    value={this.state.formData.user_password}
                                    required/>
                                <label htmlFor="sign_pass">{pageData['pass'].label}</label>
                                <div className="invalid-feedback">
                                    {pageData['pass'].error}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group form-check" style={{borderWidth: 0}}>
                        <div className="form-check" style={{padding: "0 12px"}}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck_1"
                                checked={this.state.formData.aggrement1}
                                onChange={(event) => { this.updateFormData('aggrement1', event.target.checked) }}
                                required/>
                            <label className="form-check-label user-conditions" htmlFor="gridCheck_1" >
                                      <span><a href="https://pazardan.app/user-contract">{pageData['user']}</a>, <a href="https://pazardan.app/clarification-text">{pageData['personal']}</a>, <a
                                          href="https://pazardan.app/kvkk">{pageData['protect']}</a>, <a href="https://pazardan.app/cookies">{pageData['contract']}</a> {pageData['and']} <a href="https://pazardan.app/cookies">{pageData['cookies']}</a> {pageData['accept']}</span>
                            </label>
                        </div>
                    </div>
                    <div className="form-group form-check" style={{borderWidth: 0}}>
                        <div className="form-check" style={{padding: "0 12px"}}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="gridCheck_"
                                checked={this.state.formData.aggrement2}
                                onChange={(event) => { this.updateFormData('aggrement2', event.target.checked) }}
                                required/>
                            <label className="form-check-label" htmlFor="gridCheck_" >
                                <span>{pageData['agree'].label}</span>
                            </label>
                        </div>
                    </div>
                    <div className="col-10 m-auto">
                        <button
                            type="submit"
                            className="btn btn-secondary w-100"
                            onClick={this.signUpButtonPressed}
                            // onClick={()=>console.log("fr", this.state.formData)}
                        >ÜYE OL</button>
                    </div>
                </div>
                <p className="kvkk">Pazardana’a üyeyseniz <a onClick={()=>{
                    this.props.closeSignupModal();
                    this.props.openLoginModal();
                }}>giriş yapın.</a></p>
            </div>
        </Modal>);
}
}

const mapStateToProps = state => ({
    signupModal: state.settings.layout.signupModal,
    loginModal: state.settings.layout.loginModal,
});

const mapDispatchToProps = dispatch => ({
    closeSignupModal: ()=>dispatch(closeSignupModal()),
    openConfirmSignupModal: () =>dispatch(openConfirmSignupModal()),
    openLoginModal: () =>dispatch(Actions.openLoginModal()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps) (SignUp));
