import React, {useContext, useEffect} from "react";
import Context from "../../../Utils/Context";

const PriceInput = ({inCart, count, onMinus, onPlus, onAdd, product}) => {
    const context = useContext(Context);
    useEffect(()=>{
    },[context.cartTransactionLines]);
    return((inCart && count!==0) ? (<div className="size input-group" style={{background:" rgb(253, 121, 34)"}}>
                <button className="minus-item input-group-addon" disabled={count<=0} onClick={()=>onMinus()} style={{opacity: 1}}/>
                <input type="number"
                       className="item-count form-control"
                       onChange={(e)=>console.log(e.target.value)}
                       data-id="1"
                       value={product.product_unit_type === "Demet" || product.product_unit_type === "Adet" ? parseInt(count,10) : count}
                       disabled=""
                       style={{opacity: 1}}/>
                <button className="plus-item input-group-addon" onClick={()=>onPlus()}/>
            </div>):(<div className="size input-group">
                <button className="minus-item input-group-addon"/>
                <input
                    type="number"
                    value={product.product_unit_type === "Demet" || product.product_unit_type === "Adet" ? parseInt(count,10) : count}
                    className="item-count form-control"
                    onChange={(e)=>console.log(e.target.value)}
                    data-id="1"
                    disabled=""/>
                <button className="plus-item input-group-addon" onClick={()=>onAdd()}/>
            </div>)
    );
}
export default PriceInput;
