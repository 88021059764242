import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import ProfileForm from "./components/ProfileForm";

function Profile() {
    return (
        <ProfileForm/>
    );
}

export default withRouter(Profile);
