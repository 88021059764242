import React, { useContext, useEffect, useState } from "react";
import { getItem, removeItem } from "../../Utils/LocalStorage";
import { config } from "../../Utils/Config";
import file from "../../assets/images/file.svg";
import _ from "lodash";
import moment from "moment";
import PaymentModal from "./components/PaymentModal";
import Context from "../../Utils/Context";
import { toast } from "react-toastify";
import ProductDetailsModal from "../stalls/components/ProductDetailsModal";
import { Button } from "react-bootstrap";
import { CalculateLineAmount } from "../../Utils/CalculateLineAmount";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";

const OrderSummary = () => {
  const { t } = useTranslation();
  const OrderSummaryData = t("order.orderSummary", { returnObjects: true });
  const toastsData = t("toasts", { returnObjects: true });
  const pageData = {
    "entry-price": OrderSummaryData["custom-content"].find(
      (x) => x.name === "entry-price"
    )["value-single"],
    empty: OrderSummaryData["custom-content"].find((x) => x.name === "empty")[
      "value-single"
    ],
    "address-name": OrderSummaryData["custom-content"].find(
      (x) => x.name === "address-name"
    )["value-single"],
    address: OrderSummaryData["custom-content"].find(
      (x) => x.name === "address"
    )["value-single"],
    "order-time": OrderSummaryData["custom-content"].find(
      (x) => x.name === "order-time"
    )["value-single"],
    address_directions: OrderSummaryData["custom-content"].find(
      (x) => x.name === "address_directions"
    )["value-single"],
    "sum-price": OrderSummaryData["custom-content"].find(
      (x) => x.name === "sum-price"
    )["value-single"],
    "order-sum": OrderSummaryData["custom-content"].find(
      (x) => x.name === "order-sum"
    )["value-single"],
    "top-price": OrderSummaryData["custom-content"].find(
      (x) => x.name === "top-price"
    )["value-single"],
    "delivery-cost": OrderSummaryData["custom-content"].find(
      (x) => x.name === "delivery-cost"
    )["value-single"],
    "delivery-free": OrderSummaryData["custom-content"].find(
      (x) => x.name === "delivery-free"
    )["value-single"],
    "top-cost": OrderSummaryData["custom-content"].find(
      (x) => x.name === "top-cost"
    )["value-single"],
    "delivery-price": OrderSummaryData["custom-content"].find(
      (x) => x.name === "delivery-price"
    )["value-single"],
    "aggrement-1": OrderSummaryData["custom-content"].find(
      (x) => x.name === "aggrement-1"
    )["value-single"],
    "aggrement-2": OrderSummaryData["custom-content"].find(
      (x) => x.name === "aggrement-2"
    )["value-single"],
    "aggrement-3": OrderSummaryData["custom-content"].find(
      (x) => x.name === "aggrement-3"
    )["value-single"],
    "aggrement-4": OrderSummaryData["custom-content"].find(
      (x) => x.name === "aggrement-4"
    )["value-single"],
    secure: OrderSummaryData["custom-content"].find((x) => x.name === "secure")[
      "value-single"
    ],
    "discount-btn": OrderSummaryData["buttons"].find(
      (x) => x.name === "discount-btn"
    )["label"],
    continue: OrderSummaryData["buttons"].find((x) => x.name === "continue")[
      "label"
    ],
    "discount-info": OrderSummaryData["buttons"].find(
      (x) => x.name === "discount-info"
    )["label"],
    "order-note": OrderSummaryData["inputs"].find(
      (x) => x.name === "order-note"
    )["placeholder"],
    "promotion-code": OrderSummaryData["inputs"].find(
      (x) => x.name === "promotion-code"
    )["placeholder"],
    "promotion-code-error": OrderSummaryData["inputs"].find(
      (x) => x.name === "promotion-code"
    )["error"],
    "discount-amount": OrderSummaryData["custom-content"].find(
      (x) => x.name === "discount-amount"
    )["value-single"],
    "close-btn": OrderSummaryData["buttons"].find(
      (x) => x.name === "close-btn"
    )["label"],
    "go-basket": OrderSummaryData["buttons"].find(
      (x) => x.name === "go-basket"
    )["label"],
    "clear-btn": OrderSummaryData["buttons"].find(
      (x) => x.name === "clear-btn"
    )["label"],
    "top-basket": OrderSummaryData["custom-content"].find(
      (x) => x.name === "top-basket"
    )["value-single"],
    dropped: toastsData.find((x) => x.name === "dropped"),
    emptied: toastsData.find((x) => x.name === "emptied"),
  };
  const location = useLocation();
  const history = useHistory();
  const context = useContext(Context);
  const [cart, setCart] = useState([]);
  const [times, setTimes] = useState([]);
  const [validTimes, setValidTimes] = useState([]);
  const [days, setDays] = useState(context.getDays());
  const [address, setAddress] = useState(null);
  const [stall, setStall] = useState(null);
  const [orderNote, setOrderNote] = useState("");
  const [promotionCode, setPromotionCode] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [closestWeekDayType, setClosestWeekdayType] = useState(null);
  const [closestWeekdayTypeDisplay, setClosestWeekdayTypeDisplay] =
    useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [user, setUser] = useState(null);
  const [products, setProducts] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [productDetailsModal, setProductDetailsModal] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0.0);
  const [productsTotalPrice, setProductsTotalPrice] = useState(0.0);
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [deliveryTime, setDeliveryTime] = useState(null);
  const [ipAddress, setIpAddress] = useState(null);
  const [promo, setPromo] = useState(null);
  const [discountLoading, setDiscountLoading] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(null);
  const [minValue, setMinValue] = useState(0);
  const [aggrement, setAggrement] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [product_transaction_secretkey, set_product_transaction_secretkey] =
    useState(null);
  const [mobileBasket, setMobileBasket] = useState(false);
  const [transactionData, setTransactionData] = useState({
    product_transaction_buyer_user_id: null,
    product_transaction_buyer_delivery_address_id: null,
    product_transaction_buyer_delivery_address_details: null,
    product_transaction_buyer_delivery_address_information: null,
    product_transaction_buyer_delivery_address_city: null,
    product_transaction_buyer_delivery_address_county: null,
    product_transaction_buyer_delivery_address_district: null,
    product_transaction_buyer_delivery_address_neighborhood: null,
    product_transaction_buyer_delivery_address_building_number: null,
    product_transaction_buyer_delivery_address_floor_number: null,
    product_transaction_buyer_delivery_address_door_number: null,
    product_transaction_buyer_delivery_name: null,
    product_transaction_buyer_delivery_mobile_number: null,
    product_transaction_created_by_user_id: null,
    product_transaction_is_active: 1,
    product_transaction_is_deleted: 0,
    product_transaction_line_status: 0,
    product_transaction_buyer_note: "",
  });

  useEffect(() => {
    if (
      context.cartTransactionLines &&
      context.cartTransactionLines.length &&
      context.cartTransactionLines.length !== 0
    ) {
      init();
    }
  }, [
    context.cartUpdated,
    context.user,
    location,
    context.cartTransactionLines,
  ]);

  const init = async () => {
    const transactionLines = context.cartTransactionLines;
    const LAddress = JSON.parse(await getItem("address"));
    if (LAddress) {
      setAddress(LAddress);
    }
    setCart(transactionLines);
    setUser(context.user);
    await deliveryTimeCalc();
    await context.fetchCart(
      async (transaction) => {
        context.setLoading(true);
        await context.userCampaignCheck(
          transaction.product_transaction_campaign_code,
          async (data) => {
            /*this.setState({
                            products: transactionLines,
                            transaction,
                        });*/
            setProducts(transactionLines);
            setTransaction(transaction);
            context.setLoading(false);
            const totalPrice = parseFloat(
              _.replace(
                transaction.product_transaction_release_total_price_included_vat,
                ",",
                "."
              )
            ).toFixed(2);
            if (
              data.message === "campaignpactive" &&
              transaction.product_transaction_is_campaign_active === "True"
            ) {
              const discountAmount = parseFloat(
                _.replace(
                  transaction.product_transaction_seller_discount,
                  ",",
                  "."
                )
              ).toFixed(2);
              /*this.setState({
                                promo: true,
                                productsTotalPrice: parseFloat(
                                    parseFloat(totalPrice) + parseFloat(discountAmount),
                                ).toFixed(2),
                                totalPrice,
                                discountAmount,
                            });*/
              setPromo(true);
              setTotalPrice(totalPrice);
              setProductsTotalPrice(
                parseFloat(
                  parseFloat(totalPrice) + parseFloat(discountAmount)
                ).toFixed(2)
              );
              setDiscountAmount(discountAmount);
            } else {
              /*this.setState({
                                promo: false,
                                productsTotalPrice: parseFloat(totalPrice).toFixed(2),
                                totalPrice,
                                discountAmount: null,
                            });*/
              setPromo(false);
              setTotalPrice(totalPrice);
              setProductsTotalPrice(parseFloat(totalPrice).toFixed(2));
              setDiscountAmount(null);
            }
          }
        );
      },
      false,
      false
    );
  };

  const deliveryTimeCalc = async () => {
    const transactionLines = context.cartTransactionLines;
    const transaction = transactionLines[0];
    const neighborhoods =
      transaction.market_active_marketer_work_range_neighborhoods.split(",");
    const timeIntervalList =
      transaction.market_active_marketer_delivery_selected_time_interval_list.split(
        ";"
      );

    if (!timeIntervalList || timeIntervalList?.length === 0) {
      const { t } = this.props;
      const dialogTData = t("showDialogs", { returnObjects: true });
      const dialogData = dialogTData.find(
        (x) => x.name === "order-summary-stall-no-longer-active"
      );
      await context.setGlobalState("showDialog", {
        title: dialogData.title,
        message:
          transaction.market_name !== ""
            ? transaction.market_name
            : dialogData.message,
        buttons: [
          {
            text: "Tamam",
            onPress: async () => {
              context.setGlobalState("showDialog", false);
              window.location.href = "/stalls";
            },
          },
        ],
      });
      return;
    }

    const activeNeighborhood = neighborhoods.find((item) =>
      item.includes(
        transaction.product_transaction_buyer_delivery_address_neighborhood
      )
    );
    let currentTime = moment();
    const currentDay = days[moment().format("dddd")];
    const currentHour = parseInt(moment().format("HH"));

    const data = activeNeighborhood?.split("_");
    const deliverSameDay = data?.[3] === "1";

    const timesRaw = timeIntervalList;
    const times = timesRaw.map((item) => {
      let timeData = item.split(",");
      const hours = timeData[0].split("-");
      return {
        key: timeData[0],
        startTime: hours[0].trim(),
        endTime: hours[1].trim(),
        validTime: Number(timeData[1]),
      };
    });

    let validTimesCopy = [];
    let validTimes = data?.[4]?.split(";").map((item, index) => {
      const hours = item.split("-");
      const validTimeTemp = times?.find((time) => time.key === item);
      const activeHour = hours[0]?.trim().split(":")[0];
      const validHour =
        parseInt(activeHour, 10) - parseInt(validTimeTemp?.validTime, 10);
      return {
        key: item,
        canOrder: currentHour < validHour,
      };
    });
    validTimesCopy = validTimes;

    if (currentDay === transaction.market_weekday_type && deliverSameDay) {
      validTimes = validTimes?.filter((item) => item.canOrder);
    }
    if (
      validTimes?.length > 0 &&
      deliverSameDay &&
      currentDay === transaction.market_weekday_type
    ) {
      validTimes[0].selected = true;
      setDeliveryTime(validTimes[0].key);
    } else {
      validTimes = validTimesCopy;
      validTimes[0].selected = true;
      setDeliveryTime(validTimes[0].key);
      currentTime.add(1, "day").startOf("day");
    }

    /* this.setState({
             times: times,
             validTimes: validTimes,
         });*/
    setTimes(times);
    console.log("valid times", validTimes);
    setValidTimes(validTimes);

    await getClosestWeekdayType(
      transaction.market_weekday_type,
      currentTime,
      deliverSameDay
    );
    setTransaction(transaction);

    await context.select(
      "view_active_marketer_list",
      (data) => {
        if (!data) {
          return;
        }
        setStall(data[0]);
      },
      "market_active_marketer_status:1," +
        "market_active_marketer_is_deleted:0," +
        "market_is_active:1," +
        "market_is_deleted:0," +
        "market_active_marketer_market_id:" +
        transaction.product_transaction_market_id +
        ",market_active_marketer_user_id:" +
        transaction.product_transaction_seller_user_id +
        "&pgidx=0&pagelimit=1&getEncrypted=0"
    );
  };

  const onCheckPromoButtonPressed = async () => {
    setDiscountLoading(true);
    console.log("discountCode ", promotionCode);
    await context.updateCartTransaction(
      {
        product_transaction_campaign_code: promotionCode,
        product_transaction_release_total_price_included_vat: _.replace(
          parseFloat(totalPrice).toFixed(2),
          ".",
          ","
        ),
        product_transaction_is_campaign_active: 1,
      },
      (updatedProductTransaction) => {
        console.log("promo ", updatedProductTransaction);
        const dialogTData = t("showDialogs", { returnObjects: true });
        const dialogData = dialogTData.find(
          (x) => x.name === "order-summary-discount"
        );
        if (
          updatedProductTransaction.product_transaction_is_campaign_active !==
          "True"
        ) {
          setDiscountLoading(false);

          context.select(
            "view_product_sales_list",
            (data) => {
              context.setGlobalState("showDialog", {
                title: "MERHABA",
                message:
                  data[0].campaign_reply_message_passive ||
                  "Kampanya kodu geçersiz.",
                buttons: [
                  {
                    text: "TAMAM",
                    onPress: () => {
                      this.context.setGlobalState("showDialog", false);
                    },
                  },
                ],
              });
            },
            "product_transaction_id:" +
              updatedProductTransaction.product_transaction_id +
              "&pageLimit=1&getEncrypted=0"
          );
          return;
        }
        context.select(
          "view_product_sales_list",
          (data) => {
            context.setGlobalState("showDialog", {
              title: "MERHABA",
              message: data[0].campaign_reply_message_active,
              buttons: [
                {
                  text: "TAMAM",
                  onPress: () => {
                    this.context.setGlobalState("showDialog", false);
                  },
                },
              ],
            });
          },
          "product_transaction_id:" +
            updatedProductTransaction.product_transaction_id +
            "&pageLimit=1&getEncrypted=0"
        );
        const discountAmount = parseFloat(
          _.replace(
            updatedProductTransaction.product_transaction_seller_discount,
            ",",
            "."
          )
        ).toFixed(2);
        setDiscountLoading(false);
        setPromo(true);
        setTransaction(updatedProductTransaction);
        setTotalPrice(
          parseFloat(
            _.replace(
              updatedProductTransaction.product_transaction_release_total_price_included_vat,
              ",",
              "."
            )
          ).toFixed(2)
        );
        setDiscountAmount(discountAmount);
      },
      false,
      false,
      false,
      undefined,
      false
    );
  };
  const getClosestWeekdayType = (weekday_type, day) => {
    //--//--console.warn("weekday_type, day >> ", weekday_type, day);
    if (
      days[moment(day).format("dddd")] === weekday_type ||
      moment(day).format("dddd") === weekday_type
    ) {
      /*this.setState({
                closestWeekdayType: moment(day).format('YYYY-MM-DD'),
                closestWeekdayTypeDisplay: moment(day).format('DD.MM.YYYY'),
            });*/
      setClosestWeekdayType(moment(day).format("YYYY-MM-DD"));
      setClosestWeekdayTypeDisplay(moment(day).format("DD.MM.YYYY"));
    } else {
      const nextDay = moment(day).add(1, "days");
      getClosestWeekdayType(weekday_type, nextDay);
    }
  };
  const selectTime = (ttime, timeIndex) => {
    const ttimes = validTimes.map((item, idx) => {
      return {
        ...item,
        selected: timeIndex === idx,
      };
    });
    //console.log("times ", ttimes);
    setDeliveryTime(ttime.key);
    setValidTimes(ttimes);
  };
  const onPTPayWithButtonPressed = async () => {
    const updateTransactionFormData = {
      product_transaction_buyer_note: orderNote,
      product_transaction_buyer_delivery_name: user.user_name,
      product_transaction_buyer_delivery_surname: user.user_surname,
      product_transaction_buyer_delivery_mobile_number: user.user_active_gsm,
      product_transaction_delivery_time_interval: deliveryTime,
      product_transaction_ip_address: ipAddress,
      product_transaction_delivery_start_datetime: closestWeekDayType,
    };
    await context.updateCartTransaction(
      updateTransactionFormData,
      (x) => {
        context.paymentStart(
          transaction.product_transaction_secret_key,
          (data) => {
            context.setLoading(false);
            if (data.statuscode === "200") {
              /*this.setState({
                                paymentURL: data.data,
                                modalVisible: true,
                            });*/
              setPaymentUrl(data.data);
              setModalVisible(true);
            }
          }
        );
      },
      true,
      false,
      false
    );
  };
  const beforeProcessChecks = async () => {
    if (!aggrement) {
      const dialogTData = t("showDialogs", { returnObjects: true });
      const dialogData = dialogTData.find(
        (x) => x.name === "order-summary-agree"
      );
      context.setGlobalState("showDialog", {
        title: dialogData?.title,
        message: dialogData?.message,
        buttons: [
          {
            text: dialogData?.buttons[0].label,
            onPress: () => {
              context.setGlobalState("showDialog", false);
            },
          },
        ],
      });
      return;
    }
    const transactionLines = context.cartTransactionLines;
    const transaction = transactionLines[0];
    const neighborhoods =
      products[0].market_active_marketer_work_range_neighborhoods.split(",");
    const neighborhood = neighborhoods
      .find((neighborhood_item) => {
        const sn = neighborhood_item.split("_");
        if (
          sn[0] ===
            transaction.product_transaction_buyer_delivery_address_county &&
          sn[1] ===
            transaction.product_transaction_buyer_delivery_address_neighborhood
        ) {
          return true;
        }
        return false;
      })
      ?.split("_");
    if (!neighborhood) {
      return;
    }
    if (parseInt(neighborhood?.[2]) > parseInt(productsTotalPrice)) {
      context.setLoading(false);
      const dialogTData = t("showDialogs", { returnObjects: true });
      const dialogData = dialogTData.find((x) => x.name === "basket-min-price");
      context.setGlobalState("showDialog", {
        title: dialogData?.title,
        message: dialogData?.message,
        buttons: [
          {
            text: dialogData?.buttons[0].label,
            onPress: () => {
              context.setGlobalState("showDialog", false);
            },
          },
        ],
      });
      return;
    }
    context.selectStalls(
      transaction.product_transaction_buyer_delivery_address_city,
      transaction.product_transaction_buyer_delivery_address_county,
      transaction.product_transaction_buyer_delivery_address_neighborhood,
      "market_active_marketer_is_deleted:0," +
        "market_active_marketer_market_id:" +
        transaction.product_transaction_market_id +
        "," +
        "market_is_active:1," +
        "market_is_deleted:0&pageLimit=100&getEncrypted=0",
      (data) => {
        if (!data) {
          return;
        }
        if (
          data[0].is_delivery_possible === "" ||
          data[0].is_delivery_possible.length < 4
        ) {
          const dialogTData = t("showDialogs", { returnObjects: true });
          const dialogData = dialogTData.find(
            (x) => x.name === "order-summary-timeout"
          );
          context.setGlobalState("showDialog", {
            title: dialogData?.title,
            message: dialogData?.message,
            buttons: [
              {
                text: dialogData?.buttons[0].label,
                onPress: () => {
                  context.setGlobalState("showDialog", false);
                },
              },
            ],
          });
        } else {
          onPTPayWithButtonPressed();
        }
      }
    );
  };
  function updateTransactionData(key, value) {
    const f = transactionData;
    f[key] = value;
    setTransactionData(f);
  }

  const onCartDeleteButtonPressed = async (product) => {
    if (cart.length === 1) {
      clearCart();
      return;
    }
    if (!product?.isFake && !product?.isLoading) {
      const updateFormData = {
        product_transaction_line_original_piece:
          product.product_transaction_line_original_piece,
        product_transaction_line_is_active: 0,
        product_transaction_line_is_deleted: 1,
        product_transaction_line_piece: "0.0",
      };
      await context.updateItemInCart(updateFormData, product).then(() => {
        toast.success(pageData["dropped"].message, { autoClose: 1000 });
      });
    }
  };
  const toggleProductDetailsModal = (product) => {
    setProductDetailsModal(!productDetailsModal);
    setSelectedProduct(product);
  };
  const toggleMobileBasket = () => {
    setMobileBasket(!mobileBasket);
  };
  const clearCart = () => {
    const updateFormData = {
      product_transaction_line_is_active: "False",
      product_transaction_line_is_deleted: "True",
      product_transaction_line_piece: "0.0",
    };
    const dialogTData = t("showDialogs", { returnObjects: true });
    const dialogData = dialogTData.find((x) => x.name === "basket-warning");
    context.setGlobalState("showDialog", {
      title: dialogData?.title,
      message: dialogData?.message,
      buttons: [
        {
          text: dialogData.buttons.find((x) => x.name === "refuse").label,
          onPress: async () => {
            context.setGlobalState("showDialog", false);
          },
          isSecondary: true,
        },
        {
          text: dialogData.buttons.find((x) => x.name === "delete").label,
          onPress: async () => {
            await context.setGlobalState("showDialog", false);
            products.map(async (product) => {
              await context.updateItemInCart(
                {
                  ...updateFormData,
                  product_transaction_line_original_piece:
                    product.product_transaction_line_original_piece,
                },
                product,
                undefined,
                false
              );
            });
            setTimeout(() => {
              context.setCartTransactionLines([]);
              toast.success(pageData["emptied"].message, { autoClose: 1000 });
            }, 1000);
            history.push("/stalls");
          },
        },
      ],
    });
  };
  const calculateTotalPrice = (
    cartTransactionLines = context.cartTransactionLines
  ) => {
    const totalPrice = _.sumBy(
      cartTransactionLines?.filter((item) => !item?.isFake && !item?.isLoading),
      (product) => {
        return CalculateLineAmount(
          product?.product_active_seller_entry_price,
          product?.product_vat_rate,
          product?.product_transaction_line_piece
        );
      }
    );
    setTotalPrice(parseFloat(totalPrice).toFixed(2));
  };
  //TODO: We have to fix payment thing
  const handleWebViewNavigationStateChange = async (newNavState) => {
    const { url, title, didFailProvisionalNavigation } = newNavState;
    //--console.warn("newNavState >> ", newNavState);

    if (didFailProvisionalNavigation) {
      /*this.setState({
                modalVisible: false,
                message: 'Sistemsel bir hata',
            });*/
      setModalVisible(false);
      console.log("message", "Sistemsel bir hata");
    }

    if (!url) return;
    if (title === "Ödeme Başarılı") {
      /*this.setState({
                modalVisible: false,
            });*/
      setModalVisible(false);

      await removeItem("cart");
      await removeItem("address");
      await removeItem("stall");
      //--//--console.warn("product_transaction_secretkey >> ", product_transaction_secretkey);
      /*this.props.navigation.navigate('OrderSummaryDetails', {
                product_transaction_secretkey: product_transaction_secretkey,
                success: true,
            });*/
      console.log("success: redirect to Order Summary");
    }
  };

  //console.log("deliv", deliveryTime)
  return (
    <div className="order-summary-page">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-12" style={{ marginTop: "30px" }}>
            <div className="all-basket" style={{ height: "100%" }}>
              <h2>
                {pageData["top-basket"]}{" "}
                <span onClick={() => clearCart()} className="clear-basket pr-2">
                  {products && products.length && products.length > 0
                    ? pageData["clear-btn"]
                    : ""}
                </span>
              </h2>
              <h3 onClick={() => toggleMobileBasket()}>
                {mobileBasket ? pageData["close-btn"] : pageData["go-basket"]}{" "}
                <i className="mobile-basketsum">
                  {totalPrice} {pageData["entry-price"]}
                </i>
              </h3>
              <div
                className={
                  "addbasket " + (mobileBasket ? "basket-show-mobile" : "")
                }
              >
                {cart.map((product, index) => (
                  <div
                    className="basket"
                    key={index}
                    style={{ paddingBottom: 0 }}
                  >
                    <div className="container p-0">
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col-1"></div>
                        <div className="image col-4 p-2">
                          <img
                            src={
                              config.image.baseUrl + product?.product_images_url
                            }
                            alt={product.product_name}
                            className="img-fluid"
                          />
                        </div>
                        <div className="col-5 flex flex-column justify-content-center">
                          <div className="name">{product.product_name}</div>
                          <div className="size">
                            {parseFloat(
                              product?.product_transaction_line_piece
                            ).toFixed(2)}{" "}
                            {product?.product_unit_type}
                          </div>
                          <div className="price">
                            {CalculateLineAmount(
                              product?.product_active_seller_entry_price,
                              product?.product_vat_rate,
                              product?.product_transaction_line_piece
                            ).toFixed(2)}{" "}
                            {pageData["top-price"]}
                          </div>
                        </div>
                        <div
                          className="col-2"
                          style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        >
                          <div
                            className="edit"
                            onClick={() => toggleProductDetailsModal(product)}
                          />
                          <div
                            data-name={product.product_name}
                            onClick={() => onCartDeleteButtonPressed(product)}
                            className="trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {isEmpty && (
                <div className="empty-products mt-5">
                  <img src={file} className="img-fluid" alt={"Empty"} />
                  <p>{pageData["empty"]}</p>
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-8 summary">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="box orders col-12">
                  <div className="address-summary col-12">
                    <h2>{pageData["address-name"]}</h2>
                    <p className="name">
                      {
                        transaction?.product_transaction_buyer_delivery_address_title
                      }
                    </p>
                    <div className="border-line" />
                    <h2>{pageData["address"]}</h2>
                    <p className="detail">
                      {
                        transaction?.product_transaction_buyer_delivery_address_full_address
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="box orders">
                  <div className="address-summary col-12">
                    <div className="row">
                      <div className="col-12">
                        <h2>{pageData["address_directions"]}</h2>
                        <p className="name">
                          {
                            transaction?.product_transaction_buyer_delivery_address_details
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="box orders">
                  <div className="address-summary col-12">
                    <div className="row">
                      <div className="col-12">
                        <h2 className="order-date">
                          {closestWeekdayTypeDisplay}
                        </h2>
                        <p>{stall?.market_weekday_type}</p>
                        <div className="border-line" />
                        <p className="order-time">{pageData["order-time"]}.</p>
                        <div className="row radio-btns">
                          {validTimes.map((vTime, index) => (
                            <div key={index} className="form-group col">
                              <div className="form-check">
                                {/*<input className="form-radio-input" value={vTime.key} checked={vTime.selected} onChange={()=>selectTime(vTime, index)}  name={"time"} type="radio"/>*/}
                                <input
                                  type="radio"
                                  className="form-radio-input"
                                  value={vTime.key || false}
                                  checked={!!vTime.selected}
                                  id={vTime.key}
                                  onChange={() => console.log("check")}
                                  onClick={() => selectTime(vTime, index)}
                                  name={"time"}
                                />
                                <label
                                  className="form-radio-label basket-conditions"
                                  htmlFor={vTime.key}
                                >
                                  {vTime.key}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="box orders flex">
                  <div
                    className="address-summary col-12"
                    style={{ marginBottom: "40px" }}
                  >
                    <div className="row">
                      <div className="col-12 form-group order-note">
                        <h2>{pageData["order-note"]}</h2>
                        <textarea
                          className="form-control p-0"
                          value={orderNote}
                          style={{ border: "none" }}
                          onChange={(e) => setOrderNote(e.target.value)}
                          placeholder={"Sipariş notunuzu ekleyin"}
                          id="exampleFormControlTextarea1"
                          rows="5"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="address-summary col-12">
                <div className="box promotion">
                  <div className="form-row">
                    <div className="col-md-12">
                      <div className="form-group flex flex-lg-row flex-column">
                        <div className="col-lg-9 col-12">
                          <h2>{pageData["promotion-code"]}</h2>
                          <input
                            className="form-control"
                            value={promotionCode}
                            disabled={discountLoading}
                            style={{
                              border: "1px solid #90b52a",
                              borderRadius: "15px",
                              color: "#90b52a",
                            }}
                            onChange={(e) => setPromotionCode(e.target.value)}
                            placeholder={"Kodu buraya yazınız"}
                            id="exampleFormControlTextarea1"
                          />
                        </div>
                        <div
                          className="col-lg-3 text-right col-12"
                          style={{ marginTop: "30px", marginBottom: "auto" }}
                        >
                          <Button
                            disabled={discountLoading}
                            onClick={
                              discountLoading ? null : onCheckPromoButtonPressed
                            }
                            className="btn btn-primary col-lg-12"
                          >
                            {discountLoading
                              ? pageData["discount-info"]
                              : pageData["discount-btn"]}
                          </Button>
                        </div>
                        <div className="invalid-feedback">
                          {pageData["promotion-code-error"]}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="box orders ">
                  <div className="order-summary col-12">
                    <h1>{pageData["order-sum"]}</h1>
                    <div className="row">
                      <div className="sum col">{pageData["sum-price"]}</div>
                      <div className="price col">
                        {parseFloat(productsTotalPrice).toFixed(2)}{" "}
                        {pageData["top-price"]}
                      </div>
                    </div>
                    <div className="row">
                      <div className="sum col">{pageData["delivery-cost"]}</div>
                      <div className="price col">
                        <span className="cargo-free">
                          {pageData["delivery-free"]}
                        </span>
                        <span className="cargo-fee">
                          {parseFloat(
                            transaction?.product_transaction_delivery_price
                          ).toFixed(2)}{" "}
                          TL
                        </span>
                      </div>
                    </div>
                    {discountAmount > 0 && (
                      <div className="row">
                        <div className="sum col">
                          {pageData["discount-amount"]}
                        </div>
                        <div className="price col">
                          {parseFloat(discountAmount).toFixed(2)} TL
                        </div>
                      </div>
                    )}
                    <div className="border-line" />
                    <div className="row mt-3">
                      <div className="total col">{pageData["top-cost"]}</div>
                      <div className="total-price col">
                        {parseFloat(totalPrice).toFixed(2)} TL
                      </div>
                    </div>
                    <div className="border-line" />
                    <div className="row mt-3 basket-info">
                      <div className="col-lg-8 col-12">
                        <div className="form-group form-check">
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              checked={aggrement}
                              onChange={(e) => setAggrement(e.target.checked)}
                              type="checkbox"
                              id="conditions"
                              required
                            />
                            <label
                              className="form-check-label basket-conditions"
                              htmlFor="conditions"
                            >
                              <span
                                onClick={async () => {
                                  context.showAggrement({
                                    type: "MesafeliSatis",
                                    buttons: [
                                      {
                                        text: "TAMAM",
                                        onPress: () => {
                                          context.setGlobalState(
                                            "showAggrementDialog",
                                            false
                                          );
                                        },
                                      },
                                    ],
                                    params: {
                                      user: user,
                                      products: products,
                                      stall: stall,
                                      address: address,
                                      deliveryTime:
                                        closestWeekDayType + " " + deliveryTime,
                                      transaction: transaction,
                                      totalPrice: totalPrice,
                                      userData: JSON.parse(
                                        await getItem("userData")
                                      ),
                                    },
                                  });
                                }}
                              >
                                {pageData["aggrement-1"]}
                              </span>{" "}
                              {pageData["aggrement-2"]}{" "}
                              <span
                                onClick={async () => {
                                  context.showAggrement({
                                    type: "MesafeliSatis",
                                    buttons: [
                                      {
                                        text: "TAMAM",
                                        onPress: () => {
                                          context.setGlobalState(
                                            "showAggrementDialog",
                                            false
                                          );
                                        },
                                      },
                                    ],
                                    params: {
                                      user: user,
                                      products: products,
                                      stall: stall,
                                      address: address,
                                      deliveryTime:
                                        closestWeekDayType + " " + deliveryTime,
                                      transaction: transaction,
                                      totalPrice: totalPrice,
                                      userData: JSON.parse(
                                        await getItem("userData")
                                      ),
                                    },
                                  });
                                }}
                              >
                                {pageData["aggrement-3"]}
                              </span>{" "}
                              {pageData["aggrement-4"]}
                            </label>
                          </div>
                        </div>
                        <p className="secure-shopping">{pageData["secure"]}</p>
                      </div>
                      <div className="col-lg-4 text-right col-12">
                        <a
                          onClick={() => beforeProcessChecks()}
                          className="btn btn-secondary col-lg-12"
                        >
                          {pageData["continue"]}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentModal
          open={modalVisible}
          toggle={setModalVisible}
          paymentUrl={paymentUrl}
        />
        {selectedProduct && (
          <ProductDetailsModal
            open={productDetailsModal}
            location={"summary"}
            toggle={toggleProductDetailsModal}
            setSelectedProduct={setSelectedProduct}
            setUpdated={setUpdated}
            updated={updated}
            product={selectedProduct}
            stall={stall}
          />
        )}
      </div>
    </div>
  );
};
export default OrderSummary;
