import React from 'react';
import {withRouter} from "react-router";
import SupportForm from "./components/SupportForm";


function Support() {
    return (
            <SupportForm />
    );
}

export default withRouter(Support);
