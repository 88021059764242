import React from "react";
import appleStore from "../assets/images/apple-store.svg";
import googlePlay from "../assets/images/google-play.svg";
import facebook from "../assets/images/facebook.svg";
import instagram from "../assets/images/instagram.svg";
import {Link, useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";


const Footer = () => {
    const { t } = useTranslation();
    const footerData = t('modals.footer', { returnObjects: true })
    const pageData = {
        "download": footerData['custom-content'].find(x => x.name === 'download')['value-single'],
        "copyright": footerData['custom-content'].find(x => x.name === 'copyright')['value-single'],
        "cookies": footerData.buttons.find(x => x.name === 'cookies'),
        "personal": footerData.buttons.find(x => x.name === 'personal-info'),
        "kvkk": footerData.buttons.find(x => x.name === 'kvkk'),
        "contract": footerData.buttons.find(x => x.name === 'contract'),
        "faq": footerData.buttons.find(x => x.name === 'faq'),
        "seller": footerData.buttons.find(x => x.name === 'become-seller'),
    }
    const history = useHistory();
    return(
        <><footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12  mb-3 flex align-content-lg-start align-items-center ">
                        <p className="align-self-lg-start align-self-center">{ pageData['download']}</p>
                    </div>
                    <div className="col-lg-3 col-12 flex-column flex align-items-start download-icons-mobile">
                        <a href="https://apps.apple.com/tr/app/pazardan/id1541579666?l=tr" target="_blank" className="d-block" rel="noreferrer">
                            <img src={appleStore} className="mb-3 img-fluid" alt="Apple Store"/>
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.pazardan&gl=TR" target="_blank" className="d-block" rel="noreferrer">
                            <img src={googlePlay} className="img-fluid" alt="Google Play"/>
                        </a>
                    </div>
                    <div className="col-lg-3 col-12 flex justify-content-center mt-2">
                        <ul className="footer-menu">
                            <li onClick={()=>history.push("/cookies")} style={{color: "#46742C", cursor: "pointer"}}>{ pageData['cookies'].label}</li>
                            <li onClick={()=>history.push("/clarification-text")} style={{color: "#46742C", cursor: "pointer"}}>{ pageData['personal'].label}</li>
                            <li onClick={()=>history.push("/kvkk")} style={{color: "#46742C", cursor: "pointer"}}>{ pageData['kvkk'].label}</li>
                            <li onClick={()=>history.push("/user-contract")} style={{color: "#46742C", cursor: "pointer"}}>{ pageData['contract'].label}</li>
                            <li onClick={()=>history.push("/sss-footer")} style={{color: "#46742C", cursor: "pointer"}}>{ pageData['faq'].label}</li>

                        </ul>
                    </div>
                    <div className="col-lg-6 col-12 flex flex-column align-items-end">
                        <ul className="socials mb-5">
                            <li>
                                <a href="#" target="_blank">
                                    <img src={facebook} alt={"facebook"}/>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/pazardan_app" target="_blank" rel="noreferrer">
                                    <img src={instagram} alt={"instagram"}/>
                                </a>
                            </li>
                        </ul>
                        <div className="seller-btn">
                            <Link to={"/become-seller"} className={"btn btn-secondary"}>{ pageData['seller'].label}</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
            <div className="copyright pb-1">
                <p>{ pageData['copyright']}</p>
            </div>
        </>
    );
}

export default Footer;
