import React, {useEffect, useState} from "react";
import Context from "../Utils/Context";
import "./MyrodersDrawer.css";
import OrderDetailsModal from "../containers/myOrders/components/OrderDetailsModal";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const MyOrdersDrawer = () => {
    const { t } = useTranslation();
    const drawerData = t('modals.myOrdersDrawer', { returnObjects: true })
    const pageData = {
        "text-1": drawerData['custom-content'].find(x => x.name === 'orders-drawer-text-1')['value-single'],
        "text-2": drawerData['custom-content'].find(x => x.name === 'orders-drawer-text-2')['value-single'],
        "cost": drawerData['custom-content'].find(x => x.name === 'drawer-cost')['value-single'],
        "info": drawerData['custom-content'].find(x => x.name === 'drawer-info')['value-single'],
        "again": drawerData.buttons.find(x => x.name === 'again'),
    }

    const context = React.useContext(Context);
    const Ref = React.createRef();
    const [orders, setOrders] = useState([]);
    const [scrollAmount, setScrollAmount] = useState(110);
    const [showContent, setShowContent] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [selected, setSelected] = useState(null);
    const [orderDetailsModal, setOrderDetailsModal] = useState(false);


    useEffect(() => {
        function handleResize() {
            if(window.innerWidth < 400) {
                setScrollAmount(window.innerWidth - 100);
            }else if(window.innerWidth < 801) {
                setScrollAmount(297);
            }else if(window.innerWidth < 1201){
                setScrollAmount(295);
            }else {
                setScrollAmount(335);
            }

            if(window.innerWidth < 992){
                setMobile(true)
            }else{
                setMobile(false);
            }
            }
        window.addEventListener('resize', handleResize)
    });

    function scrollNext(ref, amountScroll){
        console.log("scr",amountScroll)
        ref.current.scrollBy(amountScroll, 0, "smooth");
    }

    const toggleClass = async () => {
        if (!document.getElementById("last-orders-container").classList.contains("d-block")) {
            setShowContent(true);
            await fetchData();
        }else{
            setShowContent(false);
        }
        document.getElementById("last-orders-container").classList.toggle("d-block");
    }
    const fetchData = async () => {
        await context.select(
            'product_transaction',
            (data) => {
                setOrders(data ? data : []);
            },
            'product_transaction_payment_status:3&pageLimit=10&orderparameters=product_transaction_id:desc',
        );
        return false;
    };
    const toggleOrderDetailsModal = (order) => {
        setSelected(order);
        setOrderDetailsModal(!orderDetailsModal);
    }

    console.log("rr", orders)



    return (<div className="last-orders d-none d-sm-block">
        <div className="container">
            <div className="no-gutters p-0 row">
                <div onClick={()=>toggleClass()} className={showContent ? "col-lg-2 d-flex justify-content-around order-label showContent" :
                    "col-lg-2 d-flex justify-content-around order-label" } >
                    <span>{pageData['text-1']} <br />{ pageData['text-2']}</span>
                </div>
                <div className="col-lg-10 col-12">
                    <div id={"last-orders-container"} className="last-orders-container horizontal-scrollable">
                        <div className="slick-prev" onClick={()=>scrollNext(Ref, -scrollAmount)}/>
                        <div className="slick-next" onClick={()=>scrollNext(Ref, scrollAmount)}/>
                        <div className="last-orders-inner" ref={Ref}>
                        {orders && orders.length && orders.length > 0 && orders.filter(order => order.product_transaction_delivery_status!=="2").length > 0
                            ? (orders.filter(order => order.product_transaction_delivery_status!=="2").map((order, index)=>(
                                <div key={index} className="item col-3 d-flex flex-column justify-content-between">
                                    <div className="name">{order.product_transaction_buyer_delivery_address_title}</div>
                                    <div className="address">{order.product_transaction_buyer_delivery_address_county} / {order.product_transaction_buyer_delivery_address_neighborhood}</div>
                                    <div className="total">{parseFloat(_.replace(order?.product_transaction_release_total_price_included_vat, ',', '.')).toFixed(2)} {pageData['cost'] }</div>
                                    <a onClick={() => toggleOrderDetailsModal(order)} className="btn-sm btn-secondary" style={{ borderRadius: "0.4rem" }}>
                                        {pageData['again'].label}
                                    </a>
                                </div>
                            )
                        )) :
                        null}

                            {orders.length === 0 && (<div className="item col d-flex flex-column justify-content-between" style={{height:"165px"}}>
                                <div className="name mt-auto mb-auto">{ pageData['info']}</div>
                        </div>)}
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {selected && (<OrderDetailsModal open={orderDetailsModal} order={selected} toggle={toggleOrderDetailsModal}/>)}
    </div>);
}
export default MyOrdersDrawer;
