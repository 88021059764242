import React, {useContext, useEffect, useState} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import Context from "../../../Utils/Context";
import moment from "moment";
import {config} from "../../../Utils/Config";
import _ from "lodash";
import {removeItem, setItem} from "../../../Utils/LocalStorage";
import {useHistory} from "react-router";
import { useTranslation } from "react-i18next";

const OrderDetailsModal = ({order, open, toggle}) => {
    const { t } = useTranslation()
    const ModalData = t('myOrders.OrderDetails', {returnObjects: true})
    const pageData = {
        price: ModalData['custom-content'].find(x => x.name === 'price')['value-single'],
        'order-sum':ModalData['custom-content'].find(x => x.name === 'order-sum')['value-single'],
        'sum-price': ModalData['custom-content'].find(x => x.name === 'sum-price')['value-single'],
        'order-number':ModalData['custom-content'].find(x => x.name === 'order-number')['value-single'],
        'top-price':ModalData['custom-content'].find(x => x.name === 'top-price')['value-single'],
        'delivery-cost':ModalData['custom-content'].find(x => x.name === 'delivery-cost')['value-single'],
        'delivery-free':ModalData['custom-content'].find(x => x.name === 'delivery-free')['value-single'],
        'top-cost':ModalData['custom-content'].find(x => x.name === 'top-cost')['value-single'],
        'address-name':ModalData['custom-content'].find(x => x.name === 'address-name')['value-single'],
        'address': ModalData['custom-content'].find(x => x.name === 'address')['value-single'],
        'delivery-time':ModalData['custom-content'].find(x => x.name === 'delivery-time')['value-single'],
        'delivery-note': ModalData['custom-content'].find(x => x.name === 'delivery-note')['value-single'],
        'repeat': ModalData['buttons'].find(x => x.name === 'repeat')['label'],
    }
    const context = useContext(Context);
    const history = useHistory();
    const [transaction, setTransaction] = useState({});
    const [transactionLines, setTransactionLines] = useState([]);

    useEffect(() => {
        context.select('view_product_sales_list', (data) => {
            //--console.warn("view_product_sales_list >> data >> ", data);
            setTransaction(data[0]);
            setTransactionLines(data);
        }, 'product_transaction_id:' + order.product_transaction_id + '&pageLimit=999999&getEncrypted=0');
    }, [order.product_transaction_id]);

    console.log("transaction ", transaction);
    const onRepeatPress = async () => {
        await context.startBuyerSameSales(
           transaction.product_transaction_secret_key,
            (response) => {

               console.log("resss", response);

                if (!response) {
                    return;
                }
                switch (response.data) {
                    case 'address_is_not_active':
                        const dialogT5Data = t("showDialogs", { returnObjects: true });
                        const dialogData5 = dialogT5Data.find(
                              (x) => x.name === "order-details-status-error"
                            );
                            context.setGlobalState("showDialog", {
                              title: dialogData5?.title,
                              message: dialogData5?.message,
                              buttons: [
                                {
                                  text: dialogData5?.buttons[0].label,
                                  onPress: () => {
                                    context.setGlobalState("showDialog", false);
                                  },
                                },
                              ],
                            });
                        return;
                    case 'address_returnstatus_error':
                        const dialogT4Data = t("showDialogs", { returnObjects: true });
                        const dialogData4 = dialogT4Data.find(
                              (x) => x.name === "order-details-status-error"
                            );
                            context.setGlobalState("showDialog", {
                              title: dialogData4?.title,
                              message: dialogData4?.message,
                              buttons: [
                                {
                                  text: dialogData4?.buttons[0].label,
                                  onPress: () => {
                                    context.setGlobalState("showDialog", false);
                                  },
                                },
                              ],
                            });
                        return;
                    case 'active_marketer_returnstatus_error':
                        const dialogT2Data = t("showDialogs", { returnObjects: true });
                        const dialogData2 = dialogT2Data.find(
                              (x) => x.name === "order-details-marketer-not-active"
                            );
                            context.setGlobalState("showDialog", {
                              title: dialogData2?.title,
                              message: dialogData2?.message,
                              buttons: [
                                {
                                  text: dialogData2?.buttons[0].label,
                                  onPress: () => {
                                    context.setGlobalState("showDialog", false);
                                  },
                                },
                              ],
                            });
                        return;
                    case 'active_marketer_is_not_active':
                        const dialogT3Data = t("showDialogs", { returnObjects: true });
                        const dialogData3 = dialogT3Data.find(
                              (x) => x.name === "order-details-marketer-not-active"
                            );
                            context.setGlobalState("showDialog", {
                              title: dialogData3?.title,
                              message: dialogData3?.message,
                              buttons: [
                                {
                                  text: dialogData3?.buttons[0].label,
                                  onPress: () => {
                                    context.setGlobalState("showDialog", false);
                                  },
                                },
                              ],
                            });
                        return;
                    default:
                        const data = JSON.parse(response)?.data;
                        const transaction = data?.[0] ?? {};
                        const neighborhoods = transaction.market_active_marketer_work_range_neighborhoods?.split(
                            ',',
                        );
                        console.log("neighborhoods ", neighborhoods);

                        let isError = !neighborhoods || (neighborhoods.length  && neighborhoods.length=== 1 && neighborhoods[0] === '');
                        const activeNeighborhood = neighborhoods?.find((item) =>
                            item.includes(
                                transaction.product_transaction_buyer_delivery_address_neighborhood,
                            ),
                        );
                        isError = isError || activeNeighborhood?.length === 0;
                        if (!isError) {
                            const activeNeighborhoodData = activeNeighborhood.split('_');

                            const timesRaw = activeNeighborhoodData?.[activeNeighborhoodData.length - 1]?.split(';');
                            isError = !timesRaw;
                            if (timesRaw?.length > 0) {
                                let timeData = timesRaw[0].split(',');
                                const hours = timeData[0].split('-');
                                if (hours.length === 0 || hours?.[0] === '') {
                                    isError = true;
                                } else {
                                    isError = false;
                                }
                            }
                        }
                        if (isError) {
                            context.updateCartTransaction(
                                {
                                    'product_transaction_is_active': 0,
                                    'product_transaction_is_deleted': 1,
                                },
                                undefined,
                                false,
                                false,
                                true,
                                transaction,
                            );
                            toggle(order);
                            const dialogTData = t("showDialogs", { returnObjects: true });
                            const dialogData = dialogTData.find(
                              (x) => x.name === "order-details-address-too-far"
                            );
                            context.setGlobalState("showDialog", {
                              title: dialogData?.title,
                              message: dialogData?.message,
                              buttons: [
                                {
                                  text: dialogData?.buttons[0].label,
                                  onPress: () => {
                                    context.setGlobalState("showDialog", false);
                                  },
                                },
                              ],
                            });
                            return;
                        }
                        const dialogTData = t("showDialogs", { returnObjects: true });
                        const dialogData = dialogTData.find(
                          (x) => x.name === "order-details-time-diff"
                        );
                        context.setGlobalState('showDialog', {
                            title: dialogData?.title,
                            message: dialogData?.message,
                            buttons: [
                                {
                                    text: dialogData?.buttons.find(x => x.name === 'go-stall').label,
                                    onPress: () => {
                                        //this.props.navigation.navigate('Cart');
                                        console.log("goto cart");
                                        history.push('/order-summary');
                                        context.setGlobalState(
                                            'showDialog',
                                            false,
                                        );
                                    },
                                },
                                {
                                    text: dialogData?.buttons.find(x => x.name === 'close').label,
                                    isSecondary: true,
                                    onPress: () => {
                                        context.setGlobalState(
                                            'showDialog',
                                            false,
                                        );
                                        toggle(order);
                                    },
                                },
                            ],
                        });
                        context.setCartTransactionLines(data ?? []);
                        context.setCartTransaction(data?.[0]);
                        break;
                }
            },
        );
    };
    if (context.loading){
        return null;
    }

    return (<Modal show={open} id="myOrders" centered>
        <div className="close-modal" onClick={()=>toggle(order)} data-dismiss="modal"><i/></div>
        <ModalBody>
            <div className="row">
                <div className="col-lg-5 col-12">
                    <div className="row">
                        <div className="order-basket">
                            <h2>File </h2>
                            <div className="addbasket" style={{maxHeight:"75vh"}}>
                                {transactionLines.map((line, index) => (
                                    <div className="basket" key={index}>
                                        <div className="container p-0">
                                            <div className="row no-gutters">
                                                <div className="image col-4"><img src={config.image.baseUrl +line.product_images_url} alt={line.product_name} className="img-fluid"/>
                                                </div>
                                                <div className="col-6">
                                                    <div className="name">{line.product_name}</div>
                                                    <div className="size">{parseFloat(_.replace(line?.product_transaction_line_piece, ',', '.')).toFixed(2)} {line.product_transaction_line_unit_type}</div>
                                                    <div className="price">
                                                        {(parseFloat(_.replace(line?.product_transaction_line_release_price, ',', '.')) * parseFloat(_.replace(line?.product_transaction_line_piece, ',', '.'))).toFixed(2)} { pageData['price'] }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className={"col"}>
                            <a onClick={async () => await onRepeatPress()} className="btn btn-secondary btn-block">{pageData['repeat']}</a>
                        </div>
                    </div>
                </div>
                <div className="order-summary col-lg-7 col-12">
                    <h1>Sipariş Özeti</h1>
                    <div className="row">
                        <div className="sum col order-number-tag">{ pageData['order-number']}</div>
                        <div className="price col order-number">{transaction?.product_transaction_id}</div>
                        <div className="border-line col-11"/>
                    </div>

                    <div className="row">
                        <div className="sum col">{ pageData['sum-price']}</div>
                        <div
                            className="price col">{parseFloat(_.replace(transaction?.product_transaction_seller_earned_price, ',', '.')).toFixed(2)} TL
                        </div>
                    </div>
                    <div className="row">
                        <div className="sum col">{pageData['delivery-cost']}</div>
                        <div className="price col"><span className="cargo-free">{pageData['delivery-free']}</span><span
                            className="cargo-fee">{pageData['delivery-price']}</span>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="total col">{pageData['top-cost']}</div>
                        <div
                            className="total-price col">{parseFloat(_.replace(transaction?.product_transaction_release_total_price_included_vat, ',', '.')).toFixed(2)} TL
                        </div>
                    </div>
                    <div className="address-summary">
                        <div className="row">
                            <h2 className="col-12">{pageData['address-name']}</h2>
                            <p className="col-12 name">{transaction?.product_transaction_buyer_delivery_address_title}</p>
                            <h2 className="col-12">{pageData['address']}</h2>
                            <p className="col-12 detail">{transaction?.product_transaction_buyer_delivery_address_full_address}</p>
                            <div className="border-line col-11"/>
                            <h2 className="col-12">{pageData['delivery-time']}</h2>
                            <p className="col-12 delivery-detail">{moment(transaction?.product_transaction_delivery_start_datetime, 'DD.MM.YYYY HH:ii:ss').format('DD.MM.YYYY')} {transaction?.product_transaction_weekday_type}, {transaction?.product_transaction_delivery_time_interval}</p>
                            <div className="border-line col-11"/>
                            <h2 className="col-12">{pageData['delivery-note']}</h2>
                            <p className="delivery-note col-12">{transaction?.product_transaction_buyer_note}</p>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBody>
    </Modal>);
}
export default OrderDetailsModal;
