import React, {useContext, useState} from "react";
import * as Actions from "../store/actions";
import {Modal, ModalBody} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import Context from "../Utils/Context";
import {getItem} from "../Utils/LocalStorage";
import { useTranslation } from "react-i18next";

const NewPassConfirm = () => {
    const { t } = useTranslation();
    const newPassData = t('modals.newPassConfirm', { returnObjects: true })
    const pageData = {
        "title": newPassData['custom-content'].find(x => x.name === 'pass-confirm')['value-single'],
        "activation": newPassData.inputs.find(x => x.name === 'activation-code'),
        "pass": newPassData.inputs.find(x => x.name === 'new-pass'),
        "again": newPassData.inputs.find(x => x.name === 'new-pass-again'),
        "send": newPassData.buttons.find(x => x.name === 'new-pass'),
    }

    const context = useContext(Context);
    const {newPassModal} = useSelector(state => state.settings.layout);
    const [password, setPassword]=useState("");
    const [user_active_gsm_validation_code, set_user_active_gsm_validation_code]=useState("");
    const [password2, setPassword2]=useState("");
    const dispatch = useDispatch();

    const onChangePasswordButtonPressed = async () => {
        let isValid = true;
        let userGSM = await getItem("user_active_gsm");
        if (password !== password2 || user_active_gsm_validation_code.length < 5 || password.length < 5 || !userGSM || userGSM.length < 5) {
            isValid = false;
        }

        console.log(".formData >> ", userGSM, user_active_gsm_validation_code, password, password2);

        if (isValid) {
            context.passwordCheckValidationCode(
                {
                    user_active_gsm: userGSM,
                    user_active_gsm_validation_code: user_active_gsm_validation_code,
                    user_password: password,
                    user_password_again: password2,
                },
                async (response) => {

                    console.log("passwordCheckValidationCode >> response >> ", response);
                    if (response?.statuscode === '401') {
                        const dialogTData = t('showDialogs', { returnObjects: true })
                        const dialogData = dialogTData.find(x => x.name === 'new-pass-confirm')
                        context.setGlobalState('showDialog', {
                            title: dialogData?.title,
                            message: dialogData?.message,
                            buttons: [
                                {
                                    text: dialogData?.buttons[0].label,
                                    onPress: () => {
                                        context.setGlobalState('showDialog', false);
                                    },
                                },
                            ],
                        });
                        return;
                    }
                    const loginType = await getItem('loginType');
                    if (loginType === 'user' || loginType === 'enduser') {
                        //this.props.navigation.navigate('Home');
                        window.location.href = '/';
                    } else if (loginType === 'clerk') {
                        //this.props.navigation.navigate('ClerkHome');
                        window.location.href = '/';
                    } else {
                        //this.props.navigation.navigate('Home');
                        window.location.href = '/';
                    }
                },
            );
        }
    };


    console.log("afdda", user_active_gsm_validation_code)
    return (
        <Modal show={newPassModal} id={"newpass"}>
        <div onClick={() => {
            dispatch(Actions.closeNewPassModal())
        }} className="close-modal" data-dismiss="modal"><i/></div>
        <ModalBody>
                <h2>{ pageData['title']}</h2>
            <form id="newpass-form" autoComplete="off" className="needs-validation" noValidate>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="text" value={user_active_gsm_validation_code} onChange={(e)=>set_user_active_gsm_validation_code(e.target.value)}
                                className="form-control"
                                    autoComplete="new-code"

                                   placeholder={ pageData['activation'].placeholder}  required/>
                                <label htmlFor="validation_code">{ pageData['activation'].label}</label>
                            <div className="invalid-feedback">
                                { pageData['activation'].error}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" autoComplete="new-password" id="password"
                                   placeholder={ pageData['pass'].placeholder} required/>
                            <label htmlFor="password">{ pageData['pass'].label}</label>
                            <div className="invalid-feedback">
                                { pageData['pass'].error}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <input type="password" className="form-control" autoComplete="new-password" value={password2} onChange={(e)=>setPassword2(e.target.value)} id="password2"
                                   placeholder={ pageData['again'].placeholder} required/>
                            <label htmlFor="password2">{ pageData['again'].label}</label>
                            <div className="invalid-feedback">
                                { pageData['again'].error}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-12 p-0 m-auto">
                    <button type="submit" onClick={async (e) => {
                        e.preventDefault();
                        await onChangePasswordButtonPressed();
                    }} className="btn btn-secondary w-100">{ pageData['send'].label}</button>
                </div>
            </form>
        </ModalBody>
    </Modal>);
}

export default NewPassConfirm;
