import React, {useContext, useEffect} from 'react';
import NewToolbar from "./NewToolBar";
import {useDispatch, useSelector} from "react-redux";
import {withRouter} from "react-router";
import {setMessage} from "../store/actions";
import AOS from 'aos';
import "./toolbar.css";
import "../assets/css/bootstrap.css";
import "../assets/css/slick.css";
import "../assets/css/aos.css";
import "../assets/css/style.css";
import 'mapbox-gl/dist/mapbox-gl.css';
import Footer from "./Footer";
import Login from "./Login";
import Signup from "./Signup";
import ForgotPass from "./ForgotPass";
import NewPassConfirm from "./NewPassConfirm";
import Sidebar from "../containers/profile/components/Sidebar";
import SubHeader from "../containers/profile/components/SubHeader";
import Dialog from "./Dialog";
import Context from "../Utils/Context";
import { ToastContainer } from 'react-toastify';
import ConfirmSignup from "./ConfirmSignup";
import {useHistory} from "react-router-dom";
import LoginAlert from "./LoginAlert";
import AgreementDialog from "./AgreementDialog";
import MyOrdersDrawer from "./MyOrdersDrawer";
import AddressSelectModal from "./AddressSelectModal";
import AddAddressModal from "./AddAddressModal";


function App(props) {

    AOS.init();
    const dispatch = useDispatch();
    const {name, toolbar, children} = props;
    const {message} = useSelector(state => state.settings.layout);
    const {newPassModal} = useSelector(state => state.settings.layout);


    const context = useContext(Context);

    useEffect(() => {
        // dispatch(setLoading(true));
        if (message) {
            clearMessage();
        }
    }, []);

    function clearMessage() {
        setTimeout(() => {
            dispatch(setMessage(null, "success"))
        }, 3000)
    }

    const history = useHistory();

    return (
        <div style={{minHeight: "100vh", display:"flex", flexDirection:"column"}}>
            {toolbar && (<NewToolbar auth={!!context.user}/>)}
            <Dialog/>
            <AgreementDialog/>
            {<main style={{flex: 1}}>
                    {toolbar && (<div/>)}
                {!!context.user
                && name !== "Anasayfa"
                && name !== "Satıcı Olmak İstiyorum"
                && name !== "Tezgahlar"
                && name !== "Sipariş Özeti"
                && name !== "Teşekkürler"
                && name !== "Üzgünüz"
                && name !== "GİZLİLİK SÖZLEŞMESİ"
                && name !== "AYDINLATMA METNİ"
                && name !== "KVKK"
                && name !== "KULLANICI SÖZLEŞMESİ"
                && name !== "SIK SORULAN SORULAR"
                && name !== "BLOG"
                && name !== "BLOGLAR"
                    ? (<>
                    <SubHeader name={name}/>
                        <div className="profile-page">
                            <div className="container">
                                <div className="row">
                                     <Sidebar/>
                                    {children}
                                </div>
                            </div>
                        </div>
                    </>
                ):(
                    <div>
                        {!!context.user && (name === "Anasayfa" || name === "Tezgahlar")  ?
                            <SubHeader name={name}/> :
                          !context.user && (name === "Tezgahlar") ?
                              <SubHeader name={name}/> : null
                        }
                        {children}
                    </div>
                )}
                </main>}
             <Footer/>
            <Login/>
            <LoginAlert/>
            <Signup/>
            <ConfirmSignup />
            <ForgotPass/>
            {context.user && <AddressSelectModal/>}
            {context.user && <AddAddressModal/>}
            <NewPassConfirm/> 
            <ToastContainer/>

            {!!context.user && ( name === "Anasayfa" || name === "Tezgahlar" ) ?

            <MyOrdersDrawer/> : null
            }
            {/*{ loginModal && <div className="modal-backdrop fade show"/>}*/}
        </div>
    );
}

export default withRouter(App);
