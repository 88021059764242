import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router";
import { Dropdown } from "react-bootstrap";
import Context from "../../../Utils/Context";
import {
  checkItem,
  getItem,
  removeItem,
  setItem,
} from "../../../Utils/LocalStorage";
import Stalls from "../../stalls/Stalls";
import moment from "moment";
import _ from "lodash";
import img3 from "../../../assets/icon3.png";
import { CalculateLineAmount } from "../../../Utils/CalculateLineAmount";
import { useTranslation } from "react-i18next";

function SubHeader({ name, history }) {
  const { t } = useTranslation();
  const SubHeaderData = t("profile.SubHeader", { returnObjects: true });
  const pageData = {
    "add-address": SubHeaderData["custom-content"].find(
      (x) => x.name === "add-address"
    )["value-single"],
    "empty-address": SubHeaderData["custom-content"].find(
      (x) => x.name === "empty-address"
    )["value-single"],
    "show-stalls": SubHeaderData["custom-content"].find(
      (x) => x.name === "show-stalls"
    )["value-single"],
    "current-price": SubHeaderData["buttons"].find(
      (x) => x.name === "current-price"
    )["label"],
    "get-stall": SubHeaderData["buttons"].find((x) => x.name === "get-stall")[
      "label"
    ],
    "choose-address": SubHeaderData["dropdowns"].find(
      (x) => x.name === "choose-address"
    )["placeholder"],
  };
  const context = useContext(Context);
  const [days] = useState(context.getDays());
  const [monthsShortTR]=useState(context.getMonths().listTr.split('_'));
  const [monthsShortEN]=useState(context.getMonths().listEn.split('_'));
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [resetMarket, setResetMarket] = useState(null);
  const [markets, setMarkets] = useState([]);
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, [context.cartTransactionLines]);
  const fetchData = async () => {
    setProducts(context.cartTransactionLines);
    if (context.cartTransactionLines.length > 0) {
      console.log(context.cartTransactionLines[0]);
    }
  };

  const init = async () => {
    const user = JSON.parse(await getItem("user"));
    const currentAddress = JSON.parse(await getItem("address"));
    if (currentAddress) {
      setSelectedAddress(currentAddress);
    }
    setAddresses([]);
    //--console.warn("user  >> ", user);
    if (context.user) {
      context.select(
        "address",
        (data) => {
          setAddresses(data ? data : []);
        },
        "user_address_is_active:1,user_address_is_deleted:0,user_address_created_by_user_id:" +
          user.member_id +
          "&pageLimit=99999"
      );
    }
  };
  useEffect(() => {
    init();
  }, [context.user]);

  const toggleStallsModal = () => {
    setOpen(!open);
  };

  const onAddressSelected = async (value, redirect, id) => {
    console.log("set vaule address >> 111");
    if (value) {
      console.log("set vaule address >> 222");
      setResetMarket(null);
      setMarkets([]);
      let currentAddress = JSON.parse(await getItem("address"));
      let isCartEmpty = JSON.parse(await checkItem("cart"));
      if (
        isCartEmpty &&
        !!currentAddress &&
        currentAddress.user_address_id !== value.user_address_id
      ) {
        const dialogTData = t("showDialogs", { returnObjects: true });
        const dialogData = dialogTData.find(
          (x) => x.name === "subheader-basket-warning"
        );
        context.setGlobalState("showDialog", {
          title: dialogData.title,
          message:
          dialogData.message,
          buttons: [
            {
              text: dialogData.buttons.find(x => x.name === 'refuse').label,
              onPress: async () => {
                context.setGlobalState("showDialog", false);
              },
              isSecondary: true,
            },
            {
              text: dialogData.buttons.find(x => x.name === 'yes').label,
              onPress: async () => {
                await removeItem("cart");
                await removeItem("address");
                await removeItem("stall");
                setSelectedAddress(value);
                context.setGlobalState("showDialog", false);
                await context.updateCart([]);
                await setItem("address", JSON.stringify(value));
                await onAddressSelected(value);
                //window.location.reload();
              },
            },
          ],
        });
      } else {
        console.log("set vaule address >> 333");
        await context.select(
          "view_active_marketer_list",
          async (data) => {
            console.log("markets >> ", data);
            if (data && data.length === 0) {
              const dialogTData = t("showDialogs", { returnObjects: true });
              const dialogData = dialogTData.find(
                (x) => x.name === "subheader-empty-marketers"
              );
              context.setGlobalState("showDialog", {
                title: dialogData?.title,
                message: dialogData?.message,
                buttons: [
                  {
                    text: dialogData?.buttons[0].label,
                    onPress: () => {
                      context.setGlobalState("showDialog", false);
                    },
                  },
                ],
              });
              return false;
            }
            setSelectedAddress(value);
            console.log(
              "set vaule address >> ",
              value.user_address_city,
              isCartEmpty
            );
            await setItem("address", JSON.stringify(value));
            await routeToMarketSelection(value, redirect, id);
          },
          "market_city:" +
            value.user_address_city +
            ",market_active_marketer_work_range_neighborhoods-like-*" +
            value.user_address_county +
            "_" +
            value.user_address_neighborhood +
            "*&pageLimit=99999&getEncrypted=0"
        );
        //await toggleStallsModal();
        //window.location.href="/stalls"
      }
    }
  };
  const getClosestWeekdayType = (weekday_type, day, i) => {
    if (days[moment(day).format("dddd")] === weekday_type) {
      return {
        date:
          moment(day).format("DD") +
          " " +
          monthsShortTR[monthsShortEN.indexOf(moment(day).format("MMM"))],
        order: i,
      };
    } else {
      const nextDay = moment(day).add(1, "days");
      // console.warn("nextDay >> ", nextDay, i);
      i++;
      return getClosestWeekdayType(weekday_type, nextDay, i);
    }
  };

  const routeToStalls = async () => {
    /* if (!selectedAddress) {
            context.setGlobalState('showDialog', {
                title: 'Merhaba',
                message:
                    'Lütfen adres seçimi yapınız.',
                buttons: [
                    {
                        text: 'Tamam',
                        onPress: () => {
                            context.setGlobalState('showDialog', false);
                        },
                    },
                ],
            });
            return false;
        }*/
    let cartAddressId =
      products[0]?.product_transaction_buyer_delivery_address_id;
    let addressExists = addresses.find(
      (address) => address.user_address_id === cartAddressId
    );
    //if (!(window.location.href.indexOf("stalls") > -1)) {
    if (
      addressExists &&
      selectedAddress &&
      selectedAddress.user_address_id === addressExists.user_address_id
    ) {
      //history.push("/stalls/" + products[0]?.market_active_marketer_id);
      window.location.href =
        "/stalls/" + products[0]?.market_active_marketer_id;
    } else if (
      addressExists &&
      selectedAddress &&
      selectedAddress.user_address_id !== addressExists.user_address_id
    ) {
      console.log("select this address", addressExists.user_address_city);
      await onAddressSelected(
        addressExists,
        false,
        products[0]?.market_active_marketer_id
      ).then(() => {
        // window.location.href = "/stalls/" + products[0]?.market_active_marketer_id;
      });
      //await routeToMarketSelection(addressExists, false);
      //history.push("/stalls/" + products[0]?.market_active_marketer_id);
    } else if (addressExists && !selectedAddress) {
      await onAddressSelected(
        addressExists,
        false,
        products[0]?.market_active_marketer_id
      );
    } else {
      console.log("add address");
      await removeItem("cart");
      //await removeItem('address');
      //await removeItem('stall');
      await context.updateCart([]);
      window.location.reload();
    }
  };
  const routeToMarketSelection = async (address, redirect = true, id) => {
    console.log("markets", markets);
    if (!address) {
      const dialogTData = t("showDialogs", { returnObjects: true });
      const dialogData = dialogTData.find(
        (x) => x.name === "subheader-choose-address"
      );
      context.setGlobalState("showDialog", {
        title: dialogData?.title,
        message: dialogData?.message,
        buttons: [
          {
            text: dialogData?.buttons[0].label,
            onPress: () => {
              context.setGlobalState("showDialog", false);
            },
          },
        ],
      });
      return false;
    }
    await context.select(
      "view_active_marketer_list",
      async (data) => {
        //--//--console.warn("markets >> ", typeof data);
        if (!_.isEmpty(data) && typeof data !== "string") {
          let check = [],
            ms = [];
          _.forEach(data, async (m) => {
            // const closestWeekday = await this.getClosestWeekdayType(
            //   m.market_weekday_type,
            //   moment(),
            //   0,
            // );
            // console.warn("closestWeekday >> ", closestWeekday);
            if (
              check.indexOf(m.market_weekday_type + " | " + m.market_name) ===
              -1
            ) {
              check.push(m.market_weekday_type + " | " + m.market_name);
              // console.warn("check >> ", check);
              m.isSameDay =
                days[moment().format("dddd")] === m.market_weekday_type;

              let isValid = true;
              if (m.isSameDay) {
                const neighborhoods =
                  m.market_active_marketer_work_range_neighborhoods.split(",");
                const activeNeighborhood = neighborhoods.find((item) =>
                  item.includes(address.user_address_neighborhood)
                );
                const currentHour = parseInt(moment().format("HH"));
                const neighboorData = activeNeighborhood?.split("_");
                const deliverSameDay = neighboorData[3] === "1";
                const timesRaw =
                  m.market_active_marketer_delivery_selected_time_interval_list.split(
                    ";"
                  );
                const times = timesRaw.map((item) => {
                  let timeData = item.split(",");
                  const hours = timeData[0].split("-");
                  return {
                    key: timeData[0],
                    startTime: hours[0].trim(),
                    endTime: hours[1].trim(),
                    validTime: Number(timeData[1]),
                  };
                });
                const validTimes = neighboorData[4]?.split(";").map((item) => {
                  const hours = item.split("-");
                  const validTimeTemp = times?.find(
                    (time) => time.key === item
                  );
                  const activeHour = hours[0]?.trim().split(":")[0];
                  const validHour =
                    parseInt(activeHour, 10) -
                    parseInt(validTimeTemp.validTime, 10);
                  return {
                    key: item,
                    canOrder: currentHour < validHour,
                  };
                });
                const canOrder = validTimes.find((item) => item.canOrder);
                if (!canOrder) {
                  isValid = false;
                }
              }
              const closestWeekday = await getClosestWeekdayType(
                m.market_weekday_type,
                days[moment().format("dddd")] === m.market_weekday_type
                  ? isValid
                    ? moment()
                    : moment().add(1, "days")
                  : moment(),
                0
              );

              m.marketDay = closestWeekday.date + " " + m.market_weekday_type;

              if (!isValid) {
                m.isSameDay = false;
              }
              ms.push({
                label:
                  closestWeekday.date +
                  " " +
                  m.market_weekday_type +
                  " | " +
                  m.market_name,
                value: m,
                order: closestWeekday.order,
              });
              // console.warn("ms >> ", ms);
            }
          });
          // console.warn("ms >>> ", await ms, _.sortBy(ms, 'order'));
          setMarkets(_.sortBy(await ms, "order"));
          if (id) {
            window.location.href = "/stalls/" + id;
          } else {
            window.location.href = "/stalls";
          }
        }
      },
      "market_city:" +
        address.user_address_city +
        ",market_active_marketer_work_range_neighborhoods-like-*" +
        address.user_address_county +
        "_" +
        address.user_address_neighborhood +
        "*&pageLimit=99999&getEncrypted=0"
    );
    //await toggleStallsModal();
  };
  const calculateTotalPrice = (
    cartTransactionLines = context.cartTransactionLines
  ) => {
    const totalPrice = _.sumBy(
      cartTransactionLines?.filter((item) => !item?.isFake && !item?.isLoading),
      (product) => {
        return CalculateLineAmount(
          product?.product_active_seller_entry_price,
          product?.product_vat_rate,
          product?.product_transaction_line_piece
        );
      }
    );
    return parseFloat(totalPrice).toFixed(2);
  };
  return (
    <>
      <div className="sub-header z-40 d-block">
        <div className="container">
          <div className="row sub-header-content">
            <span className="flex col-xl-9 col-md-6  col-12 address-dropdown">
              {!!context.user ? (
                <Dropdown className="col-lg-4 col-12 align-self-center  header-dropdown addresses">
                  {selectedAddress ? (
                    <Dropdown.Toggle
                      role={"button"}
                      id="dropdownMenuLink"
                      style={{
                        backgroundColor: "transparent",
                        textAlign: "left",
                        borderColor: "white",
                      }}
                    >
                      {selectedAddress.user_address_title}
                    </Dropdown.Toggle>
                  ) : (
                    <Dropdown.Toggle
                      role={"button"}
                      id="dropdownMenuLink"
                      style={{
                        backgroundColor: "transparent",
                        textAlign: "left",
                        borderColor: "white",
                      }}
                    >
                      {pageData["choose-address"]}
                    </Dropdown.Toggle>
                  )}
                  <Dropdown.Menu
                    className="dropdown-menu "
                    aria-labelledby="dropdownMenuLink"
                  >
                    <Dropdown.Item
                      className="new-address"
                      onClick={async () => {
                        history.push("/add-address/new");
                      }}
                    >
                      {pageData["add-address"]}
                    </Dropdown.Item>
                    {addresses.map((address, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={async () => {
                          await onAddressSelected(address);
                        }}
                      >
                        {address.user_address_title}
                      </Dropdown.Item>
                    ))}
                    {addresses.length === 0 && (
                      <Dropdown.Item>{pageData["empty-address"]}</Dropdown.Item>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              ) : null}

              {!!context.user && name === "Anasayfa" ? (
                <div className="show-stalls-btn-header align-self-center">
                  <div
                    onClick={() =>
                      routeToMarketSelection(selectedAddress, true)
                    }
                    className={"btn btn-secondary"}
                  >
                    {pageData["show-stalls"]}
                  </div>
                </div>
              ) : null}

              {/* { name === "Anasayfa" ||name ==="Şifre Bilgilerim" ? null :
                                <div className="col-lg-4 d-none d-sm-block mt-auto mb-auto">
                                    <h1 className="mb-0">{name === "Anasayfa" ? "" : name}</h1>
                                </div>
                            } */}

              {/*{!!context.user && (name === "Anasayfa") ?
                            <div className="show-stalls-btn-header align-self-center">
                                <div onClick={()=>routeToMarketSelection()} className={"btn btn-secondary"}>TEZGAHLARI GÖSTER</div>
                            </div> : null
                        }*/}
            </span>
            {products.length > 0 ? (
              <div className=" col-xl-3 col-md-6 col-12 show-basket-btn-header mt-0 flex justify-content-end">
                <button
                  onClick={() => routeToStalls()}
                  className={
                    "btn btn-secondary header-basket-btn text-2xl font-weight-bold w-full flex justify-content-between"
                  }
                  style={{ maxHeight: "3rem" }}
                >
                  <img src={img3} alt="" className="mr-0" style={{height: '1.5rem'}} />
                  <span className="text-xl">
                    {calculateTotalPrice()} {pageData["current-price"]}
                  </span>
                  <span>{pageData["get-stall"]}</span>
                </button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Stalls
        open={open}
        toggle={toggleStallsModal}
        selectedAddress={selectedAddress}
        markets={markets}
      />
    </>
  );
}

export default withRouter(SubHeader);
