import React, {useContext, useEffect, useState} from "react";
import Basket from "./components/Basket";
import Products from "./components/Products";
import MarketPlacesModal from "./components/MarketPlacesModal";
import CommentsModal from "./components/CommentsModal";
import {getItem, setItem} from "../../Utils/LocalStorage";
import {useParams} from "react-router";
import Context from "../../Utils/Context";
import StallsColumn from "./components/StallsColumn";
import ClerkDetails from "./components/ClerkDetails";
import file from "../../assets/images/file.svg";
import _ from "lodash";
import moment from "moment";
import {useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";
const Stall=()=>{
    const { t } = useTranslation()
    const Stallsata = t('stalls.Stall', { returnObjects: true })
    const pageData = {
        'show-products': Stallsata['custom-content'].find(x => x.name === 'show-products')['value-single'],
        'show-products-2': Stallsata['custom-content'].find(x => x.name === 'show-products-2')['value-single'],
    }
    let { id } = useParams();
    const context = useContext(Context);
    const history = useHistory();
    const [days] = useState(context.getDays());
    const [monthsShortTR]=useState(context.getMonths().listTr.split('_'));
    const [monthsShortEN]=useState(context.getMonths().listEn.split('_'));
    const [stall, setStall]=useState(null);
    const [offset, setOffset]=useState(0);
    const [limit, setLimit]=useState(40);
    const [market, setMarket]=useState([]);
    const [myNeighborhood, setMyNeighborhood]=useState(null);

    const [stories, setStories]=useState([]);
    const [marketPlacesModal, setMarketPlacesModal]=useState(false);
    const [commentsModal, setCommentsModal]=useState(false);
    const [stallMode] = useState(window.location.pathname.split('/stalls/')[1] === '' || window.location.pathname.split('/stalls')[1] === '')
    const toggleMarketPlacesModal =()=>{
        setMarketPlacesModal(!marketPlacesModal);
    }
    const toggleCommentsModal =()=>{
        setCommentsModal(!commentsModal);
    }

    useEffect( () => {
        if (id){
            init(id);
        }
    },[id, context.cartUpdated]);

    const init =async (id) => {
        const res = JSON.parse(await getItem('myNeighborhood'));
        setMyNeighborhood(res);
        // console.log("mar", JSON.parse(await getItem('market')));
        let market
        try{
            market = JSON.parse(await getItem('market'));
        } catch (e){
            history.push("/stalls");
        }

        setMarket(market);
        const address = JSON.parse(await getItem('address'));
        const CCN = JSON.parse(await getItem('CCN'));
        //--//--console.warn("address >> ", address);
        if (address) {
            context.select(
                'view_active_marketer_list',
                (data) => {
                    setStall(data.find((datum) => datum.market_active_marketer_id === id));
                    getAndSetMarket(data,address.user_address_neighborhood);
                },
                'market_active_marketer_id:' +
                id +
                ',market_city:' +
                address.user_address_city +
                ',market_active_marketer_work_range_neighborhoods-like-*' +
                address.user_address_county +
                '_' +
                address.user_address_neighborhood +
                '*&pgidx=' +
                offset +
                '&pagelimit=' +
                limit +
                '&getEncrypted=0',
            );
        } else if(CCN && !context.user) {
            context.select(
                'view_active_marketer_list',
                (data) => {
                    //console.log('view_active_marketer_list >> data >> ', data);
                    setStall(data.find((datum) => datum.market_active_marketer_id === id));
                    getAndSetMarket(data,CCN.neighbourhood.neighborhood_name);
                },
                'market_active_marketer_id:' +
                id +
                ',market_city:' +
                CCN.city.city_name +
                ',market_active_marketer_work_range_neighborhoods-like-*' +
                CCN.county.county_name +
                '_' +
                CCN.neighbourhood.neighborhood_name +
                '*&pgidx=' +
                offset +
                '&pagelimit=' +
                limit +
                '&getEncrypted=0',
            );
        }
    }
    const getClosestWeekdayType = (weekday_type, day, i) => {
        if (days[moment(day).format('dddd')] === weekday_type) {
            return {
                date:
                    moment(day).format('DD') +
                    ' ' +
                    monthsShortTR[
                        monthsShortEN.indexOf(moment(day).format('MMM'))
                        ],
                order: i,
            };
        } else {
            const nextDay = moment(day).add(1, 'days');
            // console.warn("nextDay >> ", nextDay, i);
            i++;
            return getClosestWeekdayType(weekday_type, nextDay, i);
        }
    };

    const getAndSetMarket = async (stall, neighborhood) => {
        if (!_.isEmpty(stall) && typeof stall !== 'string') {
            let check = [],
                ms = [];
            _.forEach(stall, async (m) => {
                // const closestWeekday = await this.getClosestWeekdayType(
                //   m.market_weekday_type,
                //   moment(),
                //   0,
                // );
                // console.warn("closestWeekday >> ", closestWeekday);
                if (
                    check.indexOf(m.market_weekday_type + ' | ' + m.market_name) ===
                    -1
                ) {
                    check.push(m.market_weekday_type + ' | ' + m.market_name);
                    // console.warn("check >> ", check);

                    m.isSameDay =
                        days[moment().format('dddd')] ===
                        m.market_weekday_type;

                    let isValid = true;
                    if (m.isSameDay) {
                        const neighborhoods =
                            m.market_active_marketer_work_range_neighborhoods.split(
                                ',',
                            );

                        const activeNeighborhood = neighborhoods.find((item) =>
                            item.includes(neighborhood),
                        );

                        const currentHour = parseInt(moment().format('HH'));

                        const neighboorData = activeNeighborhood?.split('_');
                        const deliverSameDay = neighboorData[3] === '1';

                        const timesRaw =
                            m.market_active_marketer_delivery_selected_time_interval_list.split(
                                ';',
                            );
                        const times = timesRaw.map((item) => {
                            let timeData = item.split(',');
                            const hours = timeData[0].split('-');
                            return {
                                key: timeData[0],
                                startTime: hours[0].trim(),
                                endTime: hours[1].trim(),
                                validTime: Number(timeData[1]),
                            };
                        });
                        if (neighboorData[4].length === 0) {
                            return;
                        }
                        const validTimes = neighboorData[4]
                            ?.split(';')
                            .map((item) => {
                                const hours = item.split('-');
                                const validTimeTemp = times?.find(
                                    (time) => time.key === item,
                                );
                                const activeHour = hours[0]?.trim().split(':')[0];
                                let validHour;
                                try{
                                    validHour =
                                        parseInt(activeHour, 10) -
                                        parseInt(validTimeTemp.validTime, 10);
                                }catch (e){
                                    history.push("/stalls");
                                }

                                return {
                                    key: item,
                                    canOrder: currentHour < validHour,
                                };
                            });

                        const canOrder = validTimes.find((item) => item.canOrder);
                        if (!canOrder || !deliverSameDay) {
                            isValid = false;
                        }
                    }

                    const closestWeekday = await getClosestWeekdayType(
                        m.market_weekday_type,
                        days[moment().format('dddd')] ===
                        m.market_weekday_type
                            ? isValid
                                ? moment()
                                : moment().add(1, 'days').startOf('day')
                            : moment(),
                        0,
                    );

                    m.marketDay = closestWeekday.date + ' ' + m.market_weekday_type;

                    if (!isValid) {
                        m.isSameDay = false;
                    }
                    ms.push({
                        label:
                            closestWeekday.date +
                            ' ' +
                            m.market_weekday_type +
                            ' | ' +
                            m.market_name,
                        value: m,
                        order: closestWeekday.order,
                    });
                    // console.warn("ms >> ", ms);
                }
            });
            // console.warn("ms >>> ", await ms, _.sortBy(ms, 'order'));
            let market = await ms;
            await setItem('market', JSON.stringify(market[0]));
            setMarket(market[0]);
        }
    }

    return(<><div className="markets mt-5">
            <div className="container m-container">
                <div className="row">
                    {(id && stall) && (
                            <ClerkDetails stall={stall} rating={stall.market_active_marketer_like_score} visiter={stall.market_active_marketer_visit_counter} market={market} stories={stories} myNeighborhood={myNeighborhood} toggleCommentsModal={toggleCommentsModal} toggleMarketPlacesModal={toggleMarketPlacesModal}/>
                    )}
                    <div className="flex justify-content-between flex-wrap w-full">
                    {/*<div className="col-lg-3 mb-4 left-stalls-column" >*/}
                    <div className={ !stallMode ? "d-none d-lg-block col-12 col-lg-2 mb-4 left-stalls-column" : "col-12 col-lg-2 mb-4 left-stalls-column"} style={{paddingLeft: "0", paddingRight:"0"}}>
                        <StallsColumn/>
                    </div>
                    {/*<div className="col-lg-6 mb-4">*/}
                    <div  className={ stallMode ? "d-none d-md-block col-12 col-lg-7 col-md-8 mb-4" : "col-md-8 col-lg-7 col-12 mb-4"} style={{paddingLeft: "10", paddingRight:"10",}}>
                        {(id && stall) ? (<Products stall={stall} />):(
                            <div className="all-products" data-aos="fade-up">
                                <div className="empty-products mt-5"
                                     style={{display: "block"}}>
                                    <img src={file} className="img-fluid" alt={"Tezgah seçiniz."}/>
                                    <p>{pageData['show-products']} <br/> {pageData['show-products-2']}</p>
                                </div>
                            </div>
                        )}
                    </div>
                    {/*<div className="col-lg-3" style={{paddingLeft: "0", paddingRight:"0"}}>*/}
                    <div className="col-lg-3 col-md-4 col-12" style={{paddingLeft: "0", paddingRight:"0"}}>
                        <Basket stall={stall} />
                    </div>
                    </div>
                </div>
            </div>
        </div>
        {stall && (<>
            <MarketPlacesModal open={marketPlacesModal} stall={stall} toggle={toggleMarketPlacesModal}/>
            <CommentsModal open={commentsModal} stall={stall} toggle={toggleCommentsModal}/>
            </>
            )}
    </>);
}

export default Stall;
