import React, {useContext,useState, useRef} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import ModalHeader from "react-bootstrap/ModalHeader";
import Context from "../../../Utils/Context";

const PaymentModal = ({open, toggle, paymentUrl}) => {
    const context = useContext(Context);
    const divRef = useRef(null);
    const [content, setContent] = useState(null);

    const payTrRender=(url)=>{
        fetch(url)
            .then((res)=> res.text())
            .then(
                (result) => {
                    console.log("result ", result);
                    setContent(result);
                    divRef.current.innerHTML = result;
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log("error ", error);
                }
            );
    }
    return (<Modal show={open} size={"lg"} centered>
            <div className="close-modal" onClick={() => toggle(false)} data-dismiss="modal"><i/></div>
            <ModalHeader className="col-12 col-lg-12 m-auto pb-4">Ödeme</ModalHeader>
            <ModalBody className={"pt-1"}>
                <div className="row justify-content-center">
                    <iframe src={paymentUrl} ref={divRef } className={"pay-tr-iframe"} title="payment" onError={() => {
                        toggle(false);
                        context.showSystemError();
                    }} onLoad={(e)=>

                        {}
                        // console.log("title ", e.target.contentWindow.document.title)
                    } width={"100%"} height={"500px"}/>
                </div>
            </ModalBody>
            <Modal.Footer>
                {/*<button className={"btn btn-secondary"} >Kapat</button>*/}
                {/*<button onClick={()=>console.log("pay now")} className={"btn btn-primary"}>Save changes</button>*/}
            </Modal.Footer>
        </Modal>
    );
}
export default PaymentModal;
