import * as Actions from '../actions/index';

const initialState = {
    layout : {
        isNavBarOpen: true,
        loading: false,
        message: null,
        loginModal: false,
        signupModal: false,
        confirmSignupModal: false,
        forgotPassModal: false,
        newPassModal: false
    }
};

const settingsReducer = function (state = initialState, action) {
    switch ( action.type )
    {
        case Actions.OPEN_CONFIRM_SIGNUP_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    confirmSignupModal: true
                }
            };
        }
        case Actions.CLOSE_CONFIRM_SIGNUP_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    confirmSignupModal: false
                }
            };
        }

        case Actions.OPEN_NAVBAR:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    isNavBarOpen: true
                }
            };
        }
        case Actions.OPEN_LOGIN_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    loginModal: true
                }
            };
        }
        case Actions.CLOSE_LOGIN_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    loginModal: false
                }
            };
        }
        case Actions.OPEN_SIGNUP_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    signupModal: true
                }
            };
        }
        case Actions.CLOSE_SIGNUP_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    signupModal: false
                }
            };
        }
        case Actions.OPEN_FORGOT_PASS_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    forgotPassModal: true
                }
            };
        }
        case Actions.CLOSE_FORGOT_PASS_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    forgotPassModal: false
                }
            };
        }
        case Actions.OPEN_NEW_PASS_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    newPassModal: true
                }
            };
        }
        case Actions.CLOSE_NEW_PASS_MODAL:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    newPassModal: false
                }
            };
        }
        case Actions.CLOSE_NAVBAR:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    isNavBarOpen: false
                }
            };
        }
        case Actions.SET_LOADING:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    loading: action.payload.loading
                }
            };
        }
        case Actions.SET_MESSAGE:
        {
            return {
                ...state,
                layout: {
                    ...state.layout,
                    message: action.payload
                }
            };
        }
        default:
        {
            return state;
        }
    }
};

export default settingsReducer;
