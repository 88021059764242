import React, { useContext, useEffect, useRef, useState } from "react";
import Context from "../../../Utils/Context";
import _ from "lodash";
import ProductDetailsModal from "./ProductDetailsModal";
import Product from "./Product";
import { useParams } from "react-router";
import file from "../../../assets/images/file.svg";
import { ScrollingCarousel } from "@trendyol-js/react-carousel";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { useTranslation } from "react-i18next";


const defaultFilterValues = {
    offset: 0,
    limit: 40,
    search: false,
    searchTerm: '',
    isSearch: false,
};
const Products = ({ stall }) => {
    const { t } =  useTranslation()
    const ProductsData = t('stalls.Products', { returnObjects: true })
    const pageData = {
        search: ProductsData['inputs'].find(x => x.name === 'search')['placeholder'],
        'all-products': ProductsData['buttons'].find(x => x.name === 'all-products')['label'],
        'empty-product' :ProductsData['custom-content'].find(x => x.name === 'empty-product')['value-single'],
    }
    const context = useContext(Context);
    const { id } = useParams();
    const [products, setProducts] = useState([]);

    const [activeCategory, setActiveCategory] = useState("");
    const [categories, setCategories] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(9999);
    const [search, setSearch] = useState(false);
    const [filterValues, setFilterValues] = useState(defaultFilterValues);
    const [searchTerm, setSearchTerm] = useState("");
    const [productDetailsModal, setProductDetailsModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [updated, setUpdated] = useState(false);
    const [notThisAddress, setNotThisAddress] = useState(false);
    const [searchID, setSearchID] = useState(0)

    const myRef = useRef(null);


    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        if (searchTerm.length === 0) {
            fetchResult("category", "");
        }
    }, [searchTerm]);

    useEffect(() => {
        setSearchTerm("");
        setSearch(false);
    }, [id]);

    const clearSearch = async () => {
        setSearchTerm("");
        setSearch(false);
        await fetchResult("category", "");
    };
    const toggleProductDetailsModal = (product) => {
        setProductDetailsModal(!productDetailsModal);
        setSelectedProduct(product);
    }
    const handleCategoryClick = async (category) => {

        await fetchResult('category', category.product_category_id);
        await setActiveCategory(category.product_category_id);
    }
    const handleSearch = async () => {
        await fetchResult("search");
    }
    const fetchResult = async (e, cat) => {
        context.setLoading(true)
        if (searchTerm || e === 'search') {
            //setProducts([]);
            setActiveCategory("");
            setSearch(true);
        }
        if (e === 'category') {
            //setProducts([]);
        }
        setProducts([]);
        //console.log("stall, offset, limit >> ", products, offset, limit);
        context.select(
            'view_active_product_list',
            async (data) => {
                // console.warn("data >> ", searchTerm, data);
                if (!data) return;
                //setProducts(searchTerm ? data : products.concat(data));
                //setOffset(searchTerm || e === 'search' ? 0 : offset + 1);
                //setLimit(searchTerm ? 99999 : limit);
                //getCategories(searchTerm ? data : products.concat(data));
                const filteredData = data?.filter(
                    (item) => item?.product_active_seller_entry_price !== '',
                );
                setProducts(activeCategory.length > 0 ? filteredData : filteredData);
                // setAllProducts(activeCategory.length>0 ? data : products.concat(data));
                // setOffset(activeCategory.length>0 || e === 'category' ? 0 : offset + 1);
                // setLimit(activeCategory.length>0 ? 99999 : limit);
                if (searchTerm || (e === 'search' && activeCategory.length === 0)) {
                    await getCategoriesProductCount();
                } else if (e === 'category' && activeCategory.length === 0) {
                    await getCategoriesProductCount();
                }
                context.setLoading(false)

            },
            'product_active_created_by_user_id:' +
            stall?.market_active_marketer_created_by_user_id +
            ',product_active_is_active:1,product_active_is_deleted:0,market_is_active:1,market_is_deleted:0,market_id:' +
            stall.market_id +
            (searchTerm
                ? ',product_name-like-*' + searchTerm + '*'
                : '') +
            (cat
                ? ',product_category_id:' + cat + ''
                : '') +
            (!searchTerm || !cat
                ? '&pageLimit=' + limit + '&pgidx=' + offset
                : '') +
            '&getEncrypted=0',
        );
    };
    const getCategories = () => {
        context.select(
            'product_category',
            (data) => {
                if (!data) {
                    return;
                }
                setCategories(data);
                getCategoriesProductCount(data);
            },
            'product_category_is_active:1,product_category_is_deleted:0',
        );
    };
    const getCategoriesProductCount = async (
        newCategories,
        newSelectedCategory = activeCategory,
    ) => {
        const categoriesData = newCategories ?? categories;
        await Promise.all(categoriesData.map(async (category) => {
            await context.select(
                'view_active_product_list',
                async (categoryProductData) => {
                    category.product_count =
                        !categoryProductData || categoryProductData?.length === 0
                            ? 0
                            : parseInt(categoryProductData[0].TotalRecord);

                    setCategories(
                        categoriesData.map((cat) => {
                            if (
                                cat.product_category_name === category.product_category_name
                            ) {
                                return category;
                            }
                            return cat;
                        }),
                    );
                    if (
                        newSelectedCategory === category.product_category_name &&
                        category.product_count === 0 &&
                        newSelectedCategory !== null
                    ) {
                        await fetchResult('category', null);
                    }
                },
                'product_active_created_by_user_id:' +
                stall?.market_active_marketer_created_by_user_id +
                ',product_active_is_active:1,product_active_is_deleted:0,market_is_active:1,market_is_deleted:0' +
                ',market_id:' +
                stall?.market_id +
                ',product_category_name:' +
                category?.product_category_name +
                (search
                    ? ',product_name-like-*' + searchTerm + '*'
                    : '') +
                '&pageLimit=1&pgidx=0' +
                '&getEncrypted=0',
                'select',
                false,
            );
        }));
    };
    const setSearchAndFind = (e) => {
        clearTimeout(searchID)
        const sID = setTimeout(() => {
            if (e.length >= 3) {
                handleSearch()
            }
        }, 500);
        setSearchID(sID)
        setSearchTerm(e)        
    }

    console.log('products', products);
    return (<div className={""} data-aos="fade-up">
        <div className="search-input  mb-2">
            <div className="row">
                <div className="col-10 col-lg-10">
                    <input type="text" value={searchTerm} onKeyPress={e => e.key === 'Enter' && handleSearch()} onChange={(e) => setSearchAndFind(e.target.value)} className="form-control border-0" placeholder={pageData['search']} />
                </div>
                {searchTerm.length > 0 && search ? (
                    <div className="col-2 flex justify-content-end" style={{ padding: 15 }}>
                        <div className="btn green-clearX" onClick={async () => await clearSearch()} />
                    </div>
                ) : (<div className="col-2 flex justify-content-end" style={{ padding: 15 }}>
                    <div className="btn green-search" onClick={() => handleSearch()} />
                </div>)}
            </div>
        </div>
        <div className="all-products">
            <div className="product-categories">
                <div className="container">
                    <div className="row">
                        <ScrollingCarousel
                            name="product-categories"
                            slide={2}
                            leftIcon={<button name="left"><ChevronLeft style={{ color: "#90b437" }} fontSize="large" /></button>}
                            rightIcon={<button name="right"><ChevronRight style={{ color: "#90b437" }} fontSize="large" /></button>}
                            className="tr-carousel"
                        >
                            {categories.length !== 0 && (<div className="ml-2" style={{  marginRight: "10px" }}>
                                <a onClick={() => handleCategoryClick({ product_category_id: "" })}
                                    className={("" === activeCategory ? "category btn  m-1 active " : "category btn m-1  ")}>{pageData['all-products']}
                                    <span className="ml-2 category-dot">
                                        {_.sumBy(categories, (o) => o.product_count)}
                                    </span> </a>
                            </div>)}
                            {categories.map((category, index) => (
                                (category?.product_count !== 0) && (
                                    <div ref={(category.product_category_id === activeCategory ? myRef : null)}
                                        id={(category.product_category_id === activeCategory ? "active" : "")} className=""
                                        key={index} style={{  marginLeft: "10px", marginRight: "10px" }} lang="tr">
                                        <a onClick={() => handleCategoryClick(category)}
                                            className={(category.product_category_id === activeCategory ? "category btn  m-1 active " : "category btn m-1  ")}>
                                            {category?.product_category_name} <span className="ml-2 category-dot">{category?.product_count}</span>
                                        </a>
                                    </div>)
                            ))}
                        </ScrollingCarousel>

                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        {products.map((product, index) => (
                            <Product key={index} index={index} product={product} toggle={toggleProductDetailsModal} activeCategory={activeCategory} setUpdated={setUpdated} updated={updated} stall={stall} />
                        ))}
                        {(products && products.length === 0 && !context.loading) && (
                            <div className={"col-12"}>
                                <div className="empty-products mt-5"
                                    style={{ display: "block" }}>
                                    <img src={file} className="img-fluid" alt={"Tezgah seçiniz."} />
                                    <p>{pageData['empty-product']}</p>
                                </div>
                            </div>
                        )}
                        {/*<div className="right" style={{backgroundColor:"none"}}/>*/}
                        {/* <div className="bottom" /> */}
                    </div>
                </div>
            </div>
            {selectedProduct && (<ProductDetailsModal open={productDetailsModal} toggle={toggleProductDetailsModal} setSelectedProduct={setSelectedProduct} setUpdated={setUpdated} updated={updated} product={selectedProduct} stall={stall} />)}
        </div></div>);
}
export default Products;
