import React, { useContext, useEffect, useState } from "react";
import PriceInput from "./PriceInput";
import ajax_loader from "../../../assets/images/ajax-loader.gif";
import { config } from "../../../Utils/Config";
import _ from "lodash";
import Context from "../../../Utils/Context";
import { getItem } from "../../../Utils/LocalStorage";
import { toast } from 'react-toastify';
import "./Product.css";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";


const Product = ({ index, product, stall, updated, setUpdated, toggle, notThisAddress = false }) => {
    const { t } = useTranslation()
    const productData = t('stalls.Product', { returnObjects: true })
    const toastsData = t('toasts', { returnObjects: true })
    const pageData = {
        'unit-price': productData['custom-content'].find(x => x.name === 'unit-price')['value-single'],
        'title': productData['custom-content'].find(x => x.name === 'dialog-title')['value-single'],
        'text-1': productData['custom-content'].find(x => x.name === 'dialog-text-1')['value-single'],
        'text-2': productData['custom-content'].find(x => x.name === 'dialog-text-2')['value-single'],
        'text-3': productData['custom-content'].find(x => x.name === 'dialog-text-3')['value-single'],
        'text-4': productData['custom-content'].find(x => x.name === 'dialog-text-4')['value-single'],
        'text-5': productData['custom-content'].find(x => x.name === 'dialog-text-5')['value-single'],
        'text-6': productData['custom-content'].find(x => x.name === 'dialog-text-6')['value-single'],
        'text-7': productData['custom-content'].find(x => x.name === 'dialog-text-7')['value-single'],
        'text-8': productData['custom-content'].find(x => x.name === 'dialog-text-8')['value-single'],
        'continued': toastsData.find(x => x.name === 'continued'),
    }
    const context = useContext(Context);
    const history = useHistory();
    const [productCartItem, setProductCartItem] = useState(null);
    const [cartItem, setCartItem] = useState(null);
    const [isReqInProcess, setIsReqInProcess] = useState(false);
    const [waitUpdate, setWaitUpdate] = useState(false);
    const [newUPT, setNewUPT] = useState(null);
    const [newUpdateReceived, setNewUpdateReceived] = useState(false);
    const [atCart, setAtCart] = useState(false);
    const [count, setCount] = useState(1.0);
    const [nextActionTimeout, setNextActionTimeout] = useState(null);


    useEffect(() => {
        const foundCartItem = context.cartTransactionLines.find(
            (cartTransactionLine) =>
                cartTransactionLine?.product_transaction_line_product_id ===
                product?.product_id,
        );
        if (
            foundCartItem?.product_id &&
            !_.isEqual(productCartItem, foundCartItem) &&
            !nextActionTimeout &&
            !cartItem?.isFake
        ) {
            setProductCartItem(foundCartItem);
        } else if (!foundCartItem) {
            setProductCartItem(null);
            setCount(1);
            setAtCart(false);
        }

    }, [context.cartTransactionLines]);

    useEffect(() => {
        if (productCartItem && !notThisAddress) {
            if (
                (productCartItem.product_transaction_market_id ===
                    stall?.market_active_marketer_market_id &&
                    productCartItem.product_transaction_seller_user_id ===
                    stall?.market_active_marketer_user_id) ||
                (newUPT !== null &&
                    newUPT?.product_transaction_market_id ===
                    stall?.market_active_marketer_market_id &&
                    newUPT?.product_transaction_seller_user_id ===
                    stall?.market_active_marketer_user_id)
            ) {
                if (!newUpdateReceived) {
                    setNewUpdateReceived(false);
                    if (waitUpdate) {
                        clearTimeout(waitUpdate);
                    }
                    const newUpdate = setTimeout(() => {
                        setAtCart(true);
                        setCartItem(productCartItem);
                        setCount(
                            parseFloat(
                                _.replace(
                                    productCartItem.product_transaction_line_piece,
                                    ',',
                                    '.',
                                ),
                            ).toFixed(2),
                        );
                    }, 1200);
                    setWaitUpdate(newUpdate);
                }
            } else if (!cartItem?.isFake) {
                setAtCart(false);
                setCount(1);
            }
        } else {
            setAtCart(false);
            setCount(1);
        }
    }, [history, product, productCartItem, updated]);

    const onMinusButtonPressed = async () => {
        if (context.user) {
            await context.setGlobalState("cartLoading", true);
            const decrementValue = product?.product_unit_type === 'Kg' ? 0.5 : 1;
            const newCount = parseFloat(count) - parseFloat(decrementValue);
            const newCountTo =
                product?.product_unit_type === 'Kg' ? newCount.toFixed(1) : newCount;

            if (newCountTo >= (product?.product_unit_type === 'Kg' ? 0.5 : 1)) {
                const productCartItemTo = {
                    quantity: parseFloat(newCountTo).toFixed(1),
                    unitType: product.product_unit_type,
                };
                await productTransactionLineAction(productCartItemTo);
            } else {
                if (isReqInProcess) {
                    await new Promise((resolve) => setTimeout(() => resolve(), 1500));
                    if (isReqInProcess) {
                        toast.info(pageData['continued'].message, { autoClose: 1200 });
                        return;
                    }
                }
                if (nextActionTimeout !== null) {
                    clearTimeout(nextActionTimeout);
                    setNextActionTimeout(null);
                }
                if (waitUpdate !== null) {
                    clearTimeout(waitUpdate);
                    setWaitUpdate(null);
                }
                if (newUpdateReceived !== null) {
                    clearTimeout(newUpdateReceived);
                    setNewUpdateReceived(null);
                }
                const copyOfCartItem = cartItem;
                setAtCart(false);
                setCartItem(null);
                setProductCartItem(null);
                setCount(1);
                if (cartItem?.product_transaction_line_product_id) {
                    const updateFormData = {
                        product_transaction_line_original_piece:
                            cartItem?.product_transaction_line_original_piece,
                        product_transaction_line_is_active: 'False',
                        product_transaction_line_is_deleted: 'True',
                        product_transaction_line_piece: '0.0',
                    };
                    await context.updateItemInCart(
                        updateFormData,
                        copyOfCartItem,
                        undefined,
                        false,
                    );
                } else if (cartItem?.product_id) {
                    context.setCartTransactionLines(
                        context.cartTransactionLines.filter(
                            (ct) =>
                                ct.product_transaction_line_product_id !== cartItem.product_id,
                        ),
                    );
                }
                await context.setGlobalState("cartLoading", false);
            }
        }
    };

    const onPlusButtonPressed = async () => {
        if (context.user) {
            await context.setGlobalState("cartLoading", true);
            const incrementValue = product?.product_unit_type === 'Kg' ? 0.5 : 1;
            const newCount = parseFloat(count) + parseFloat(incrementValue);
            const newCountTo =
                product?.product_unit_type === 'Kg' ? newCount.toFixed(1) : newCount;
            const productCartItem = {
                quantity: parseFloat(newCountTo).toFixed(1),
                unitType: product.product_unit_type,
            };
            await productTransactionLineAction(productCartItem);
        }
    };

    const onAddButtonPressed = async (
        e,
        UPCT = undefined,
        skipUserControl = false,
    ) => {
        if (!context.user && !skipUserControl) {
            context.setGlobalState('loginAlert', true);
            return;
        }
        await addButtonAction(e, UPCT);
    };

    const addButtonAction = async (e, UPCT = undefined) => {
        if (UPCT !== undefined) {
            context.setLoading(true);
        }
        const address = JSON.parse(await getItem('address'));
        const neighborhoods =
            stall.market_active_marketer_work_range_neighborhoods.split(',');
        const selectedNeigborhood = neighborhoods.find((item) => {
            const neighborhood = item.split('_');
            return (
                neighborhood[0] === address.user_address_county &&
                neighborhood[1] === address.user_address_neighborhood
            );
        });
        if (!selectedNeigborhood) {
            context.setGlobalState('showDialog', {
                title: pageData['title'],
                message:
                    pageData['text-1'] +
                    (address.isMarketFromGeocodingLocation
                        ? pageData['text-2']
                        : address.isMarketFromManuelLocation
                            ? address.user_address_neighborhood + pageData['text-3'] +
                            address.user_address_county + address.user_address_city + pageData['text-4']
                            : `${address?.user_address_title === undefined
                                ?  + address.user_address_title + pageData['text-5']
                                : pageData['text-6']
                            }`) +
                    pageData['text-7'] +
                    pageData['text-8'],
                showCloseButton: true,
                customTopStyle: '30%',
                buttons: [
                    {
                        text: 'Tamam',
                        onPress: () => {
                            context.setGlobalState('showDialog', false);
                        },
                    },
                    {
                        text: 'Adres Ekle',
                        isSecondary: true,
                        onPress: () => {
                            console.log("add address");
                            alert("add address");
                        },
                    },
                ],
            });
            return;
        }
        console.log("tmid", context.cartTransaction.product_transaction_market_id)
        console.log("smaid", stall.market_active_marketer_market_id);

        console.log("tsid", context.cartTransaction.product_transaction_seller_user_id)
        console.log("smamid", stall.market_active_marketer_user_id);
        if (
            context.cartTransactionLines.length !== 0 &&
            (context.cartTransaction.product_transaction_market_id !==
                stall.market_active_marketer_market_id ||
                context.cartTransaction.product_transaction_seller_user_id !==
                stall.market_active_marketer_user_id) &&
            UPCT === undefined
        ) {
            context.setLoading(false);
             const dialogTData = t('showDialogs', { returnObjects: true })
            const dialogData = dialogTData.find(x => x.name === 'product-same-marketer')
            context.setGlobalState('showDialog', {
                title: dialogData.title,
                message:
                dialogData.message,
                buttons: [
                    {
                        text: dialogData.buttons.find(x => x.name === 'no').label,
                        onPress: () => {
                            context.setGlobalState('showDialog', false);
                        },
                    },
                    {
                        text: dialogData.buttons.find(x => x.name === 'yes').label,
                        isSecondary: true,
                        onPress: async () => {
                            await context.setGlobalState('showDialog', false);
                            context.setLoading(true);
                            if (!address) {
                                return;
                            }
                            const existAddress =
                                !address.isMarketFromGeocodingLocation &&
                                    !address.isMarketFromManuelLocation
                                    ? {
                                        product_transaction_buyer_delivery_address_details:
                                            address.user_address_detail ?? '',
                                        product_transaction_buyer_delivery_address_id:
                                            address.user_address_id ?? '',
                                        product_transaction_buyer_delivery_address_building_number:
                                            address.user_address_building_number ?? '',
                                        product_transaction_buyer_delivery_address_floor_number:
                                            address.user_address_floor_number ?? '',
                                        product_transaction_buyer_delivery_address_door_number:
                                            address.user_address_door_number ?? '',
                                        product_transaction_buyer_delivery_address_information:
                                            address.user_address_information ?? '',
                                        product_transaction_buyer_delivery_address_full_address:
                                            address.user_address_full_address ?? '',
                                        product_transaction_buyer_delivery_address_title:
                                            address.user_address_title ?? '',
                                    }
                                    : {
                                        product_transaction_buyer_delivery_address_details: '',
                                        product_transaction_buyer_delivery_address_id: '',
                                        product_transaction_buyer_delivery_address_building_number:
                                            '',
                                        product_transaction_buyer_delivery_address_floor_number:
                                            '',
                                        product_transaction_buyer_delivery_address_door_number:
                                            '',
                                        product_transaction_buyer_delivery_address_information:
                                            '',
                                        product_transaction_buyer_delivery_address_full_address:
                                            '',
                                        product_transaction_buyer_delivery_address_title:
                                            address.isMarketFromGeocodingLocation
                                                ? 'Lokasyon'
                                                : address.isMarketFromManuelLocation
                                                    ? 'Manuel'
                                                    : 'Mevcut Adres',
                                    };
                            const newTransactionFormData = {
                                ...existAddress,
                                product_transaction_seller_user_id:
                                    stall?.market_active_marketer_user_id,
                                product_transaction_market_id:
                                    stall?.market_active_marketer_market_id,
                                product_transaction_buyer_delivery_address_city:
                                    address.user_address_city ?? '',
                                product_transaction_buyer_delivery_address_county:
                                    address.user_address_county ?? '',
                                product_transaction_buyer_delivery_address_neighborhood:
                                    address.user_address_neighborhood ?? '',
                            };
                            setTimeout(async () => {
                                context.createNewProductTransaction(
                                    true,
                                    (createdProductTransaction) => {
                                        setNewUPT(createdProductTransaction);
                                        context.setLoading(false);
                                        onAddButtonPressed({}, createdProductTransaction);
                                    },
                                    newTransactionFormData,
                                );
                            }, 1000);
                        },
                    },
                ],
            });
            return;
        }
        const productCartItemTo = {
            quantity: count,
            unitType: product.product_unit_type,
        };
        // await setItem('stall', JSON.stringify(stall));
        await context.setGlobalState("cartLoading", true);
        await productTransactionLineAction(productCartItemTo, UPCT);
        //await context.setGlobalState("cartLoading", false);
    };

    const productTransactionLineAction = async (
        customProductCartItem = undefined,
        UPCT = undefined,
    ) => {
        if (
            isReqInProcess && //||
            // context.cartTransaction.product_transaction_market_id !==
            //   stall.market_active_marketer_market_id ||
            // context.cartTransaction.product_transaction_seller_user_id !==
            //   stall.market_active_marketer_user_id
            UPCT === undefined
        ) {
            await new Promise((resolve) => setTimeout(() => resolve(), 1500));
            if (isReqInProcess) {
                toast.info(pageData['continued'].message, { autoClose: 1200 });
                return;
            }
        }

        const incrementValue = product?.product_unit_type === 'Kg' ? 0.5 : 1;
        const newCount = parseFloat(count) + parseFloat(incrementValue);
        const productCartItemTo =
            customProductCartItem !== undefined
                ? customProductCartItem
                : {
                    quantity: newCount.toFixed(1),
                    unitType: product.product_unit_type,
                };

        if (UPCT !== undefined) {
            const product_transaction_line = {
                product_transaction_line_transaction_id: UPCT.product_transaction_id,
                product_transaction_line_product_id: product.product_active_product_id,
                product_transaction_line_seller_user_id:
                    stall.market_active_marketer_user_id,
                product_transaction_line_buyer_user_id: context.user.member_id,
                product_transaction_line_product_name: product.product_name,
                product_name: product.product_name,
                product_unit_type: product.product_unit_type,
                product_active_seller_entry_price: product.product_active_seller_entry_price,
                product_vat_rate: product.product_vat_rate,
                product_images_url: product.product_images_url,
                product_transaction_line_piece: productCartItemTo.quantity,
                product_transaction_line_original_piece: productCartItemTo.quantity,
                product_transaction_line_unit_type: product.product_unit_type,
                product_transaction_line_piece_price: product.product_active_seller_entry_price,
                product_transaction_line_seller_entry_price: product.product_active_seller_entry_price,
                product_transaction_line_release_price: product.product_active_seller_entry_price,
                product_transaction_line_release_total_price_included_vat: (
                    parseFloat(
                        _.replace(product.product_active_seller_entry_price, ',', '.'),
                    ) * parseFloat(productCartItemTo.quantity)
                ).toFixed(2),
                product_transaction_line_is_active: 1,
                product_transaction_line_is_deleted: 0,
                product_transaction_line_created_by_user_id: context.user.member_id,
            };
            //product?.product_active_seller_entry_price,
            //product?.product_vat_rate,
            //product?.product_transaction_line_piece
            setCartItem(() => ({
                ...product,
                ...product_transaction_line,
                product_transaction_line_piece: parseFloat(
                    productCartItemTo.quantity,
                ).toFixed(2),
                isFake: true,
            }));
            setAtCart(true);
            setCount(parseFloat(productCartItemTo.quantity).toFixed(2));
            if (
                !context.cartTransactionLines.some(
                    (cartTransactionLine) =>
                        cartTransactionLine?.product_transaction_line_product_id ===
                        product.product_active_product_id &&
                        cartTransactionLine?.product_transaction_line_product_name ===
                        product?.product_name &&
                        (cartTransactionLine?.isLoading || cartTransactionLine?.isFake),
                )
            ) {
                await context.setCartTransactionLines([
                    {
                        ...product_transaction_line,
                        isLoading: true,
                    },
                ]);
            }
            await context.addItemToCart(
                product_transaction_line,
                (CPTL) => {
                    context.setGlobalState("cartLoading", false);
                    context.setLoading(false);
                    setProductCartItem(CPTL);
                    setCartItem(CPTL);
                    setIsReqInProcess(false);
                },
                false,
            );
            return;
        }

        setCartItem((cartItem) => ({
            ...product,
            ...cartItem,
            product_transaction_line_piece: parseFloat(
                productCartItemTo.quantity,
            ).toFixed(2),
            isFake: true,
        }));
        setAtCart(true);
        setCount(parseFloat(productCartItemTo.quantity).toFixed(2));

        if (productCartItem) {
            const updateFormData = {
                product_transaction_line_original_piece: parseFloat(
                    productCartItemTo.quantity,
                ).toFixed(1),
                product_transaction_line_piece: parseFloat(
                    productCartItemTo.quantity,
                ).toFixed(1),
                product_transaction_line_unit_type: productCartItemTo.unitType,
            };
            const UPTL = {
                ...cartItem,
                ...updateFormData,
                isFake: false,
                isLoading: false,
            };
            setProductCartItem(UPTL);
            setCartItem(UPTL);
        }

        if (
            !productCartItem &&
            !context.cartTransactionLines.some(
                (cartTransactionLine) =>
                    cartTransactionLine?.product_transaction_line_product_id ===
                    product.product_active_product_id &&
                    cartTransactionLine?.product_transaction_line_product_name ===
                    product?.product_name &&
                    (cartTransactionLine?.isLoading || cartTransactionLine?.isFake),
            )
        ) {
            await context.setCartTransactionLines(
                _.concat(context.cartTransactionLines, {
                    product_transaction_line_transaction_id:
                        context.cartTransaction.product_transaction_id,
                    product_transaction_line_product_id:
                        product.product_active_product_id,
                    product_transaction_line_seller_user_id:
                        stall.market_active_marketer_user_id,
                    product_transaction_line_buyer_user_id: context.user.member_id,
                    product_transaction_line_product_name: product.product_name,
                    product_name: product.product_name,
                    product_active_seller_entry_price: product.product_active_seller_entry_price,
                    product_vat_rate: product.product_vat_rate,
                    product_images_url: product.product_images_url,
                    product_unit_type: product.product_unit_type,
                    product_transaction_line_piece: productCartItemTo.quantity,
                    product_transaction_line_original_piece: productCartItemTo.quantity,
                    product_transaction_line_unit_type: product.product_unit_type,
                    product_transaction_line_piece_price:
                        product.product_active_seller_entry_price,
                    product_transaction_line_seller_entry_price:
                        product.product_active_seller_entry_price,
                    product_transaction_line_release_price:
                        product.product_active_seller_entry_price,
                    product_transaction_line_release_total_price_included_vat: (
                        parseFloat(
                            _.replace(product.product_active_seller_entry_price, ',', '.'),
                        ) * parseFloat(productCartItemTo.quantity)
                    ).toFixed(2),
                    product_transaction_line_is_active: 1,
                    product_transaction_line_is_deleted: 0,
                    product_transaction_line_created_by_user_id: context.user.member_id,
                    isLoading: true,
                }),
            );
        }

        if (nextActionTimeout !== null) {
            clearTimeout(nextActionTimeout);
            setNextActionTimeout(null);
        }

        const newDefinedNextActionTimeout = setTimeout(
            () => {
                if (!productCartItem) setIsReqInProcess(true);

                const incrementValue = product?.product_unit_type === 'Kg' ? 0.5 : 1;
                const newCount = parseFloat(count) + parseFloat(incrementValue);
                const productCartItemTo =
                    customProductCartItem !== undefined
                        ? customProductCartItem
                        : {
                            quantity: newCount.toFixed(1),
                            unitType: product.product_unit_type,
                        };

                const product_transaction_line = {
                    product_transaction_line_transaction_id: UPCT
                        ? UPCT.product_transaction_id
                        : context.cartTransaction.product_transaction_id,
                    product_transaction_line_product_id:
                        product.product_active_product_id,
                    product_transaction_line_seller_user_id:
                        stall.market_active_marketer_user_id,
                    product_transaction_line_buyer_user_id: context.user.member_id,
                    product_transaction_line_product_name: product.product_name,
                    product_name: product.product_name,
                    product_unit_type: product.product_unit_type,
                    product_active_seller_entry_price: product.product_active_seller_entry_price,
                    product_vat_rate: product.product_vat_rate,
                    product_images_url: product.product_images_url,
                    product_transaction_line_piece: productCartItemTo.quantity,
                    product_transaction_line_original_piece: productCartItemTo.quantity,
                    product_transaction_line_unit_type: product.product_unit_type,
                    product_transaction_line_piece_price:
                        product.product_active_seller_entry_price,
                    product_transaction_line_seller_entry_price:
                        product.product_active_seller_entry_price,
                    product_transaction_line_release_price:
                        product.product_active_seller_entry_price,
                    product_transaction_line_release_total_price_included_vat: (
                        parseFloat(
                            _.replace(product.product_active_seller_entry_price, ',', '.'),
                        ) * parseFloat(productCartItemTo.quantity)
                    ).toFixed(2),
                    product_transaction_line_is_active: 1,
                    product_transaction_line_is_deleted: 0,
                    product_transaction_line_created_by_user_id: context.user.member_id,
                };

                if (!productCartItem) {
                    context.setGlobalState("cartLoading", true);
                    context.addItemToCart(
                        product_transaction_line,
                        (CPTL) => {
                            setProductCartItem(CPTL);
                            setCartItem(CPTL);
                            setIsReqInProcess(false);
                            context.setGlobalState("cartLoading", false);

                        },
                        false,
                    );
                } else {
                    const updateFormData = {
                        product_transaction_line_original_piece: parseFloat(
                            productCartItemTo.quantity,
                        ).toFixed(1),
                        product_transaction_line_piece: parseFloat(
                            productCartItemTo.quantity,
                        ).toFixed(1),
                        product_transaction_line_unit_type: productCartItemTo.unitType,
                    };
                    setNewUpdateReceived(true);
                    context.setGlobalState("cartLoading", true);
                    context.updateItemInCart(updateFormData, cartItem, () => {
                        context.setGlobalState("cartLoading", false);

                    }, false);
                }
            },
            !productCartItem ? 2000 : 1000,
        );
        setNextActionTimeout(newDefinedNextActionTimeout);
    };


    //console.log("cartItem", cartItem);
    //console.log("product", product);
    return (<div className="products col-lg-3 col-6" style={{ borderRightWidth: (index + 1) % 4 === 0 ? 0 : 1 }}>
        <PriceInput count={count} inCart={atCart}
            updated={context.cartUpdated}
            onMinus={onMinusButtonPressed}
            onPlus={onPlusButtonPressed}
            onAdd={onAddButtonPressed}
            product={product} />
        <div onClick={() => toggle(atCart ? cartItem : product)} style={{ cursor: "pointer" }}>
            <div className="image" data-toggle="modal" data-target="#productModal1">
                <img style={{ backgroundImage: `url(${ajax_loader})`, backgroundSize: 134 }}
                    src={config.image.baseUrl + product.product_images_url} className="img-fluid" loading="lazy"
                    alt={product.product_name} />
            </div>
            <div className="name">{product.product_name}</div>
            <div className="price">{parseFloat(
                _.replace(product.product_active_seller_entry_price, ',', '.',)).toFixed(2)}
                {' '}
                {pageData['unit-price']} / {product.product_unit_type}</div>
        </div>
    </div>);
}

export default Product;
