import React, {useContext, useEffect, useState} from "react";
import * as Actions from "../store/actions/index"
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import Context from "../Utils/Context";
import { getItem } from "../Utils/LocalStorage";
import { useTranslation } from "react-i18next";

function NewToolBar() {
      const { t } = useTranslation();
      const newToolbarData = t('modals.newToolbar', { returnObjects: true })
      const pageData = {
         "address": newToolbarData.dropdowns.find(x => x.name === 'addresses'),
         "orders": newToolbarData.dropdowns.find(x => x.name === 'orders'),
         "profile": newToolbarData.dropdowns.find(x => x.name === 'profile'),
         "pass": newToolbarData.dropdowns.find(x => x.name === 'password'),
         "contact": newToolbarData.dropdowns.find(x => x.name === 'contact'),
         "faq": newToolbarData.dropdowns.find(x => x.name === 'faq'),
         "home": newToolbarData.buttons.find(x => x.name === 'home'),
         "logout": newToolbarData.buttons.find(x => x.name === 'logout'),
         "login": newToolbarData.buttons.find(x => x.name === 'login'),
         "signup": newToolbarData.buttons.find(x => x.name === 'signup'),
      }

   const dispatch = useDispatch();
   const context = useContext(Context);
   const [nav, setNav] = useState(false);
   const [user, setUser] = useState(null);

   useEffect(()=>{
      init();
   },[context.userUpdated, context.user]);

   const init = async () => {
      const user = JSON.parse(await getItem('user'));
      setUser(user);
   };
   const toggleNav = () => {
     setNav(!nav);
   }
   return(<div className="header">
      <div className={(nav ? " menu-mobile" : "hidden")}>
         {user ?
            <>
            <Dropdown.Item href="/addresses"><i className="menu-icons address-icon-white" />{ pageData['address'].placeholder }</Dropdown.Item>
            <Dropdown.Item href="/orders"><i className="menu-icons order-icon-white" />{ pageData['orders'].placeholder }</Dropdown.Item>
            <Dropdown.Item href="/profile"><i className="menu-icons profile-icon-white"/>{ pageData['profile'].placeholder }</Dropdown.Item>
            <Dropdown.Item href="/password-settings"><i className="menu-icons lock-icon-white"/>{ pageData['pass'].placeholder }</Dropdown.Item>
            <Dropdown.Item href="/contact-settings"><i className="menu-icons contact-icon-white"/>{ pageData['contact'].placeholder }</Dropdown.Item>
            <Dropdown.Item href="/faq"><i className="menu-icons sss-icon-white"/>{ pageData['faq'].placeholder }</Dropdown.Item>
            <Dropdown.Item onClick={async () => {
            context.clearSession();
            context.setGlobalState('user', null);
            //history.push('/');
            window.location.href="/";
            console.log("logout");
         }}><i className="menu-icons logout-icon-white"/>{ pageData['logout'].label }</Dropdown.Item>
             </>
            :
             <>
            <Dropdown.Item
                // onClick={()=>{dispatch(Actions.openLoginModal()); toggleNav()}}
                onClick={()=>{
                   window.location.href="/";
                }}

            >
               { pageData['home'].label }</Dropdown.Item>
            <Dropdown.Item
                onClick={()=>{dispatch(Actions.openLoginModal())}}
            >
               { pageData['login'].label }</Dropdown.Item>
            <Dropdown.Item
                onClick={()=>{dispatch(Actions.openSignupModal())}}
            >
               { pageData['signup'].label }</Dropdown.Item>
             </>
         }
      </div>


      <div className="container">
         <div className="row justify-content-between" style={{marginLeft: "0"}}>
            <a className={"nav-toggle fh5co-nav-white"} onClick={toggleNav}><i/></a>
            <Link to="/" className={"logo"}  />
            {/*<a className="search"/>*/}
            {/*<div className={"navbar "+ (nav ? "" : "hidden")}>*/}
            {user ? (
                <Dropdown className="header-dropdown col-lg-3 col-md-6" >
                   <Dropdown.Toggle  className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                      aria-expanded="false">
                      <i className="navbar-signup"/>{user.member_name} {user.member_surname}
                   </Dropdown.Toggle>
                   <Dropdown.Menu className="dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                      <Dropdown.Item href="/addresses"><i className="menu-icons address-icon"/>{ pageData['address'].placeholder }</Dropdown.Item>
                      <Dropdown.Item href="/orders"><i className="menu-icons order-icon"/>{ pageData['orders'].placeholder }</Dropdown.Item>
                      <Dropdown.Item href="/profile"><i className="menu-icons profile-icon"/>{ pageData['profile'].placeholder }</Dropdown.Item>
                      <Dropdown.Item href="/password-settings"><i className="menu-icons lock-icon"/>{ pageData['pass'].placeholder }</Dropdown.Item>
                      <Dropdown.Item href="/contact-settings"><i className="menu-icons contact-icon"/>{ pageData['contact'].placeholder }</Dropdown.Item>
                      <Dropdown.Item href="/faq"><i className="menu-icons sss-icon"/>{ pageData['faq'].placeholder }</Dropdown.Item>
                      <Dropdown.Item onClick={async () => {
                        await context.clearSession();
                         await context.setGlobalState('user', null);
                         //history.push('/');
                         window.location.href="/";
                      }}><i className="menu-icons logout-icon"/>{ pageData['logout'].label }</Dropdown.Item>
                   </Dropdown.Menu>
                </Dropdown>
            ):(<div className={"navbar "}>
                   <a onClick={()=>{dispatch(Actions.openLoginModal())}} className="btn btn-primary mr-3" data-toggle="modal" data-target="#login">
                      <i className="navbar-signup"/>{ pageData['login'].label }</a>
                   <a onClick={()=>{dispatch(Actions.openSignupModal())}} className="btn btn-primary signup-btn" data-toggle="modal" data-target="#signup">
                      { pageData['signup'].label }</a>
                </div>
            )}
            </div>
      </div>
   </div>);
}

export default NewToolBar;
