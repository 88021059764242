import React, {useContext, useEffect} from "react";
import {Button, Modal} from "react-bootstrap";
import Context from "../Utils/Context";

const AgreementDialog = () => {
  const context = useContext(Context);
  const handleClose=()=>{
    context.setGlobalState("showAggrementDialog", false);
  }

  useEffect(()=>{
      context.setLoading(false);
  },[]);
  return(
      <Modal show={!!context.showAggrementDialog} onHide={handleClose} centered>
          <div onClick={handleClose} className="close-modal" data-dismiss="modal"><i/></div>
        <Modal.Body>
{/*            <div className="row">
                <div className="col"><h1 className={"dialog-title text-uppercase"}>{context.showDialog.title}</h1></div>
            </div>*/}
            <div className="row">
                <div className="col" style={{maxHeight:"500px", overflowY:"scroll"}} dangerouslySetInnerHTML={{__html: context.showAggrementDialog.helpDetails?.user_agreement_detail}} />
                   {/* <p className={"dialog-content text-center"}   style={{color:"#90b52a"}}>{context.showAggrementDialog.helpDetails?.user_agreement_detail}</p>
                </div>*/}
            </div>
        </Modal.Body>
        <Modal.Footer className={"border-0 pr-5 pl-5 pb-5"}>
            {context.showAggrementDialog.buttons?.map((button, index)=>(
                <Button block key={index} variant={button.hasOwnProperty('isSecondary') ? "primary": "secondary"} onClick={button.onPress}>
                    {button.text}
                </Button>
            ))}
          {/*<Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>*/}
        </Modal.Footer>
      </Modal>
  );
}
export default AgreementDialog;
