import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useParams} from "react-router";
import Context from "../../Utils/Context";
import {ValToFloat} from "../../Utils/ValToFloat";
import moment from "moment";
import { useTranslation } from "react-i18next";


const Fail = () => {
  const {t} = useTranslation()
  const context = useContext(Context);
  const FailData = t('order.fail', { returnObjects: true })
  const pageData = {
    'fail-title': FailData['custom-content'].find(x => x.name === 'fail-title')['value-single'],
    'fail-text': FailData['custom-content'].find(x => x.name === 'fail-text')['value-single'],
    'home': FailData['custom-content'].find(x => x.name === 'home')['value-single'],
  }
  // const fetchResult = async () => {
  //   let params = new URLSearchParams(document.location.search.substring(1));
  //   let success = params.get("success");
  //
  // }
  //
  //
  // fetchResult();
  // // moment.updateLocale('tr', trLocale);



  return(<div className="order-page">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-12">
          <div className="order-message">
            <h1>{pageData['fail-title']}</h1>
            <h2> {pageData['fail-text']} </h2>
            {/*<p>Lütfen daha sonra tekrar deneyiniz.</p>*/}
          </div>
        </div>
        <div className="address-summary col-lg-8 offset-lg-2 col-12 mt-2">
          <div className="row">
        <Link to={'/'} className="btn btn-secondary">{pageData['home']}</Link>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default Fail
