import React from 'react';
import {withRouter} from "react-router";
import ValidationComponent from "../../../components/Validator/Validator";
import Context from "../../../Utils/Context";
import {checkItem} from "../../../Utils/LocalStorage";
import {Button, Dropdown} from "react-bootstrap";
import { withTranslation } from 'react-i18next';

class SupportForm extends ValidationComponent {
    static contextType = Context;
    deviceLocale = 'tr'
    labels = {
        formData: {
            user_name: 'Ad',
            user_surname: 'Soyadı',
            user_gsm: 'Telefon',
            user_email: 'E-mail',
            user_subject: 'Konu',
            user_note: 'Mesaj',
            // aggrement: 'Sözleşme onayı'
        }
    };
    state = {
        user: null,
        aggrement: false,
        formData: {
            user_name: null,
            user_surname: null,
            user_gsm: null,
            user_email: null,
            user_subject: "",
            user_note: null,
            // aggrement: null
        }
    }
    constructor(props) {
        super(props)
    }

    async componentDidMount() {
        if (await checkItem('user')) {
            await this.context.getUser((data) => {
                //--//--console.warn("user >> data >> ", data);
                const user = data[0];
                this.setState({
                    user: user
                })
                this.updateFormData('user_name', user.user_name);
                this.updateFormData('user_surname', user.user_surname);
                this.updateFormData('user_gsm', user.user_active_gsm);
                this.updateFormData('user_email', user.user_active_email);
            })
        }
    }

    signUpButtonPressed = () => {

        this.validate({
            formData: {
                user_name: { required: true },
                user_surname: { required: true },
                user_gsm: { required: true },
                user_email: { required: true },
                user_subject: { required: true },
                user_note: { required: true },
                // aggrement: { required: !this.state.user }
            }
        });
        if (this.isFormValid() && (this.state.user || this.state.formData.aggrement)) {

            const formData = {
                "optarget": "tbl_dialog_record",
                "optype": "insert",
                "opvalue": "",
                "formid": "",
                dialog_record_source_type: "Mobile App",
                dialog_record_subject_type: this.state.formData.user_subject,
                dialog_record_contact_information: this.state.formData.user_name + ' ' + this.state.formData.user_surname + ' ' + this.state.formData.user_gsm + ' ' + this.state.formData.user_email,
                dialog_record_user_note: this.state.formData.user_note,
                dialog_record_is_active: 1,
                dialog_record_is_deleted: 0
            }

            if (!this.state.user) {
                this.context.fromWebApp('DialogRecordInsert', () => {
                    //--//--console.warn("newmember >> response >> ", response, message);
                    const { t } = this.props
                    const dialogTData = t('showDialogs', { returnObjects: true })
                    const dialogData = dialogTData.find(x => x.name === 'support-dialog')
                    this.context.setGlobalState('showDialog', {
                        title: dialogData.title,
                        message: dialogData.message,
                        buttons: [
                            {
                                text: dialogData.buttons[0].label,
                                onPress: () => { this.context.setGlobalState('showDialog', false); this.props.history.goBack(); }
                            },
                        ],
                    });
                }, '', JSON.stringify(formData))
            } else {
                this.context.insert('dialog_record', formData, () => {
                    const { t } = this.props
                    const dialogTData = t('showDialogs', { returnObjects: true })
                    const dialogData = dialogTData.find(x => x.name === 'support-dialog')
                    //--//--console.warn("data >> ", data);
                    this.context.setGlobalState('showDialog', {
                        title: dialogData.title,
                        message: dialogData.message,
                        buttons: [
                            {
                                text: dialogData.buttons[0].label,
                                onPress: () => { this.context.setGlobalState('showDialog', false); this.props.history.goBack(); }
                            },
                        ],
                    });                });
            }
        }
    }

    updateFormData(key, value) {
        const f = this.state.formData;
        f[key] = value;
        this.setState(f);
    }



    render() {
        const {t} = this.props
        const supportData = t('support.supportForm', { returnObjects: true })
        const pageData = {
            "name": supportData.inputs.find(x => x.name === 'name'),
            "surname": supportData.inputs.find(x => x.name === 'surname'),
            "phone": supportData.inputs.find(x => x.name === 'phone'),
            "email": supportData.inputs.find(x => x.name === 'email'),
            "message": supportData.inputs.find(x => x.name === 'message'),
            "subject": supportData.dropdowns.find(x => x.name === 'subject'),
            "button": supportData.buttons.find(x => x.name === 'send'),
            "subject-1": supportData['custom-content'].find(x => x.name === 'subject-1'),
            "subject-2": supportData['custom-content'].find(x => x.name === 'subject-2'),
            "subject-3": supportData['custom-content'].find(x => x.name === 'subject-3'),
            "subject-4": supportData['custom-content'].find(x => x.name === 'subject-4'),
        }
        return (
            <div className="col-12 col-lg-9 mb-4">
                <div className="address-form">
                    <div className="container">
                        <div className="row">
                            <div className=" col-12">
                                <div id="support-form" className="profile-form needs-validation">
                                    <div className="form-group">
                                        <input type="text"
                                               className="form-control"
                                               id="name"
                                               required
                                               onChange={(value) => { this.updateFormData('user_name', value) }}
                                               autoCorrect={false}
                                               placeholder={pageData['name'].placeholder}
                                               disabled={!this.state.user}
                                               value={this.state.user?.user_name}
                                        />
                                        <label htmlFor="name">{pageData['name'].label}</label>
                                        <div className="invalid-feedback">
                                            {pageData['name'].error}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="text"
                                               className="form-control"
                                               id="surname"
                                               required
                                               onChange={(value) => { this.updateFormData('user_surname', value) }}
                                               autoCorrect={false}
                                               placeholder={pageData['surname'].placeholder}
                                               disabled={!this.state.user}
                                               value={this.state.user?.user_surname}
                                        />
                                        <label htmlFor="surname">{pageData['surname'].label}</label>
                                        <div className="invalid-feedback">
                                            {pageData['surname'].error}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="number"
                                               className="form-control"
                                               id="phone"
                                               placeholder={pageData['phone'].placeholder}
                                               required
                                               autoCorrect={false}
                                               maxLength={10}
                                               disabled={!this.state.user}
                                               value={this.state.user?.user_active_gsm}
                                               onChange={(value) => { this.updateFormData('user_gsm', value); this.updateFormData('user_username', value) }}
                                        />
                                        <label htmlFor="phone">{ pageData['phone'].label }</label>
                                        <div className="invalid-feedback">
                                            {pageData['phone'].error}
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <input type="email"
                                               className="form-control"
                                               id="email"
                                               required
                                               autoCorrect={false}
                                               placeholder={pageData['email'].placeholder}
                                               disabled={!this.state.user}
                                               value={this.state.user?.user_active_email}
                                               onChange={(value) => { this.updateFormData('user_email', value) }}
                                        />
                                        <label htmlFor="email">{ pageData['email'].label }</label>
                                        <div className="invalid-feedback">
                                            {pageData['email'].error}
                                        </div>
                                    </div>
                                    <div className="form-group form-group-select ">
                                        <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                            {this.state.formData.user_subject.length!==0 ? (
                                                <Dropdown.Toggle role={"button"} style={{textAlign:"left"}} className={"select-selected"} as={Button} id="dropdownMenuLink">
                                                    {this.state.formData.user_subject}
                                                </Dropdown.Toggle>
                                            ) : (
                                                <Dropdown.Toggle role={"button"} style={{textAlign:"left"}} className={"select-selected"} as={Button} id="dropdownMenuLink">
                                                    {pageData['subject'].placeholder}
                                                </Dropdown.Toggle>
                                            )}
                                            <Dropdown.Menu className="dropdown-menu scrollable-menu-markets">
                                                <Dropdown.Item className={"dropdown-item"} onClick={() =>this.updateFormData('user_subject', "Öneri")}>Öneri</Dropdown.Item>
                                                <Dropdown.Item className={"dropdown-item"} onClick={() =>this.updateFormData('user_subject', "Şikayet")}>Şikayet</Dropdown.Item>
                                                <Dropdown.Item className={"dropdown-item"} onClick={() =>this.updateFormData('user_subject', "İade")}>İade</Dropdown.Item>
                                                <Dropdown.Item className={"dropdown-item"} onClick={() =>this.updateFormData('user_subject', "Diğer")}>Diğer</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                            <div className="invalid-feedback">
                                                {pageData['subject'].error}
                                            </div>
                                            <label htmlFor="">{pageData['subject'].label}</label>
                                    </div>

                                    <div className="form-group">
                                        <textarea id="message" className="form-control"
                                                  placeholder={pageData['message'].placeholder}
                                                  required
                                                  onChange={(event) => { this.updateFormData('user_note', event.target.value) }}
                                                  autoCorrect={false}
                                        />
                                        <label htmlFor="inputAddress">{pageData['message'].label}</label>
                                        <div className="invalid-feedback">
                                            {pageData['message'].error}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 m-auto">
                                        <button type="submit" className="btn btn-secondary w-100" onClick={()=>{this.signUpButtonPressed()}}>{pageData['button'].label}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(SupportForm));
