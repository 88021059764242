import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import Context from "../../Utils/Context";
import { ValToFloat } from "../../Utils/ValToFloat";
import moment from "moment";
import Fail from "./Fail";
import CircularProgress from '@material-ui/core/CircularProgress';
import { parse } from "postcss";
import { useTranslation } from "react-i18next";

const Thanks = () => {

  // const { id } = useParams();
  const {t} = useTranslation()
  const ThanksData = t('order.thanks')
  const pageData = {
    thanks: ThanksData['custom-content'].find(x => x.name === 'thanks')['value-single'],
    'order-number': ThanksData['custom-content'].find(x => x.name === 'order-number')['value-single'],
    'order-info':ThanksData['custom-content'].find(x => x.name === 'order-info')['value-single'],
    'order-sum': ThanksData['custom-content'].find(x => x.name === 'order-sum')['value-single'],
    'sum-price':ThanksData['custom-content'].find(x => x.name === 'sum-price')['value-single'],
    'top-price': ThanksData['custom-content'].find(x => x.name === 'top-price')['value-single'],
    'delivery-cost': ThanksData['custom-content'].find(x => x.name === 'delivery-cost')['value-single'],
    'delivery-free': ThanksData['custom-content'].find(x => x.name === 'delivery-free')['value-single'],
    'delivery-price':ThanksData['custom-content'].find(x => x.name === 'delivery-price')['value-single'],
    'top-cost':ThanksData['custom-content'].find(x => x.name === 'top-cost')['value-single'],
    'address-name':ThanksData['custom-content'].find(x => x.name === 'address-name')['value-single'],
    'delivery-time':ThanksData['custom-content'].find(x => x.name === 'delivery-time')['value-single'],
    'delivery-note':ThanksData['custom-content'].find(x => x.name === 'delivery-note')['value-single'],
    'home-btn': ThanksData['buttons'].find(x => x.name === 'home-btn')['label'],
    'address': ThanksData['custom-content'].find(x => x.name === 'address')['value-single'],
  }
  const context = useContext(Context);

  // moment.updateLocale('tr', trLocale);


  const [parsed, setParsed] = useState(false);
  const [success, setSuccess] = useState(false);
  const [orderId, setOrderId] = useState(null);


  useEffect(() => {
    let params = new URLSearchParams(document.location.search.substring(1));
    let success1 = params.get("success");
    let orderid1 = params.get("orderid");

    console.log("sss", params.get("success"), typeof success1, orderid1)

    setParsed(true);
    if (success1 && success1 === "true") {

      console.log("trr")
      setOrderId(orderid1)
      setSuccess(success1)
    }
  }, [])




  const [transaction, setTransaction] = useState({});
  const [transactionLines, setTransactionLines] = useState([]);

  useEffect(() => {
    if (orderId) {
      context.select('view_product_sales_list', (data) => {
        //--console.warn("view_product_sales_list >> data >> ", data);
        setTransaction(data[0]);
        setTransactionLines(data);
      }, 'product_transaction_id:' + orderId + '&pageLimit=999999&getEncrypted=0');
    }
  }, [orderId]);

  console.log("idd", orderId, transaction, success, typeof success)


  if (!parsed) {
    return (
      <div className="order-page">
        <div className="container">
          <CircularProgress />
        </div>
      </div>
    )
  }

  if (parsed && !success) {
    return (
      <Fail />
    )
  }

  return (<div className="order-page">
    <div className="container">
      <div className="row">
        <div className="col-lg-8 offset-lg-2 col-12">
          <div className="order-message">
            <h1>{pageData['thanks']}</h1>
            <h2>{orderId} {pageData['order-number']} </h2>
            <p>{pageData['order-info']}</p>
          </div>
        </div>
        <div className="order-summary col-lg-8 offset-lg-2 col-12 mt-5">
          <h1>Sipariş Özeti</h1>
          <div className="row">
            <div className="sum col">{pageData['sum-price']}</div>
            <div className="price col">{transaction && transaction.product_transaction_release_total_price_included_vat && ValToFloat(transaction?.product_transaction_release_total_price_included_vat)?.toFixed(2)} {pageData['top-price']}</div>
          </div>
          <div className="row">
            <div className="sum col">{pageData['delivery-cost']}</div>
            <div className="price col"><span className="cargo-free">{pageData['delivery-free']}</span><span
              className="cargo-fee">{pageData['delivery-price']}</span></div>
          </div>
          <div className="row mt-3">
            <div className="total col">{pageData['top-cost']}</div>
            <div className="total-price col">{transaction && transaction.product_transaction_release_total_price_included_vat && ValToFloat(transaction?.product_transaction_release_total_price_included_vat)?.toFixed(2)} TL</div>
          </div>
          <div className="border-line"></div>
        </div>
        <div className="address-summary col-lg-8 offset-lg-2 col-12 mt-2">
          <div className="row">
            <h2 className="col-12">{pageData['address-name']}</h2>
            <p className="col-12 name">{transaction?.product_transaction_buyer_delivery_address_title}</p>
            <h2 className="col-12">{pageData['address']}</h2>
            <p className="col-12 detail">{transaction?.product_transaction_buyer_delivery_address_full_address}</p>
            <div className="col-12">
              <div className="border-line"></div>
            </div>
            <h2 className="col-12">{pageData['delivery-time']}</h2>
            <p className="col-12 delivery-detail">{transaction?.product_transaction_delivery_start_datetime?.split(" ")[0]} ,
              {transaction?.product_transaction_delivery_time_interval}
            </p>
            <div className="col-12">
              <div className="border-line"></div>
            </div>
            <h2 className="col-12">{pageData['delivery-note']}</h2>
            <p className="delivery-note col-12">{transaction?.product_transaction_buyer_note}</p>

            <Link to={'/'} className="btn btn-secondary">{pageData['home-btn']}</Link>
          </div>
        </div>
      </div>
    </div>
  </div>);
}

export default Thanks
