import React from "react";
import "./NewHome.css";
import img1 from "../../../assets/icon1.png";
import img2 from "../../../assets/icon2.png";
import img3 from "../../../assets/icon3.png";
import img4 from "../../../assets/icon4.png";
import { useTranslation } from "react-i18next";

const MainHowWorks = () => {
    const { t } = useTranslation(); 
    const homeData = t('home', {returnObjects: true})
    const PazardanMainHowWorksData = homeData.components.find(x => x.name === 'main-how-works')
    const mainTitle = PazardanMainHowWorksData['custom-content'].find(x => x.name ==='main-title')['value-single']
    const content1  = PazardanMainHowWorksData['custom-content'].find(x => x.name ==='main-text-1')['value-single']
    const content2 = PazardanMainHowWorksData['custom-content'].find(x => x.name ==='main-text-2')['value-single']
    const content3 = PazardanMainHowWorksData['custom-content'].find(x => x.name ==='main-text-3')['value-single']
    const content4 = PazardanMainHowWorksData['custom-content'].find(x => x.name ==='main-text-4')['value-single']

    return(
        <div className="pazardan-main">
            <div className="main-slider-2" >
                <div>
                    <div className="slider-item-2" style={{backgroundImage:"url(assets/main2back.png)"}}>
                            <span className="main-title-2">{mainTitle}</span>
                        <div className="container">
                        <div className="row justify-content-center mobile-l-icons">
                            <div className="col mobile-s-icons">
                                <span className="dot-main">
                                    <img className="dot-img1" src={img1} alt=""/>
                                </span>
                                <div className="text-2">
                                    { content1 }
                                </div>

                            </div>
                            <div className="col mobile-s-icons">
                                <span className="dot-main">
                                    <img className="dot-img2" src={img2} alt=""/>
                                </span>
                                <div className="text-2">
                                { content2 }
                                </div>
                            </div>
                            <div className="col mobile-s-icons">
                                <span className="dot-main">
                                    <img className="dot-img3" src={img3} alt=""/>
                                </span>
                                <div className="text-2">
                                { content3 }
                                </div>
                            </div>
                            <div className="col mobile-s-icons">
                                <span className="dot-main">
                                    <img className="dot-img4" src={img4} alt=""/>
                                </span>
                                <div className="text-2">
                                { content4 }
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default MainHowWorks;
