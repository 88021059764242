import React from 'react';
import {withRouter} from "react-router";
import withReducer from "../../store/withReducer";
import home from "./store/reducers/home.reducer";
import FeaturedBlog from "./components/FeaturedBlog";
import PazardanMainNew from "./components/PazardanMainNew";
import MainHowWorks from "./components/MainHowWorks";

function Home() {
	return (
		<div style={{ marginRight: "auto", marginLeft: "auto"}}>
			{/*{!context.user ?*/}
			{/*<PazardanMain/> : null }*/}
			<PazardanMainNew />
			<MainHowWorks />
			{/*<div className="google-map">*/}
			{/*	/!*{!context.user ?*!/*/}
			{/*	/!*<DropdownHolder/> : null}*!/*/}
			{/*	<Map/>*/}
			{/*</div>*/}
			<div className="blog-bottom-blogs-mobile">
				<FeaturedBlog/>
			</div>
			<div className="blog-bottom-blogs-desktop">
				<FeaturedBlog/>
			</div>
			{/*<ContactUs/>*/}
		</div>
	)
}

export default withRouter(withReducer("home", home)(Home));
