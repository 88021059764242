import React, {useContext, useEffect, useState} from "react";
import Tezgah from "../../page1/Tezgah";
import Context from "../../../Utils/Context";
import {getItem, setItem} from "../../../Utils/LocalStorage";
import {useHistory, useParams} from "react-router";
import "./Product.css";
import _ from "lodash";
import moment from "moment";
import { useTranslation } from "react-i18next";

const StallsColumn = () => {
    const history = useHistory();
    const context = useContext(Context);
    const {id} = useParams();
    const { t } = useTranslation()
    const StallsColumnData = t('stalls.StallsColumn', { returnObjects: true })
    const pageData = {
        'all-stalls': StallsColumnData['custom-content'].find(x => x.name === 'all-stalls')['value-single']
    }
    const [days] = useState(context.getDays());
    const [monthsShortTR]=useState(context.getMonths().listTr.split('_'));
    const [monthsShortEN]=useState(context.getMonths().listEn.split('_'));
    const [stalls, setStalls] = useState([]);
    const [selected, setSelected] = useState(null);
    const [address, setAddress] = useState(null);
    const [CCN, setCCN] = useState(null);

    useEffect(() => {
        init();
        console.log("is this running?");
    }, [context.user, id]);

    const init = async () => {
        const LAddress = JSON.parse(await getItem('address'));
        const LCCN = JSON.parse(await getItem('CCN'));
        const cart = JSON.parse(await getItem('cart'));
        const stall = JSON.parse(await getItem('stall'));
        if (LAddress) {
            setAddress(LAddress);
        } else {
            setCCN(LCCN);
        }
        await fetchStalls(LAddress, LCCN);
        console.log('stalls', stalls)
    }
    //https://https://mservice.pazardan.app//view_active_marketer_list/view_active_marketer_listCustomSelect?city=%C4%B0STANBUL&county=MALTEPE&neighbourhood=ALTAY%C3%87E%C5%9EME&filterparameters=market_active_marketer_is_deleted:0,market_is_active:1,market_is_deleted:0&pageLimit=99999&getEncrypted=0
    const fetchStalls = async (address, CCN) => {
        const LUser = JSON.parse(await getItem('user'));
        if (address) {
           context.getMarketsCustom(
               address.user_address_city, address.user_address_county, address.user_address_neighborhood,
                async (data) => {
                    if (!_.isEmpty(data) && typeof data !== 'string') {
                        let check = [],
                            ms = [];
                        _.forEach(data, async (m) => {
                            // const closestWeekday = await this.getClosestWeekdayType(
                            //   m.market_weekday_type,
                            //   moment(),
                            //   0,
                            // );
                            // console.warn("closestWeekday >> ", closestWeekday);
                            if (
                                check.indexOf(m.market_weekday_type + ' | ' + m.market_name) ===
                                -1
                            ) {
                                check.push(m.market_weekday_type + ' | ' + m.market_name);
                                // console.warn("check >> ", check);

                                m.isSameDay =
                                    days[moment().format('dddd')] ===
                                    m.market_weekday_type;

                                let isValid = true;
                                if (m.isSameDay) {
                                    const neighborhoods =
                                        m.market_active_marketer_work_range_neighborhoods.split(
                                            ',',
                                        );

                                    const activeNeighborhood = neighborhoods.find((item) =>
                                        item.includes(address.user_address_neighborhood),
                                    );

                                    const currentHour = parseInt(moment().format('HH'));

                                    const neighboorData = activeNeighborhood?.split('_');
                                    const deliverSameDay = neighboorData[3] === '1';

                                    const timesRaw =
                                        m.market_active_marketer_delivery_selected_time_interval_list.split(
                                            ';',
                                        );
                                    const times = timesRaw.map((item) => {
                                        let timeData = item.split(',');
                                        const hours = timeData[0].split('-');
                                        return {
                                            key: timeData[0],
                                            startTime: hours[0].trim(),
                                            endTime: hours[1].trim(),
                                            validTime: Number(timeData[1]),
                                        };
                                    });

                                    if (neighboorData[4].length === 0) {
                                        return;
                                    }
                                    const validTimes = neighboorData[4]
                                        ?.split(';')
                                        .map((item) => {
                                            const hours = item.split('-');
                                            const validTimeTemp = times?.find(
                                                (time) => time.key === item,
                                            );
                                            const activeHour = hours[0]?.trim().split(':')[0];
                                            let validHour;
                                            try {
                                                validHour =
                                                    parseInt(activeHour, 10) -
                                                    parseInt(validTimeTemp.validTime, 10);
                                            } catch (e) {
                                                return;
                                            }
                                            return {
                                                key: item,
                                                canOrder: currentHour < validHour,
                                            };
                                        });

                                    const canOrder = validTimes.find((item) => item.canOrder);
                                    if (!canOrder || !deliverSameDay) {
                                        isValid = false;
                                    }
                                }

                                const closestWeekday = await getClosestWeekdayType(
                                    m.market_weekday_type,
                                    days[moment().format('dddd')] === m.market_weekday_type ? isValid ? moment() : moment().add(1, 'days').startOf('day') : moment(),
                                    0,
                                );

                                m.marketDay = closestWeekday.date + ' ' + m.market_weekday_type;

                                if (!isValid) {
                                    m.isSameDay = false;
                                }
                                ms.push(m);
                                // console.warn("ms >> ", ms);
                            }
                        });
                        setStalls(await ms);
                    }
                    if (id) {
                        let selected = data.find((el) => el.market_active_marketer_id === id);
                        setSelected(selected);
                    }
                },
            );
        }
        else if (CCN && !context.user && !LUser) {
            context.getMarketsCustom(
                CCN.city.city_name, CCN.county.county_name, CCN.neighbourhood.neighborhood_name,
                async (data) => {
                    if (!_.isEmpty(data) && typeof data !== 'string') {
                        let check = [],
                            ms = [];
                        _.forEach(data, async (m) => {
                            if (
                                check.indexOf(m.market_weekday_type + ' | ' + m.market_name) ===
                                -1
                            ) {
                                check.push(m.market_weekday_type + ' | ' + m.market_name);
                                // console.warn("check >> ", check);

                                m.isSameDay =
                                    days[moment().format('dddd')] ===
                                    m.market_weekday_type;

                                let isValid = true;
                                if (m.isSameDay) {
                                    const neighborhoods =
                                        m.market_active_marketer_work_range_neighborhoods.split(
                                            ',',
                                        );

                                    const activeNeighborhood = neighborhoods.find((item) =>
                                        item.includes(CCN.neighbourhood.neighborhood_name),
                                    );

                                    const currentHour = parseInt(moment().format('HH'));

                                    const neighboorData = activeNeighborhood?.split('_');
                                    const deliverSameDay = neighboorData[3] === '1';

                                    const timesRaw =
                                        m.market_active_marketer_delivery_selected_time_interval_list.split(
                                            ';',
                                        );
                                    const times = timesRaw.map((item) => {
                                        let timeData = item.split(',');
                                        const hours = timeData[0].split('-');
                                        return {
                                            key: timeData[0],
                                            startTime: hours[0].trim(),
                                            endTime: hours[1].trim(),
                                            validTime: Number(timeData[1]),
                                        };
                                    });

                                    if (neighboorData[4].length === 0) {
                                        return;
                                    }
                                    const validTimes = neighboorData[4]
                                        ?.split(';')
                                        .map((item) => {
                                            const hours = item.split('-');
                                            const validTimeTemp = times?.find(
                                                (time) => time.key === item,
                                            );
                                            const activeHour = hours[0]?.trim().split(':')[0];
                                            let validHour;
                                            try {
                                                validHour =
                                                    parseInt(activeHour, 10) -
                                                    parseInt(validTimeTemp.validTime, 10);
                                            } catch (e) {
                                                return;
                                            }
                                            return {
                                                key: item,
                                                canOrder: currentHour < validHour,
                                            };
                                        });

                                    const canOrder = validTimes.find((item) => item.canOrder);
                                    if (!canOrder || !deliverSameDay) {
                                        isValid = false;
                                    }
                                }

                                const closestWeekday = await getClosestWeekdayType(
                                    m.market_weekday_type,
                                    days[moment().format('dddd')] === m.market_weekday_type ? isValid ? moment() : moment().add(1, 'days').startOf('day') : moment(),
                                    0,
                                );

                                m.marketDay = closestWeekday.date + ' ' + m.market_weekday_type;

                                if (!isValid) {
                                    m.isSameDay = false;
                                }
                                ms.push(m);
                                // console.warn("ms >> ", ms);
                            }
                        });
                        setStalls(await ms);
                    }
                    if (id) {
                        let selected = data.find((el) => el.market_active_marketer_id === id);
                        setSelected(selected);
                    }
                }
            );
        }
        else if(context.user && LUser && !address){
            context.setGlobalState('addressSelectModal',true);
        }
    };
    const getClosestWeekdayType = (weekday_type, day, i) => {
        if (days[moment(day).format('dddd')] === weekday_type) {
            return {
                date:
                    moment(day).format('DD') +
                    ' ' +
                    monthsShortTR[
                        monthsShortEN.indexOf(moment(day).format('MMM'))
                        ],
                order: i,
            };
        } else {
            const nextDay = moment(day).add(1, 'days');
            // console.warn("nextDay >> ", nextDay, i);
            i++;
            return getClosestWeekdayType(weekday_type, nextDay, i);
        }
    };

    const handleClick = async (stall) => {
        setSelected(stall[0]);
        const loginType = await getItem('loginType')
        const endUserAddress = loginType === 'enduser' ? JSON.parse(await getItem('endUserAddress')).value : address
        //START
        if (!_.isEmpty(stall) && typeof stall !== 'string') {
            if (endUserAddress) {
                await context.setMyNeighborhood(stall[0], endUserAddress)
                await getAndSetMarket(stall, endUserAddress.user_address_neighborhood);
                if(context.cartTransactionLines && context.cartTransactionLines.length === 0 && address){
                    await createNewTransaction(stall[0]);
                }
            }
            // history.push(`/stalls/${stall[0].market_active_marketer_id}`);
            window.location.href=`/stalls/${stall[0].market_active_marketer_id}`;
        }
        //END
    };
    const getAndSetMarket = async (stall, neighborhood) => {
        if (!_.isEmpty(stall) && typeof stall !== 'string') {
            let check = [],
                ms = [];
            _.forEach(stall, async (m) => {
                // const closestWeekday = await this.getClosestWeekdayType(
                //   m.market_weekday_type,
                //   moment(),
                //   0,
                // );
                // console.warn("closestWeekday >> ", closestWeekday);
                if (
                    check.indexOf(m.market_weekday_type + ' | ' + m.market_name) ===
                    -1
                ) {
                    check.push(m.market_weekday_type + ' | ' + m.market_name);
                    // console.warn("check >> ", check);

                    m.isSameDay =
                        days[moment().format('dddd')] ===
                        m.market_weekday_type;

                    let isValid = true;
                    if (m.isSameDay) {
                        const neighborhoods =
                            m.market_active_marketer_work_range_neighborhoods.split(
                                ',',
                            );

                        const activeNeighborhood = neighborhoods.find((item) =>
                            item.includes(neighborhood),
                        );

                        const currentHour = parseInt(moment().format('HH'));

                        const neighboorData = activeNeighborhood?.split('_');
                        const deliverSameDay = neighboorData[3] === '1';

                        const timesRaw =
                            m.market_active_marketer_delivery_selected_time_interval_list.split(
                                ';',
                            );
                        const times = timesRaw.map((item) => {
                            let timeData = item.split(',');
                            const hours = timeData[0].split('-');
                            return {
                                key: timeData[0],
                                startTime: hours[0].trim(),
                                endTime: hours[1].trim(),
                                validTime: Number(timeData[1]),
                            };
                        });
                        if (neighboorData[4].length === 0) {
                            return;
                        }
                        const validTimes = neighboorData[4]
                            ?.split(';')
                            .map((item) => {
                                const hours = item.split('-');
                                const validTimeTemp = times?.find(
                                    (time) => time.key === item,
                                );
                                const activeHour = hours[0]?.trim().split(':')[0];
                                let validHour;
                                try{
                                    validHour =
                                        parseInt(activeHour, 10) -
                                        parseInt(validTimeTemp.validTime, 10);
                                }catch (e){
                                    history.push("/stalls");
                                }

                                return {
                                    key: item,
                                    canOrder: currentHour < validHour,
                                };
                            });

                        const canOrder = validTimes.find((item) => item.canOrder);
                        if (!canOrder || !deliverSameDay) {
                            isValid = false;
                        }
                    }

                    const closestWeekday = await getClosestWeekdayType(
                        m.market_weekday_type,
                        days[moment().format('dddd')] ===
                        m.market_weekday_type
                            ? isValid
                            ? moment()
                            : moment().add(1, 'days').startOf('day')
                            : moment(),
                        0,
                    );

                    m.marketDay = closestWeekday.date + ' ' + m.market_weekday_type;

                    if (!isValid) {
                        m.isSameDay = false;
                    }
                    ms.push({
                        label:
                            closestWeekday.date +
                            ' ' +
                            m.market_weekday_type +
                            ' | ' +
                            m.market_name,
                        value: m,
                        order: closestWeekday.order,
                    });
                    // console.warn("ms >> ", ms);
                }
            });
            // console.warn("ms >>> ", await ms, _.sortBy(ms, 'order'));
            let market = await ms;
            await setItem('market', JSON.stringify(market[0]));
        }
    }

    const createNewTransaction = async (stall) => {
        const existAddress = {
                    product_transaction_buyer_delivery_address_details:
                        address.user_address_detail ?? '',
                    product_transaction_buyer_delivery_address_id:
                        address.user_address_id ?? '',
                    product_transaction_buyer_delivery_address_building_number:
                        address.user_address_building_number ?? '',
                    product_transaction_buyer_delivery_address_floor_number:
                        address.user_address_floor_number ?? '',
                    product_transaction_buyer_delivery_address_door_number:
                        address.user_address_door_number ?? '',
                    product_transaction_buyer_delivery_address_information:
                        address.user_address_information ?? '',
                    product_transaction_buyer_delivery_address_full_address:
                        address.user_address_full_address ?? '',
                    product_transaction_buyer_delivery_address_title:
                        address.user_address_title ?? '',
                }
        const newTransactionFormData = {
            ...existAddress,
            product_transaction_seller_user_id:
            stall?.market_active_marketer_user_id,
            product_transaction_market_id:
            stall?.market_active_marketer_market_id,
            product_transaction_buyer_delivery_address_city:
                address.user_address_city ?? '',
            product_transaction_buyer_delivery_address_county:
                address.user_address_county ?? '',
            product_transaction_buyer_delivery_address_neighborhood:
                address.user_address_neighborhood ?? '',
        };
        await context.createNewProductTransaction(
            true,
            (createdProductTransaction) => {
                context.setLoading(false);
            },
            newTransactionFormData,
        );
    }

    return (<div className="stalls" data-aos="fade-up">
        <div className="container">
            <h2><span className="name">{pageData["all-stalls"]}</span></h2>
            <div className="row justify-center left-stalls">
                {stalls.map((stall, index) => (
                    <span className="w-full flex justify-content-between flex-column align-items-center mb-4"
                          onClick={() => handleClick([stall])} key={index}>
                    <Tezgah
                        selected={selected && (selected.market_active_marketer_id === stall.market_active_marketer_id)}
                        id={stall.market_active_marketer_id}
                        imgUrl={stall.market_active_marketer_market_image_url}
                        name={`${stall.user_name} ${stall.user_surname}`}
                        numProduct={stall.market_active_marketer_product_active_count}
                        pazarName={stall.market_name}
                        ilce={stall.market_county}
                        marketDate={stall.marketDay}
                        rate={parseFloat(
                            _.replace(
                                stall?.market_active_marketer_like_score,
                                ',',
                                '.',
                            ),
                        ) > 0 ? parseFloat(
                            _.replace(
                                stall?.market_active_marketer_like_score,
                                ',',
                                '.',
                            ),
                        ) : 5}/>
                    </span>
                ))}
            </div>
        </div>
    </div>);
}
export default StallsColumn;
