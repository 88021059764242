import React, {useContext, useEffect, useState} from "react";
import stallicon from "../../../assets/images/stall-icon.svg";
import Context from "../../../Utils/Context";
import StoryModal from "../../../layout/StoryModal";
import {getItem} from "../../../Utils/LocalStorage";
import {useHistory, useParams} from "react-router";
import { useTranslation } from "react-i18next";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

const ClerkDetails = ({stall, myNeighborhood, market, rating, visiter, toggleMarketPlacesModal, toggleCommentsModal}) => {
    const { t } = useTranslation()
    const history = useHistory();
    const ClerkDetailsData = t('stalls.ClerkDetails', {returnObjects: true})
    const pageData = {
        stall: ClerkDetailsData['custom-content'].find(x => x.name === 'stall')['value-single'],
        'min-cost': ClerkDetailsData['custom-content'].find(x => x.name === 'min-cost')['value-single'],
        'min-price': ClerkDetailsData['custom-content'].find(x => x.name === 'min-price')['value-single'],
        'comments': ClerkDetailsData['buttons'].find(x => x.name === 'comments')['label'],
        'stalls': ClerkDetailsData['buttons'].find(x => x.name === 'stalls')['label'],
    }
    const context = useContext(Context);
    const {id} = useParams();
    const [stories,setStories] = useState([]);
    const [storyModal, setStoryModal] = useState(false);
    const [ratingCount, setRatingCount] = useState([])
    const [emptyRating, setEmptyRating] = useState([])
    useEffect(()=>{
        init();
    },[id]);
    
    const init = async () => {
        const countArr = []
        const emptyArr = []
        for (let index = 0; index < parseFloat(rating); index++) {
            countArr.push(index)
        }
        for (let index = 0; index < 5 - parseFloat(rating); index++) {
            emptyArr.push(index)
        }
        setRatingCount(countArr)
        setEmptyRating(emptyArr)
        await  context.select(
            'story',
            (data) => {
                const storiesTemp = [];

                data.map((story, index) => {
                    storiesTemp.push(story.story_file_location)
                    return {
                        id: story.story_id,
                        url: story.story_file_location,
                        type: 'image',
                        duration: 5,
                        isSeen: false,
                        isPaused: index > 0,
                    };
                });
                setStories(storiesTemp);
            },
            `story_created_by_user_id:${stall.market_active_marketer_user_id},story_tagname1:${stall.market_active_marketer_market_id}&pageLimit=99999`,
        );
        const marketLocal = JSON.parse(await getItem('market'));
        if(marketLocal){
            //setMarket(marketLocal);

        }
    }
    const toggleStory = () => {
      setStoryModal(!storyModal);
    }
    const toggleBack = () => {
        // history.goBack()
        window.location.href='/stalls';
    }
    //console.log("clerk details", market);
    return (<div className="col-12">
            <div className="row single-seller d-none d-lg-flex">
                {/*<div className="seller-image col-lg-2 col-4 text-center p-0" onClick={()=>stories.length>0 && toggleStory()} data-toggle="modal" data-target="#story">*/}
                <div className="seller-image text-center p-0 col-lg-2 col-6" style={{paddingLeft: "0", paddingRight:"0"}}
                     onClick={()=>stories.length>0 && toggleStory()} data-toggle="modal" data-target="#story">
                <div className="d-lg-block flex flex-column justify-content-between h-100">
                <img src={stall?.market_active_marketer_profile_image_url} className="rounded-circle mx-lg-auto d-block"
                         style={{width: "128px", height: "128px", objectFit:"cover", border: stories && stories.length && stories.length > 0 ? "4px solid #90B52A" : ""}} alt="Pazarcı"/>
                       <div className="p-2 d-lg-none mt-2 rounded-lg" style={{border: "#E9F1D5 3px solid", color:"#46742C", borderSize: '10px'}}>
                        {/* <div className="flex w-full justify-content-between">
                            <img src={stallicon} alt="" style={{height: "2rem", width:"auto"}}/>
                            <div className="flex flex-column justify-content-end">
                                <div className="font-weight-bold text-md flex text-right">{market && market.label ? market.label.split("|")[1] : ""}</div>
                                <div className="text-right text-md font-weight-bold" style={{color: "#84B729"}}>{market && market.label ? market.label.split("|")[0] : ""}</div>
                            </div>
                        </div> */}
                        <div className="divide-y divide-green-500 text-sm text-left font-weight-bolder">
                          <h5>  {pageData['min-cost']} {!isNaN(parseFloat(myNeighborhood?.minPrice).toFixed(2)) ? parseFloat(myNeighborhood?.minPrice).toFixed(2) : "-"} {pageData['min-price']}</h5>
                        </div>
                    </div>
                </div>
                </div>
                {/*<div className="col-lg-5 col-8 seller-details">*/}
                <div className="flex col-lg-7 col-6" style={{paddingLeft: "0", paddingRight:"0"}}>
                <div className="col-lg-7 col-12 seller-details">
                    <h1>{stall?.user_name} {stall?.user_surname} {pageData['stall']}</h1>
                    <p>{stall?.market_active_marketer_information}</p>
                </div>
                <div className="col-lg-5 d-none d-lg-block clerk-sub-container">
                    <div className="divide-y divide-gray-300 p-2 rounded-lg" style={{backgroundColor: "#E9F1D5", color:"#46742C"}}>
                        <div className="flex w-full justify-content-between">
                            <img src={stallicon} alt="" style={{height: "2rem", width:"auto"}}/>
                            <div className="flex flex-column justify-content-end">
                                <div className="font-weight-bold text-md flex text-right">{market && market.label ? market.label.split("|")[1] : ""}</div>
                                <div className="text-right text-md font-weight-bold" style={{color: "#84B729"}}>{market && market.label ? market.label.split("|")[0] : ""}</div>
                            </div>
                        </div>
                        <div className="divide-y divide-green-500 text-sm text-right font-weight-bolder">
                            {pageData['min-cost']} <span>{!isNaN(parseFloat(myNeighborhood?.minPrice).toFixed(2)) ? parseFloat(myNeighborhood?.minPrice).toFixed(2) : "-"} {pageData['min-price']}</span>
                        </div>
                        <div className="text-sm text-right font-weight-bolder">Tezgahı {visiter} ziyaret etti</div>
                    </div>
                </div>
                </div>
                {/*<div className="col-lg-2">*/}
                <div className="col-lg-3"  style={{paddingLeft: "0", paddingRight:"0"}}>
                    <div className="row btn-comment-stall">
                        <a onClick={toggleMarketPlacesModal} className="btn btn-primary w-100 mb-4  col-lg-12 col-6 m-auto comments-btn">{pageData['stalls']}</a>
                        <a onClick={toggleCommentsModal} className="btn btn-primary w-100  mb-4 h-100 col-lg-12 col-6 m-auto comments-btn">{pageData['comments']}</a>
                    </div>
                </div>
            </div>
            <div className="d-lg-none text-white p-2 mb-2 rounded-md" style={{ backgroundColor: "#46742c"}}>
                <div className="d-flex align-items-start w-100 row mx-auto">
                <button onClick={toggleBack}>
                    <ArrowBackOutlinedIcon></ArrowBackOutlinedIcon>
                </button>
                <div className="col-8">
                <p className="font-weight-bold mb-0">{stall?.user_name} {stall?.user_surname} {pageData['stall']}</p>
                <div className="mt-2">
                {
                    ratingCount.map(x => {
                        return <FavoriteIcon style={{ color:"#84b700" }}></FavoriteIcon>
                    })
                }
                {
                  emptyRating.map(x => {
                    return <FavoriteIcon></FavoriteIcon>
                })  
                }
                <span className="ml-2 font-weight-bold">
                { parseFloat(rating).toFixed(1)}/5
                </span>
                </div>
                <p className="text-sm mt-2 mb-0" style={{ color:"#84b700" }}> <span className="text-white">{pageData['min-cost']}: </span> {!isNaN(parseFloat(myNeighborhood?.minPrice).toFixed(2)) ? parseFloat(myNeighborhood?.minPrice).toFixed(2) : "-"} {pageData['min-price']}</p>
                <p className="text-sm mt-2 mb-0 font-weight-bold">Tezgahı {visiter} ziyaret etti</p>
                </div>
                <div className="col-3 p-0 d-flex justify-content-end">
                <img src={stall?.market_active_marketer_profile_image_url} className="rounded-circle mx-lg-auto d-block"
                style={{width: "4rem", height: "4rem", objectFit:"cover", border: stories && stories.length && stories.length > 0 ? "4px solid #90B52A" : ""}} alt="Pazarcı"/>
                </div>
                </div>
            </div>
            <div className="w-100 my-3 row mx-auto d-md-none" style={{paddingLeft: "0", paddingRight:"0"}}>
                    <a onClick={toggleMarketPlacesModal} className="btn btn-primary border-0 w-100 col-5 m-auto">{pageData['stalls']}</a>
                    <a onClick={toggleCommentsModal} className="btn btn-primary border-0 w-100  h-100 col-5 m-auto">{pageData['comments']}</a>
                </div>
            <StoryModal open={storyModal} toggle={toggleStory} stories={stories}/>
        </div>);
}
export default ClerkDetails;
