import React, {useEffect} from 'react';
import { useTranslation } from 'react-i18next';
import {withRouter} from "react-router";

function ClarificationText() {
    const { t } =useTranslation()
    const textData = t('clarificationText')
    useEffect(() => {
        scrollToTop();
    }, []);
    const scrollToTop = () => {
        window.scroll({top: 0, behavior: "smooth"});
    };
    return (
        <div className="container" style={{marginTop: "6rem"}}>
            <div className="mb-8"/>
            <div className="mb-8"/>
            <div dangerouslySetInnerHTML={{ __html: textData }}>
            </div>
        </div>
    );
}

export default withRouter(ClarificationText);
