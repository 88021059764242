import React, {Component} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import {config} from "../../../Utils/Config";
import _ from "lodash";
import Context from "../../../Utils/Context";
import {getItem} from "../../../Utils/LocalStorage";
import 'react-toastify/dist/ReactToastify.css';
import { Trans } from 'react-i18next';
import { withTranslation } from 'react-i18next';

class ProductDetailsModal extends Component {
    static contextType = Context;
    state = {
        product: this.props.product,
        stall: this.props.stall,
        atCart: false,
        cart: [],
        productCartItem: null,
        cartItem: null,
        count: 1,
        minValue: 0,
        isReqInProcess: false,
        waitUpdate: false,
        newUPT: null,
        newUpdateReceived: false,
        increasing: false,
        nextActionTimeout: null,
        decreasing: false,
        tO: null,
        pageData: {},
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        //await this.fetchData();
        console.log("product details modal mounted", this.props.product);
        this.setState({
            product: this.props.product,
            stall: this.props.stall,
            atCart: false,
            count: 1,
        });
        if (this.props.product.product_transaction_id) {
            this.setState({
                atCart: true,
                count: parseFloat(
                    _.replace(this.props.product.product_transaction_line_piece, ',', '.'),
                ).toFixed(2),
            });
        }
    }

    onSPDQMinusButtonPressed = () => {
        if (
            parseFloat(this.state.count) >
            (this.state.product?.product_unit_type === 'Kg' ? 0.5 : 1)
        ) {
            const decrementValue =
                this.state.product?.product_unit_type === 'Kg' ? 0.5 : 1;
            const count = parseFloat(this.state.count) - parseFloat(decrementValue);
            this.setState({count: count.toFixed(1)}, () => {
                this.forceUpdate();
            });
        }
    };
    onSPDQPlusButtonPressed = () => {
        const incrementValue =
            this.state.product?.product_unit_type === 'Kg' ? 0.5 : 1;
        const count =
            this.state.count === 0
                ? 1
                : parseFloat(this.state.count) + parseFloat(incrementValue);
        this.setState({count: count.toFixed(1)}, () => {
            this.forceUpdate();
        });
    };

    onSPDAddToCartButtonPressed = async (
        e = {},
        UPT = undefined,
        forceAsk = false,
    ) => {
        this.props.setUpdated(!this.props.updated);
        if (this.state.product?.product_transaction_id) {
            await this.context.setGlobalState("cartLoading", true);
            if (
                parseFloat(this.state.count).toFixed(2) >
                parseFloat(
                    _.replace(
                        this.state.product.product_transaction_line_piece,
                        ',',
                        '.',
                    ),
                ).toFixed(2)
            ) {
                const newCount = parseFloat(this.state.count);
                const count =
                    this.state.product?.product_unit_type === 'Kg'
                        ? newCount.toFixed(2)
                        : newCount.toFixed(1);
                const productCartItem = {
                    quantity: count,
                    unitType: this.state.product.product_unit_type,
                };
                this.state.product.cart = productCartItem;
                await this.createProductTransactionLine(productCartItem);
                await this.context.setGlobalState("cartLoading", false);
            } else {
                await this.context.setGlobalState("cartLoading", true);
                const newCount = parseFloat(this.state.count);
                if (
                    newCount >= (this.state.product?.product_unit_type === 'Kg' ? 0.5 : 1)
                ) {
                    const count =
                        this.state.product?.product_unit_type === 'Kg'
                            ? newCount.toFixed(2)
                            : newCount.toFixed(1);
                    const productCartItem = {
                        quantity: count,
                        unitType: this.state.product.product_unit_type,
                    };
                    this.state.product.cart = productCartItem;
                    await this.createProductTransactionLine(productCartItem);
                    await this.context.setGlobalState("cartLoading", false);
                } else {
                    await this.context.setGlobalState("cartLoading", true);
                    const updateFormData = {
                        product_transaction_line_original_piece:
                        this.state.product.product_transaction_line_original_piece,
                        product_transaction_line_is_active: 0,
                        product_transaction_line_is_deleted: 1,
                        product_transaction_line_piece: '0.0',
                    };
                    this.context
                        .updateItemInCart(updateFormData, this.state.product)
                        .then(async () => {
                            await this.props.route.params.syncCartItems();
                            await this.context.setCartTransactionLines(
                                this.context.cartTransactionLines.filter(
                                    (item) => item !== this.state.product,
                                ),
                            );
                            await this.context.setGlobalState("cartLoading", false);
                            const { t } = this.props
                            const toastsData = t('toasts', { returnObjects: true })
                            this.toast.success(toastsData.find(x => x.name === 'dropped').message, { autoClose: 1000 });
                            //this.props.navigation.goBack();
                        });
                }
            }
        }
        else {
            if (this.context.user === null) {
                this.context.setGlobalState('loginAlert', true);
                return;
            }

            const newCount = parseFloat(this.state.count);
            const count =
                this.state.product?.product_unit_type === 'Kg'
                    ? newCount.toFixed(2)
                    : newCount.toFixed(1);
            const productCartItem = {
                quantity: count,
                unitType: this.state.product.product_unit_type,
            };
            this.state.product.cart = productCartItem;
            await this.createProductTransactionLine(productCartItem, UPT, forceAsk);
        }
    };

    createProductTransactionLine = async (
        customProductCartItem = undefined,
        UPT = undefined,
        forceAsk = false,
    ) => {
        if (!this.state.product?.product_transaction_id) {
            const address = JSON.parse(await getItem('address'));
            const neighborhoods =
                this.state.stall.market_active_marketer_work_range_neighborhoods.split(
                    ',',
                );
            const selectedNeigborhood = neighborhoods.find((item) => {
                const neighborhood = item.split('_');
                if (
                    neighborhood[0] === address.user_address_county &&
                    neighborhood[1] === address.user_address_neighborhood
                ) {
                    return true;
                }
                return false;
            });
            if (!selectedNeigborhood) {
                const { t } = this.props
                const dialogData = t('stalls.ProductDetails', { returnObjects: true })
                const dialog = {
                    'title': dialogData['custom-content'].find(x => x.name === 'dialog-title')['value-single'],
                    'text-1': dialogData['custom-content'].find(x => x.name === 'dialog-text-1')['value-single'],
                    'text-2': dialogData['custom-content'].find(x => x.name === 'dialog-text-2')['value-single'],
                    'text-3': dialogData['custom-content'].find(x => x.name === 'dialog-text-3')['value-single'],
                    'text-4': dialogData['custom-content'].find(x => x.name === 'dialog-text-4')['value-single'],
                    'text-5': dialogData['custom-content'].find(x => x.name === 'dialog-text-5')['value-single'],
                    'text-6': dialogData['custom-content'].find(x => x.name === 'dialog-text-6')['value-single'],
                    'text-7': dialogData['custom-content'].find(x => x.name === 'dialog-text-7')['value-single'],
                    'text-8': dialogData['custom-content'].find(x => x.name === 'dialog-text-8')['value-single'],
                }
                this.context.setGlobalState('showDialog', {
                    title: dialog['title'],
                    message:
                        dialog['text-1'] +
                        (address.isMarketFromGeocodingLocation
                            ? dialog['text-2']
                            : address.isMarketFromManuelLocation
                                ? address.user_address_neighborhood + dialog['text-3'] +
                                address.user_address_county + address.user_address_city + dialog['text-4']
                                : `${
                                    address?.user_address_title === undefined
                                        ? '"' + address.user_address_title + dialog['text-5']
                                        : dialog['text-6']
                                }`) +
                        dialog['text-7'] +
                        dialog['text-8'],
                    showCloseButton: true,
                    customTopStyle: '30%',
                    buttons: [
                        {
                            text: 'Tamam',
                            onPress: () => {
                                this.context.setGlobalState('showDialog', false);
                                //this.props.navigation.goBack();
                            },
                        },
                        {
                            text: 'Adres Ekle',
                            isSecondary: true,
                            onPress: () => {
                                console.log("adres ekleme");
                            },
                        },
                    ],
                });
                return;
            }
        }
        if (
            (
                ( this.context.cartTransactionLines.length !== 0 &&
                (this.context.cartTransaction?.product_transaction_market_id !==
                    this.state.stall?.market_active_marketer_market_id ||
                    this.context.cartTransaction.product_transaction_seller_user_id !==
                    this.state.stall.market_active_marketer_user_id)
                )
                && UPT === undefined) ||
            (forceAsk === true && this.context.cartTransactionLines?.length > 0)
        ) {
            const { t } = this.props
            const dialogTData = t('showDialogs', { returnObjects: true })
            const dialogData = dialogTData.find(x => x.name === 'product-detail-same-marketer')
            this.context.setGlobalState('showDialog', {
                title: dialogData.title,
                message:
                dialogData.message,
                buttons: [
                    {
                        text: dialogData.buttons.find(x => x.name === 'no').label,
                        onPress: () => {
                            this.context.setGlobalState('showDialog', false);
                            //this.props.navigation.goBack();
                        },
                    },
                    {
                        text: dialogData.buttons.find(x => x.name === 'yes').label,
                        isSecondary: true,
                        onPress: async () => {
                            await this.context.setGlobalState('showDialog', false);
                            this.context.setLoading(true);
                            const address = JSON.parse(await getItem('address'));
                            if (!address) {
                                return;
                            }
                            const existAddress =
                                !address.isMarketFromGeocodingLocation &&
                                !address.isMarketFromManuelLocation
                                    ? {
                                        product_transaction_buyer_delivery_address_details:
                                            address.user_address_detail ?? '',
                                        product_transaction_buyer_delivery_address_id:
                                            address.user_address_id ?? '',
                                        product_transaction_buyer_delivery_address_building_number:
                                            address.user_address_building_number ?? '',
                                        product_transaction_buyer_delivery_address_floor_number:
                                            address.user_address_floor_number ?? '',
                                        product_transaction_buyer_delivery_address_door_number:
                                            address.user_address_door_number ?? '',
                                        product_transaction_buyer_delivery_address_information:
                                            address.user_address_information ?? '',
                                        product_transaction_buyer_delivery_address_full_address:
                                            address.user_address_full_address ?? '',
                                        product_transaction_buyer_delivery_address_title:
                                            address.user_address_title ?? '',
                                    }
                                    : {
                                        product_transaction_buyer_delivery_address_details: '',
                                        product_transaction_buyer_delivery_address_id: '',
                                        product_transaction_buyer_delivery_address_building_number:
                                            '',
                                        product_transaction_buyer_delivery_address_floor_number:
                                            '',
                                        product_transaction_buyer_delivery_address_door_number:
                                            '',
                                        product_transaction_buyer_delivery_address_information:
                                            '',
                                        product_transaction_buyer_delivery_address_full_address:
                                            '',
                                        product_transaction_buyer_delivery_address_title:
                                            address.isMarketFromGeocodingLocation
                                                ? 'Lokasyon'
                                                : address.isMarketFromManuelLocation
                                                    ? 'Manuel'
                                                    : 'Mevcut Adres',
                                    };
                            const newTransactionFormData = {
                                ...existAddress,
                                product_transaction_seller_user_id:
                                this.state.stall?.market_active_marketer_user_id,
                                product_transaction_market_id:
                                this.state.stall?.market_active_marketer_market_id,
                                product_transaction_buyer_delivery_address_city:
                                    address.user_address_city ?? '',
                                product_transaction_buyer_delivery_address_county:
                                    address.user_address_county ?? '',
                                product_transaction_buyer_delivery_address_neighborhood:
                                    address.user_address_neighborhood ?? '',
                            };
                            setTimeout(async () => {
                                this.context.createNewProductTransaction(
                                    true,
                                    (createdProductTransaction) => {
                                        this.context.setCartTransactionLines([]);
                                        this.context.setLoading(false);
                                        this.onSPDAddToCartButtonPressed(
                                            {},
                                            createdProductTransaction,
                                        );
                                    },
                                    newTransactionFormData,
                                );
                            }, 1000);
                        },
                    },
                ],
            });
            return;
        }
        if (this.context.cartTransaction === null && UPT === undefined) {
            return;
        }
        const incrementValue =
            this.state.product?.product_unit_type === 'Kg' ? 0.5 : 1;
        const count = parseFloat(this.state.count) + parseFloat(incrementValue);
        const productCartItem =
            customProductCartItem !== undefined
                ? customProductCartItem
                : {
                    quantity: count.toFixed(1),
                    unitType: this.state.product.product_unit_type,
                };

        const product_transaction_line = {
            product_transaction_line_transaction_id:
                UPT === undefined
                    ? this.context.cartTransaction?.product_transaction_id
                    : UPT.product_transaction_id,
            product_transaction_line_product_id:
            this.state.product.product_active_product_id,
            product_transaction_line_seller_user_id:
            this.state.stall?.market_active_marketer_user_id,
            product_transaction_line_buyer_user_id: this.context.user.member_id,
            product_transaction_line_product_name: this.state.product.product_name,
            product_transaction_line_piece: productCartItem.quantity,
            product_transaction_line_original_piece: productCartItem.quantity,
            product_transaction_line_unit_type: this.state.product.product_unit_type,
            product_transaction_line_piece_price:
            this.state.product.product_active_seller_entry_price,
            product_transaction_line_seller_entry_price:
            this.state.product.product_active_seller_entry_price,
            product_transaction_line_release_price:
            this.state.product.product_active_seller_entry_price,
            product_transaction_line_release_total_price_included_vat: (
                parseFloat(
                    _.replace(
                        this.state.product.product_active_seller_entry_price,
                        ',',
                        '.',
                    ),
                ) * parseFloat(productCartItem.quantity)
            ).toFixed(2),
            product_transaction_line_is_active: 1,
            product_transaction_line_is_deleted: 0,
            product_transaction_line_created_by_user_id: this.context.user.member_id,
        };

        if (!this.state.atCart) {
            this.context.setLoading(true);
            if (
                !this.context.cartTransactionLines.some(
                    (cartTransactionLine) =>
                        cartTransactionLine?.product_transaction_line_product_id ===
                        this.state.product?.product_active_product_id &&
                        cartTransactionLine?.product_transaction_line_product_name ===
                        this.state.product?.product_name &&
                        (cartTransactionLine?.isLoading || cartTransactionLine?.isFake),
                )
            ) {
                await this.context.setCartTransactionLines(
                    _.concat(this.context.cartTransactionLines, {
                        ...product_transaction_line,
                        isLoading: true,
                    }),
                );
            }
            await this.context.addItemToCart(product_transaction_line, () => {
                //this.props.navigation.goBack(),
                console.log('addItemToCart function');
                this.props.toggle(this.state.product);
                this.props.setSelectedProduct(null);
            });
        } else {
            const updateFormData = {
                product_transaction_line_original_piece: productCartItem.quantity,
                product_transaction_line_piece: productCartItem.quantity,
            };
            await this.context.updateItemInCart(
                updateFormData,
                this.state.product,
                () => {
                    //this.props.navigation.goBack();
                    //console.log('updateItemInCart function');
                    this.props.toggle(this.state.product);
                    this.props.setSelectedProduct(null);
                },
            );
        }
    };

    render() {
        //console.log("productCartItem ", this.state.product, this.state.count);
        const { t } = this.props
        const ModalData = t('stalls.ProductDetails', { returnObjects: true })
        const pageData = {
            'more': ModalData['custom-content'].find(x => x.name === 'more')['value-single'],
            'add-basket': ModalData['buttons'].find(x => x.name === 'add-basket')['label'],
            'update-basket': ModalData['buttons'].find(x => x.name === 'update-basket')['label'],
        }
        return (<Modal show={this.props.open} id="comments" className={"product-modal"} centered>
            <div className="close-modal" onClick={() => {
                this.props.toggle(this.state.product);
                this.props.setSelectedProduct(null);
            }} data-dismiss="modal"><i/>
            </div>
            <ModalBody>
                <div className="row">
                    <div className="col-12  text-center">
                        <div className="image"><img src={config.image.baseUrl + this.state.product?.product_images_url}
                                                    alt={this.state.product?.product_name}
                                                    className="img-fluid img-fluid-2"/></div>
                    </div>
                    <div className="col-12">
                        <div className="row">
                            <div className="col-6 title">{this.state.product?.product_name}</div>
                            <div className="col-6">
                                <div className="rating ">
                                    <span
                                        className={parseFloat(this.state.stall?.market_active_marketer_like_score) >= 1 ? "" : "grey"}/>
                                    <span
                                        className={parseFloat(this.state.stall?.market_active_marketer_like_score) >= 2 ? "" : "grey"}/>
                                    <span
                                        className={parseFloat(this.state.stall?.market_active_marketer_like_score) >= 3 ? "" : "grey"}/>
                                    <span
                                        className={parseFloat(this.state.stall?.market_active_marketer_like_score) >= 4 ? "" : "grey"}/>
                                    <span
                                        className={parseFloat(this.state.stall?.market_active_marketer_like_score) >= 5 ? "" : "grey"}/>
                                    <i className="points ml-3">{parseFloat(
                                        _.replace(
                                            this.state.stall?.market_active_marketer_like_score,
                                            ',',
                                            '.',
                                        ),
                                    ) > 0 ? parseFloat(
                                        _.replace(
                                            this.state.stall?.market_active_marketer_like_score,
                                            ',',
                                            '.',
                                        ),
                                    ).toFixed(1) : 5.0}/5</i>
                                </div>
                                <div
                                    className="market-info text-right">{this.state.product?.market_name},<br/>{this.state.product?.market_county}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-line"/>
                    <div
                        className="col-12 price">{parseFloat(_.replace(this.state.product?.product_active_seller_entry_price, ',', '.')).toFixed(2)}
                        {' '}TL / {this.state.product?.product_unit_type}</div>
                    <div className="col-12">
                        <div className="row pb-4">
                            <div className="col-5">
                                <div className="size input-group">
                                    <button className="minus-item-modal input-group-addon"
                                            onClick={() => this.onSPDQMinusButtonPressed()}/>
                                    <input type="number" className="item-count-modal form-control" data-id="1"
                                           value={this.state.count} onChange={(e) => console.log(e)} disabled/>
                                    <button className="plus-item-modal input-group-addon"
                                            onClick={() => this.onSPDQPlusButtonPressed()}/>
                                </div>
                            </div>
                            <a onClick={() => this.onSPDAddToCartButtonPressed()}
                               className="btn btn-secondary d-table offset-lg-1 col-6 add-to-cart">
                                {this.state.atCart ?  pageData['update-basket'] : pageData['add-basket'] }
                            </a></div>
                    </div>
                    <div className="border-line"/>
                    <div className="col-12 description"><h5>{pageData['more']}</h5>
                        <p>{this.state.product?.product_definiton}</p>
                    </div>
                </div>
            </ModalBody>
        </Modal>);
    }
}

export default withTranslation()(ProductDetailsModal)
