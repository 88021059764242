import React, {useContext, useEffect, useState} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import Context from "../../../Utils/Context";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const CommentsModal = ({open, stall, toggle}) => {
    const context = useContext(Context);
    const { t }  = useTranslation()
    const ModalData = t('stalls.CommentsModal', { returnObjects: true })
    const pageData = {
        'comments-title': ModalData['custom-content'].find(x => x.name === 'comments-title')['value-single']
    }
    const [comments, setComments] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const fetchResult = () => {
        context.fromWebApp('MarketerCommentList', (data) => {
            //--//--console.warn("data >> ", data.data);
            if (!data) return;
/*            this.setState({
                comments: comments.concat(data.data),
                offset: offset + 1,
                limit: limit
            });*/
            setComments(comments.concat(data.data));
            setOffset(offset+1);
            setLimit(limit);
        }, '&marketer_user_id=' + stall.market_active_marketer_user_id + '&getEncrypted=0&pgidx=' + offset + '&pagelimit=' + limit);
    }
    useEffect(()=>{
        if (open) {
            fetchResult();
        }
    },[open]);

  return(<Modal show={open} id="comments" centered>
      <div className="close-modal" onClick={toggle} data-dismiss="modal"><i/></div>
      <ModalBody>
          <h1 className="col-12 col-lg-10 m-auto">{ pageData['comments-title']}</h1>
          <div className="row content">
            {
                comments.length == 0 && (
                    <p className="col-12 text-center user-comment">Bu pazarcı için henüz yorum yapılmamıştır.</p>
                )
            }
              {comments.map((comment, index)=>(
                  <div key={index} className="col-12 col-lg-11 m-auto">
                      <div className="row">
                          <p className="col-6 username">{comment.product_transaction_buyer_delivery_name.slice(0, 2) + '***'} {comment.product_transaction_buyer_delivery_surname.slice(0, 2) + '***'}</p>
                          <div className="rating col-6 p-0">
                              <span className={parseFloat(comment.product_transaction_buyer_sales_like_score || 5).toFixed(1)>=1.0 ? "":"grey"}/>
                              <span className={parseFloat(comment.product_transaction_buyer_sales_like_score || 5).toFixed(1)>=2.0 ? "":"grey"}/>
                              <span className={parseFloat(comment.product_transaction_buyer_sales_like_score || 5).toFixed(1)>=3.0 ? "":"grey"}/>
                              <span className={parseFloat(comment.product_transaction_buyer_sales_like_score || 5).toFixed(1)>=4.0 ? "":"grey"}/>
                              <span className={parseFloat(comment.product_transaction_buyer_sales_like_score || 5).toFixed(1)>=5.0 ? "":"grey"}/>

                              <i className="points">{parseFloat(comment.product_transaction_buyer_sales_like_score || 5).toFixed(1)}/5</i>
                          </div>
                          <p className="col-12 user-comment">{comment.product_transaction_buyer_sales_comment}</p>
                      </div>
                  </div>
              ))}
          </div>
      </ModalBody>
  </Modal>);
}

export default CommentsModal;
