import React from "react";
import {Modal} from "react-bootstrap";
import Stories from "react-insta-stories";

const StoryModal = ({open, toggle, stories}) => {

    return (<Modal show={open} onHide={()=>toggle()} id={"loader"} centered>
        <Stories
            stories={stories}
            defaultInterval={3000}
            width={432}
            loop={false}
            onAllStoriesEnd={()=>toggle()}
            height={768}
        />
    </Modal>);
}
export default StoryModal;
