import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import withReducer from "../../store/withReducer";
import becomeSeller from "./store/reducers/becomeSeller.reducer";
import FirstBanner from "./components/FirstBanner";
import SellerInformation from "./components/SellerInformation";
import EvaluationForm from "./components/EvaluationForm";



function BecomeSeller() {
    useEffect(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    return (
        <>
            <FirstBanner />
            <SellerInformation />
            <EvaluationForm />
        </>
    );
}

export default withRouter(withReducer("becomeSeller", becomeSeller)(BecomeSeller));
