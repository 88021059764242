import * as _ from 'lodash'


export const setItem = async (key, value) => {
    try {
        return await localStorage.setItem(key, value)
    } catch (e) {
        console.error('setItem >> ', e);
    }
}

export const getItem = async (key) => {
    try {
        return await localStorage.getItem(key)
    } catch (e) {
        console.error('getItem >> ', e);
    }
}
export const getItemNonAsync = (key) => {
    try {
        return localStorage.getItem(key)
    } catch (e) {
        console.error('getItem >> ', e);

    }
}
export const checkItem = async (key) => {
    try {
        return await localStorage.getItem(key) ? true : false;
    } catch (e) {
        console.error('checkItem >> ', e);
    }
}

export const removeItem = async (key) => {
    try {
        return await localStorage.removeItem(key)
    } catch (e) {
        console.error('removeItem >> ', e);
    }
}

export const clear = async (key) => {
    try {
        return await localStorage.clear()
    } catch (e) {
        console.error('clear >> ', e);
    }
}


export const insertItem = async (key, value, data) => {
    try {
        const item = await localStorage.getItem(key)
        if(!data)
            data = []

        if(item){
            data = JSON.parse(item);
        }
        data.push(value);

        return await localStorage.setItem(key, JSON.stringify(data), ()=>{
            //--console.warn("value >> key >> ", value, key, data.length);
        });
    } catch (e) {
        console.error('insertItem >> ', e);
    }
}

export const updateItem = async (key, value, check, checkValue) => {
    try {
        //--//--console.warn("value >> ", value);
        const item = await localStorage.getItem(key)
        let data = []
        if(item){
            data = JSON.parse(item);
        }

        const filter = {};
        filter[check] = checkValue;

        //--//--console.warn("filter >> ", filter);
        const rejected = _.reject(data, filter);

        rejected.push(value);

        //--//--console.warn("rejected >> ", rejected);
        return await localStorage.setItem(key, JSON.stringify(rejected));
    } catch (e) {
        console.error('insertItem >> ', e);
    }
}

export const deleteItem = async (key, value, check, checkValue) => {
    try {
        //--//--console.warn("value >> ", value);
        const item = await localStorage.getItem(key)
        let data = []
        if(item){
            data = JSON.parse(item);
        }

        const filter = {};
        filter[check] = checkValue;

        //--//--console.warn("filter >> ", filter);
        const rejected = _.reject(data, filter);

        //--//--console.warn("rejected >> ", rejected);
        return await localStorage.setItem(key, JSON.stringify(rejected));
    } catch (e) {
        console.error('insertItem >> ', e);
    }
}
