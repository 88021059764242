import React from "react";
import Context from "../Utils/Context";
import Lottie from 'react-lottie';
import loading from "../assets/loading.json";
import {Modal} from "react-bootstrap";
const config={
  loop: true,
  autoplay: true,
  animationData: loading,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  }
};
export default class Loading extends React.Component {
    static contextType = Context;

    state = {
        loading: true
    }

    constructor(props) {
        super(props)
        this._show = this._show.bind(this);
        this._hide = this._hide.bind(this);
    }

    _show() {
        if (!this.context.loading) {
            this.setState({loading: true});
        }
    }

    _hide() {
        this.setState({loading: false});
    }



    async componentDidMount() {
        //console.log(this.state.loading, this.context);
        //--//--console.warn("this.state.loading >> ", this.state.loading);
    }

    render() {
        return (<Modal show={this.state.loading} id={"loader"} centered><Lottie options={config} height={200} width={200}/></Modal>);
    }
}
