import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import SettingsContent from "./components/SettingsContent";
import SubHeader from "../profile/components/SubHeader";

function ContactSettings() {
    return (
            <SettingsContent />
    );
}

export default withRouter(ContactSettings);
