import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import {Button, Dropdown} from "react-bootstrap";
import Context from "../../../Utils/Context";
import { useTranslation } from 'react-i18next';

const EvaluationForm = () => {
    const { t } = useTranslation()
    const evulationFormData = t('becomeSeller.EvulationForm', { returnObjects: true })
    const evulationFormCustomContent = evulationFormData['custom-content']
    const evulationFormButtons = evulationFormData['buttons']
    const evulationFormDropdowns = evulationFormData['dropdowns']
    const evulationFormInputs = evulationFormData['inputs']
    const pageData = {
        "seller-title": evulationFormCustomContent.find(x => x.name === 'seller-title')['value-single'],
        "seller-form": evulationFormButtons.find(x => x.name === 'seller-form')['label'],
        "seller-city": evulationFormDropdowns.find(x => x.name === 'seller-city'),
        "seller-county": evulationFormDropdowns.find(x => x.name === 'seller-county'),
        "seller-name": evulationFormInputs.find(x => x.name === 'seller-name'),
        "seller-surname": evulationFormInputs.find(x => x.name === 'seller-surname'),
        "seller-phone": evulationFormInputs.find(x => x.name === 'seller-phone'),
        "seller-email": evulationFormInputs.find(x => x.name === 'seller-email'),
        "clerk": evulationFormInputs.find(x => x.name === 'clerk'),
        "delivery": evulationFormInputs.find(x => x.name === 'delivery'),
        "agreement": evulationFormInputs.find(x => x.name === 'agreement'),
    }
    const context = useContext(Context);
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);
    const [city, setCity] = useState(null);
    const [county, setCounty] = useState(null);
    const [name, setName] = useState("");
    const [surname, setSurname] = useState("");
    const [gsm, setGsm] = useState("");
    const [email, setEmail] = useState("");
    const [note, setNote] = useState("");
    const [agreement, setAgreement] = useState(false);
    const [clerk, setClerk] = useState(false);
    const [delivery, setDelivery] = useState(false);
    const [isValidated, setIsValidated] = useState(false);

    // useEffect(() => {
    //     init();
    // }, []);

    const todo = () => {

    }
    const init = async () => {
        await context.select(
            'city',
            (data) => {
                setCities(data);
            },
            '&orderparameters=city_parent_city_id:asc&pageLimit=99999',
        );
    }
    const onCitySelect = (value) => {
        if (value) {
            //this.updateFormData('user_address_city', value);
            setCity(value);
            setCounty(null);
            setCounties([]);
            context.select(
                'county',
                (data) => {
                    setCounties(data);
                },
                'county_city_id:' +
                value.city_id +
                '&pageLimit=99999&orderparameters=county_name:asc',
            );
        }
    }
    const handleSubmitButton = () => {
        setIsValidated(true);
        if ((!city || city.city_id.length === 0) || (!county || county.county_id.length === 0) || name.length < 3 || surname.length < 2 || gsm.length < 10 || email.length < 5) {
            const dialogTData = t("showDialogs", { returnObjects: true });
            const dialogData = dialogTData.find(
              (x) => x.name === "evaluation-miss"
            );
            context.setGlobalState("showDialog", {
              title: dialogData?.title,
              message: dialogData?.message,
              buttons: [
                {
                  text: dialogData?.buttons[0].label,
                  onPress: () => {
                    context.setGlobalState("showDialog", false);
                  },
                },
              ],
            });
            return false;
        }
        if (!agreement){
            const dialogTData = t("showDialogs", { returnObjects: true });
            const dialogData = dialogTData.find(
              (x) => x.name === "evaluation-agre"
            );
            context.setGlobalState("showDialog", {
              title: dialogData?.title,
              message: dialogData?.message,
              buttons: [
                {
                  text: dialogData?.buttons[0].label,
                  onPress: () => {
                    context.setGlobalState("showDialog", false);
                  },
                },
              ],
            });
            return false;
        }

        const formData = {
            "optarget": "tbl_dialog_record",
            "optype": "insert",
            "opvalue": "",
            "formid": "",
            dialog_record_source_type: "Webform",
            dialog_record_subject_type: "Pazarci Basvuru",
            dialog_record_contact_information: 'isim: '+ name + ', soyisim: ' + surname + ', telefon ' + gsm + ', e-posta: ' + email + ', ilçe: '+ county.county_name + ', il: '+ city.city_name + `, tezgahim var:${clerk ? 'Evet' : 'Hayır'}`+`, teslimat yapabilirim:${delivery ? 'Evet' : 'Hayır'}` + `, iletileri almak istiyorum:${agreement ? 'Evet' : 'Hayır'}`,
            dialog_record_user_note: note,
            dialog_record_is_active: 1,
            dialog_record_is_deleted: 0
        }
        context.insert('dialog_record', formData, (response) => {
            //--//--console.warn("newmember >> response >> ", response, message);
            const dialogTData = t("showDialogs", { returnObjects: true });
            const dialogData = dialogTData.find(
              (x) => x.name === "evaluation-send"
            );
            context.setGlobalState("showDialog", {
              title: dialogData?.title,
              message: dialogData?.message,
              buttons: [
                {
                  text: dialogData?.buttons[0].label,
                  onPress: () => {
                    context.setGlobalState("showDialog", false);
                  },
                },
              ],
            });
        });
    }
    return (
        <>
            <div className="contact-us t-0 seller-page-form">
                <div className="container">
                    <div className="row">
                        <h1 className="text-center col-12 mb-5">{pageData['seller-title']}</h1>
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <div id="seller-form" className="contact-form needs-validation">
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className={(isValidated) ? "form-group was-validated" : "form-group"}>
                                            <input type="text" value={name} onChange={(e) => setName(e.target.value)}
                                                   className="form-control" id="name" required/>
                                            <label htmlFor="name">{pageData['seller-name'].label}</label>
                                            {(name.length === 0) && (
                                                <div className="invalid-feedback">{pageData['seller-name'].error}</div>)}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className={(isValidated) ? "form-group was-validated" : "form-group"}>
                                            <input type="text" value={surname}
                                                   onChange={(e) => setSurname(e.target.value)} className="form-control"
                                                   id="surname" required/>
                                            <label htmlFor="surname">{pageData['seller-surname'].label}</label>
                                            {surname.length === 0 && (
                                                <div className="invalid-feedback">{pageData['seller-surname'].error}</div>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className={(isValidated) ? "form-group was-validated" : "form-group"}>
                                            <input type="text" className="form-control" id="phone" value={gsm}
                                                   onChange={(e) => setGsm(e.target.value)} placeholder="+90 (____) "
                                                   required/>
                                            <label htmlFor="phone">{pageData['seller-phone'].label}</label>
                                            {gsm.length < 10 && (
                                                <div className="invalid-feedback">{pageData['seller-phone'].error}</div>)}
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className={(isValidated) ? "form-group was-validated" : "form-group"}>
                                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)}
                                                   className="form-control" id="email" required/>
                                            <label htmlFor="email">{pageData['seller-email'].label}</label>
                                            {(email.length < 5) && (
                                                <div className="invalid-feedback">{pageData['seller-phone'].error}</div>)}
                                        </div>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col-md-6">
                                        <div className={(isValidated) ? "form-group was-validated" : "form-group"} onClick={()=>{cities && cities.length && cities.length > 0 ? todo() : init()}}>
                                            <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                                {city ? (<Dropdown.Toggle role={"button"} className={"select-selected"}
                                                                          as={Button} id="dropdownMenuLink">
                                                        {city.city_name}
                                                    </Dropdown.Toggle>
                                                ) : (
                                                    <Dropdown.Toggle role={"button"} className={"select-selected"}
                                                                     as={Button} id="dropdownMenuLink">
                                                        {pageData['seller-city'].placeholder}
                                                    </Dropdown.Toggle>
                                                )}
                                                <Dropdown.Menu className="dropdown-menu scrollable-menu-markets">
                                                    {cities && cities.map((CCity, index) => (
                                                        <Dropdown.Item className={"dropdown-item"} key={index}
                                                                       onClick={(e) => onCitySelect(CCity)}>{CCity.city_name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <label htmlFor="city"> {pageData['seller-city'].label}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div
                                            className={(isValidated && !county) ? "form-group was-validated" : "form-group"}>
                                            <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                                {county ? (
                                                    <Dropdown.Toggle role={"button"} className={"select-selected"}
                                                                     as={Button} id="dropdownMenuLink">
                                                        {county.county_name}
                                                    </Dropdown.Toggle>
                                                ) : (
                                                    <Dropdown.Toggle role={"button"} className={"select-selected"}
                                                                     as={Button} id="dropdownMenuLink">
                                                          {pageData['seller-county'].placeholder}
                                                    </Dropdown.Toggle>
                                                )}
                                                <Dropdown.Menu className="dropdown-menu scrollable-menu-markets">
                                                    {counties.map((CCounty, index) => (
                                                        <Dropdown.Item className={"dropdown-item"} key={index}
                                                                       onClick={(e) => setCounty(CCounty)}>{CCounty.county_name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <label htmlFor="county"> {pageData['seller-county'].label}</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group form-check">
                                    <div className="form-check">
                                        <input className="form-check-input" checked={clerk}
                                               onChange={(e) => setClerk(e.target.checked)} type="checkbox"
                                               id="gridCheck" required/>
                                        <label className="form-check-label" htmlFor="gridCheck">
                                            {pageData['clerk'].label}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group form-check">
                                    <div className="form-check">
                                        <input className="form-check-input" checked={delivery}
                                               onChange={(e) => setDelivery(e.target.checked)} type="checkbox"
                                               id="gridCheck2" required/>
                                        <label className="form-check-label" htmlFor="gridCheck2">
                                            {pageData['delivery'].label}
                                        </label>
                                    </div>
                                </div>
                                <div className="form-group form-check">
                                    <div className="form-check">
                                        <input className="form-check-input" checked={agreement}
                                               onChange={(e) => setAgreement(e.target.checked)} type="checkbox"
                                               id="gridCheck3" required/>
                                        <label className="form-check-label" htmlFor="gridCheck3">
                                           { pageData['agreement'].label}
                                        </label>
                                    </div>
                                </div>
                                <div className="col-lg-4 m-auto">
                                    <button onClick={handleSubmitButton} className="btn btn-secondary w-100">{pageData['seller-form']}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default withRouter(EvaluationForm);
