import React from "react";
import "./FeaturedBlog.css";
import { withRouter } from "react-router";

const BlogCard = ({ imgUrl, title, description, id, history }) => {
  return (
    // <div className="col-4">
    <div className="cart-container">
      <div className="featured-box card h-full">
        <div
          className="card-img-top featured-image"
          // src={imgUrl}
          // style={{backgroundImage:"url(assets/images/blog-image.png)"}}
          style={{ backgroundImage: "url(" + imgUrl + ")" }}
        />
        <div className="card-body container flex flex-column justify-content-between">
          <h5 className={"card-title featured-blog-title"}>{title}</h5>
          <p
            className="featured-blog-description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
          <div
            className="btn btn-featured"
            onClick={() => history.push("/blog/" + id)}
          >
            Devamını Oku
          </div>
        </div>
      </div>
    </div>
  );
};
export default withRouter(BlogCard);
