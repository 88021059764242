export const OPEN_NAVBAR = "open navbar";
export const CLOSE_NAVBAR = "close navbar";
export const SET_LOADING = "set loading";
export const SET_MESSAGE = "set message";
export const OPEN_LOGIN_MODAL = "open login modal";
export const CLOSE_LOGIN_MODAL = "close login modal";
export const OPEN_SIGNUP_MODAL = "open signup modal";
export const CLOSE_SIGNUP_MODAL = "close signup modal";
export const OPEN_FORGOT_PASS_MODAL = "open forgot pass modal";
export const CLOSE_FORGOT_PASS_MODAL = "close forgot pass modal";
export const OPEN_NEW_PASS_MODAL = "open new pass modal";
export const CLOSE_NEW_PASS_MODAL = "close new pass modal";
export const OPEN_CONFIRM_SIGNUP_MODAL = "open confirm signup modal";
export const CLOSE_CONFIRM_SIGNUP_MODAL = "close confirm signup modal";


export function openConfirmSignupModal() {
    return {
        type: OPEN_CONFIRM_SIGNUP_MODAL
    }
}export function closeConfirmSignupModal() {
    return {
        type: CLOSE_CONFIRM_SIGNUP_MODAL
    }
}

export function openLoginModal() {
    return {
        type: OPEN_LOGIN_MODAL
    }
}
export function closeLoginModal() {
    return {
        type: CLOSE_LOGIN_MODAL
    }
}
export function openSignupModal() {
    return {
        type: OPEN_SIGNUP_MODAL
    }
}
export function closeSignupModal() {
    return {
        type: CLOSE_SIGNUP_MODAL
    }
}
export function openForgotPassModal() {
    return {
        type:OPEN_FORGOT_PASS_MODAL
    }
}
export function closeForgotPassModal() {
    return {
        type: CLOSE_FORGOT_PASS_MODAL
    }
}
export function openNewPassModal() {
    return {
        type:OPEN_NEW_PASS_MODAL
    }
}
export function closeNewPassModal() {
    return {
        type: CLOSE_NEW_PASS_MODAL
    }
}
export function openNavBar() {
    return {
        type: OPEN_NAVBAR
    }
}

export function closeNavBar() {
    return {
        type: CLOSE_NAVBAR
    }
}

export function setLoading(loading) {
    return {
        type: SET_LOADING,
        payload: {
            loading
        }
    }
}

export function setMessage(text, type) {
    return {
        type: SET_MESSAGE,
        payload: {
            text,
            type
        }
    }
}
