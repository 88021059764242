import React, {useContext, useEffect, useState} from 'react';
import {useParams, withRouter} from "react-router";
import Context from "../../Utils/Context";
import blogHeader from "../../assets/blogHeader.png";
import blogImage from "../../assets/images/blog-image.png";
import "./Blog.css";
import FeaturedBlog from "../home/components/FeaturedBlog";


function Blog({history}) {

    const context = useContext(Context);
    const [lBlogs, setLBlogs] = useState([]);
    const [blog, setBlog] = useState(null);

    const {id} = useParams();

    useEffect(()=>{
        window.scroll({top: 0, behavior: "smooth"});
        if(!context.blogs || (context.blogs.length && context.blogs.length === 0)){
           init();
        }
    },[context.blogs, id])

    const init = async () => {
        await context.select("web_content", (data) => {
            console.log("blgos", data);
            setLBlogs(data);
                context.setGlobalState("blogs", data);
        }, "web_content_type:blog&pageLimit=99999");
        //https://mservice.pazardan.app/web_content/web_contentSelect?filterparameters=web_content_type:blog&pageLimit=99999
    }

    useEffect(()=>{
        if(context.blogs && context.blogs.length && context.blogs.length > 0){
            setLBlogs(context.blogs)
        }
    },[context.blogs]);

    useEffect(()=>{
        window.scroll({top: 0, behavior: "smooth"});
        if(lBlogs && lBlogs.length && lBlogs.length > 0 && id){
            console.log("find", lBlogs.find(bl => bl.web_content_id === id))
            setBlog(lBlogs.find(bl => bl.web_content_id === id))
        }
    },[lBlogs, id]);



    return (
        <div className="container">
            <div className="flex justify-content-center align-items-center"
                // src={imgUrl}
                // style={{backgroundImage:"url(assets/images/blog-image.png)"}}
                 style={{backgroundImage:"url("+blogHeader+")", backgroundSize: "cover", width:"100%", height:"8rem", marginBottom: "3rem "}}

            >
                <span className=" text-white font-weight-bold " style={{fontSize: "24px"}}>BLOG</span>
            </div>


                <h1 style={{color:"#46742C", fontSize:"24px", fontWeight:"bold"}} className="mb-8">{blog && blog.web_content_tagname1 ? blog.web_content_tagname1 : ""}</h1>
                {/*<p>{blog && blog?.web_content_content_text1 ? blog.web_content_content_text1 : "content"}</p>*/}
            <span>
                <p>

                    {blog && blog.web_content_tagname2 && blog.web_content_tagname2 !== "" ?
                        <img className="blog-content-img"
                             src={blog.web_content_tagname2} alt=""/> : null }

                    {blog && blog?.web_content_content_text1 ?
                    <span style={{fontSize:"14px"}} dangerouslySetInnerHTML={{__html: blog.web_content_content_text1}} /> : ""}

                </p>

            </span>

            <div className="blog-bottom-blogs-mobile">
                <FeaturedBlog/>
            </div>

            <div className="h-64 mt-32 flex justify-content-between blog-bottom-blogs">
                {
                    lBlogs && lBlogs.map((blog, index) =>{
                        if(index < 3){
                            return(
                                <span className="h-28" style={{width:"33%"}}>
                                    <div className="flex" style={{ height:"100%", width:"100%"}}>
                                    {blog && blog.web_content_tagname2 && blog.web_content_tagname2 !== "" ?

                                        <div style={{backgroundImage:"url("+blog.web_content_tagname2+")", backgroundSize: "cover", minWidth: "7rem", height:"100%", borderRadius:"12px"}} >

                                        </div>
                                        :

                                        <div style={{backgroundImage:"url("+blogImage+")", backgroundSize: "cover" , minWidth: "7rem", height:"100%", borderRadius:"12px"}} >

                                        </div>
                                       }
                                       <span className="flex-column flex justify-content-between" style={{height: "7rem"}}>
                                       <p className="blog-footer-title">
                                            {blog && blog.web_content_tagname1 ? blog.web_content_tagname1 : ""}
                                       </p>
                                        {blog && blog?.web_content_content_text1 ?
                                        <p className="blog-footer-content" style={{fontSize:"14px"}} dangerouslySetInnerHTML={{__html: blog.web_content_content_text1}}>

                                        </p> : null }

                                        <p className="blog-footer-btn" onClick={()=>{console.log("idd", blog.web_content_id); history.push("/blog/" + blog.web_content_id)}}>
                                            Devamını Oku
                                        </p>
                                       </span>

                                    </div>
                                </span>
                            )
                        }
                    })
                }
            </div>

        </div>
    );
}

export default withRouter(Blog);