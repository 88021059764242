import React, {useContext, useEffect, useState} from "react";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import blogImage from "../../../assets/images/blog-image.png"

import "./FeaturedBlog.css";
import BlogCard from "./BlogCard";
import Context from "../../../Utils/Context";
import {getItem} from "../../../Utils/LocalStorage";
import {useHistory} from "react-router";


const FeaturedBlog = () => {

    const wrapperRef = React.createRef();
    const context = useContext(Context);
    const [scrollAmount, setScrollAmount] = useState(110);
    const [blogs, setBlogs] = useState([]);
    const history = useHistory();

    useEffect(()=>{
        if(window.innerWidth < 400) {
            setScrollAmount(window.innerWidth - 100);
        }else if(window.innerWidth < 801) {
            setScrollAmount(297);
        }else if(window.innerWidth < 1201){
            setScrollAmount(295);
        }else{
            setScrollAmount(335);
        }
        init()
    },[context.isIn, context.user]);

    useEffect(() => {
        function handleResize() {
            if(window.innerWidth < 400) {
                setScrollAmount(window.innerWidth - 100);
            }else if(window.innerWidth < 801) {
                setScrollAmount(297);
            }else if(window.innerWidth < 1201){
                setScrollAmount(295);
            }else {
                setScrollAmount(335);
            }
        }
        window.addEventListener('resize', handleResize)
    });

    useEffect(()=>{
        if(context.blogs && context.blogs.length && context.blogs.length > 0){
            // setLBlogs([context.blogs[0], context.blogs[1], context.blogs[0], context.blogs[1], context.blogs[0], context.blogs[0],context.blogs[0]])
            setBlogs(context.blogs)
        }
    },[context.blogs]);


    const init = async () => {
        const loginType = await getItem('loginType');
        const SessVal = await getItem('SessVal');
        const SessLVal = await getItem('SessLVal');
        const Source = await getItem('Source');
        if (loginType && SessVal && SessLVal && Source) {
        await context.select("web_content", (data) => {
            setBlogs(data ? data : []);
            console.log(data);
            context.setGlobalState("blogs", data ? data : []);
            console.log("data", data)
        }, "web_content_type:blog&pageLimit=99999");
        //https://mservice.pazardan.app/web_content/web_contentSelect?filterparameters=web_content_type:blog&pageLimit=99999
    }}
    function scrollNext(ref, amountScroll){
        console.log(amountScroll)
        ref.current.scrollBy(amountScroll, 0, "smooth");
    }

    return(
        <div className="container">
        <div className="featured-blog">
            <div className="row">
                <span className="flex w-full">
                <h1 className="col-2"></h1>
                <h1 className="mb-5 col-8 text-center">PAZARDAN BLOG</h1>
                <h1 className="mb-5 col-2 text-center cursor-pointer contents" onClick={()=>history.push("/all-blogs")}>Tümü</h1>
                </span>
                <div className="col-12">
                    <div className="featured-blog-box" data-aos="fade-up">
                        <div className="blog-container" >
                            <NavigateBeforeIcon className="bottom-left-arrow" style={{ color:"#84b700" }} onClick={()=>scrollNext(wrapperRef, -scrollAmount)} />
                            <NavigateNextIcon className="bottom-right-arrow" style={{ color: "#84b700" }} onClick={()=>scrollNext(wrapperRef, scrollAmount)}/>
                            <div className="blogs-inner" ref={wrapperRef}>
                            {
                                blogs.map((blog, index)=>{
                                    return(
                                        <BlogCard key={index} id={blog.web_content_id}
                                                  imgUrl={blog.web_content_tagname2 && blog.web_content_tagname2 !== "" ? blog.web_content_tagname2 : blogImage }
                                                  title={blog.web_content_tagname1}
                                                  description={blog.web_content_content_text1} />
                                    )
                                })
                            }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
    {/*    <div data-aos="fade-up" className="blog-bottom"*/}
    {/*style={{backgroundImage:"url(assets/images/main-second-bg.png)"}}/>*/}
    </div>
    </div>
            );
}
export default FeaturedBlog;
