import React, {useContext, useEffect, useState} from "react";
import "./NewHome.css";
import {Dropdown} from "react-bootstrap";
import Context from "../../../Utils/Context";
import {getItem, setItem} from "../../../Utils/LocalStorage";
import Stalls from "../../stalls/Stalls";
import _ from "lodash";
import moment from "moment";
import {useHistory} from "react-router";
import { useTranslation } from "react-i18next";

const PazardanMainNew = () => {
    const { t } = useTranslation();
    const homeData = t('home', {returnObjects: true})
    const PazardanMainNewData = homeData.components.find(x => x.name === 'pazardan-main-new')
    const pageData = {
            title:  PazardanMainNewData['custom-content'].find(x => x.name === 'home-title')['value-single'],
            text: PazardanMainNewData['custom-content'].find(x => x.name === 'home-text')['value-single'],
            'card-title': PazardanMainNewData['custom-content'].find(x => x.name === 'card-title')['value-single'],
            button: PazardanMainNewData['buttons'].find(x => x.name === 'card-button')['label'],
            'card-city':PazardanMainNewData['dropdowns'].find(x => x.name === 'card-city')['placeholder'],
            'card-county': PazardanMainNewData['dropdowns'].find(x => x.name === 'card-county')['placeholder'],
            'card-neighborhood': PazardanMainNewData['dropdowns'].find(x => x.name === 'card-neighborhood')['placeholder'],
    }
    const history = useHistory();
    const context = useContext(Context);
    const [days] = useState(context.getDays());
    const [monthsShortTR]=useState(context.getMonths().listTr.split('_'));
    const [monthsShortEN]=useState(context.getMonths().listEn.split('_'));
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);
    const [neighbourhoods, setNeighbourhoods] = useState([]);
    const [open, setOpen] = useState(false);
    const [markets, setMarkets] = useState([]);
    const [city, setCity] = useState("");
    const [county, setCounty] = useState("");
    const [neighbourhood, setNeighbourhood] = useState("");

    useEffect(()=>{
        init();
    }, []);
    const toggleStallsModal = () => {
        setOpen(!open);
    }
    const init = async () => {
        const loginType = await getItem('loginType');
        if (!loginType) {
            await context.createTempMember(async () => {
                await getCities();
                context.setGlobalState("isIn", !context.isIn);
            });
        } else {
            await getCities();
        }
    }

    const getMarkets = async () => {
        await setItem("CCN", JSON.stringify({city:city, county:county, neighbourhood:neighbourhood}));
        let newAddress = {
            label: neighbourhood.neighborhood_name,
            value: {
                user_address_city: city.city_name,
                user_address_county: county.county_name,
                user_address_neighborhood: neighbourhood.neighborhood_name
            }
        };
        await setItem("endUserAddress", JSON.stringify(newAddress));
        await context.select(
            'view_active_marketer_list',
            async (data) => {
                //console.log("markets >> ", data);
                if (!_.isEmpty(data) && typeof data !== 'string') {
                    history.push('/stalls');
                }else {
                    const dialogTData = t("showDialogs", { returnObjects: true });
                    const dialogData = dialogTData.find(
                      (x) => x.name === "mainview"
                    );
                    context.setGlobalState("showDialog", {
                      title: dialogData?.title,
                      message: dialogData?.message,
                      buttons: [
                        {
                          text: dialogData?.buttons[0].label,
                          onPress: () => {
                            context.setGlobalState("showDialog", false);
                          },
                        },
                      ],
                    });
                    }
            },
            'market_city:' +
            city.city_name +
            ',market_active_marketer_work_range_neighborhoods-like-*' +
            county.county_name +
            '_' +
            neighbourhood.neighborhood_name +
            '*&pageLimit=99999&getEncrypted=0',
        );
    }

    const getClosestWeekdayType = (weekday_type, day, i) => {
        if (days[moment(day).format('dddd')] === weekday_type) {
            return {
                date:
                    moment(day).format('DD') +
                    ' ' +
                    monthsShortTR[
                        monthsShortEN.indexOf(moment(day).format('MMM'))
                        ],
                order: i,
            };
        } else {
            const nextDay = moment(day).add(1, 'days');
            // console.warn("nextDay >> ", nextDay, i);
            i++;
            return getClosestWeekdayType(weekday_type, nextDay, i);
        }
    };

    const getCities = async () => {
        await context.select(
            'city',
            (data) => {
                setCities(data);
            },
            '&orderparameters=city_parent_city_id:asc&pageLimit=99999',
        );
    }
    const onCitySelect = (value) => {
        if (value) {
            setCity(value);
            setCounty(null);
            setCounties([]);
            setNeighbourhood(null);
            setNeighbourhoods([]);
            context.select(
                'county',
                (data) => {
                    setCounties(data);
                },
                'county_city_id:' +
                value.city_id +
                '&pageLimit=99999&orderparameters=county_name:asc',
            );
        }
    }
    const onCountySelect = (value) => {
        if (value) {
            setCounty(value);
            setNeighbourhood(null);
            setNeighbourhoods([]);
            context.select(
                'neighborhood',
                (data) => {
                    setNeighbourhoods(data);
                },
                'neighborhood_county_id:' +
                value.county_id +
                '&pageLimit=99999&orderparameters=neighborhood_name:asc',
            );
        }
    }
    const onNeighbourhoodSelect = (value) => {
        if (value){
            setNeighbourhood(value);
        }
    }

    return(
        <div className="pazardan-main">
            <div className="">
                    <div>
                        <div className="slider-item" style={{backgroundImage:"url(/web_banner_2.jpg)"}}>
                            <div className="overlay-new"/>
                            <div className="">
                                <div className="container">
                                    <div className="row justify-content-between"  style={{paddingRight:"15px"}}>
                                        <div className="col-lg-8 text-white">
                                            <div className="col-lg-8 mb-3 col-12 pt-4">
                                                <p data-aos="fade-up "><span className="main-title">{pageData.title}</span></p>
                                                <p data-aos="fade-up">{pageData.text}.</p>
                                                <a data-aos="fade-up" href="https://apps.apple.com/tr/app/pazardan/id1541579666?l=tr" className="d-inline-block mt-4" target="_blank" rel="noreferrer"><img src={"assets/images/apple-store.svg"} alt={"apple store"} className="img-fluid"/></a>
                                                <a data-aos="fade-up" href="https://play.google.com/store/apps/details?id=com.pazardan&gl=TR" className="d-inline-block mt-4 google-play" target="_blank" rel="noreferrer"><img src={"assets/images/google-play.svg"} alt={"google play"} className="img-fluid"/></a>
                                            </div>
                                        </div>
                                        {!context.user ?
                                        <div className="col-lg-4 mb-lg-3 home-select-market">
                                            <div className="select-title">
                                                {pageData['card-title']}
                                            </div>
                                            <Dropdown className="map-dropdown">
                                                {city? (
                                                    <Dropdown.Toggle id="dropdownMenuLink" className={"text-left"}><i className="grey-pin"/>{city.city_name}</Dropdown.Toggle>
                                                ) : (
                                                    <Dropdown.Toggle id="dropdownMenuLink" className={"text-left"}><i className="grey-pin"/>{ pageData['card-city']}</Dropdown.Toggle>
                                                )}
                                                <Dropdown.Menu className="dropdown-menu scrollable-menu">
                                                    {cities?.map((CCity, index)=>(
                                                        <Dropdown.Item key={index} className={"dropdown-item"} onClick={() =>onCitySelect(CCity)}>{CCity.city_name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown className="map-dropdown">
                                                {county? (
                                                    <Dropdown.Toggle id="dropdownMenuLink" className={"text-left"}><i className="grey-pin"/>{county.county_name}</Dropdown.Toggle>
                                                ) : (
                                                    <Dropdown.Toggle id="dropdownMenuLink" className={"text-left"} disabled={!city}><i className="grey-pin"/>{ pageData['card-county']}</Dropdown.Toggle>
                                                )}
                                                <Dropdown.Menu className="dropdown-menu scrollable-menu">
                                                    {counties.map((CCounty, index)=>(
                                                        <Dropdown.Item key={index} className={"dropdown-item"} onClick={() =>onCountySelect(CCounty)}>{CCounty.county_name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            <Dropdown className="map-dropdown">
                                                {neighbourhood? (
                                                    <Dropdown.Toggle id="dropdownMenuLink" className={"text-left"}><i className="grey-pin"/>{neighbourhood.neighborhood_name}</Dropdown.Toggle>
                                                ) : (
                                                    <Dropdown.Toggle id="dropdownMenuLink" className={"text-left"} disabled={!county}><i className="grey-pin"/>{pageData["card-neighborhood"]}</Dropdown.Toggle>
                                                )}
                                                <Dropdown.Menu className="dropdown-menu scrollable-menu">
                                                    {neighbourhoods.map((CNeighborhood, index)=>(
                                                        <Dropdown.Item key={index} className={"dropdown-item"} onClick={() =>onNeighbourhoodSelect(CNeighborhood)}>{CNeighborhood.neighborhood_name}</Dropdown.Item>
                                                    ))}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                            <div className="show-stalls-btn">
                                                <button disabled={!neighbourhood} onClick={()=>getMarkets()}  className={"btn btn-secondary"}>{pageData.button}</button>
                                            </div>

                                            <Stalls open={open} toggle={toggleStallsModal} markets={markets}/>
                                        </div>
                                            : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    );
}

export default PazardanMainNew;
