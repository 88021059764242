import React from 'react';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';
import Home from "./containers/home/Home";
import Layout from "./layout/Layout";
import BecomeSeller from "./containers/becomeSeller/BecomeSeller";
import Addresses from "./containers/addresses/Addresses";
import AddAddress from "./containers/addAddress/AddAddress";
import Support from "./containers/support/Support";
import ContactSettings from "./containers/contactSettings/ContactSettings";
import Profile from "./containers/profile/Profile";
import PasswordSettings from "./containers/passwordSetting/PasswordSettings";
import FAQ from "./containers/FAQ/FAQ";
import { getItemNonAsync } from "./Utils/LocalStorage";
import Stall from "./containers/stalls/Stall";
import MyOrders from "./containers/myOrders/MyOrders";
import OrderSummary from "./containers/order/OrderSummary";
import Thanks from "./containers/order/Thanks";
import PrivacyCokiesPolicies from "./containers/PrivacyCokiesPolicy/PrivacyCokiesPolicies";
import ClarificationText from "./containers/aydinlatmaMetni/ClarificationText";
import KVKK from "./containers/KVKK/KVKK";
import UserContract from "./containers/userContract/UserContract";
import SSSFooter from "./containers/sssFooter/SSSFooter";
import Blog from "./containers/blog/Blog";
import AllBlogs from "./containers/blog/AllBlogs";
import Fail from "./containers/order/Fail";

function Routes() {
    const history = useHistory();
    const renderRoute = (name, toolbar, child) => {
        //console.log("cc", getItemNonAsync("user"))
        // if (await getItem("user")) {
        if (!!getItemNonAsync("user")) {
            return (
                <Layout name={name} toolbar={toolbar} children={child} />
            )
        } else {
            history.push('/');
        }
    }

    return (
        <Switch>
            <Route exact path='/' render={() => <Layout name={"Anasayfa"} toolbar={true} children={<Home />} />} />
            <Route exact path='/become-seller' render={() => <Layout name={"Satıcı Olmak İstiyorum"} navbar={false} toolbar={true} children={<BecomeSeller />} />} />
            <Route exact path='/addresses' render={() => renderRoute("Adreslerim", true, <Addresses />)} />
            <Route exact path='/stalls' render={() => <Layout name={"Tezgahlar"} toolbar={true} children={<Stall />} />} />
            <Route exact path='/stalls/:id' render={() => <Layout name={"Tezgahlar"} toolbar={true} children={<Stall />} />} />
            <Route exact path='/add-address/:id' render={() => renderRoute("Adreslerim", true, <AddAddress />)} />
            <Route exact path='/support' render={() => renderRoute("Destek", true, <Support />)} />
            <Route exact path='/contact-settings' render={() => renderRoute("İletişim Tercihlerim", true, <ContactSettings />)} />
            <Route exact path='/profile' render={() => renderRoute("Profil Bilgilerim", true, <Profile />)} />
            <Route exact path='/orders' render={() => renderRoute("Siparişlerim", true, <MyOrders />)} />
            <Route exact path='/order-summary' render={() => renderRoute("Sipariş Özeti", true, <OrderSummary />)} />
            {/*<Route exact path='/thanks' render={() => renderRoute("Teşekkürler", true,  <Thanks/>) } />*/}
            <Route exact path='/order-complete' render={() => renderRoute("Teşekkürler", true, <Thanks />)} />
            {/* <Route exact path='/order-complete' render={() => renderRoute("Üzgünüz", true,  <Fail/>) } /> */}
            <Route exact path='/faq' render={() => renderRoute("SSS", true, <FAQ />)} />
            <Route exact path='/password-settings' render={() => renderRoute("Şifre Bilgilerim", true, <PasswordSettings />)} />
            <Route exact path='/cookies' render={() => <Layout name={"GİZLİLİK SÖZLEŞMESİ"} toolbar={true} children={<PrivacyCokiesPolicies />} />} />
            <Route exact path='/clarification-text' render={() => <Layout name={"AYDINLATMA METNİ"} toolbar={true} children={<ClarificationText />} />} />
            <Route exact path='/kvkk' render={() => <Layout name={"KVKK"} toolbar={true} children={<KVKK />} />} />
            <Route exact path='/user-contract' render={() => <Layout name={"KULLANICI SÖZLEŞMESİ"} toolbar={true} children={<UserContract />} />} />
            <Route exact path='/sss-footer' render={() => <Layout name={"SIK SORULAN SORULAR"} toolbar={true} children={<SSSFooter />} />} />
            <Route exact path='/blog/:id' render={() => <Layout name={"BLOG"} toolbar={true} children={<Blog />} />} />
            <Route exact path='/all-blogs' render={() => <Layout name={"BLOGLAR"} toolbar={true} children={<AllBlogs />} />} />

        </Switch>
    );
}

export default withRouter(Routes);
