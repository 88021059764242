import React, {useContext, useEffect, useState} from 'react';
import {withRouter} from "react-router";
import Context from "../../Utils/Context";
import {getItem} from "../../Utils/LocalStorage";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
function Addresses() {
    const { t } = useTranslation()
    const AddressesData = t('addresses', { returnObjects: true })
    const addressesButtons = AddressesData.buttons
    const addressesCustomContent = AddressesData['custom-content']
    const pageData = {
        'add_address': addressesButtons.find(x => x.name === 'add_address')['label'],
        'address_name': addressesCustomContent.find(x => x.name === 'address_name')['value'],
        'address': addressesCustomContent.find(x => x.name === 'address')['value'],
        'neighborhood': addressesCustomContent.find(x => x.name === 'neighborhood')['value'],
        'city-county': addressesCustomContent.find(x => x.name === 'city-county')['value'],
        'no_address': addressesCustomContent.find(x => x.name === 'no_address')['value'],
        'no_address_text': addressesCustomContent.find(x => x.name === 'no_address')['value'],
    }
    const context = useContext(Context);
    const [addresses, setAddresses] = useState(null);

    const init = async () => {
        const user = JSON.parse(await getItem('user'));
        //--console.warn("user  >> ", user);
        context.select(
            'address',
            (data) => {
                //console.log('Addresses >> address >> ', data);
                setAddresses(data ? data : []);
            },
            'user_address_is_active:1,user_address_is_deleted:0,user_address_created_by_user_id:' +
            user.member_id +
            '&pageLimit=99999',
        );
    };

    useEffect(()=>{
        init();
    },[]);
    const deleteAddress = (address) => {
        context.deleted('address', { user_address_is_active: 0, user_address_is_deleted: 1 }, address.user_address_secret_key, (r) => {
            //--//--console.warn("r >> ", r);
            init();
        })
    }

   const onAAddressDeleteButtonPressed = async (address) => {
    const dialogTData = t("showDialogs", { returnObjects: true });
    const dialogData = dialogTData.find(
      (x) => x.name === "addresses-delete"
    );
        context.setGlobalState('showDialog', {  title: dialogData?.title,message: dialogData?.message, buttons: [{ text: dialogData.buttons.find(x => x.name === 'yes').label, onPress: () => { deleteAddress(address); context.setGlobalState('showDialog', false) } }, { text: dialogData.buttons.find(x => x.name === 'no').label, isSecondary: true, onPress: () => { context.setGlobalState('showDialog', false) } }] });

    }
    return (
        <div className="col-12 col-lg-9">
            {addresses?.map((address, index)=>(
                <div key={index} className="address-details box">
                    <div className="row">
                        <div className="col-10 col-lg-11">
                            <h1>{pageData.address_name}</h1>
                            <p>{address?.user_address_title}</p>
                            <div className="border-line"/>
                            <div className="row">
                                <div className="col-lg-6 col-12">
                                    <h1>{pageData.address}</h1>
                                    <p>{address?.user_address_full_address}</p>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <h1>{pageData.neighborhood}</h1>
                                    <p>{address?.user_address_neighborhood}</p>
                                </div>
                                <div className="col-lg-3 col-12">
                                    <h1>{pageData['city-county']}</h1>
                                    <p>{address?.user_address_city}, {address?.user_address_county}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-1">
                            <div className="address-edit">
                                {/*<a href="#">*/}
                                {/*    <div className={address?.user_address_is_default ? 'selected active': 'selected'}/>*/}
                                {/*</a>*/}
                                <Link to={`/add-address/${address.user_address_id}`}>
                                    <div className="edit"/>
                                </Link>
                                <a onClick={()=>onAAddressDeleteButtonPressed(address)} data-toggle="modal" data-target="#deleteWarning">
                                    <div className="trash"/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {(addresses!==null && addresses?.length === 0 && !context.loading) &&(
                <div className="address-details box">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h1>{pageData['no_address']}</h1>
                            <p>{pageData['no_address_text']}</p>
                        </div>
                    </div>
                </div>
            )}
            {addresses!==null && (
                <div className="more mt-4">
                    <Link to={"/add-address/new"} className="more-btn"><i/></Link>
                    <Link to={"/add-address/new"} className="text-center more-btn-text">{pageData['add_address']}</Link>
                </div>
            )}
        </div>
    );
}

export default withRouter(Addresses);
