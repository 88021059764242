import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import PasswordForm from "./components/PasswordForm";
import SubHeader from "../profile/components/SubHeader";

function PasswordSettings() {
    return (
            <PasswordForm />
    );
}

export default withRouter(PasswordSettings);
