import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import Context from "../../../Utils/Context";
import { useTranslation } from "react-i18next";
const Sidebar = () => {
    const {t}  = useTranslation()
    const SidebarData = t('profile.Sidebar', {returnObjects:true})
    const pageData = {
        'my-addresses': SidebarData['custom-content'].find(x => x.name === 'my-addresses')['value-single'],
        'my-orders': SidebarData['custom-content'].find(x => x.name === 'my-orders')['value-single'],
        'my-info': SidebarData['custom-content'].find(x => x.name === 'my-info')['value-single'],
        'my-password': SidebarData['custom-content'].find(x => x.name === 'my-password')['value-single'],
        'my-contact': SidebarData['custom-content'].find(x => x.name === 'my-contact')['value-single'],
        'faq': SidebarData['custom-content'].find(x => x.name === 'faq')['value-single'],
        'support':SidebarData['custom-content'].find(x => x.name === 'support')['value-single'],
    }
    const { pathname } = useLocation();
    const context = useContext(Context);
    const [user, setUser]=useState(null);
    async function init() {
        context.getUser((data) => {
            //--//--console.warn("user >> data >> ", data);
            setUser(data[0]);
        })
    }
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
        }, 0);
        init();
    }, [pathname, context.userUpdated]);


    return(<div className="col-12 col-lg-3 d-none d-sm-block mobile-profile-menu">
        <div className="user-info box">
            <h1>{user?.user_name} {user?.user_surname}</h1>
            <ul className="profile-items">
                <li><a href="#" className="overflow-text"><i className="menu-icons mail-icon"/>{user?.user_active_email}</a>
                </li>
                <li><a href="#"><i className="menu-icons phone-icon"/>{user?.user_active_gsm}</a></li>
            </ul>
        </div>
        <div className="profile-menu box">
            <ul className="profile-items">
                <li>
                    <Link to={"/addresses"} className={pathname==="/addresses" ? "active":""}><i className="menu-icons address-icon"/>{pageData['my-addresses']}</Link>
                </li>
                <li><Link to={"/orders"} className={pathname==="/orders" ? "active":""}><i className="menu-icons order-icon"/>{pageData['my-orders']}</Link></li>
                <li><Link to={"/profile"} className={pathname==="/profile" ? "active":""}><i className="menu-icons profile-icon"/>{pageData['my-info']}</Link></li>
                <li><Link to={"/password-settings"} className={pathname==="/password-settings" ? "active":""}><i className="menu-icons lock-icon"/>{pageData['my-password']}</Link></li>
                <li><Link to={"/contact-settings"} className={pathname==="/contact-settings" ? "active":""}><i className="menu-icons contact-icon"/>{pageData['my-contact']}</Link></li>
                <li><Link to={"/faq"} className={pathname==="/faq" ? "active":""}><i className="menu-icons sss-icon"/>{pageData['faq']}</Link></li>
                <li><Link to={"/support"} className={pathname==="/support" ? "active":""}><i className="menu-icons support-icon"/>{pageData['support']}</Link></li>
                <li><a href="tel:08502421130" className="overflow-text"><i className="menu-icons support-icon"/>08502421130</a></li>
            </ul>
        </div>
    </div>);
}

export default Sidebar;
