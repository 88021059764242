import React, {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import * as Actions from "../store/actions/index";
import "../assets/css/style.css";
import {Modal, ModalBody} from "react-bootstrap";
import Context from "../Utils/Context";
import {checkItem, getItem, setItem} from "../Utils/LocalStorage";
import {decyrptLocalDataString, encryptLocalDataString} from "../Utils/Crypt";
import {req} from "../Utils/Service";
import InputMask from "react-input-mask";
import {Link} from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";


const Login = () => {
    const { t } = useTranslation();
    const loginData = t('modals.login', { returnObjects: true })
    const pageData = {
        "title": loginData['custom-content'].find(x => x.name === 'login')['value-single'],
        "text-1": loginData['custom-content'].find(x => x.name === 'contract-text-1')['value-single'],
        "text-2": loginData['custom-content'].find(x => x.name === 'contract-text-2')['value-single'],
        "phone": loginData.inputs.find(x => x.name === 'login-phone'),
        "pass": loginData.inputs.find(x => x.name === 'login-pass'),
        "login": loginData.buttons.find(x => x.name === 'login')['label'],
        "forgot": loginData.buttons.find(x => x.name === 'forgot-pass')['label'],
        "signup": loginData.buttons.find(x => x.name === 'signup')['label'],
    }

    const context = useContext(Context);
    //console.log("props", props, context);

    const {loginModal} = useSelector(state => state.settings.layout);
    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [loginType] = useState("user");
    const [password, setPassword] = useState("");

    useEffect(()=>{
        async function checkUp() {
            if (await checkItem('SessLVal')) {
                //const user = JSON.parse(await getItem('user'));
                const loginType = await getItem('loginType');
                if (loginType === 'user') {
                    console.log("go to user home");
                } else if (loginType === 'clerk') {
                    console.log("go to clerk home");
                }
            }
        }
        checkUp();
    });

    const onLoginButtonPressed = () => {
        let RUsername = (username.replace(/\s/g, ''));
        if (password && username) {
            const phoneRegex = RegExp(/^(5)[0-9][0-9][1-9]([0-9]){6}$/).test(
                RUsername,
            );
            const emailRegex = RegExp(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            ).test(username);
            if (!phoneRegex && !emailRegex) {
                const dialogTData = t('showDialogs', { returnObjects: true })
                const dialogData = dialogTData.find(x => x.name === 'login-remove-0')
                context.setGlobalState('showDialog', {
                    title: dialogData?.title,
                    message: dialogData?.message,
                    buttons: [
                        {
                            text: dialogData?.buttons[0].label,
                            onPress: () => {
                                context.setGlobalState('showDialog', false);
                            },
                        },
                    ],
                });
                return;
            }
            context.login(
                {username: RUsername, password: password},
                async (response) => {
                    if (response && response.statuscode !== '200') {
                        context.clearSession();
                        const dialogTData = t('showDialogs', { returnObjects: true })
                        const dialogData = dialogTData.find(x => x.name === 'login-check')
                        context.setGlobalState('showDialog', {
                            title: dialogData?.title,
                            message: dialogData?.message,
                            buttons: [
                                {
                                    text: dialogData?.buttons[0].label,
                                    onPress: () => {
                                        context.setGlobalState('showDialog', false);
                                    },
                                },
                            ],
                        });
                        return;
                    }
                    if (response) {
                        const s = response.data;

                        //const deviceState = await OneSignal.getDeviceState();
                        const user = JSON.parse(await getItem('user'));
                        const sk = await encryptLocalDataString(user?.member_secretkey);
                        const pushData = {
                            optarget: 'tbl_user',
                            optype: 'membership',
                            opvalue: sk,
                            formid: '',
                            //user_temp_token: deviceState.userId,
                        };

                        req('user/userUpdate', JSON.stringify(pushData))
                            .then(async (response) => {
                                // console.log({response})
                                console.log(2, {
                                    response: JSON.parse(await decyrptLocalDataString(response)),
                                });
                            })
                            .catch((error) => {
                                console.log({error});
                            });

                        //OneSignal.sendTag('user_type', user.member_role);
                        await setItem('loginType', loginType);
                        if (
                            loginType === 'user' &&
                            (s.member_role === 'enduser' || s.member_role === 'adminuser')
                        ) {
                            //this.props.navigation.navigate('Home');
                            console.log("go to user home");
                            window.location.href="/stalls";
                        } else if (
                            loginType === 'clerk' &&
                            (s.member_role === 'marketuser' || s.member_role === 'adminuser')
                        ) {
                            //this.props.navigation.navigate('ClerkHome');
                            console.log("go to clerk home");
                        } else {
                            context.clearSession();
                            const dialogTData = t('showDialogs', { returnObjects: true })
                            const dialogData = dialogTData.find(x => x.name === 'login-check')
                            context.setGlobalState('showDialog', {
                                title: dialogData?.title,
                                message: dialogData?.message,
                                buttons: [
                                    {
                                        text: dialogData?.buttons[0].label,
                                        onPress: () => {
                                            context.setGlobalState('showDialog', false);
                                        },
                                    },
                                ],
                            });
                        }
                    } else {
                        context.clearSession();
                         const dialogTData = t('showDialogs', { returnObjects: true })
                         const dialogData = dialogTData.find(x => x.name === 'login-check-2')
                         context.setGlobalState('showDialog', {
                             title: dialogData.title,
                             message: dialogData.message,
                             buttons: [
                                 {
                                     text: dialogData.buttons[0].label,
                                     onPress: () => {
                                         context.setGlobalState('showDialog', false);
                                     },
                                 },
                             ],
                         });
                    }
                },
            );

        }
    };

    const forgotPass=()=>{
        dispatch(Actions.closeLoginModal());
        dispatch(Actions.openForgotPassModal());
    }

    return (
        <Modal show={loginModal} id={"login"} centered>
            <div onClick={() => {
                dispatch(Actions.closeLoginModal())
            }} className="close-modal" data-dismiss="modal"><i/></div>
            <ModalBody>
                <h1>{ pageData['title']}</h1>
                <form id="login-form" className=" needs-validation" noValidate>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group" style={{display: "flex", justifyContent:"center", alignItems:"center", paddingLeft:"0"}}>
                                {/* <div className="mb-0" style={{color: "#90B52A", fontSize: "14px", paddingLeft: "20px",
                                    fontWeight: 500,
                                    minHeight: "53px",
                                    justifyContent:"center",
                                    flexDirection:"column",
                                    display:"flex"

                                }}>+90 </div> */}
                                <input
                                    className="form-control" id="login-phone"
                                    placeholder={ pageData.phone.placeholder }
                                    value={username}
                                       onChange={(e) => setUsername(e.target.value)} required/>
                                <label htmlFor="password">{ pageData.phone.label }</label>
                                <div className="invalid-feedback">{ pageData.phone.error }</div>
                            </div>
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input type="password" className="form-control" id="login-pass" placeholder={pageData.pass.placeholder}
                                       value={password} onChange={(e) => setPassword(e.target.value)} required/>
                                <label htmlFor="password2">{ pageData.pass.label }</label>
                                <div className="invalid-feedback">
                                    { pageData.pass.error }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-10 m-auto">
                        <button type="submit" onClick={(e) => {
                            e.preventDefault();
                            onLoginButtonPressed();
                            dispatch(Actions.closeLoginModal())
                        }} className="btn btn-secondary w-100">{ pageData.login }
                        </button>
                    </div>
                </form>
                <p className="kvkk">{pageData["text-1"] } <Link onClick={()=>dispatch(Actions.closeLoginModal())} to={"/clarification-text"} >{pageData["text-2"]}</Link>
                </p>
                <p style={{cursor:"pointer"}}><a onClick={forgotPass} data-target="#forgotpass">{ pageData.forgot }</a></p>
                <p style={{cursor:"pointer"}}><a onClick={()=>{
                    dispatch(Actions.closeLoginModal());
                    dispatch(Actions.openSignupModal());
                }}>{ pageData.signup }</a></p>
            </ModalBody>
        </Modal>
    );
}
export default Login;
