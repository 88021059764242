import React from 'react';
import Context from './Context';
import axios from 'axios';
import {checkItem, getItem, setItem, removeItem} from './LocalStorage';
import {encryptLocalDataString, decyrptLocalDataString} from './Crypt';
import {config} from './Config';
import * as _ from 'lodash';
import * as Sentry from '@sentry/react';
import {findFirst} from 'obj-traverse/lib/obj-traverse';

import moment from 'moment';
import {createSLT} from './Service';
import Loading from "../layout/Loading";
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

class GlobalState extends React.Component {
  static contextType = Context;
  state = {
    addresses: [],
    loading: false,
    showDialog: false,
    showSelectDialog: false,
    addAddressModal: false,
    showAggrementDialog: false,
    loginAlert: false,
    user: null,
    loadingTimeout: {},
    cart: [],
    cartLoading: false,
    addressSelectModal:false,
    lastCartFetchDate: null,
    cartTransaction: null,
    cartTransactionLines: [],
    cartUpdated:false,
    manuelAddress: {
      user_address_city: null,
      user_address_county: null,
      user_address_neighborhood: null,
    },
    isEndUser: false,
    endUserAddress: null,
    cities: [],
    cityPicker: [],
  };

  async componentDidMount() {
    if (await checkItem('SessLVal')) {
      const user = JSON.parse(await getItem('user'));
      await this.getUser((data) => {
        //--//--console.warn("user >> data >> ", data);
        setItem('userData',JSON.stringify(data[0]))
    });
      this.setState({
        user: user,
      });
    }
    if ((await getItem('loginType')) !== 'clerk') {
      await this.fetchCart();
    }
  }

  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  req = async (
    endpoint,
    data,
    isDecyrpt = true,
    body,
    loading = true,
    showSystemErrorWhenError = true,
  ) => {
    let headers = {};
    const setLoading = this.setLoading;
    const showSystemError = this.showSystemError;
    loading && setLoading(true);
    if (data) {
      headers.Data = await encryptLocalDataString(data);
    }
    let a = (await getItem('SessVal'))
      ? (headers.SessVal = await getItem('SessVal'))
      : false;
    let b =(await getItem('SessLVal'))
      ? (headers.SessLVal = await getItem('SessLVal'))
      : false;
    let c =(await getItem('Source'))
      ? (headers.Source = await getItem('Source'))
      : false;
    headers['Content-Type'] = 'multipart/form-data';
    return axios
      .request({
        method: 'post',
        url: endpoint,
        baseURL: config.api.url,
        timeout: config.api.timeout,
        headers: headers,
        data: body,
      })
      .then(async (response) => {
        let responseData;
        if (isDecyrpt) {
          responseData = await decyrptLocalDataString(response.data);
          console.log(responseData, 'Response data')
        } else {
          responseData = response.data;
        }

        loading && setLoading(false);
        if (this.isJson(responseData)) {
          try {
            const r = JSON.parse(responseData);
            if (
              r.statuscode !== '200' &&
              r.data !== 'Login user member psswrd not matched' &&
              r.data !== 'Login user member data null'
            ) {
              throw r;
            }
          } catch (error) {
            if (showSystemErrorWhenError)
              showSystemErrorWhenError && showSystemError();
            console.error('error isJson >> ', error);
            if (error.statuscode === '401') {
              await removeItem('loginType');
              await removeItem('endUserAddress');
              await this.setGlobalState('isEndUser', true);
              await this.setGlobalState('endUserAddress', null);
              await this.clearSession();
              await this.setGlobalState('user', null);
              await this.createTempMember();
            }
            Sentry.setUser(headers);
            Sentry.setContext('FormData', body ?? '');
            Sentry.setContext('ResponseData', responseData);
            error.message = error.message ? error.message : 'Unknown Error';
            Sentry.captureException(new Error(error.message), {
              tags: {
                endpoint: endpoint,
                isDecyrpt: isDecyrpt || false,
              },
            });
            return error;
          }
        }
        return responseData;
      })
      .catch(function (error) {
        loading && setLoading(false);
        showSystemErrorWhenError && showSystemError();
        console.error('error >> ', error);
        Sentry.setUser(headers);
        error.message = error.message ? error.message : 'Unknown Error';
        Sentry.captureException(new Error(error.message), {
          tags: {
            endpoint: endpoint,
            isDecyrpt: isDecyrpt || false,
          },
        });
      });
  };

  clearSession = async () => {
    this.setState({
      cart: [],
      cartTransaction: null,
      cartTransactionLines: [],
    });
    await removeItem('SessVal');
    await removeItem('SessLVal');
    await removeItem('Source');
    await removeItem('user');
    await removeItem('cart');
    await removeItem('market');
    await removeItem('stall');
    await removeItem('address');
    await removeItem('loginType');
    await removeItem('myNeighborhood');
  };

  marketerWorkSummary = async (callback, market_id) => {
    //--//--console.warn('{"optarget":"view_product_sales_list","optype":"select","opvalue":"","formid":"","product_transaction_market_id":"' + market_id + '"}');
    this.req(
      'pazardanWebApp/MarketerWorkSummary',
      '{"optarget":"view_product_sales_list","optype":"select","opvalue":"","formid":"","product_transaction_market_id":"' +
        market_id +
        '"}',
    ).then(async (response) => {
      callback(JSON.parse(response).data);
    });
  };

  marketerProductList = async (callback) => {
    //--//--console.warn('{"optarget":"view_active_product_list","optype":"select","opvalue":"","formid":""}');
    this.req(
      'pazardanWebApp/MarketerProductList',
      '{"optarget":"view_active_product_list","optype":"select","opvalue":"","formid":""}',
    ).then(async (data) => {
      callback(JSON.parse(data).data);
    });
  };

  marketerTotalLikeScore = async (clerk_id, callback) => {
    //--//--console.warn('{"optarget":"view_active_product_list","optype":"select","opvalue":"","formid":""}');
    this.req(
      'pazardanWebApp/marketerTotalLikeScore',
      '{"optarget":"view_product_sales_list","optype":"select","opvalue":"","formid":"","product_transaction_seller_user_id":"' +
        clerk_id +
        '","product_transaction_market_id":"0" }',
    ).then(async (data) => {
      callback(JSON.parse(data).data);
    });
  };

  userCampaignCheck = async (campaign_id, callback) => {
    this.req(
      'pazardanWebApp/UserCampaignCheck',
      '{"optarget":"campaign","optype":"select","opvalue":"","formid":"","campaign_short_code":"' +
        campaign_id +
        '"}',
    ).then(async (data) => {
      callback(JSON.parse(data));
    });
  };

  fromWebApp = async (endpoint, callback, filter, body, errorCallBack) => {
    const setLoading = this.setLoading;
    const showSystemError = this.showSystemError;
    setLoading(true);
    axios
      .request({
        method: 'post',
        url: 'pazardanWebApp/' + endpoint + '?role=enduser' + (filter || ''),
        baseURL: config.api.url,
        timeout: config.api.timeout,
        data: body,
      })
      .then(async (response) => {
        console.log(`res`, response)
        if (this.isJson(response.data)) {
          try {
            const r = JSON.parse(response.data);
            if (r.statuscode !== '200') {
              throw r;
            }
          } catch (error) {
            showSystemError();
            console.error('error isJson >> ', error);
            Sentry.setUser(null);
            error.message = error.message ? error.message : 'Unknown Error';
            Sentry.captureException(new Error(error.message), {
              tags: {
                endpoint: endpoint,
              },
            });
            return error;
          }
        }
        console.log(`is callback`, response.data)
        callback(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error('error >> ', error);
        error.message = error.message ? error.message : 'Unknown Error';
        Sentry.captureException(new Error(error.message), {
          tags: {
            endpoint: endpoint,
            filter: filter || false,
            body: body,
          },
        });
        showSystemError();
        setLoading(false);
        errorCallBack(error);
      });
  };

  paymentStart = async (secretkey, callback) => {
    //--console.warn('{"optarget":"tbl_product_transaction","optype":"paymentstart","opvalue":"","formid":"","product_transaction_secret_key":"' + secretkey + '"}');

    console.log("payment", "websiteactive");

    this.req(
      'pazardanWebApp/PaymentStart?websiteactive=true',
      '{"optarget":"tbl_product_transaction","optype":"paymentstart","opvalue":"","formid":"","product_transaction_secret_key":"' +
        secretkey +
        '"}',
    ).then(async (data) => {
      callback(JSON.parse(data));
    });
  };

  createSLT = async (callback) => {
    this.req('pazardanWebApp/CreateSession', null, false).then(
      async (SessVal) => {
        if (SessVal) {
          await setItem('SessVal', SessVal);
          this.req(
            'pazardanWebApp/CreateLoginType?UserType=user',
            null,
            false,
          ).then(async (Source) => {
            await setItem('Source', Source);
            if (Source) {
              callback(true);
            } else {
              this.showSystemError();
            }
          });
        } else {
          this.showSystemError();
        }
      },
    );
  };

  createTempMember = async (callback = null) => {
    // await this.createSLT(async (response) => {
    //   if (response) {
        await this.req('pazardanWebApp/TempMember2?getEncrypted=0', null, false)
          .then(async (response) => {
            console.log({ response})
            // const responseData = JSON.parse(response.data);

            await setItem('Source', response.data.Source);
            await setItem('SessVal', response.data.SessVal);
            await setItem('SessLVal', response.data.SessLVal);
            await setItem('loginType', 'enduser');

            callback && callback(response);
          })
          .catch(() => {
            this.showSystemError();
            callback && callback(false);
          });
      // }
    // });
  };

  login = async (user, callback) => {
    await this.clearSession();
    this.createSLT(async () => {
      this.req(
        'pazardanWebApp/Login',
        '{"optarget":"user", "optype":"login", "opvalue": "", "formid":"","user_username":"' +
          user.username +
          '","user_password":"' +
          user.password +
          '","usertype":"user"}',
      ).then(async (response) => {
        let pRData;
        if (this.isJson(response)) {
          pRData = JSON.parse(response);
        } else {
          pRData = response;
        }

        if (!pRData || pRData.statuscode !== '200') {
          callback(pRData);
          return;
        }

        await setItem('Source', pRData.data.Source);
        await setItem('SessLVal', pRData.data.SessLVal);
        await setItem('user', JSON.stringify(pRData.data));
        await this.setState({
          user: pRData.data,
        });
        callback(pRData);
      });
    });
  };

  newmember = async (formData, callback) => {
    this.clearSession();
    this.createSLT(async () => {
      const body = _.merge(
        {optarget: 'user', optype: 'newmember', opvalue: '', formid: ''},
        formData,
      );
      //--//--console.warn("newmember >> body >> ", body);
      this.req('pazardanWebApp/NewMember', JSON.stringify(body)).then(
        async (response) => {
          let pRData;
          if (this.isJson(response)) {
            pRData = JSON.parse(response);
          } else {
            pRData = response;
          }
          callback(pRData);
        },
      );
    });
  };
  checkAsyncStorage = () => {
    localStorage.getAllKeys((err, keys) => {
      localStorage.multiGet(keys, (error, stores) => {
        stores.map((result, i, store) => {
          console.log({[store[i][0]]: store[i][1]});
          return true;
        });
      });
    });
  };
  checkValidationCode = (formData, callback) => {
    this.clearSession();
    const body = _.merge(
      {optarget: 'user', optype: 'validationcode', opvalue: '', formid: ''},
      formData,
    );
    //--//--console.warn("checkValidationCode >> body >> ", body);
    this.createSLT(async () => {
      this.req('pazardanWebApp/CheckValidationCode', JSON.stringify(body)).then(
        async (response) => {
          let pRData;
          if (this.isJson(response)) {
            pRData = JSON.parse(response);
          } else {
            pRData = response;
          }

          if (!pRData || pRData?.statuscode !== '200') {
            callback(pRData);
            return;
          }

          await setItem('Source', pRData.data?.Source);
          await setItem('SessLVal', pRData.data?.SessLVal);
          await setItem('user', JSON.stringify(pRData.data));
          await this.setState({
            user: pRData.data,
          });
          callback(true);
        },
      );
    });
  };

  resendValidationCode = (formData, callback) => {
    this.clearSession();
    const body = _.merge(
      {
        optarget: 'tbl_user',
        optype: 'validation',
        opvalue: '',
        formid: '',
        usertype: 'user',
      },
      formData,
    );
    //--//--console.warn("checkValidationCode >> body >> ", body);
    this.createSLT(async () => {
      this.req(
        'pazardanWebApp/ResendValidationCode',
        JSON.stringify(body),
        false
      ).then(async (response) => {
        console.log("response >> ", response);
        if (!response) {
          callback(false);
          return;
        }
        //--//--console.warn("rData >> ", rData);
        const pRData =response;
        await setItem('Source', pRData.data.Source);
        await setItem('SessLVal', pRData.data.SessLVal);
        await setItem('user', JSON.stringify(pRData.data));
        callback(true);
      });
    });
  };

  resetPassword = (formData, callback) => {
    this.clearSession();
    //--console.warn("formData >> ", formData);

    const body = _.merge(
      {
        optarget: 'user',
        optype: 'resetpassword',
        opvalue: '',
        formid: '',
        usernametype: 'gsm',
      },
      formData,
    );
    //--console.warn("resetPassword >> body >> ", body);
    this.createSLT(async () => {
      this.req('pazardanWebApp/ResetPassword', JSON.stringify(body)).then(
        async (response) => {
          //--console.warn("response >> ", response);
          let pRData;
          if (this.isJson(response)) {
            pRData = JSON.parse(response);
          } else {
            pRData = response;
          }

          if (!pRData || pRData.statuscode !== '200') {
            callback(pRData);
            return;
          }
          pRData = JSON.parse(response);
          callback(pRData);
        },
      );
    });
  };

  passwordCheckValidationCode = (formData, callback) => {
    this.clearSession();
    const body = _.merge(
      {
        optarget: 'tbl_user',
        optype: 'passwordvalidation',
        opvalue: '',
        formid: '',
      },
      formData,
    );
    //--console.warn("checkValidationCode >> body >> ", body);
    this.createSLT(async () => {
      this.req(
        'pazardanWebApp/PasswordCheckValidationCode',
        JSON.stringify(body),
      ).then(async (response) => {
        //--console.warn("response >> ", response);
        let pRData;
        if (this.isJson(response)) {
          pRData = JSON.parse(response);
        } else {
          pRData = response;
        }

        if (!pRData || pRData.statuscode !== '200') {
          callback(pRData);
          return;
        }
        //--//--console.warn("rData >> ", rData);

        await setItem('Source', pRData.data.Source);
        await setItem('SessLVal', pRData.data.SessLVal);
        await setItem('user', JSON.stringify(pRData.data));
        await setItem('loginType', pRData.data.member_role);
        this.setGlobalState('user', pRData.data);
        callback(true);
      });
    });
  };

  getUser = async (callback) => {
    const user = JSON.parse(await getItem('user'));
    if (user?.member_secretkey) {
      const sk = await encryptLocalDataString(user.member_secretkey);

      this.req(
        'user/userUpdateGetData',
        '{"optarget":"user", "optype":"membership", "opvalue": "' +
          sk +
          '", "formid":""}',
      ).then(async (response) => {
        const decyrptedReponse = JSON.parse(response);
        callback(decyrptedReponse.data);
      });
    }
  };

  select = (table, callback, filterParameters, optype, loading = true) => {
    const fp = filterParameters ? '?filterparameters=' + filterParameters : '';

    this.req(
      table + '/' + table + 'Select' + fp,
      '{ "optarget":"' +
        table +
        '", "optype": "' +
        (optype || 'select') +
        '", "opvalue": "", "formid": "" }',
      fp?.indexOf('getEncrypted=0') === -1 ? true : false,
      undefined,
      loading,
    ).then(async (response) => {
      let decyrptedReponse;
      if (typeof response === 'string') {
        decyrptedReponse = JSON.parse(response);
      } else {
        decyrptedReponse = response;
      }
      callback(decyrptedReponse?.data);
    });
  };
  getMarketsCustom = (city, county, neighbourhood, callback, loading = true) => {
    this.req(
        'view_active_marketer_list/view_active_marketer_listCustomSelect?city='+city+'&county='+county+'&neighbourhood='+neighbourhood+'&filterparameters=market_active_marketer_is_deleted:0,market_is_active:1,market_is_deleted:0&pageLimit=99999&getEncrypted=0',
        null, false,
      undefined,
      loading,
    ).then(async (response) => {
      let decyrptedReponse;
      if (typeof response === 'string') {
        decyrptedReponse = JSON.parse(response);
      } else {
        decyrptedReponse = response;
      }
      callback(decyrptedReponse?.data);
    });
  };

  insert = async (table, data, callback, loading = true) => {
    const body = _.merge(
      {optarget: table, optype: 'insert', opvalue: '', formid: ''},
      data,
    );

    const formData = new FormData();
    formData.append('Data', await encryptLocalDataString(JSON.stringify(body)));

    //--console.warn("insert >> body >> ", body);
    this.req(
      table + '/' + table + 'Insert',
      null,
      true,
      formData,
      loading,
    ).then(async (response) => {
      const decyrptedReponse = JSON.parse(response);
      callback(decyrptedReponse.data);
    });
  };

  update = async (
    table,
    data,
    secretkey,
    callback,
    optype,
    where,
    loading = true,
  ) => {
    const body = _.merge(
      {
        optarget: table,
        optype: optype || 'update',
        opvalue: await encryptLocalDataString(secretkey),
        formid: '',
      },
      data,
    );

    const formData = new FormData();
    formData.append('Data', await encryptLocalDataString(JSON.stringify(body)));

    this.req(
      table + '/' + table + 'Update' + (where ? where : ''),
      null,
      true,
      formData,
      loading,
    ).then(async (response) => {
      const decyrptedReponse = JSON.parse(response);
      callback(decyrptedReponse.data);
    });
  };

  deleted = async (table, formData, secretkey, callback) => {
    const sk = await encryptLocalDataString(secretkey);

    //--//--console.warn("sk> >> ", sk);
    const data = {optarget: table, optype: 'update', opvalue: sk, formid: ''};
    const body = _.merge(data, formData);

    //--console.warn("deleted >> body >> ", JSON.stringify(body));
    this.req(table + '/' + table + 'Update', JSON.stringify(body)).then(
      async (response) => {
        const decyrptedReponse = JSON.parse(response);
        callback(decyrptedReponse.data);
      },
    );
  };

  setGlobalState = (key, value) => {
    const s = {};
    s[key] = value;
    this.setState(s);
  };

  getGlobalState = (key) => {
    return this.state[key];
  };

  setMyNeighborhood = async (value, address) => {
    if (value) {
      const wrs = _.split(
        value.market_active_marketer_work_range_neighborhoods,
        ',',
      );
      let counties = [];
      if (wrs.length > 0) {
        await _.map(wrs, (wr, index) => {
          const cnp = _.split(wr, '_');

          const county = cnp[0];
          const neighborhood = cnp[1];
          const minPrice = cnp[2];

          const c = _.find(counties, {county: county});
          if (!c) {
            counties.push({
              county: county,
              neighborhoods: [{name: neighborhood, minPrice: minPrice}],
            });
          } else {
            c.neighborhoods.push({name: neighborhood, minPrice: minPrice});
          }

          if (index === wrs.length - 1) {
            this.setState({
              selectedMarketCounties: counties,
            });
          }
        });
      }
      const myCounty = _.find(counties, {county: address.user_address_county});
      if (myCounty !== undefined) {
        const myNeighborhood = findFirst(await myCounty, 'neighborhoods', {
          name: address.user_address_neighborhood,
        });
        await setItem('myNeighborhood', JSON.stringify(await myNeighborhood));
      }
    }
  };

  showAggrement = (props) => {
    this.fromWebApp(
      'AgreementSelect',
      (data) => {
        const helpDetails = data.data[0];
        console.log(`helpDetails`, helpDetails);
        console.log(`props`, props.params);
        if (props.params) {
          let products = [];
          _.map(props.params.products, (product) => {
            products.push(
              product.product_name +
                '  ' +
                parseFloat(product.product_transaction_line_piece).toFixed(2) +
                ' ' +
                product.product_unit_type +
                ' - ' +
                parseFloat(
                  parseFloat(
                    parseFloat(
                      parseFloat(
                        _.replace(
                          product?.product_active_seller_entry_price,
                          ',',
                          '.',
                        ),
                      ).toFixed(2) /
                        parseFloat(
                          1 +
                            parseFloat(
                              _.replace(product?.product_vat_rate, ',', '.'),
                            ),
                        ).toFixed(2),
                    ).toFixed(2) *
                      parseFloat(
                        parseFloat(
                          _.replace(
                            product?.product_transaction_line_piece,
                            ',',
                            '.',
                          ),
                        ),
                      ).toFixed(2),
                  ).toFixed(2) *
                    parseFloat(
                      1 +
                        parseFloat(
                          _.replace(product?.product_vat_rate, ',', '.'),
                        ),
                    ).toFixed(2),
                )
                  .toFixed(2)
                  .toString() +
                'TL',
            );
          });
          products.push(
            ((false)
              ? ('<br>İndirim Tutarı: ' +
                parseFloat(
                  _.replace(
                    props.params.transaction
                      .product_transaction_seller_discount,
                    ',',
                    '.',
                  ),
                ).toFixed(2) +
                ' TL (' +
                props.params.transaction.product_transaction_campaign_code +
                ')')
              : ('') +
              '<br>Toplam: ' +
              props.params?.totalPrice?.toString() +
              'TL')
          );

          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<saticiunvan>/g,
            props.params.stall.user_name+` `+[props.params.stall.user_surname],
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<aliciteslimatadsoyad>/g,
            props.params.user.member_name + ' ' + props.params.user.member_surname,
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<aliciteslimatadres>/g,
            props.params.transaction
              .product_transaction_buyer_delivery_address_full_address,
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<alicitelefon>/g,
            props.params.userData.user_active_gsm,
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<aliciemail>/g,
            props.params.userData.user_active_email,
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<satisverileri>/g,
            _.join(products, '<br>'),
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<teslimatadresi>/g,
            props.params.transaction
              .product_transaction_buyer_delivery_address_full_address,
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<faturaadresi>/g,
            props.params.transaction
              .product_transaction_buyer_delivery_address_full_address,
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<siparistarihi>/g,
            moment().format('YYYY-MM-DD HH:mm'),
          );
          helpDetails.user_agreement_detail = _.replace(
            helpDetails.user_agreement_detail,
            /<teslimattarihi>/g,
            props.params.deliveryTime,
          );
        }
        this.setGlobalState('showAggrementDialog', {
          type: props.type,
          buttons: props.buttons,
          helpDetails: helpDetails,
        });
      },
      '&type=' + props.type,
    );
  };

  setLoading = (status) => {
    if (status && !this.state.loading) {
      if (this._loading) {
        this._loading._show();
      }
      this.setGlobalState('loading', true);
    } else {
      this.setGlobalState('loading', false);
      if (this._loading) {
        this._loading._hide();
      }
    }
  };

  showSystemError = () => {
    const { t } = this.props
    const dialogData = t('showDialogs', { returnObjects: true })
    const systemErrorData = dialogData.find(x => x.name === 'global-state-unexpected')
    this.setGlobalState('showDialog', {
      title: systemErrorData.title,
      message: systemErrorData.message,
      buttons: [
        {
          text: systemErrorData.buttons[0].label,
          onPress: () => {
            this.setGlobalState('showDialog', false);
          },
        },
      ],
    });
  };

  updateCart = (cartItems) => {
    this.setState({cartUpdated: !this.state.cartUpdated});
    this.setGlobalState('cart', cartItems);
  };

  uploadStory = (body, callback, errorCallBack) => {
    this.req(
      '/pazardanWebApp/FileUpload?targetFolder=story&targetType=user&opType=insert&IDSecretKey=storyInsertView',
      null,
      true,
      body,
    )
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCallBack(error);
      });
  };

  getStories = (data, callback, errorCallBack) => {
    this.req('/story/storySelect', data, true)
      .then((response) => {
        callback(response);
      })
      .catch((error) => {
        errorCallBack(error);
      });
  };

  updateProductReady = async (formData, callback) => {
    //--console.warn("update >> body >> ", body);
    this.req(
      'product_transaction_line/product_transaction_lineUpdate',
      JSON.stringify(formData),
    ).then(async (response) => {
      callback(response);
    });
  };

  updateProductNotAvailable = async (formData, callback) => {
    //--console.warn("update >> body >> ", body);
    this.req(
      'product_transaction_line/product_transaction_lineUpdate',
      JSON.stringify(formData),
    )
      .then(async (response) => {
        // const decyrptedReponse = JSON.parse(response);
        callback(response);
      })
      .catch((error) => {
        console.log({error});
      });
  };

  updateOrderApprove = async (formData, callback) => {
    this.req(
      'product_transaction/product_transactionUpdate',
      JSON.stringify(formData),
    )
      .then(async (response) => {
        callback(response);
      })
      .catch((error) => {
        console.log({error});
      });
  };

  deleteUserAccount = (callback, errorCallBack) => {
    this.req('pazardanWebApp/DeleteMembership')
      .then((response) => callback(JSON.parse(response)))
      .catch((error) => callback(error));
  };

  fetchCart = (
    callback = undefined,
    createNew = false,
    isSyncCartItems = true,
    filterParameters = '',
  ) => {
    if (this.state.user !== null) {
      this.setLoading(true);
      if (createNew === true) {
        this.createNewProductTransaction(true, (createdProductTransaction) => {
          callback && callback(createdProductTransaction);
          this.setLoading(false);
        });
        return;
      }
      this.select(
        'product_transaction',
        (data) => {
          if (!data) {
            return;
          }
          if (data.length === 0) {
            this.createNewProductTransaction(
              true,
              (createdProductTransaction) => {
                this.setLoading(false);
                callback && callback(createdProductTransaction);
                isSyncCartItems && this.syncCartItems(undefined, true);
              },
            );
            return;
          } else {
            this.setState({
              cartTransaction: data[0],
            });
            this.setLoading(false);
            callback && callback(data[0]);
            isSyncCartItems && this.syncCartItems(undefined, true);
          }
        },
        'product_transaction_is_active:1,' +
          'product_transaction_is_deleted:0,' +
          'product_transaction_payment_status-is-null,' +
          filterParameters +
          'product_transaction_created_by_user_id:' +
          this.state.user.member_id +
          '&orderparameters=product_transaction_id:desc&pageLimit=1',
      );
    }
  };

  createNewProductTransaction = (
    loading = true,
    callback = undefined,
    extraData = {},
  ) => {
    loading && this.setLoading(true);
    const formData = {...extraData};
    formData.product_transaction_created_by_user_id = this.state.user.member_id;
    formData.product_transaction_buyer_user_id = this.state.user.member_id;
    formData.product_transaction_buyer_delivery_name =
      this.state.user.member_name;
    formData.product_transaction_buyer_delivery_surname =
      this.state.user.member_surname;
    formData.product_transaction_is_active = 1;
    formData.product_transaction_is_deleted = 0;

    this.insert('product_transaction', formData, (transactionResponse) => {
      if (!transactionResponse) {
        return;
      }

      if (transactionResponse?.id && transactionResponse?.secretkey) {
        this.select(
          'product_transaction',
          (transactionr) => {
            if (!transactionr) {
              return;
            }

            this.setState({
              cartTransaction: transactionr?.[0],
              cartTransactionLines: [],
            });
            loading && this.setLoading(false);
            callback && callback(transactionr?.[0]);
          },
          'product_transaction_is_active:1' +
            ',product_transaction_is_deleted:0' +
            ',product_transaction_id:' +
            transactionResponse?.id +
            ',product_transaction_secret_key:' +
            transactionResponse?.secretkey +
            ',product_transaction_created_by_user_id:' +
            this.state.user.member_id,
        );
      }
    });
  };

  syncCartItems = (
    callback = undefined,
    loading = true,
    isIncludeFakeDatas = false,
  ) => {
    if (this.state.user === null || this.state.cartTransaction === null) {
      this.setState({
        cartItems: [],
      });
      return;
    }
    if (this.state.cartTransaction?.product_transaction_id) {
      this.select(
        'view_product_sales_list',
        (data) => {
          if (!data) {
            return;
          }
          const newCartTransactionLines = !isIncludeFakeDatas
            ? data
            : data.concat(
                this.state.cartTransactionLines.filter(
                  (transactionLine) =>
                    transactionLine?.isFake || transactionLine?.isLoading,
                ),
              );
          this.setState({
            cartTransactionLines: newCartTransactionLines,
            lastCartFetchDate: new Date(),
          });
          callback && callback(newCartTransactionLines);
        },
        'product_transaction_line_is_active:1,' +
          'product_transaction_line_is_deleted:0,' +
          'product_transaction_id:' +
          this.state.cartTransaction.product_transaction_id +
          '&orderparameters=product_transaction_id:desc&pageLimit=99999&getEncrypted=0',
        'select',
        loading,
      );
    } else {
      this.fetchCart();
    }
  };

  addItemToCart = async (
    product_transaction_line,
    callback = undefined,
    loading = true,
  ) => {
    console.log('Startxx:' + new Date());
    console.log('product_transaction_line:',product_transaction_line);
    this.setState({
      cartTransactionLines: _.map(
        this.state.cartTransactionLines,
        (cartTransactionLine) => {
          if (
            cartTransactionLine.product_transaction_line_product_id ===
              product_transaction_line?.product_transaction_line_product_id &&
            cartTransactionLine.product_transaction_line_product_name ===
              product_transaction_line?.product_transaction_line_product_name &&
            cartTransactionLine.isLoading
          ) {
            return {
              ...product_transaction_line,
              isFake: true,
              isLoading: false,
            };
          }
          return cartTransactionLine;
        },
      ),
    });
    await this.insert(
      'product_transaction_line',
      product_transaction_line,
      async (IPTLR) => {
        if (!IPTLR) {
          return;
        }
        await this.select(
          'view_product_sales_list',
          (CPTLR) => {
            if (!CPTLR) {
              return;
            }
            console.log('Endxx:' + new Date());
            const newCartTransactionLines = this.state.cartTransactionLines.map(
              (cartTransactionLine) => {
                if (
                  cartTransactionLine.product_transaction_line_product_id ===
                    CPTLR?.[0]?.product_transaction_line_product_id &&
                  cartTransactionLine.product_transaction_line_product_name ===
                    CPTLR?.[0]?.product_transaction_line_product_name &&
                  cartTransactionLine.isFake
                ) {
                  return CPTLR?.[0];
                }
                return cartTransactionLine;
              },
            );
            this.setState({
              cartTransactionLines: newCartTransactionLines,
            });
            callback && callback(CPTLR?.[0]);
          },
          'product_transaction_line_is_active:1,' +
            'product_transaction_line_is_deleted:0,' +
            'product_transaction_id:' +
            this.state.cartTransaction.product_transaction_id +
            ',product_transaction_line_id:' +
            IPTLR.id +
            ',product_transaction_line_secret_key:' +
            IPTLR.secretkey +
            '&pageLimit=99999',
          undefined,
          loading,
        );
      },
      loading,
    );
  };

  updateItemInCart = async (
    updateFormData,
    cartItem,
    callback = undefined,
    loading = true,
  ) => {
    const isRemoving = updateFormData?.product_transaction_line_piece === '0.0';
    const body = _.merge(
      {
        optarget: 'tbl_product_transaction_line',
        optype: 'update',
        opvalue: await encryptLocalDataString(
          cartItem?.product_transaction_line_secret_key,
        ),
        formid: '',
      },
      updateFormData,
    );

    let formData = new FormData();
    formData.append('Data', await encryptLocalDataString(JSON.stringify(body)));
    await this.req(
      'product_transaction_line/product_transaction_lineUpdate',
      null,
      true,
      formData,
      loading,
    ).then(async (response) => {
      if (!isRemoving) {
        const UPTLR = {
          ...cartItem,
          ...updateFormData,
          isFake: false,
          isLoading: false,
        };
        const newCartTransactionLines = this.state.cartTransactionLines.map(
          (cartTransactionLine) => {
            if (
              cartTransactionLine.product_transaction_line_secret_key ===
              cartItem?.product_transaction_line_secret_key
            ) {
              return UPTLR;
            }
            return cartTransactionLine;
          },
        );
        this.setState({
          cartTransactionLines: newCartTransactionLines,
        });
        callback && callback(UPTLR);
      }
    });
    if (isRemoving) {
      const newCartTransactionLines = [
        ...this.state.cartTransactionLines,
      ].filter(
        (cartTransactionLine) =>
          cartTransactionLine.product_transaction_line_secret_key !==
          cartItem?.product_transaction_line_secret_key,
      );
      this.setState({
        cartTransactionLines: newCartTransactionLines,
      });
    }
  };

  setCartTransaction = async (cartTransaction) => {
    await this.setState({
      cartTransaction,
    });
  };

  setCartTransactionLines = async (cartTransactionLines) => {
    await this.setState({
      cartTransactionLines,
    });
  };

  updateCartTransaction = async (
    updateFormData,
    callback = undefined,
    loading = true,
    isSyncCartItems = true,
    isJustUpdate = false,
    customTransaction = undefined,
    showSystemErrorWhenError = true,
  ) => {
    if (this.state.user) {
      if (loading) this.setLoading(true);

      const transaction =
        customTransaction !== undefined
          ? customTransaction
          : this.state.cartTransaction;
      const body = _.merge(
        {
          optarget: 'tbl_product_transaction',
          optype: 'update',
          opvalue: await encryptLocalDataString(
            transaction.product_transaction_secret_key,
          ),
          formid: '',
        },
        updateFormData,
      );
      const formData = new FormData();
      formData.append(
        'Data',
        await encryptLocalDataString(JSON.stringify(body)),
      );

      this.req(
        'product_transaction/product_transactionUpdate',
        null,
        true,
        formData,
        true,
        showSystemErrorWhenError,
      ).then(async (response) => {
        if (!isJustUpdate) {
          this.fetchCart(
            (UPCT) => {
              if (!isSyncCartItems) {
                callback && callback(UPCT);
              } else {
                this.syncCartItems(callback, loading);
              }
              if (loading) this.setLoading(false);
            },
            false,
            isSyncCartItems,
            'product_transaction_secret_key:' +
              transaction?.product_transaction_secret_key +
              ',',
          );
        } else {
          if (loading) this.setLoading(false);
          callback && callback();
        }
      });
    }
  };

  startBuyerSameSales = async (product_transaction_secret_key, callback) => {
    await removeItem('cart');
    await removeItem('address');
    await removeItem('stall');
    await removeItem('myNeighborhood');

    const formData = {
      optarget: 'tbl_product_transaction',
      optype: 'samesalesstart',
      opvalue: '',
      formid: '',
      product_transaction_secret_key,
    };
    this.req(
      'pazardanWebApp/BuyerSameSalesStart',
      JSON.stringify(formData),
    ).then((response) => callback(response));
  };

  getClerkActiveProducts = async (
    callback,
    filterValues = undefined,
    loading = true,
  ) => {
    this.req(
      'view_active_product_list/view_active_product_listCustomSelect' +
        (filterValues !== undefined ? '?filterParameters=' + filterValues : ''),
      '{"optarget":"view_active_product_list", "optype": "select", "opvalue": "", "formid": ""}',
      true,
      null,
      loading,
    ).then(async (data) => {
      const responseData = JSON.parse(data);
      callback(responseData?.data, responseData?.rowcount);
    });
  };

  getCities = async () => {

    let _cities = await getItem("cities");
    if (_cities && JSON.parse(_cities).length > 10) {
      this.setCities(JSON.parse(_cities));
    } else {
      this.select(
        'city',
        async (data) => {
          setItem("cities", JSON.stringify(data));
          this.setCities(data);
        },
        '&orderparameters=city_parent_city_id:asc&pageLimit=99999',
      );
    }
  }

  setCities = async (data) => {
    this.setGlobalState('showDialog', false);
    const c = _.map(data, (d) => {
      return {label: d.city_name, value: d.city_name};
    });
    this.setState({
      cityPicker: c,
      cities: data,
    });
  }
  selectStalls = (city, county, neigbourhood, filterParameters, callback) => {
    const fp = filterParameters ? '&filterparameters=' + filterParameters : '';
    this.req('view_active_marketer_list/view_active_marketer_listCustomSelect?city=' + city + '&county=' + county + '&neighbourhood='+ neigbourhood + fp,
      '{ "optarget":"view_active_marketer_list", "optype": "select", "opvalue": "", "formid": "" }',
      false,
      undefined,
      true,
    ).then(async (response) => {
      let decyrptedReponse;
      if (typeof response === 'string') {
        decyrptedReponse = JSON.parse(response);
      } else {
        decyrptedReponse = response;
      }
      callback(decyrptedReponse?.data);
    });
  }

  getDays = () => {
    const { t } = this.props
    const globalData = t('global', { returnObjects: true })
    const days = {}
    Object.entries(globalData.days).forEach(([key, value]) => {
      days[value.index] = value.value
    })
    return days
  }
  getMonths = () => {
    const { t } = this.props
    const globalData = t('global', { returnObjects: true })
    return globalData.months[0]
  }
  render() {
    return (
      <Context.Provider
        value={{
          user: this.state.user,
          cart: this.state.cart,
          cartLoading: this.state.cartLoading,
          cities: this.state.cities,
          cityPicker: this.state.cityPicker,
          loginAlert: this.state.loginAlert,
          manuelAddress: this.state.addresses,
          addressSelectModal: this.state.addressSelectModal,
          addAddressModal: this.state.addAddressModal,
          endUserAddress: this.state.endUserAddress,
          isEndUser: this.state.isEndUser,
          cartUpdated: this.state.cartUpdated,
          loading: this.state.loading,
          showDialog: this.state.showDialog,
          showSelectDialog: this.state.showSelectDialog,
          showAggrementDialog: this.state.showAggrementDialog,
          showAggrement: this.showAggrement,
          select: this.select,
          insert: this.insert,
          update: this.update,
          deleted: this.deleted,
          login: this.login,
          clearSession: this.clearSession,
          setGlobalState: this.setGlobalState,
          getGlobalState: this.getGlobalState,
          marketerWorkSummary: this.marketerWorkSummary,
          marketerProductList: this.marketerProductList,
          fromWebApp: this.fromWebApp,
          userCampaignCheck: this.userCampaignCheck,
          paymentStart: this.paymentStart,
          createSLT: this.createSLT,
          newmember: this.newmember,
          checkValidationCode: this.checkValidationCode,
          checkAsyncStorage: this.checkAsyncStorage,
          getUser: this.getUser,
          setMyNeighborhood: this.setMyNeighborhood,
          resendValidationCode: this.resendValidationCode,
          resetPassword: this.resetPassword,
          passwordCheckValidationCode: this.passwordCheckValidationCode,
          marketerTotalLikeScore: this.marketerTotalLikeScore,
          isJson: this.isJson,
          showSystemError: this.showSystemError,
          setLoading: this.setLoading,
          updateCart: this.updateCart,
          uploadStory: this.uploadStory,
          getStories: this.getStories,
          updateProductReady: this.updateProductReady,
          updateProductNotAvailable: this.updateProductNotAvailable,
          updateOrderApprove: this.updateOrderApprove,
          deleteUserAccount: this.deleteUserAccount,
          createTempMember: this.createTempMember,
          fetchCart: this.fetchCart,
          cartTransaction: this.state.cartTransaction,
          cartTransactionLines: this.state.cartTransactionLines,
          addItemToCart: this.addItemToCart,
          updateItemInCart: this.updateItemInCart,
          setCartTransactionLines: this.setCartTransactionLines,
          updateCartTransaction: this.updateCartTransaction,
          syncCartItems: this.syncCartItems,
          setCartTransaction: this.setCartTransaction,
          startBuyerSameSales: this.startBuyerSameSales,
          createNewProductTransaction: this.createNewProductTransaction,
          lastCartFetchDate: this.state.lastCartFetchDate,
          getClerkActiveProducts: this.getClerkActiveProducts,
          getCities: this.getCities,
          getMarketsCustom: this.getMarketsCustom,
          selectStalls: this.selectStalls,
          req: this.req,
          getDays: this.getDays,
          getMonths: this.getMonths,
        }}>
        {this.props.children}
        <Loading ref={(ref) => (this._loading = ref)} />
      </Context.Provider>
    );
  }
}
export default withTranslation()(GlobalState)