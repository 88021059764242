import { createGenerateClassName, jssPreset, StylesProvider } from '@material-ui/core/styles';
import { create } from 'jss';
import jssExtend from 'jss-plugin-extend';
import rtl from 'jss-rtl';
import React, {useEffect} from 'react';
import Provider from 'react-redux/es/components/Provider';
import { BrowserRouter} from 'react-router-dom'
import { createStore, compose, applyMiddleware  } from 'redux'
import thunk from 'redux-thunk'
import createReducer from "./store/reducers";
import Routes from './routes';
import {getItem} from "./Utils/LocalStorage";
import {decyrptLocalDataString, encryptLocalDataString} from "./Utils/Crypt";
import {req} from "./Utils/Service";
import GlobalState from "./Utils/GlobalState";

const jss = create({
	...jssPreset(),
	plugins: [...jssPreset().plugins, jssExtend(), rtl()],
	insertionPoint: document.getElementById('jss-insertion-point')
});

const generateClassName = createGenerateClassName();

//For Redux Dev Tools Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(createReducer(), composeEnhancers(applyMiddleware(thunk)));

store.asyncReducers = {};

export const injectReducer = (key, reducer) => {
	if ( store.asyncReducers[key] )
	{
		return;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(createReducer(store.asyncReducers));
	return store;
};

const App = () => {
	useEffect(  () => {
		async function doSomething() {
			const user = JSON.parse(await getItem('user'));
			const sk = await encryptLocalDataString(user?.member_secretkey);
			const pushData = {
				optarget: 'tbl_user',
				optype: 'membership',
				opvalue: sk,
				formid: '',
				//user_temp_token: "deviceState.userId",
			};

			req('user/userUpdate', JSON.stringify(pushData))
				.then(async (response) => {
					//console.log(response);
					//console.log("res ",JSON.parse(await decyrptLocalDataString(response)));
					//console.log(2, {response: JSON.parse(await decyrptLocalDataString(response))});
				})
				.catch((error) => {
					console.log("error ",error);
				});
		}
		  //doSomething();
	},[]);
	return (
		<GlobalState>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<Provider store={store}>
					<BrowserRouter>
						<Routes/>
					</BrowserRouter>
				</Provider>
			</StylesProvider>
		</GlobalState>
	);
};

export default App;
