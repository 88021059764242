import React from 'react';
import {withRouter} from "react-router";
import Context from "../../Utils/Context";
import _ from "lodash";
import {getItem} from "../../Utils/LocalStorage";
import ValidationComponent from "../../components/Validator/Validator";
import {Button, Dropdown} from "react-bootstrap";
import { withTranslation } from 'react-i18next';
 class AddAddress extends ValidationComponent {
    static contextType = Context;
    deviceLocale = 'tr';
    labels = {
        formData: {
            user_address_title: 'Adres İsmi',
            user_address_city: 'İl',
            user_address_county: 'İlçe',
            user_address_neighborhood: 'Mahalle',
            user_address_detail: 'Adres',
            user_address_building_number: 'Bina No',
        },
    };
    state = {
        cities: [],
        counties: [],
        neighborhoods: [],
        cityPicker: [],
        neighborhoodPicker: [],
        countyPicker: [],
        address: null,
        isUpdate: false,
        formData: {
            user_address_title: "",
            user_address_city: "",
            user_address_county: "",
            user_address_neighborhood: "",
            user_address_detail: "",
            user_address_building_number: "",
            user_address_floor_number: "",
            user_address_door_number: "",
            user_address_information: "",
            user_address_full_address: "",
            user_address_created_by_user_id: null,
            user_address_is_active: 1,
            user_address_is_deleted: 0,
        },
    };

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const {params} = this.props.match
        if (params && params.id !== 'new') {
            //--//--console.warn("this.props.address >> ", route.params.address);
            // this.setState({
            //     formData: params.address,
            //     address: params.address,
            //     isUpdate: true,
            // });
            await this.getAddressById(params.id);
        }
        this.context.select(
            'city',
            (data) => {
                this.context.setGlobalState('showDialog', false);
                //--//--console.warn('NewAddress >> city >> ', data);
                const c = _.map(data, (d) => {
                    return {label: d.city_name, value: d.city_name};
                });
                //--//--console.warn("c >> ", c);
                this.setState({
                    cityPicker: c,
                    cities: data,
                });
            },
            '&orderparameters=city_parent_city_id:asc&pageLimit=99999',
        );
    }

    getAddressById =async (id) => {
        const user = JSON.parse(await getItem('user'));
        this.context.select(
            'address',
            (data) => {
                //console.log('Addresses >> address >> ', data);
                if (data.length!==0){
                    this.setState({formData: data[0], address: data[0], isUpdate: true,});
                }
            },
            'user_address_is_active:1,user_address_is_deleted:0,user_address_created_by_user_id:' +
            user.member_id + ',user_address_id:' + id +
            '&pageLimit=99999',
        );
    }
    onNAAddAddressButtonPressed = async () => {
        //--//--console.warn("this.state.formData >> ", this.state.formData);
        this.validate({
            formData: {
                user_address_title: {required: true},
                user_address_city: {required: true},
                user_address_county: {required: true},
                user_address_neighborhood: {required: true},
                user_address_detail: {required: true},
                user_address_building_number: {required: true},
            },
        });
        const user = JSON.parse(await getItem('user'));
        // this.updateFormData('user_address_name', user.member_name + ' ' + user.member_surname);
        // this.updateFormData('user_address_name', user.member_name);
        this.updateFormData('user_address_created_by_user_id', user.member_id);
        this.updateFormData(
            'user_address_full_address',
            this.state.formData.user_address_detail +
            ' Bina No:' +
            this.state.formData.user_address_building_number +
            (this.state.formData.user_address_floor_number
                ? ' Kat:' + this.state.formData.user_address_floor_number
                : '') +
            (this.state.formData.user_address_door_number
                ? ' Daire:' + this.state.formData.user_address_door_number
                : '') +
            ' ' +
            this.state.formData.user_address_neighborhood +
            '/' +
            this.state.formData.user_address_county +
            '/' +
            this.state.formData.user_address_city,
        );
        if (this.isFormValid()) {
            if (!this.state.address) {
                this.context.insert('address', this.state.formData, (r) => {
                    //--//--console.warn("r >> ", r);
                    this.props.history.goBack();
                });
            } else {
                this.context.update(
                    'address',
                    this.state.formData,
                    this.state.address.user_address_secret_key,
                    (r) => {
                        //--//--console.warn("r >> ", r);
                        this.props.history.goBack();
                    },
                );
            }
        }
    };

    updateFormData(key, value) {
        const f = this.state.formData;
        f[key] = value;
        this.setState(f);
    }

    onCitySelected = (value) => {
        // //--//--console.warn("onCitySelected >> value >> ", value);
        if (value) {
            this.updateFormData('user_address_city', value);
            this.updateFormData('user_address_county', "");
            this.updateFormData('user_address_neighborhood', "");
            this.context.select(
                'county',
                (data) => {
                    //--//--console.warn('NewAddress >> county >> ', data);
                    const c = _.map(data, (d) => {
                        return {label: d.county_name, value: d.county_name};
                    });
                    this.setState({
                        counties: data,
                        countyPicker: c,
                    });
                },
                'county_city_id:' +
                this.getId(value, this.state.cities, 'city_name').city_id +
                '&pageLimit=99999&orderparameters=county_name:asc',
            );
        }
    };

    onCountySelected = (value) => {
        if (value) {
            this.updateFormData('user_address_county', value);
            this.updateFormData('user_address_neighborhood', "");

            this.context.select(
                'neighborhood',
                (data) => {
                    //--//--console.warn('NewAddress >> county >> ', data);
                    const n = _.map(data, (d) => {
                        return {label: d.neighborhood_name, value: d.neighborhood_name};
                    });
                    this.setState({
                        neighborhoodPicker: n,
                        neighborhoods: data,
                    });
                },
                'neighborhood_county_id:' +
                this.getId(value, this.state.counties, 'county_name').county_id +
                '&pageLimit=99999&orderparameters=neighborhood_name:asc',
            );
        }
    };

    onNeighborhoodSelected = (value) => {
        if (value) {
            this.updateFormData('user_address_neighborhood', value);
        }
    };

    getId = (value, data, search) => {
        const f = {};
        f[search] = value;
        if (_.filter(data, f)[0]) return _.filter(data, f)[0];
    };

    warnEditable = () => {
        const { t } = this.props
        const dialogTData = t('showDialogs', { returnObjects: true })
        const dialogData = dialogTData.find(x => x.name === 'add-address')
        this.context.setGlobalState('showDialog', {
            title: dialogData.title,
            message: dialogData.message,
            buttons: [
                {
                    text: dialogData.buttons[0].label,
                    onPress: () => { this.context.setGlobalState('showDialog', false); }
                },
            ],
        });
    };

    render() {
        const {t} = this.props
        const dropdowns = t('addAddress.dropdowns', { returnObjects: true })
        const inputs = t('addAddress.inputs', { returnObjects: true })
        const buttons = t('addAddress.buttons', { returnObjects: true })
        const pageData = {
            "city":  dropdowns.find(x => x.name === 'city'),
            "county":  dropdowns.find(x => x.name === 'county'),
            "neighborhood":  dropdowns.find(x => x.name === 'neighborhood'),
            "title":  inputs.find(x => x.name === 'title'),
            "building_number":  inputs.find(x => x.name === 'building_number'),
            "floor":  inputs.find(x => x.name === 'floor'),
            "apartment_number": inputs.find(x => x.name === 'apartment_number'),
            "detail":  inputs.find(x => x.name === 'detail'),
            "address_directions":  inputs.find(x => x.name === 'address_directions'),
            "add_address":  buttons.find(x => x.name === 'add_address').label
        }
        const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
            <Button
                ref={ref}
                className={"btn-block select-selected text-left"}
                onClick={(e) => {
                    e.preventDefault();
                    if (this.props.match.params.id !== "new"){
                        this.warnEditable();
                    }else {
                        onClick(e);
                    }
                }}
            >
                {children}
            </Button>
        ));
        return (
            <div className="col-12 col-lg-9">
                <div className="address-form">
                    <div className="container">
                        <div className="row">
                            {this.getErrorMessages()}
                        </div>
                        <div className="row">
                            <div className=" col-12">
                                <div className="address-add needs-validation" noValidate>
                                    {/*title*/}
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" value={this.state.formData.user_address_title}
                                                       onChange={(e) => this.updateFormData('user_address_title', e.target.value)}
                                                       className="form-control" id="name"
                                                       placeholder={pageData['title'].placeholder} required/>
                                                <label htmlFor="name">{pageData['title'].label}</label>
                                                <div className="invalid-feedback">
                                                    {pageData['title'].error}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*city, county, neighborhood*/}
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                                    {this.state.formData.user_address_city.length!==0 ? (
                                                        <Dropdown.Toggle role={"button"} className={"select-selected justify-content-start"}
                                                                         style={{paddingLeft: "15px", display: "flex"}}
                                                                         as={CustomToggle} id="dropdownMenuLink"
                                                                         >
                                                            {this.state.formData.user_address_city}
                                                        </Dropdown.Toggle>
                                                    ) : (
                                                        <Dropdown.Toggle role={"button"}
                                                                         className={"select-selected justify-content-start"}
                                                                         style={{paddingLeft: "15px", display: "flex"}}
                                                                         as={Button} id="dropdownMenuLink">
                                                            {pageData['city'].placeholder}
                                                        </Dropdown.Toggle>
                                                    )}
                                                    <Dropdown.Menu className="dropdown-menu scrollable-menu-markets" >
                                                        {this.state.cityPicker.map((city, index)=>(
                                                            <Dropdown.Item className={"dropdown-item"}
                                                                           key={index} onClick={(e) => {
                                                                if (this.state.isUpdate) {
                                                                    this.warnEditable();
                                                                }else {
                                                                    this.onCitySelected(city.value);
                                                                }
                                                            }}>{city.label}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="invalid-feedback">
                                                    {pageData['city'].error}
                                                </div>
                                                <label htmlFor="">{pageData['city'].label}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group form-group-select">
                                                <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                                    {this.state.formData.user_address_county.length!==0 ? (
                                                        <Dropdown.Toggle role={"button"}
                                                                         className={"select-selected justify-content-start"}
                                                                         style={{paddingLeft: "15px", display: "flex"}}
                                                                         as={CustomToggle} id="dropdownMenuLink"
                                                        >
                                                            {this.state.formData.user_address_county}
                                                        </Dropdown.Toggle>
                                                    ) : (
                                                        <Dropdown.Toggle role={"button"}
                                                                         className={"select-selected justify-content-start"}
                                                                         style={{paddingLeft: "15px", display: "flex"}}
                                                                         as={Button} id="dropdownMenuLink">
                                                            {pageData['county'].placeholder}
                                                        </Dropdown.Toggle>
                                                    )}
                                                    <Dropdown.Menu className="dropdown-menu scrollable-menu-markets">
                                                        {this.state.countyPicker.map((county, index)=>(
                                                            <Dropdown.Item className={"dropdown-item"} key={index} onClick={(e) => {
                                                                if (this.state.isUpdate) {
                                                                    this.warnEditable();
                                                                }else {
                                                                    this.onCountySelected(county.value);
                                                                }
                                                            }}>{county.label}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="invalid-feedback">
                                                {pageData['county'].error}
                                                </div>
                                                <label htmlFor="">  {pageData['county'].label}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group form-group-select ">
                                                <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                                    {this.state.formData.user_address_neighborhood.length!==0 ? (
                                                        <Dropdown.Toggle role={"button"}
                                                                         className={"select-selected justify-content-start"}
                                                                         style={{paddingLeft: "15px", display: "flex"}}
                                                                         as={CustomToggle} id="dropdownMenuLink"
                                                        >
                                                            {this.state.formData.user_address_neighborhood}
                                                        </Dropdown.Toggle>
                                                    ) : (
                                                        <Dropdown.Toggle role={"button"}
                                                                         className={"select-selected justify-content-start"}
                                                                         style={{paddingLeft: "15px", display: "flex"}}
                                                                         as={Button} id="dropdownMenuLink">
                                                            {pageData['neighborhood'].placeholder}
                                                        </Dropdown.Toggle>
                                                    )}
                                                    <Dropdown.Menu className="dropdown-menu scrollable-menu-markets">
                                                        {this.state.neighborhoodPicker.map((neighborhood, index)=>(
                                                            <Dropdown.Item className={"dropdown-item"} key={index} onClick={(e) => {
                                                                if (this.state.isUpdate) {
                                                                    this.warnEditable();
                                                                }else {
                                                                    this.onNeighborhoodSelected(neighborhood.value);
                                                                }
                                                            }}>{neighborhood.label}</Dropdown.Item>
                                                        ))}
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <div className="invalid-feedback">
                                                {pageData['neighborhood'].error}
                                                </div>
                                                <label htmlFor=""> {pageData['neighborhood'].label}</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/*bina, numara, kat*/}
                                    <div className="form-row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={this.state.formData.user_address_building_number}
                                                       onChange={(e) => {
                                                           if (this.state.isUpdate) {
                                                               this.warnEditable();
                                                           }else {
                                                               this.updateFormData('user_address_building_number', e.target.value)
                                                           }
                                                       }}
                                                       className="form-control" id="bina"
                                                       placeholder={pageData['building_number'].placeholder} required/>
                                                <label htmlFor="inputPassword4"> {pageData['building_number'].label}</label>
                                                <div className="invalid-feedback">
                                                {pageData['building_number'].error}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={this.state.formData.user_address_floor_number}
                                                       onChange={(e) => this.updateFormData('user_address_floor_number', e.target.value)}
                                                       className="form-control" id="kat"
                                                       placeholder={pageData['floor'].placeholder} required/>
                                                <label htmlFor="inputPassword4">{pageData['floor'].label} </label>
                                                <div className="invalid-feedback">
                                                {pageData['floor'].error} 
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <input type="text" value={this.state.formData.user_address_door_number}
                                                       onChange={(e) => this.updateFormData('user_address_door_number', e.target.value)}
                                                       className="form-control" id="daire"
                                                       placeholder={pageData['apartment_number'].label} required/>
                                                <label htmlFor="inputEmail4">{pageData['floor'].label} </label>
                                                <div className="invalid-feedback">
                                                  {pageData['floor'].error} 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*tam adres*/}
                                    <div className="form-group">
                                                    <textarea id="address" value={this.state.formData.user_address_detail}
                                                              onChange={(e) => this.updateFormData('user_address_detail', e.target.value)}
                                                              className="form-control"
                                                              placeholder={pageData['detail'].placeholder}
                                                              required/>
                                        <label htmlFor="inputAddress">{pageData['detail'].label} </label>
                                        <div className="invalid-feedback">
                                        {pageData['detail'].error}
                                        </div>
                                    </div>
                                    {/*adres tarifi*/}
                                    <div className="form-group">
                                                    <textarea id="address-note" value={this.state.formData.user_address_information}
                                                              onChange={(e) => this.updateFormData('user_address_information', e.target.value)}
                                                              className="form-control"
                                                              placeholder={pageData['address_directions'].placeholder} required/>
                                        <label htmlFor="inputAddress">{pageData['address_directions'].label}</label>
                                        <div className="invalid-feedback">
                                            {pageData['address_directions'].error}
                                        </div>
                                    </div>
                                    <div className="col-lg-4 m-auto">
                                        <button onClick={() => this.onNAAddAddressButtonPressed()}
                                                className="btn btn-secondary w-100">{pageData['add_address']}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withTranslation()(withRouter(AddAddress));
