import React, {Component} from "react";
import Context from "../../Utils/Context";
import {getItem} from "../../Utils/LocalStorage";
import {Modal, ModalBody, Dropdown, Button} from "react-bootstrap";
import {withRouter} from "react-router-dom";
import { withTranslation } from 'react-i18next';
class Stalls extends Component{
    static contextType = Context;
    state = {
        stalls: [],
        market: null,
        market_id: "",
        offset: 0,
        limit: 10,
    };
    constructor(props) {
        super(props);
    }

    fetchResult = async (market) => {
        const {offset, limit, stalls} = this.state;
        const address = JSON.parse(await getItem('address'));
        //--//--console.warn("address >> ", address);
        this.context.select(
            'view_active_marketer_list',
            (data) => {
                console.log('view_active_marketer_list >> data >> ', data);
                this.setState({
                    stalls: data,
                    offset: offset ,
                    limit: limit,
                    market: market,
                });
            },
            'market_active_marketer_market_id:' +
            market.value.market_id +
            ',market_city:' +
            address.user_address_city +
            ',market_active_marketer_work_range_neighborhoods-like-*' +
            address.user_address_county +
            '_' +
            address.user_address_neighborhood +
            '*&pgidx=' +
            offset +
            '&pagelimit=' +
            limit +
            '&getEncrypted=0',
        );
    };

    async componentDidMount() {
        //this.fetchResult();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    async onMarketSelected(market_id) {

        console.log("mar", market_id);
        let market;
        market = this.props.markets.find((market) => market.value.market_id === market_id);
        this.setState({market: market});
        console.log("market", market);
        //await setItem('market', JSON.stringify(market));
        if (this.props.selectedAddress){
            await this.context.setMyNeighborhood(market.value, this.props.selectedAddress);
        }
        window.location.href='/stalls';
    }

    render() {
        const { t } = this.props
        const StallsData = t('stalls.Stalls', {returnObjects: true })
        const pageData = {
            'stalls': StallsData['custom-content'].find(x => x.name === 'stalls')['value-single'],
            'stall-select': StallsData['dropdowns'].find(x => x.name === 'stall-select')['placeholder']
        }
        return(<Modal show={this.props.open} centered>
            <div className="close-modal" onClick={this.props.toggle} data-dismiss="modal"><i/></div>
            <ModalBody>
                <h1 className="col-12 col-lg-12 m-auto">{pageData['stalls']}</h1>
                <div className="mb-4"/>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <Dropdown className="col-12 align-self-center" style={{padding: "inherit"}}>
                                {this.state.market ? (
                                    <Dropdown.Toggle role={"button"} className={"select-selected"} as={Button} id="dropdownMenuLink">
                                        {this.state.market.label}
                                    </Dropdown.Toggle>
                                ) : (
                                    <Dropdown.Toggle role={"button"} className={"select-selected"} as={Button} id="dropdownMenuLink">
                                        { pageData['stall-select']}
                                    </Dropdown.Toggle>
                                )}
                            <Dropdown.Menu className="dropdown-menu scrollable-menu-markets">
                                {this.props.markets.map((market, index)=>(
                                    <Dropdown.Item className={"dropdown-item"} key={index} onClick={()=>{this.setState({market_id:market.value.market_id});this.onMarketSelected(market.value.market_id);}}>{market.label}</Dropdown.Item>
                                    ))}
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>);
    }
}
export default withTranslation()(withRouter(Stalls));
