import React, {useContext, useEffect, useRef, useState} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Context from "../../../Utils/Context";
const MarketPlacesModal = ({open, stall, toggle}) => {
    const { t }  = useTranslation()
    const ModalData = t('stalls.MarketPlacesModal', { returnObjects: true })
    const pageData = {
        'marketplace-title': ModalData['custom-content'].find(x => x.name === 'marketplace-title')['value-single']
    }
    const context = useContext(Context);
    const modalRef = useRef();
    const [markets, setMarkets] = useState([]);

    useEffect(()=>{
        context.select('view_active_marketer_list', (data) => {
            setMarkets(data);
        }, 'market_active_marketer_is_active:1,market_active_marketer_is_deleted:0,market_is_active:1,market_is_deleted:0,market_active_marketer_created_by_user_id:' + stall.user_id + '&pageLimit=99999&getEncrypted=0');
    },[]);
 return(<Modal show={open} id="market-places" ref={modalRef} centered>
             <div className="close-modal" onClick={toggle} data-dismiss="modal"><i/></div>
             <ModalBody>
                 <div className="row">
                     <h1 className="col-12 col-lg-10 m-auto">{pageData['marketplace-title']}</h1>
                     {markets.map((market, index)=>(
                         <div key={index} className="col-12 col-lg-10 m-auto places">
                             <p className="">{market.market_weekday_type}</p> <span className="text-right">{market.market_name},<br/>{market.market_county}</span>
                         </div>
                     ))}
                 </div>
             </ModalBody>
 </Modal>);
}

export default MarketPlacesModal;
