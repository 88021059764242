import React, {useContext, useState} from "react";
import * as Actions from "../store/actions";
import {Modal, ModalBody} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import InputMask from "react-input-mask";
import Context from "../Utils/Context";
import {setItem} from "../Utils/LocalStorage";
import { useTranslation } from "react-i18next";


const ForgotPass = () => {
   const { t } = useTranslation();
    const forgotData = t('modals.forgotPass', { returnObjects: true })
    const pageData = {
        "title": forgotData['custom-content'].find(x => x.name === 'forgot-pass')['value-single'],
        "text-1": forgotData['custom-content'].find(x => x.name === 'forgot-text-1')['value-single'],
        "text-2": forgotData['custom-content'].find(x => x.name === 'forgot-text-2')['value-single'],
        "send": forgotData.buttons.find(x => x.name === 'forgot-send'),
        "phone": forgotData.inputs.find(x => x.name === 'forgot-phone'),
    }

  const context = useContext(Context);
  const {forgotPassModal} = useSelector(state => state.settings.layout);
  const [user_active_gsm, set_ser_active_gsm]=useState("");
  const dispatch = useDispatch();

  const sendCode = () => {
    if (!user_active_gsm || user_active_gsm.length < 10) {
      const dialogTData = t('showDialogs', { returnObjects: true })
      const dialogData = dialogTData.find(x => x.name === 'forgot-pass-number-error')
      context.setGlobalState('showDialog', {
          title: dialogData?.title,
          message: dialogData?.message,
          buttons: [
              {
                  text: dialogData?.buttons[0].label,
                  onPress: () => {
                      context.setGlobalState('showDialog', false);
                  },
              },
          ],
      });
    } else {
      let UAG = (user_active_gsm.replace(/\s/g, '')).substring(3);
      context.resetPassword(
          {user_active_gsm: UAG},
          async (response) => {
            //--console.warn("response >> >> >> ", response);
            if (response.statuscode === '200') {
              /*this.props.navigation.navigate('LostPasswordActivation', {
                user_active_gsm: user_active_gsm,
              });*/
              await setItem("user_active_gsm", UAG);
              dispatch(Actions.closeForgotPassModal());
              dispatch(Actions.openNewPassModal());
            } else {
              if (response.message === 'operationerror') {
                const dialogTData = t('showDialogs', { returnObjects: true })
                const dialogData = dialogTData.find(x => x.name === 'forgot-pass-error')
                context.setGlobalState('showDialog', {
                    title: dialogData?.title,
                    message: dialogData?.message,
                    buttons: [
                        {
                            text: dialogData?.buttons[0].label,
                            onPress: () => {
                                context.setGlobalState('showDialog', false);
                                dispatch(Actions.openForgotPassModal());
                            },
                        },
                    ],
                });
              } else {
                const dialogTData = t('showDialogs', { returnObjects: true })
                const dialogData = dialogTData.find(x => x.name === 'forgot-pass-wrong-number')
                context.setGlobalState('showDialog', {
                    title: dialogData?.title,
                    message: dialogData?.message,
                    buttons: [
                        {
                            text: dialogData?.buttons[0].label,
                            onPress: () => {
                                context.setGlobalState('showDialog', false);
                                dispatch(Actions.openForgotPassModal());
                            },
                        },
                    ],
                });
              }
            }
          },
      );
    }
  };

  return(
      <Modal show={forgotPassModal} id={"forgotpass"}>
        <div onClick={() => {
          dispatch(Actions.closeForgotPassModal())
        }} className="close-modal" data-dismiss="modal"><i/></div>
        <ModalBody>
        <h2>{ pageData['title']}</h2>
        <p>{pageData['text-1']} <br />{ pageData['text-2']}</p>

          <form id="forgotpass-form" className="col-10 needs-validation">
            <div className="form-row">
              <div className="col-md-12">
                <div className="form-group">
                <InputMask mask={user_active_gsm.length !== 0 ? "+\\90599 999 9999" : null} className="form-control" id="fotgot_pass_phone" onChange={(e) => set_ser_active_gsm(e.target.value)} placeholder={ pageData['phone'].placeholder}
                         value={user_active_gsm} required />
                    <label htmlFor="password">{ pageData['phone'].label}</label>
                    <div className="invalid-feedback">
                      { pageData['phone'].error}
                    </div>
                </div>
              </div>
            </div>
            <div className="col-12 p-0 m-auto">
              <button type="submit" onClick={(e)=> {
                e.preventDefault();
                sendCode();
                dispatch(Actions.closeForgotPassModal())
              }} className="btn btn-secondary w-100">{ pageData['send'].label}</button>
            </div>
          </form>

        </ModalBody></Modal>);
}
export default ForgotPass;
