import React, {useContext, useEffect, useState} from "react";
import Context from "../../Utils/Context";
import moment from "moment";
import _ from "lodash";
import OrderDetailsModal from "./components/OrderDetailsModal";
import OrderRatingModal from "./components/OrderRatingModal";
import { TransactionStatus } from "../../Utils/TransactionStatus";
import { useTranslation } from "react-i18next";
const MyOrders = () => {
    const {t} = useTranslation()
    const MyOrdersData = t('myOrders.MyOrders', { returnObjects: true })
    const pageData = {
        'delivery-time': MyOrdersData['custom-content'].find(x => x.name === 'delivery-time')['value-single'],
        'price': MyOrdersData['custom-content'].find(x => x.name === 'price')['value-single'],
        'delivered': MyOrdersData['custom-content'].find(x => x.name === 'delivered')['value-single'],
        'in-prep': MyOrdersData['custom-content'].find(x => x.name === 'in-prep')['value-single'],
        'in-delivery': MyOrdersData['custom-content'].find(x => x.name === 'in-delivery')['value-single'],
        'canceled': MyOrdersData['custom-content'].find(x => x.name === 'canceled')['value-single'],
        'no-order': MyOrdersData['custom-content'].find(x => x.name === 'no-order')['value-single'],
        'in-prep-btn': MyOrdersData['buttons'].find(x => x.name === 'in-prep-btn')['label'],
        'evaluate': MyOrdersData['buttons'].find(x => x.name === 'evaluate')['label'],
    }
    const context = useContext(Context);
    const [orders, setOrders] = useState([]);
    const [ratingModal, setRatingModal] = useState(false);
    const [selected, setSelected] = useState(null);
    const [orderDetailsModal, setOrderDetailsModal] = useState(false);

    const fetchData = () => {
        context.select(
            'product_transaction',
            (data) => {
                //--//--console.warn("product_transaction >> ", data);
                setOrders(data);
                console.log('orders', data)
            },
            'product_transaction_payment_status:3&pageLimit=999&orderparameters=product_transaction_id:desc',
        );
    };

    const toggleOrderDetailsModal = (order) => {
        setSelected(order);
        setOrderDetailsModal(!orderDetailsModal);
    }
    const toggleRatingModal = (order) => {
        setSelected(order);
        if (ratingModal){
            fetchData();
        }
        setRatingModal(!ratingModal);
    }

    useEffect(() => {
        fetchData();
    }, []);

   const cancelOrder = (order) => {
       console.log("asasa ", order, order.product_transaction_secret_key);
        const formData = {
            product_transaction_buyer_cancel_status: 'Alıcı alımdan vazgeçti',
        };
        context.update(
            'product_transaction',
            formData,
            order.product_transaction_secret_key,
            () => {
                fetchData();
            },
        );
    };
    const handleCancelOrder = (order) => {
        const dialogTData = t("showDialogs", { returnObjects: true });
        const dialogData = dialogTData.find(
          (x) => x.name === "my-orders"
        );
        context.setGlobalState('showDialog', {
            title: dialogData?.title,
            message: dialogData?.message,
            buttons: [
                {
                    text: dialogData.buttons.find(x => x.name === 'yes').label,
                    isSecondary: true,
                    onPress: async () => {
                        context.setGlobalState('showDialog', false);
                        await cancelOrder(order);
                    },
                },
                {
                    text: dialogData.buttons.find(x => x.name === 'no').label,
                    onPress: () => {
                        context.setGlobalState('showDialog', false);
                    },
                },
            ],
        });
    }

    return (
        <div className="col-12 col-lg-9">
            {orders && orders.length && orders.length > 0 ? orders.map((order, index) => (
                <div className="box orders flex flex-column justify-content-center" key={index} style={{minHeight:"18rem"}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-10">
                                <h1>{order.product_transaction_buyer_delivery_address_title},{' '}
                                    {order.product_transaction_buyer_delivery_address_county}</h1>
                                    { (order.product_transaction_delivery_start_datetime && order.product_transaction_payment_status === '3') && <p className="border-bottom">{pageData['delivery-time']}: {' '}
                                    {moment(order.product_transaction_delivery_start_datetime, 'DD.MM.YYYY HH:ii:ss',).format('DD.MM.YYYY')}{' '}
                                    {order.product_transaction_weekday_type}{' '}
                                    {order.product_transaction_delivery_time_interval}</p>}
                                <span className="price border-bottom">{parseFloat(
                                    _.replace(order.product_transaction_release_total_price_included_vat, ',', '.',),).toFixed(2)}{' '}
                                    {pageData['price']}</span>
                            </div>
                            <div className="col-2 align-arrow">
                                <a onClick={()=>toggleOrderDetailsModal(order)} className={"btn-arrow btn "+ (order.product_transaction_delivery_status != '4' ? "in-progress":"")}
                                   data-target="#myOrders"><i/></a>
                            </div>
                        </div>
                        {/*Order Status*/}
                        {/*IF DELIVERY STATUS IS 4 "Sipariş Teslim Edildi"*/}
                        {TransactionStatus(order, "user") === "Completed" && (
                            <div className={"row"}>
                                <div className="col-lg-7 order-status">
                                    <div className="check-icon">
                                        <span className="icon-line line-tip"/>
                                        <span className="icon-line line-long"/>
                                    </div>
                                    {pageData['delivered']}
                                </div>
                                {order.product_transaction_buyer_sales_like_score === ''  && (
                                    <div className="col-lg-5 col-12 text-right mt-2">
                                        <a className="btn btn-secondary col-12" data-toggle="modal"
                                           onClick={()=>toggleRatingModal(order)}>{pageData['evaluate']}</a>
                                    </div>
                                )}
                            </div>
                        )}
                        {!moment().isAfter(moment(_.replace(order.product_transaction_delivery_start_datetime, ' 00:00:00', 'T20:00:00',), 'DD.MM.YYYYTHH:ii:ss',).subtract(1, 'days'),) &&
                        TransactionStatus(order, "user") === "Preparing" ? (
                            <div className={"row"}>
                                <div className="col-lg-7 order-status">
                                    <div className="check-icon in-progress">
                                        <span className="icon-line line-tip"/>
                                        <span className="icon-line line-long"/>
                                    </div>
                                    {pageData['in-prep']}
                                </div>
                            <div className="col-lg-5 col-12 text-right mt-1">
                                <a className="btn btn-primary col-12" onClick={()=>handleCancelOrder(order)} data-toggle="modal"
                                   data-target="#deleteWarning">{pageData['in-prep-btn']}</a>
                            </div>
                            </div>
                        ):((TransactionStatus(order, "user") === "Prepared" || TransactionStatus(order, "user") === "OnWay")&&(
                            <div className={"row"}>
                                <div className="col-lg-7 order-status">
                                    <div className="check-icon in-progress">
                                        <span className="icon-line line-tip"/>
                                        <span className="icon-line line-long"/>
                                    </div>
                                    {pageData['in-delivery']}
                                </div>
                            </div>
                        ))}
                        {TransactionStatus(order, "user") === "Canceled" && (
                            <div className="col-lg-8 order-status">
                                <div className="check-icon in-progress">
                                    <span className="icon-line line-tip"/>
                                    <span className="icon-line line-long"/>
                                </div>
                                {pageData['canceled']}
                            </div>
                        )}
                    </div>
                </div>
            )) : null }
            {orders.length === 0 && (
                <div className="col-12 text-center box orders flex flex-column justify-content-center">
                    <h1>{pageData['no-order']}</h1>
                </div>
            )}
            {selected ? (<OrderDetailsModal open={orderDetailsModal} order={selected} toggle={toggleOrderDetailsModal}/>) : null}
            {selected ? (<OrderRatingModal open={ratingModal} order={selected} toggle={toggleRatingModal}/>) : null}
        </div>
    )
}
export default MyOrders;
