export const config = {
  api: {
    url: 'https://mservice.pazardan.app/',
    timeout: 20000,
  },
  image: {
    baseUrl:
      'https://cdn.pazardan.app/wwwroot/img/pazardanapp_images/android_images/urun_detay/4x/android_900_900_',
  },
};
