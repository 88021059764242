import React from 'react';
import {withRouter} from "react-router";
import "./Tezgah.css";


function Tezgah({imgUrl, name, numProduct, pazarName, ilce, rate, selected, marketDate}) {

    //console.log("rate", parseFloat(rate.replace(',','.')).toFixed(2));
    return (
        <div className="tezgah-container" style={{backgroundColor: selected ? "#84b700": "white", cursor:"pointer"}}>
            {/*<img className="stall-img-background" src={imgUrl} style={{maxHeight:120, overflow:"hidden"}} alt="background"/>*/}
            <div className="tezgah-background-img" style={{backgroundImage: `url(${imgUrl})`, minHeight:"140px" ,height:"140px", backgroundSize: "cover"}} />
            <div className="stall-sub-container" style={{color: selected ? "white": "#84b700"}}>
                <div className="stall-name-rate">
                    <div className="w-1/3" style={{color: selected ? "white": "#46742C"}}>{name}</div>
                    {selected ?
                    <div className="rating w-2/3 selected-rating">
                            <span className={parseFloat(rate).toFixed(1) >= 1 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 2 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 3 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 4 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 5 ? "" : "grey"}/>
                            <i className="points" style={{color:"white"}}>{parseFloat(rate).toFixed(1) || 5}/5</i>
                    </div>
                            :
                        <div className="rating w-2/3">
                            <span className={parseFloat(rate).toFixed(1) >= 1 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 2 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 3 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 4 ? "" : "grey"}/>
                            <span className={parseFloat(rate).toFixed(1) >= 5 ? "" : "grey"}/>
                            <i className="points">{parseFloat(rate).toFixed(1) || 5}/5</i>
                    </div>
                        }

                </div>
                <div className="product-num-address">
                        <div className='text-left mb-1'>{numProduct + " Çeşit Ürün"}</div>
                        <div>{pazarName+","}</div>
                        <div>{ilce}</div>
                        <div>{marketDate}</div>
                </div>
                <div className="block_1" style={{background: selected ? "unset" : "#ebe0dc"}}/>
            </div>
        </div>
    )
}

export default withRouter(Tezgah);
