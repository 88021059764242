import {getItem} from './LocalStorage';
import * as CryptoJS from 'crypto-js';

export const encryptLocalDataString = async (Data) => {
  const SessLVal = await getItem('SessLVal');
  const SessVal = await getItem('SessVal');

  var SessLValueP = '';
  if (SessLVal) {
    SessLValueP = DecrypttoStringAESLocal(SessLVal).split('~|~');
  } else if (SessVal) {
    SessLValueP = DecrypttoStringAESLocal(SessVal).split('~|~');
  }

  return EncrypttoStringAESLocal(
    EncrypttoStringAESbyKey(Data, SessLValueP[1], SessLValueP[0]),
  ).toString();
};

export const decyrptLocalDataString = async (Data) => {
  const SessLVal = await getItem('SessLVal');
  const SessVal = await getItem('SessVal');

  var SessLValueP = '';
  if (SessLVal) {
    SessLValueP = DecrypttoStringAESLocal(SessLVal)?.split('~|~');
  } else if (SessVal) {
    SessLValueP = DecrypttoStringAESLocal(SessVal)?.split('~|~');
  }

  return DecrypttoStringAESbyKey(
    DecrypttoStringAESLocal(Data),
    SessLValueP[1],
    SessLValueP[0],
  );
};

function EncrypttoStringAESLocal(
  cryptText,
  key = 'bCr556tFe46mg4S2jNfROktYHbWzPbMx',
  iv = 'U3cHver8ygVd3Az2',
) {
  var _key = CryptoJS.enc.Utf8.parse(key);
  var _iv = CryptoJS.enc.Utf8.parse(iv);

  var encryptedstr = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(cryptText),
    _key,
    {
      keySize: 256,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return encryptedstr;
}

function DecrypttoStringAESLocal(
  cryptText,
  key = 'bCr556tFe46mg4S2jNfROktYHbWzPbMx',
  iv = 'U3cHver8ygVd3Az2',
) {
  var _key = CryptoJS.enc.Utf8.parse(key);
  var _iv = CryptoJS.enc.Utf8.parse(iv);

  const crypted = CryptoJS.enc.Base64.parse(cryptText);

  var decryptedstr = CryptoJS.AES.decrypt({ciphertext: crypted}, _key, {
    iv: _iv,
    keySize: 256,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  return decryptedstr.toString(CryptoJS.enc.Utf8);
}

function EncrypttoStringAESbyKey(cryptText, key, iv) {
  var _key = CryptoJS.enc.Utf8.parse(key);
  var _iv = CryptoJS.enc.Utf8.parse(iv);

  var encryptedstr = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(cryptText),
    _key,
    {
      keySize: 256,
      iv: _iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    },
  );
  return encryptedstr;
}

function DecrypttoStringAESbyKey(cryptText, key, iv) {
  var _key = CryptoJS.enc.Utf8.parse(key);
  var _iv = CryptoJS.enc.Utf8.parse(iv);

  const crypted = CryptoJS.enc.Base64.parse(cryptText);

  var decryptedstr = CryptoJS.AES.decrypt({ciphertext: crypted}, _key, {
    keySize: 256,
    iv: _iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decryptedstr.toString(CryptoJS.enc.Utf8);
}
