import React from 'react';
import { useTranslation } from 'react-i18next';
import {withRouter} from "react-router";

function FirstBanner() {
    const { t } = useTranslation()
    const evulationFormData = t('becomeSeller.FirstBanner', { returnObjects: true })
    const pageData = {
        title: evulationFormData['custom-content'].find(x => x.name === 'title')['value-single'],
        description: evulationFormData['custom-content'].find(x => x.name === 'description')['value-single'],
    }
    return (
            <div className="pazardan-banner">
                <div className="container p-0">
                    <div className="banner-inner-text ">
                        <div className="row">
                            <div className="col-lg-6 col-12">
                                <h1>{pageData.title}</h1>
                                <p>{pageData.description}</p>
                                <a href="https://apps.apple.com/tr/app/pazardan/id1541579666?l=tr" className="d-inline-block mt-4" target="_blank"><img
                                    src="assets/images/apple-store.svg" className="img-fluid" alt={"apple store"}/></a>
                                <a href="https://play.google.com/store/apps/details?id=com.pazardan" className="d-inline-block mt-4" target="_blank"><img
                                    src="assets/images/google-play.svg" className="img-fluid" alt={"google play"}/></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default withRouter(FirstBanner);
