import React, { useContext, useEffect, useRef, useState } from "react";
import file from "../../../assets/images/file.svg";
import Context from "../../../Utils/Context";
import { config } from "../../../Utils/Config";
import { toast } from "react-toastify";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import ProductDetailsModal from "./ProductDetailsModal";
import { CalculateLineAmount } from "../../../Utils/CalculateLineAmount";
import { useParams } from "react-router";
import { ValToFloat } from "../../../Utils/ValToFloat";
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";



const useStylesFacebook = makeStyles((theme) => ({
    top: {
        color: "#90b437",
        animationDuration: '1550ms',
    },
}));

const Basket = ({ stall }) => {
    const { t } = useTranslation()
    const BasketData = t('stalls.Basket', { returnObjects: true })
    const toastsData = t('toasts', { returnObjects: true })
    const pageData = {
        close: BasketData['buttons'].find(x => x.name === 'close')['label'],
        'go-basket':BasketData['buttons'].find(x => x.name === 'go-basket')['label'],
        'continue':BasketData['buttons'].find(x => x.name === 'continue')['label'],
        'delete': BasketData['buttons'].find(x => x.name === 'delete')['label'],
        'basket': BasketData['custom-content'].find(x => x.name === 'basket')['value-single'],
        'top-price': BasketData['custom-content'].find(x => x.name === 'top-price')['value-single'],
        'total-mobile': BasketData['custom-content'].find(x => x.name === 'total-mobile')['value-single'],
        'empty-basket': BasketData['custom-content'].find(x => x.name === 'empty-basket')['value-single'],
        'dropped': toastsData.find(x => x.name === 'dropped'),
        'emptied': toastsData.find(x => x.name === 'emptied'),
        'wait': toastsData.find(x => x.name === 'wait'),
    }
    const context = useContext(Context);
    const history = useHistory();
    const { id } = useParams();
    const list = useRef();
    const [products, setProducts] = useState([]);
    const [totalPrice, setTotalPrice] = useState(0.0);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [productDetailsModal, setProductDetailsModal] = useState(false);
    const [updated, setUpdated] = useState(false);
    const [mobileBasket, setMobileBasket] = useState(false);

    const [loading1, setLoading1] = useState(false);


    const classes = useStylesFacebook();

    useEffect(() => {
        setLoading1(context.cartLoading);
    }, [context.cartLoading])


    useEffect(() => {
        if (context.user) {
            setProducts(context.cartTransactionLines);
            calculateTotalPrice(context.cartTransactionLines);
        } else {
            setProducts([]);
            setTotalPrice(0.0);
        }
        if (list.current) {
            list.current.scrollTop = list.current.scrollHeight - list.current.clientHeight;
        }
    }, [id, context.user, context.cartTransactionLines]);

    const syncCartItems = (callback = undefined) => {
        context.syncCartItems((cartTransactionLines) => {
            calculateTotalPrice(cartTransactionLines);
            callback && callback();
        }, false);
    };

    const calculateTotalPrice = (cartTransactionLines = context.cartTransactionLines,) => {
        const totalPrice = _.sumBy(
            cartTransactionLines?.filter((item) => !item?.isFake && !item?.isLoading),
            (product) => {
                return CalculateLineAmount(product?.product_active_seller_entry_price, product?.product_vat_rate, product?.product_transaction_line_piece);
            },
        );
        setTotalPrice(parseFloat(totalPrice).toFixed(2));

    };
    const onCartDeleteButtonPressed = async (product) => {
        if (!product?.isFake && !product?.isLoading) {
            const updateFormData = {
                product_transaction_line_original_piece: product.product_transaction_line_original_piece,
                product_transaction_line_is_active: 0,
                product_transaction_line_is_deleted: 1,
                product_transaction_line_piece: '0.0',
            };
            context
                .updateItemInCart(updateFormData, product)
                .then(() => {
                    toast.success(pageData['dropped'].message, { autoClose: 1000 });
                });
        }
    };

    const clearCart = () => {
        const updateFormData = {
            product_transaction_line_is_active: 'False',
            product_transaction_line_is_deleted: 'True',
            product_transaction_line_piece: '0.0',
        };
        const dialogTData = t('showDialogs', { returnObjects: true })
        const dialogData = dialogTData.find(x => x.name === 'basket-warning')
        context.setGlobalState('showDialog', {
            title: dialogData?.title,
            message: dialogData?.message,
            buttons: [
                {
                    text: dialogData.buttons.find(x => x.name === 'refuse').label,
                    onPress: async () => {
                        context.setGlobalState('showDialog', false);
                    },
                    isSecondary: true,
                },
                {
                    text: dialogData.buttons.find(x => x.name === 'delete').label,
                    onPress: async () => {
                        await context.setGlobalState('showDialog', false);
                        products.map(async (product) => {
                            await context.updateItemInCart(
                                {
                                    ...updateFormData,
                                    product_transaction_line_original_piece:
                                        product.product_transaction_line_original_piece,
                                },
                                product,
                                undefined,
                                false,
                            );
                        });
                        setTimeout(() => {
                            context.setCartTransactionLines([]);
                            toast.success(pageData['emptied'].message, { autoClose: 1000 });
                        }, 1000);
                    },
                },
            ],
        });
    };
    const onCartApproveButtonPressed = async () => {
        if (
            context.cartTransactionLines?.some(
                (transactionLineItem) =>
                    transactionLineItem?.isFake || transactionLineItem?.isLoading,
            )
        ) {
            //this.toastify?.show('Lütfen filenizdeki ürünlerin eklenmesini bekleyiniz.', 1000,);
            toast.info(pageData['wait'].message, { autoClose: 1000 });

            setTimeout(() => {
                if (
                    context.cartTransactionLines?.some(
                        (transactionLineItem) =>
                            transactionLineItem?.isFake || transactionLineItem?.isLoading,
                    )
                ) {
                    context.syncCartItems(undefined, false);
                }
            }, 10000);
            return;
        }
        context.setLoading(true);
        const transaction = context.cartTransaction;
        const neighborhoods =
            products[0].market_active_marketer_work_range_neighborhoods.split(
                ',',
            );
        const neighborhood = neighborhoods
            .find((neighborhood_item) => {
                const sn = neighborhood_item.split('_');
                if (
                    sn[0] ===
                    transaction.product_transaction_buyer_delivery_address_county &&
                    sn[1] ===
                    transaction.product_transaction_buyer_delivery_address_neighborhood
                ) {
                    return true;
                }
                return false;
            })
            ?.split('_');
        const stall = transaction.product_transaction_seller_user_id;
        const myNeighborhood = (
            transaction.user_name_seller_spq +
            ' ' +
            transaction.user_surname_seller_spq
        ).toUpperCase();

        console.log("neigh", transaction, neighborhoods)
        if (neighborhood) {
            if (parseInt(neighborhood?.[2]) > parseInt(totalPrice)) {
                context.setLoading(false);
                const dialogTData = t('showDialogs', { returnObjects: true })
                const dialogData = dialogTData.find(x => x.name === 'basket-min-price')
                context.setGlobalState('showDialog', {
                    title: dialogData?.title,
                    message: dialogData?.message,
                    buttons: [
                        {
                            text: dialogData?.buttons[0].label,
                            onPress: () => {
                                context.setGlobalState('showDialog', false);
                            },
                        },
                    ],
                });
                return;
            }
        }
        /// Lokasyon, Manuel, Mevcut Adres
        const addressType =
            transaction?.product_transaction_buyer_delivery_address_title;
        if (
            addressType === 'Lokasyon' ||
            addressType === 'Manuel' ||
            transaction?.product_transaction_buyer_delivery_address_id === ''
        ) {
            context.select(
                'address',
                async (data) => {
                    if (!data) {
                        return;
                    }
                    const relatedUserAddressesWithoutIsDelivery = data.filter(
                        (user_address) =>
                            user_address.user_address_city ===
                            transaction.product_transaction_buyer_delivery_address_city &&
                            user_address.user_address_county ===
                            transaction.product_transaction_buyer_delivery_address_county &&
                            user_address.user_address_neighborhood ===
                            transaction.product_transaction_buyer_delivery_address_neighborhood,
                    );
                    const relatedUserAddresses = data.filter((user_address) => {
                        const activeNeighborhood = neighborhoods.find((item) =>
                            item.includes(
                                transaction.product_transaction_buyer_delivery_address_county +
                                '_' +
                                transaction.product_transaction_buyer_delivery_address_neighborhood,
                            ),
                        );

                        const data = activeNeighborhood?.split('_');

                        return (
                            user_address.user_address_city ===
                            transaction.product_transaction_buyer_delivery_address_city &&
                            user_address.user_address_county ===
                            transaction.product_transaction_buyer_delivery_address_county &&
                            user_address.user_address_neighborhood ===
                            transaction.product_transaction_buyer_delivery_address_neighborhood &&
                            data !== undefined
                        );
                    });
                    context.setLoading(false);
                    switch (relatedUserAddresses.length) {
                        case 0:
                            //this.showAddressAddModal(transaction, stall, myNeighborhood, relatedUserAddressesWithoutIsDelivery.length === 1,);
                            console.log("showAddressAddModal");
                            return;
                        case 1:
                            context.setLoading(true);
                            /*await this.updateTransactionAddress(relatedUserAddresses[0],
                                () => {
                                    this.context.setLoading(false);
                                    this.onCartApproveButtonPressed();
                                },
                            );*/
                            console.log("updateTransactionAddress");
                            return;
                        default:
                            //this.showAddressSelectModal(transaction, relatedUserAddresses, stall, myNeighborhood,);
                            console.log("default showAddressSelectModal");
                            return;
                    }
                },
                'user_address_is_active:1,' +
                'user_address_is_deleted:0,' +
                'user_address_created_by_user_id:' +
                context.user.member_id +
                '&orderparameters=user_address_id:desc&pageLimit=99999',
            );
        } else {
            context.selectStalls(
                transaction.product_transaction_buyer_delivery_address_city,
                transaction.product_transaction_buyer_delivery_address_county,
                transaction.product_transaction_buyer_delivery_address_neighborhood,
                'market_active_marketer_is_deleted:0,' +
                'market_active_marketer_market_id:'+transaction.product_transaction_market_id+',' +
                'market_is_active:1,' +
                'market_is_deleted:0&pageLimit=100&getEncrypted=0',
                (data) => {
                  if (!data) {
                    return;
                  }
                  if (data[0].is_delivery_possible === "") {
                      const dialogTData = t("showDialogs", { returnObjects: true });
                      const dialogData = dialogTData.find(
                        (x) => x.name === "order-summary-timeout"
                      );
                      context.setGlobalState("showDialog", {
                        title: dialogData?.title,
                        message: dialogData?.message,
                        buttons: [
                          {
                            text: dialogData?.buttons[0].label,
                            onPress: () => {
                              context.setGlobalState("showDialog", false);
                            },
                          },
                        ],
                      });
                  } else {
                    goToDeliveryTimeScreen(stall, myNeighborhood);
                    context.setLoading(false);
                  }
                }
              );
        }
    };

    const goToDeliveryTimeScreen = (stall, myNeighborhood) => {
        if (
            stall === '288' &&
            (myNeighborhood === 'GÖKTÜRK MERKEZ' ||
                myNeighborhood === 'MİMAR SİNAN' ||
                myNeighborhood === 'MİTHAT PAŞA')
        ) {
            //this.props.navigation.navigate('DeliveryTime2');
            history.push('/order-summary');
        } else {
            //this.props.navigation.navigate('DeliveryTime');
            history.push('/order-summary');
        }
    };
    const toggleProductDetailsModal = (product) => {
        setProductDetailsModal(!productDetailsModal);
        setSelectedProduct(product);
    }
    const toggleMobileBasket = () => {
        setMobileBasket(!mobileBasket);
    }

    console.log("cart", products);
    return (
        <div className="mobile-basket">
            <div className={"all-basket " + (mobileBasket ? "" : "all-basket-mobile-hide")}>
                <h2><span className="name">{pageData['basket']}</span><span onClick={() => clearCart()}
                    className="clear-basket">{products && products.length && products.length > 0 ? pageData['delete'] : ""}</span>
                </h2>
                <h3 onClick={() => toggleMobileBasket()}>{mobileBasket ?  pageData['close'] : pageData['go-basket']}
                 <i className="mobile-basketsum">
                 { !loading1 ? totalPrice + ' ' + pageData['top-price']: <CircularProgress size={24} className={classes.top} /> }   
                </i></h3>
                {products.length !== 0 && <>
                    <div className={"addbasket " + (mobileBasket ? "basket-show-mobile" : "")} ref={list}>
                        {products.map((product, index) => (
                            <div key={index} className="basket" style={{
                                background: "white",
                                borderRadius: "7px",
                                paddingTop: "1em"
                            }} data-name={product?.product_name}>
                                <div className="container p-0">
                                    <div className="row no-gutters">
                                        <div className="image col-4"><img
                                            src={config.image.baseUrl + product?.product_images_url}
                                            className="img-fluid" alt={product?.product_name} /></div>
                                        <div className="col-6">
                                            <div className="name">{product?.product_name}</div>
                                            <div
                                                className="size">{parseFloat(ValToFloat(product?.product_transaction_line_piece)).toFixed(2)}{' '}{product?.product_unit_type}</div>
                                            <div className="price">{CalculateLineAmount(product?.product_active_seller_entry_price, product?.product_vat_rate, product?.product_transaction_line_piece).toFixed(2)}{" "} TL</div>
                                        </div>
                                        <div className="col-2" style={{ marginTop: "1rem", marginBottom: "1rem" }}>
                                            <div data-id="1" data-name={product?.product_name}
                                                data-image="assets/images/product1.png"
                                                onClick={() => toggleProductDetailsModal(product)} className="edit" />
                                            <div data-name={product?.product_name}
                                                onClick={() => onCartDeleteButtonPressed(product)} className="trash" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className={"basket-sum " + (mobileBasket ? "basket-show-mobile" : "")}
                        style={{ marginTop: "-2rem" }}>
                        <p className="total-mobile">{pageData['total-mobile']}</p>
                        {loading1 ?
                            <CircularProgress className={classes.top} />
                            :
                            <span className="total-cart">
                                {totalPrice}{' '}
                                { pageData['top-price']}
                            </span>
                        }
                        <button disabled={loading1} onClick={() => onCartApproveButtonPressed()} className="btn btn-secondary">{ pageData['continue'] }</button>
                    </div>
                </>}
                <div className="empty-products mt-5"
                    style={products.length === 0 ? { display: "block" } : { display: "none" }}>
                    <img src={file} className="img-fluid" alt={pageData['empty-basket']} />
                    <p>{pageData['empty-basket']}</p>
                </div>
            </div>
            {selectedProduct && (<ProductDetailsModal open={productDetailsModal} toggle={toggleProductDetailsModal}
                setUpdated={setUpdated} updated={updated} setSelectedProduct={setSelectedProduct}
                product={selectedProduct} stall={stall} />)}
        </div>
    );
}
export default Basket;
