import React, {useContext, useEffect} from "react";
import {Modal} from "react-bootstrap";
import Context from "../Utils/Context";
import * as Actions from "../store/actions";
import {useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";

const LoginAlert = () => {
    const { t } = useTranslation();
    const loginAlertData = t('modals.loginAlert', { returnObjects: true })
    const pageData = {
        "title": loginAlertData['custom-content'].find(x => x.name === 'login-alert')['value-single'],
        "text-1": loginAlertData['custom-content'].find(x => x.name === 'alert-text-1')['value-single'],
        "text-2": loginAlertData['custom-content'].find(x => x.name === 'alert-text-2')['value-single'],
        "login": loginAlertData.buttons.find(x => x.name === 'login'),
        "signup": loginAlertData.buttons.find(x => x.name === 'signup'),
    }
    const context = useContext(Context);
    const dispatch = useDispatch();
    useEffect(()=>{
    },[context.loginAlert]);
    return (
        <Modal show={context.loginAlert} onHide={()=>console.log("asi")}>
            <div onClick={() => {context.setGlobalState('loginAlert', false)}} className="close-modal" data-dismiss="modal"><i/></div>
            <Modal.Body>
                <div className="row">
                    <div className="col-10 col-lg-8 m-auto">
                        <h1>{ pageData['title']}</h1>
                        <p>{pageData['text-1']} <br />{ pageData['text-2']}</p>
                    </div>

                    <a data-toggle="modal" onClick={()=>{context.setGlobalState('loginAlert', false);
                        dispatch(Actions.openSignupModal())
                    }} data-target="#login" className="btn btn-primary col-10 col-lg-8 mx-auto mb-3">
                        {pageData['signup'].label}
                        </a>
                    <a data-toggle="modal" onClick={()=>{context.setGlobalState('loginAlert', false);
                        dispatch(Actions.openLoginModal())
                    }} data-target="#signup" className="btn btn-secondary col-10 col-lg-8 mx-auto">
                        {pageData['login'].label}
                        </a>
                </div>
            </Modal.Body>
        </Modal>
    );
}
export default LoginAlert;
