import React from 'react';
import { withTranslation } from 'react-i18next';
import {withRouter} from "react-router";
import ValidationComponent from "../../../components/Validator/Validator";
import Context from "../../../Utils/Context";
import {getItem, setItem} from "../../../Utils/LocalStorage";

class ProfileForm extends ValidationComponent {
    static contextType = Context;
    deviceLocale = 'tr';
    labels = {
        formData: {
            user_name: 'Ad',
            user_surname: 'Soyad',
            user_active_gsm: 'Telefon',
            user_active_email: 'E-mail',
        },
    };
    state = {
        formData: {
            user: null,
            user_name: "",
            user_surname: "",
            user_active_gsm: "",
            user_active_email: "",
        },
    };

    constructor(props) {
        super(props);
    }

    async init() {
        const user = JSON.parse(await getItem('user'))

        await this.context.getUser((data) => {
            //--//--console.warn("user >> data >> ", data);
            this.setState({
                formData: data[0],
                user: user
            })
        });
    }
    async componentDidMount() {
        await this.init();
    }

    onEPUpdateProfileButtonPressed = async () => {
        this.validate({
            formData: {
                user_name: {required: true},
                user_surname: {required: true},
                user_active_gsm: {required: true},
                user_active_email: {required: true},
            },
        });
        if (this.isFormValid()) {
            await this.context.update(
                'user',
                this.state.formData,
                await this.state.formData.user_secret_key,
                async (r) => {
                    console.log("update >> ", r);
                    await this.context.getUser(async (data) => {
                        let usr = this.state.user;
                        console.log("user >> data >> ", data, usr);
                        usr.member_name = this.state.formData.user_name;
                        usr.member_surname = this.state.formData.user_surname;
                        await setItem('user', JSON.stringify(usr));
                        this.context.setGlobalState('userUpdated', !this.context.userUpdated);

                        this.props.history.push('/profile');
                    });
                },
                'membership',
            );
        }
    };

    updateFormData(key, value) {
        const f = this.state.formData;
        f[key] = value;
        this.setState(f);
    }

    onEPDeleteProfileButtonPressed = () => {
        const { t } = this.props
        const dialogTData = t("showDialogs", { returnObjects: true });
        const dialogData = dialogTData.find(
          (x) => x.name === "profile-delete"
        );
        this.context.setGlobalState('showDialog', {
            title: dialogData.title,
            message:
            dialogData.message,
            buttons: [
                {
                    text:  dialogData.buttons.find(x => x.name ==='cancel').label,
                    isSecondary: true,
                    onPress: () => {
                        this.context.setGlobalState('showDialog', false);
                    },
                },
                {
                    text:  dialogData.buttons.find(x => x.name ==='delete-account').label,
                    onPress: () => {
                        this.context.deleteUserAccount(async (response) => {
                            if (
                                response &&
                                response.deletestatus === 'thereis_activesales_on_user'
                            ) {
                                const dialogTData = t("showDialogs", { returnObjects: true });
                                const dialogData = dialogTData.find(
                                  (x) => x.name === "profile-delete-active-order"
                                );
                                this.context.setGlobalState("showDialog", {
                                  title: dialogData?.title,
                                  message: dialogData?.message,
                                  buttons: [
                                    {
                                      text: dialogData?.buttons[0].label,
                                      onPress: () => {
                                        this.context.setGlobalState("showDialog", false);
                                      },
                                    },
                                  ],
                                });
                            } else if (response && response.deletestatus === 'user_deleted') {
                                await this.context.clearSession();
                                this.context.setGlobalState('user', null);
                                this.context.setGlobalState('cart', []);
                                const dialogTData = t("showDialogs", { returnObjects: true });
                                const dialogData = dialogTData.find(
                                  (x) => x.name === "profile-deleted"
                                );
                                this.context.setGlobalState("showDialog", {
                                  title: dialogData?.title,
                                  message: dialogData?.message,
                                  buttons: [
                                    {
                                      text: dialogData?.buttons[0].label,
                                      onPress: () => {
                                        this.context.setGlobalState("showDialog", false);
                                         window.location.href = '/';
                                      },
                                    },
                                  ],
                                });
                            } else {
                                const dialogTData = t("showDialogs", { returnObjects: true });
                                const dialogData = dialogTData.find(
                                  (x) => x.name === "profile-delete-error"
                                );
                                this.context.setGlobalState("showDialog", {
                                  title: dialogData?.title,
                                  message: dialogData?.message,
                                  buttons: [
                                    {
                                      text: dialogData?.buttons[0].label,
                                      onPress: () => {
                                        this.context.setGlobalState("showDialog", false);
                                      },
                                    },
                                  ],
                                });
                            }
                        });
                        // this.context.update(
                        //   'user',
                        //   { user_is_deleted: "True" },
                        //   this.state.formData.user_secret_key,
                        //   async (response) => {
                        //     console.log("RESPONSE", response);
                        //     await this.context.clearSession();
                        //     this.context.setGlobalState('user', null);
                        //     this.context.setGlobalState('cart', []);
                        //     this.props.navigation.navigate('Home');
                        //
                        //     this.context.setGlobalState('showDialog', {
                        //       title: 'MERHABA',
                        //       message: 'Hesabınız başarılı bir şekilde silindi.',
                        //       buttons: [
                        //         {
                        //           text: 'Tamam',
                        //           onPress: () => {
                        //             this.context.setGlobalState('showDialog', false);
                        //           },
                        //         },
                        //       ],
                        //     });
                        //   },
                        //   'membership',
                        // );
                    },
                },
            ],
        });

    };

    render() {
        const { t } = this.props
        const ProfileFormData = t('profile.ProfileForm', { returnObjects: true })
        const pageData = {
            'name': ProfileFormData['inputs'].find(x => x.name === 'name'),
            'surname': ProfileFormData['inputs'].find(x => x.name === 'surname'),
            'phone':ProfileFormData['inputs'].find(x => x.name === 'phone'),
            'email': ProfileFormData['inputs'].find(x => x.name === 'email'),
            'update-profile':ProfileFormData['buttons'].find(x => x.name === 'update-profile')['label'],
            'delete-profile': ProfileFormData['buttons'].find(x => x.name === 'delete-profile')['label'],
        }
        return(
            <div className="col-12 col-lg-9">
                <div className="address-form">
                    <div className="container">
                        <div className="row">
                            <div className=" col-12">
                                <div id="profile-form" className="profile-form needs-validation">
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="name"
                                                       placeholder={pageData['name'].placeholder}
                                                       value={this.state.formData?.user_name}
                                                       autoCorrect={"false"}
                                                       required
                                                       onChange={(event) => {
                                                           this.updateFormData('user_name', event.target.value);
                                                       }}/>
                                                <label htmlFor="name">{pageData['name'].label}</label>
                                                <div className="invalid-feedback">
                                                    {pageData['name'].error}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="text" className="form-control" id="surname"
                                                       placeholder={pageData['surname'].placeholder}
                                                       onChange={(event) => {
                                                           this.updateFormData('user_surname', event.target.value);
                                                       }}
                                                       value={this.state.formData?.user_surname}
                                                       autoCorrect={"false"}
                                                       required />
                                                <label htmlFor="surname">{pageData['surname'].label}</label>
                                                <div className="invalid-feedback">
                                                    {pageData['surname'].error}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="number" className="form-control" id="phone"
                                                       placeholder={pageData['phone'].placeholder}
                                                       onChange={(value) => {
                                                           this.updateFormData('user_active_gsm', value);
                                                       }}
                                                       value={this.state.formData?.user_active_gsm}
                                                       autoCorrect={"false"}
                                                       disabled={true} style={{backgroundColor:"white", cursor:"not-allowed"}} />
                                                <label htmlFor="phone">{pageData['phone'].label}</label>
                                                <div className="invalid-feedback">
                                                    {pageData['phone'].error}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <input type="email" className="form-control" id="email"
                                                       onChange={(event) => {
                                                           this.updateFormData('user_active_email', event.target.value);
                                                       }}
                                                       value={this.state.formData?.user_active_email}
                                                       autoCorrect={"false"}
                                                       placeholder={pageData['email'].placeholder} required />
                                                <label htmlFor="email">{pageData['email'].label}</label>
                                                <div className="invalid-feedback">
                                                   {pageData['email'].error}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 m-auto">
                                        <button className="btn btn-secondary w-100" onClick={async ()=> await this.onEPUpdateProfileButtonPressed()}>
                                            {pageData['update-profile']}
                                        </button>
                                    </div>
                                    <div className="col-lg-4 pt-4 m-auto text-center">
                                        <a className="text-2 text-center" style={{color:"#84b700", cursor:"pointer"}} onClick={async ()=> {
                                            await this.onEPDeleteProfileButtonPressed();
                                        }}>
                                            {pageData['delete-profile']}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default withTranslation()(withRouter(ProfileForm));
