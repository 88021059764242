import React from 'react';
import { useTranslation } from 'react-i18next';
import {withRouter} from "react-router";

function SellerInformation() {
    const { t } = useTranslation()
    const textData = t('becomeSeller.SellerInformation')
    return (
        <div className="seller-information">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-6 order-2 order-md-1 seller-text" dangerouslySetInnerHTML={{ __html: textData }}>
                    </div>
                    <div className="col-md-6 col-12 order-1 order-md-2">
                        <div className="phone"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(SellerInformation);
