import React from "react";
import {Modal} from "react-bootstrap";
import ValidationComponent from "../components/Validator/Validator";
import {closeConfirmSignupModal} from "../store/actions/index";
import { connect } from "react-redux";
import Context from "../Utils/Context";
import {getItem} from "../Utils/LocalStorage";
import {withRouter} from 'react-router-dom';
import { fromPairs } from "lodash";
import { withTranslation } from "react-i18next";

class ConfirmSignup extends ValidationComponent {

    static contextType = Context;
    state = {
        formData: {},
    };
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        getItem("user_active_gsm").then((response)=>{
            this.setState({
                formData: {
                    // user_active_gsm: route.params.formData.user_active_gsm,
                    user_active_gsm:  response
                },
            });
        })

    }

    onActivationButtonPressed = async () => {


        this.setState({
            formData: {
                // user_active_gsm: route.params.formData.user_active_gsm,
                ...this.state.formData,
                user_active_gsm:  await getItem("user_active_gsm")
            },
        });

        console.log("formData2", this.state.formData);


        if (
            this.state.formData.user_active_gsm_validation_code &&
            this.state.formData.user_active_gsm_validation_code.length === 6
        ) {
            this.context.checkValidationCode(this.state.formData, (response) => {
                console.log("resss111", response)
                if (response.statuscode === '401') {
                    const { t } = this.props
                    const dialogTData = t('showDialogs', { returnObjects: true })
                    const dialogData = dialogTData.find(x => x.name === 'new-pass-confirm')
                    this.context.setGlobalState('showDialog', {
                        title: dialogData.title,
                        message: dialogData.message,
                        buttons: [
                            {
                                text: dialogData.buttons[0].label,
                                onPress: () => {
                                    this.context.setGlobalState('showDialog', false);
                                },
                            },
                        ],
                    });
                    return;
                }
                // this.props.navigation.navigate('Home');
                this.props.closeConfirmSignupModal();
                this.props.history.push("/")
            });
        } else {
            const { t } = this.props
            const dialogTData = t('showDialogs', { returnObjects: true })
            const dialogData = dialogTData.find(x => x.name === 'new-pass-confirm')
            this.context.setGlobalState('showDialog', {
                title: dialogData.title,
                message: dialogData.message,
                buttons: [
                    {
                        text: dialogData.buttons[0].label,
                        onPress: () => {
                            this.context.setGlobalState('showDialog', false);
                        },
                    },
                ],
            });
        }
    };

    onResendButtonPressed = () => {
        const formData = {
            user_active_gsm: this.state.formData.user_active_gsm,
        };

        this.context.resendValidationCode(formData, (response) => {

            console.log(`re resss`, response)

            if (response) {
                const { t } = this.props
                const dialogTData = t('showDialogs', { returnObjects: true })
                const dialogData = dialogTData.find(x => x.name === 'confirm-signup-new-code')
                this.context.setGlobalState('showDialog', {
                    title: dialogData.title,
                    message: dialogData.message,
                    buttons: [
                        {
                            text: dialogData.buttons[0].label,
                            onPress: () => {
                                this.context.setGlobalState('showDialog', false);
                            },
                        },
                    ],
                });
            }
        });
    };

    updateFormData(key, value) {
        const f = this.state.formData;
        f[key] = value;
        this.setState(f);
    }


    render() {
        const {t} = this.props
        const confirmSignup = t('modals.confirmSignup', { returnObjects: true })
        const pageData = {
            "activation": confirmSignup.inputs.find(x => x.name === 'confirm-activation'),
            "send": confirmSignup.buttons.find(x => x.name === 'confirm-send'),
            "resend": confirmSignup.buttons.find(x => x.name === 'confirm-resend'),
            "text-1": confirmSignup['custom-content'].find(x => x.name === 'confirm-text-1')['value-single'],
            "text-2": confirmSignup['custom-content'].find(x => x.name === 'confirm-text-2')['value-single'],
            "info": confirmSignup['custom-content'].find(x => x.name === 'confirm-info')['value-single'],
        }
        return (

            <Modal show={this.props.confirmSignupModal} id={"confirmSignup"}>
                <div onClick={() => this.props.closeConfirmSignupModal()} className="close-modal" data-dismiss="modal"><i/>
                </div>
                <div className="modal-body" style={{color: "#84b700"}}>
                    <h2 className="mt-4 mb-4 text-center">{pageData['text-1']}{'\n'}{ pageData['text-2']}</h2>

                    <h6 style={{color: "#84b700"}} className="text-center">
                        {pageData['info']}
                    </h6>

                    <div className="h-8">

                    </div>

                    <div id="signup-form" className=" needs-validation">

                        <div className="form-row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <input
                                        // type="password"
                                        className="form-control"
                                        id="sign_pass"
                                        onChange={(event) => {
                                            this.updateFormData('user_active_gsm_validation_code', event.target.value);
                                        }}
                                        placeholder={pageData['activation'].placeholder}
                                        // value={this.state.formData?.user_active_gsm_validation_code}
                                        required/>
                                    <label htmlFor="sign_pass">{pageData['activation'].label}</label>
                                    <div className="invalid-feedback">
                                        {pageData['activation'].error}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-10 m-auto">
                            <button
                                type="submit"
                                className="btn btn-secondary w-100"
                                onClick={this.onActivationButtonPressed}
                                // onClick={()=>console.log("fr", this.state.formData)}
                            >{pageData['send'].label}</button>
                        </div>
                    </div>
                    <p className="kvkk" style={{cursor: "pointer"}} onClick={this.onResendButtonPressed}>{pageData['resend'].label}</p>
                </div>
            </Modal>);
    }
}

const mapStateToProps = state => ({
    confirmSignupModal: state.settings.layout.confirmSignupModal
});

const mapDispatchToProps = dispatch => ({
    closeConfirmSignupModal: ()=>dispatch(closeConfirmSignupModal())
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps) (withRouter(ConfirmSignup)));