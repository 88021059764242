export const ValToFloat = (val) => {
  let newVal = "";
  if (val && typeof val !== "undefined") {
    newVal = val.toString();
    if (newVal.includes(",")) {
      return parseFloat(newVal.replace(/\,/, "."));
    } else {
      return val;
    }
  } else {
    return val;
  }
}