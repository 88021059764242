import * as axios from 'axios';
import {checkItem, getItem, removeItem, setItem} from './LocalStorage';
import {encryptLocalDataString, decyrptLocalDataString} from './Crypt';
import {config} from './Config';
import * as _ from 'lodash';

export const req = async (endpoint, data) => {
  let headers = {};
  // Global.variables.state.loading = true
  let a= data ? (headers.Data = await encryptLocalDataString(data)) : false;
  a = (await getItem('SessVal'))
    ? (headers.SessVal = await getItem('SessVal'))
    : false;
  a = (await getItem('SessLVal'))
    ? (headers.SessLVal = await getItem('SessLVal'))
    : false;
  a = (await getItem('Source'))
    ? (headers.Source = await getItem('Source'))
    : false;

  return axios
    .request({
      method: 'post',
      url: endpoint,
      baseURL: config.api.url,
      headers: headers,
    })
    .then((response) => {
      // Global.variables.loading = false;
      return response.data;
    })
    .catch(function (error) {
      // Global.variables.loading = false;
      console.error('error >> ', error);
    });
};

const clearSession = async () => {
  await removeItem('user');
  await removeItem('loginType');
  await removeItem('SessLVal');
  await removeItem('SessVal');
  await removeItem('Source');
};

export const marketerWorkSummary = async (callback, market_id) => {
  //--//--console.warn('{"optarget":"view_product_sales_list","optype":"select","opvalue":"","formid":"","product_transaction_market_id":"' + market_id + '"}');
  req(
    'pazardanWebApp/MarketerWorkSummary',
    '{"optarget":"view_product_sales_list","optype":"select","opvalue":"","formid":"","product_transaction_market_id":"' +
      market_id +
      '"}',
  ).then(async (data) => {
    const rData = await decyrptLocalDataString(data);
    //--//--console.warn("marketerWorkSummary >> rData >> ", rData);
    callback(JSON.parse(rData).data);
  });
};

export const marketerProductList = async (callback) => {
  //--//--console.warn('{"optarget":"view_active_product_list","optype":"select","opvalue":"","formid":""}');
  req(
    'pazardanWebApp/MarketerProductList',
    '{"optarget":"view_active_product_list","optype":"select","opvalue":"","formid":""}',
  ).then(async (data) => {
    const rData = await decyrptLocalDataString(data);
    callback(JSON.parse(rData).data);
  });
};

export const fromWebApp = async (endpoint, callback, filter, body) => {
  axios
    .request({
      method: 'post',
      url: 'pazardanWebApp/' + endpoint + '?role=enduser' + (filter || ''),
      baseURL: config.api.url,
      data: body,
    })
    .then(async (response) => {
      //--//--console.warn("fromWebApp >> response >> ", endpoint, response);
      callback(response.data);
    })
    .catch(function (error) {
      console.error('error >> ', error);
    });
};

export const paymentStart = async (secretkey, callback) => {
  //--//--console.warn('{"optarget":"tbl_product_transaction","optype":"paymentstart","opvalue":"","formid":"","product_transaction_secret_key":"' + secretkey + '"}');


    req(
    'pazardanWebApp/PaymentStart',
    '{"optarget":"tbl_product_transaction","optype":"paymentstart","opvalue":"","formid":"","product_transaction_secret_key":"' +
      secretkey +
      '"}',
  ).then(async (data) => {
    const rData = await decyrptLocalDataString(data);
    callback(JSON.parse(rData));
  });
};

export const createSLT = async (callback) => {
  req('pazardanWebApp/CreateSession').then(async (SessVal) => {
    await setItem('SessVal', SessVal);
    req('pazardanWebApp/CreateLoginType?UserType=user').then(async (Source) => {
      await setItem('Source', Source);
      callback(true);
    });
  });
};

export const login = async (user, callback) => {
  if (!(await checkItem('SessLVal'))) {
    clearSession();
    createSLT(async () => {
      // await req('pazardanWebApp/Login', '{"optarget":"tbl_user", "optype":"login", "opvalue": "", "formid":"","user_username":"adminusr1","user_password":"Gtyh4512Edf5c","usertype":"user"}').then(async (data) => {
      await req(
        'pazardanWebApp/Login',
        '{"optarget":"user", "optype":"login", "opvalue": "", "formid":"","user_username":"' +
          user.username +
          '","user_password":"' +
          user.password +
          '","usertype":"user"}',
      ).then(async (data) => {
        const rData = await decyrptLocalDataString(data);
        const pRData = JSON.parse(rData);
        if (pRData.statuscode === '200') {
          //--//--console.warn("pRData >> ", pRData);
          await setItem('Source', pRData.data.Source);
          await setItem('SessLVal', pRData.data.SessLVal);
          await setItem('user', JSON.stringify(pRData.data));
          callback(pRData.data);
        } else {
          callback(false);
        }
      });
    });
  }
};

export const newmember = async (formData, callback) => {
  clearSession();
  createSLT(async () => {
    const body = _.merge(
      {optarget: 'user', optype: 'newmember', opvalue: '', formid: ''},
      formData,
    );
    //--//--console.warn("newmember >> body >> ", body);
    req('pazardanWebApp/NewMember', JSON.stringify(body)).then(
      async (response) => {
        const rData = await decyrptLocalDataString(response);
        const pRData = JSON.parse(rData);
        //--//--console.warn("pRData >> ", pRData);
        if (pRData.statuscode === '200') {
          callback(pRData);
        } else {
          callback(false, pRData.message);
        }
      },
    );
  });
};

export const checkValidationCode = (formData, callback) => {
  clearSession();
  const body = _.merge(
    {optarget: 'user', optype: 'validationcode', opvalue: '', formid: ''},
    formData,
  );
  //--//--console.warn("checkValidationCode >> body >> ", body);
  createSLT(async () => {
    req('pazardanWebApp/CheckValidationCode', JSON.stringify(body)).then(
      async (response) => {
        //--//--console.warn("response >> ", response);
        const rData = await decyrptLocalDataString(response);
        //--//--console.warn("rData >> ", rData);
        const pRData = JSON.parse(rData);
        if (pRData.statuscode === '200') {
          //--//--console.warn("pRData >> ", pRData);
          await setItem('Source', pRData.data.Source);
          await setItem('SessLVal', pRData.data.SessLVal);
          await setItem('user', JSON.stringify(pRData.data));
          callback(true);
        } else {
          callback(false);
        }
      },
    );
  });
};

export const getUser = async (callback) => {
  const user = JSON.parse(await getItem('user'));
  const sk = await encryptLocalDataString(user.member_secretkey);
  //--//--console.warn('{"optarget":"user", "optype":"membership", "opvalue": ' + sk + ', "formid":""}');

  req(
    'user/userUpdateGetData',
    '{"optarget":"user", "optype":"membership", "opvalue": "' +
      sk +
      '", "formid":""}',
  ).then(async (response) => {
    // //--//--console.warn("response >> ", await decyrptLocalDataString(response));
    const decyrptedReponse = JSON.parse(await decyrptLocalDataString(response));
    callback(decyrptedReponse.data);
  });
};

export const select = (table, callback, filterParameters, optype) => {
  const fp = filterParameters ? '?filterparameters=' + filterParameters : '';

  //--//--console.warn('{ "optarget":"' + table + '", "optype": "' + (optype || "select") + '", "opvalue": "", "formid": "" }');

  req(
    table + '/' + table + 'Select' + fp,
    '{ "optarget":"' +
      table +
      '", "optype": "' +
      (optype || 'select') +
      '", "opvalue": "", "formid": "" }',
  ).then(async (response) => {
    // //--//--console.warn("response >> ", await decyrptLocalDataString(response));
    const decyrptedReponse = JSON.parse(await decyrptLocalDataString(response));
    callback(decyrptedReponse.data);
  });
};

export const insert = (table, formData, callback) => {
  const body = _.merge(
    {optarget: table, optype: 'insert', opvalue: '', formid: ''},
    formData,
  );

  //--//--console.warn("insert >> body >> ", body);
  req(table + '/' + table + 'Insert', JSON.stringify(body)).then(
    async (response) => {
      const decyrptedReponse = JSON.parse(
        await decyrptLocalDataString(response),
      );
      callback(decyrptedReponse.data);
    },
  );
};

export const update = async (table, formData, secretkey, callback, optype) => {
  const body = _.merge(
    {
      optarget: table,
      optype: optype || 'update',
      opvalue: await encryptLocalDataString(secretkey),
      formid: '',
    },
    formData,
  );

  //--//--console.warn("update >> body >> ", body);
  req(table + '/' + table + 'Update', JSON.stringify(body)).then(
    async (response) => {
      const decyrptedReponse = JSON.parse(
        await decyrptLocalDataString(response),
      );
      callback(decyrptedReponse.data);
    },
  );
};

export const deleted = async (table, formData, secretkey, callback) => {
  const sk = await encryptLocalDataString(secretkey);

  //--//--console.warn("sk> >> ", sk);
  const data = {optarget: table, optype: 'update', opvalue: sk, formid: ''};
  const body = _.merge(data, formData);

  //--//--console.warn("deleted >> body >> ", JSON.stringify(body));
  req(table + '/' + table + 'Update', JSON.stringify(body)).then(
    async (response) => {
      const decyrptedReponse = JSON.parse(
        await decyrptLocalDataString(response),
      );
      callback(decyrptedReponse.data);
    },
  );
};
