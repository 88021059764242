
const initialState = {
    state: "",
};

const becomeSeller = (state = initialState, action) => {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default becomeSeller;
