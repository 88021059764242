import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import Context from "../../../Utils/Context";
import ValidationComponent from "../../../components/Validator/Validator";
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {MdRemoveRedEye} from "react-icons/md";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withTranslation } from 'react-i18next';
class PasswordForm extends ValidationComponent {

    static contextType = Context;
    deviceLocale = 'tr';
    labels = {
        formData: {
            user_password: 'Şifre',
            user_password_again: 'Şifre Tekrarı',
        },
    };
    state = {
        newPassView: false,
        newPassViewR : false,
        updateData: null,
        formData: {
            user_password: null,
            user_password_again: null,
        },
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {}

    onChangePasswordButtonPressed = () => {
        this.validate({
            formData: {
                user_password: {required: true},
                user_password_again: {
                    required: true,
                    equalPassword: this.state.formData.user_password,
                },
            },
        });
        if (this.isFormValid()) {
            delete this.state.formData.user_password_again;
            this.context.update(
                'user',
                this.state.formData,
                this.context.user.member_secretkey,
                (r) => {
                    this.props.history.goBack();
                },
                'membership',
            );
        }
    };

    updateFormData(key, value) {
        const f = this.state.formData;
        f[key] = value;
        this.setState(f);
    }


    render() {
        const { t } = this.props
        const PasswordFormData = t('passwordSettings', { returnObjects: true })
        const pageData = {
            'new-pass': PasswordFormData['inputs'].find(x => x.name === 'new-pass'),
            'new-pass-2': PasswordFormData['inputs'].find(x => x.name === 'new-pass-2'),
            'pass-update': PasswordFormData['buttons'].find(x => x.name === 'pass-update')['label'],
            'invalid-form-feedback': PasswordFormData['custom-content'].find(x => x.name === 'invalid-form-feedback')['value-single'],
        }
        return (
            <div className="col-12 col-lg-9">
                <div className="address-form">
                    <div className="container">
                        <div className="row mb-4">
                            {this.getErrorMessages()}
                        </div>
                        <div className="row">
                            <div className=" col-12">
                                <div id="pass-form" className=" needs-validation" noValidate>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group flex">
                                                <input type={this.state.newPassView ? "text" : "password"} className="form-control pass"
                                                       id="newPassword" placeholder={pageData['new-pass'].placeholder} minLength="8"
                                                       maxLength="20" required
                                                       onChange={(event) => {
                                                           this.updateFormData('user_password', event.target.value);
                                                       }}
                                                       autoCorrect={"false"}
                                                />
                                                <div className="flex w-12 align-items-center cursor-pointer">
                                                {this.state.newPassView ? <Visibility onClick={()=>this.setState({newPassView: !this.state.newPassView })}
                                                                                      style={{ color: "#90b52a" }}/>
                                                    : <VisibilityOff onClick={()=>this.setState({newPassView: !this.state.newPassView })} style={{ color: "#90b52a" }}/>}
                                                </div>
                                                <label htmlFor="newPassword">{pageData['new-pass'].label}</label>
                                                <div className="invalid-feedback">{pageData['new-pass'].error}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col-md-12">
                                            <div className="form-group flex">
                                                <input type={this.state.newPassViewR ? "text" : "password"} minLength="8" maxLength="20"
                                                       className="form-control pass" id="confirmPassword"
                                                       placeholder={pageData['new-pass'].placeholder} required
                                                       onChange={(event) => {
                                                           this.updateFormData('user_password_again', event.target.value);
                                                       }}
                                                       autoCorrect={false}
                                                />
                                                <div className="flex w-12 align-items-center cursor-pointer">
                                                    {this.state.newPassViewR ? <Visibility onClick={()=>this.setState({newPassViewR: !this.state.newPassViewR })}
                                                                                          style={{ color: "#90b52a" }}/>
                                                        : <VisibilityOff onClick={()=>this.setState({newPassViewR: !this.state.newPassViewR })} style={{ color: "#90b52a" }}/>}
                                                </div>
                                                <label htmlFor="confirmPassword">{pageData['new-pass'].label}</label>
                                                <div className="invalid-feedback">
                                                {pageData['new-pass'].error}
                                                </div>
                                                <div id="cPwdInvalid" className="invalid-feedback">{pageData['invalid-form-feedback']}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 m-auto">
                                        <button className="btn btn-secondary w-100 submit" onClick={this.onChangePasswordButtonPressed}>
                                        {pageData['pass-update']}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(withRouter(PasswordForm));
