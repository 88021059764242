import React, {useState} from "react";
import {Collapse} from "react-bootstrap";
import { useTranslation } from "react-i18next";

const FAQ = () => {

  const [open, setOpen]=useState(null);
  const { t } = useTranslation()
  const pageData = t('faq', { returnObjects: true })
  const faqs = pageData['custom-content'].map(x =>  {
    return {
      title: x['value-single'],
      content: x['value-rich'].split('<p>')[1].split('</p>')[0],
      open: false,
    }
  })
  const [SECTIONS, SETSECTIONS] = useState(faqs);

  const collapseClick = (index)=>{
    let tempSections = SECTIONS;
    let temp = SECTIONS[index];
    temp.open = !SECTIONS[index].open;
    tempSections[index] = temp;
    SETSECTIONS(tempSections);
    setOpen(!open);
  }


  return(<div className="col-12 col-lg-9">
    <div className="box">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {SECTIONS.map((faq, index)=>(
              <div key={index}>
                <p className="sss flex justify-content-between cursor-pointer" onClick={()=>collapseClick(index)}>

                    <span className="" >{faq.title}</span>
                  <a className="sss"  data-toggle="collapse" role="button" aria-controls="" aria-expanded={faq.open}>
                    {/*{faq.title}*/}
                  </a>
                </p>
                {/*<Collapse in={open===index}>*/}
                <Collapse in={faq.open}>
                  <div className="sss-collapse" id="sss">
                    {faq.content}
                  </div>
                </Collapse>
                <hr/>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>);
}
export default FAQ;
