import React, {useEffect} from 'react';
import {withRouter} from "react-router";
import Context from "../../../Utils/Context";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withTranslation } from 'react-i18next';

class SettingsContent extends React.Component{
    static contextType = Context;
    state = {
        user: {},
        user_general_sms_permission: 1,
        user_general_email_permission: 1,
        user_general_mobile_permission: 1,
        user_general_phone_permission: 1
    }
    constructor(props) {
        super(props)
    }

    fetchData = () => {
        this.context.getUser((data) => {
            //--//--console.warn("user >> data >> ", data);
            const user = data[0];
            this.setState({
                user: user
            })
            this.updateData('user_general_sms_permission', user.user_general_sms_permission);
            this.updateData('user_general_email_permission', user.user_general_email_permission);
            this.updateData('user_general_mobile_permission', user.user_general_mobile_permission);
            this.updateData('user_general_phone_permission', user.user_general_phone_permission);
        })
    }
    async componentDidMount() {
        this.fetchData();
    }

    updateData(key, value) {
        const f = this.state;
        f[key] = value;
        this.setState(f);
    }

    updatePermission = (key, value) => {
        const f = {};
        f[key] = value;
        this.context.update('user', f, this.state.user.user_secret_key, (r) => {
            const { t } = this.props
            const toastsData = t('toasts', { returnObjects: true })
            this.fetchData();
            toast.success(toastsData.find(x => x.name === 'updated').message, {
                position: "bottom-center",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }, 'membership');
    }


    render() {
        const { t } = this.props
        const contactData = t('contactSettings.settingsContent', { returnObjects: true })
        const pageData = {
            'sms': contactData.inputs.find(x => x.name === 'sms_permission'),
            'email': contactData.inputs.find(x => x.name === 'email_permission'),
            'bildirim': contactData.inputs.find(x => x.name === 'mobile_permission'),
            'telefon': contactData.inputs.find(x => x.name === 'phone_permission'),
        }
        return(
        <div className="col-12 col-lg-9">
            <div className="box">
                <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-between sss">
                                <span>{ pageData['sms'].label}</span>
                            <input type="checkbox" id="sms" className="switch"
                                   onChange={(event)=>{
                                       this.updateData('user_general_sms_permission', event.target.checked);
                                       this.updatePermission('user_general_sms_permission', event.target.checked ? "1" : "0");
                                   }}
                                   checked={this.state.user_general_sms_permission === 'True'}
                            /><label
                            className="switch-toggle" htmlFor="sms">Toggle</label>
                        </div>
                        <div className="col-12 d-flex justify-content-between sss">
                            <span>{ pageData['email'].label}</span>
                            <input type="checkbox" id="email" className="switch"
                                   onChange={(event)=>{
                                       this.updateData('user_general_email_permission', event.target.checked);
                                       this.updatePermission('user_general_email_permission', event.target.checked ? "1" : "0");
                                   }}
                                   value={this.state.user_general_email_permission === 'True'}
                            /><label
                            className="switch-toggle" htmlFor="email">Toggle</label>
                        </div>
                        <div className="col-12 d-flex justify-content-between sss">
                            <span>{ pageData['bildirim'].label}</span>
                            <input type="checkbox" id="bildirim" className="switch"
                                   onChange={(event)=>{
                                       this.updateData('user_general_mobile_permission', event.target.checked);
                                       this.updatePermission('user_general_mobile_permission', event.target.checked ? "1" : "0");
                                   }}
                                   value={this.state.user_general_mobile_permission === 'True'}
                            /><label
                            className="switch-toggle" htmlFor="bildirim">Toggle</label>
                        </div>
                        <div className="col-12 d-flex justify-content-between sss">
                            <span>{ pageData['telefon'].label}</span>
                            <input type="checkbox" id="telefon" className="switch"
                                   onChange={(event) => {
                                       this.updateData('user_general_phone_permission', event.target.checked);
                                       this.updatePermission('user_general_phone_permission', event.target.checked ? "1" : "0");
                                   }}
                                   value={this.state.user_general_phone_permission === 'True'}
                            /><label
                            className="switch-toggle" htmlFor="telefon">Toggle</label>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer/>
        </div>
        )
    }
}
export default withTranslation()(withRouter(SettingsContent));
