export const TransactionStatus = (transactionData, type = "clerk") => {
    // Canceled   -> İptal Edildi
    // Preparing  -> Sipariş Hazırlanıyor
    // Prepared   -> Sipariş Hazırlandı
    // OnWay      -> Sipariş Yolda
    // Completed  -> Sipariş Tamamlandı
    if (transactionData.product_transaction_buyer_cancel_status !== "" && transactionData.product_transaction_seller_cancel_status !== "") {
      return "Canceled";
    }
    if (transactionData.product_transaction_buyer_cancel_status !== '' || transactionData.product_transaction_seller_cancel_status !== "") {
      if (type === "clerk") {
        return "Canceled";
      } else {
        if (transactionData.product_transaction_seller_cancel_status !== '100') {
          return "Canceled";
        } else {
          return "Preparing";
        }
      }
    } else if (transactionData.product_transaction_delivery_status === '' || transactionData.product_transaction_delivery_status === '1') {
      return "Preparing";
    } else if ((transactionData.product_transaction_delivery_status === '2' || CheckBetween(transactionData.product_transaction_delivery_status, 200, 300))) {
      return "Prepared";
    } else if ((transactionData.product_transaction_delivery_status === '3' || CheckBetween(transactionData.product_transaction_delivery_status, 300, 400))) {
      return "OnWay";
    } else if (transactionData.product_transaction_delivery_status === '4') {
      return "Completed";
    } else {
      return "Preparing";
    }
  }
  
  const CheckBetween = (data, min, max) => {
    return (parseFloat(data) >= min &&  parseFloat(data) < max)
  }