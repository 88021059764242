import React, {useContext, useEffect, useState} from "react";
import {Dropdown, Modal} from "react-bootstrap";
import Context from "../Utils/Context";
import {checkItem, getItem, removeItem, setItem} from "../Utils/LocalStorage";
import {useHistory} from "react-router";
import { useTranslation } from "react-i18next";

const AddressSelectModal = () => {
    const { t } = useTranslation();
    const addressSelectData = t('modals.addressSelectModal', { returnObjects: true })
    const pageData = {
        "choose": addressSelectData['custom-content'].find(x => x.name === 'select-modal')['value-single'],
        "text-1": addressSelectData['custom-content'].find(x => x.name === 'select-text-1')['value-single'],
        "text-2": addressSelectData['custom-content'].find(x => x.name === 'select-text-2')['value-single'],
        "drop-info": addressSelectData['custom-content'].find(x => x.name === 'select-drop-info')['value-single'],
        "select": addressSelectData.dropdowns.find(x => x.name === 'select-dropdown'),
        "add": addressSelectData.buttons.find(x => x.name === 'select-add'),
        "home": addressSelectData.buttons.find(x => x.name === 'select-go-home'),
        "ok": addressSelectData.buttons.find(x => x.name === 'select-ok'),
    }
    const history = useHistory();
    const context = useContext(Context);
    const [addresses, setAddresses] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    useEffect(() => {
        init();
    }, [context.user, context.addAddressModal]);

    const init = async () => {
        const currentAddress = JSON.parse(await getItem("address"));
        if (currentAddress){
            setSelectedAddress(currentAddress);
        }
        setAddresses([]);
        if (context.user){
            context.select(
                'address',
                (data) => {
                    setAddresses(data ? data : []);
                    if (data && data.length > 0){
                        setSelectedAddress(data[data.length - 1]);
                    }
                },
                'user_address_is_active:1,user_address_is_deleted:0,user_address_created_by_user_id:' +
                context.user.member_id +
                '&pageLimit=99999',
            );
        }
    };

    const onAddressSelected = async (value) => {
        if (value) {
            setSelectedAddress(value);
            //history.push('/stalls');
        }
    };


    return (<Modal show={context.addressSelectModal} onHide={()=>console.log("asi")}>
        <div onClick={() => {context.setGlobalState('addressSelectModal', false)}} className="close-modal" data-dismiss="modal"><i/></div>
        <Modal.Body>
            <div className="row">
                <div className="col-10 col-lg-8 m-auto">
                    <h1>{pageData['choose']}</h1>
                    <p>{pageData['text-1']} <br/>{pageData['text-2']}</p>
                    <div className="row sub-header-content" >
                        <Dropdown className="col-12 align-self-center  header-dropdown addresses mb-3" >
                            {selectedAddress ? (
                                <Dropdown.Toggle role={"button"} id="dropdownMenuLink" style={{textAlign:"left", borderColor:"white"}}>
                                    {selectedAddress.user_address_title}
                                </Dropdown.Toggle>
                            ) : (
                                <Dropdown.Toggle role={"button"} id="dropdownMenuLink" style={{textAlign:"left", borderColor:"white"}}>
                                    {pageData['select'].label}
                                </Dropdown.Toggle>
                            )}
                            <Dropdown.Menu className="dropdown-menu " aria-labelledby="dropdownMenuLink">
                                <Dropdown.Item className="new-address"
                                               onClick={async () => {
                                                   context.setGlobalState('addAddressModal', true);
                                               }}>{pageData['add'].label}
                                </Dropdown.Item>
                                {addresses.map((address, index) => (
                                    <Dropdown.Item key={index}
                                                   onClick={async () => {
                                                       await onAddressSelected(address);
                                                   }}>{address.user_address_title}</Dropdown.Item>
                                ))}
                                {addresses.length === 0 && (
                                    <Dropdown.Item>
                                        {pageData['drop-info']}
                                    </Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
                <a data-toggle="modal" onClick={async () => {
                    await setItem('address', JSON.stringify(selectedAddress));
                    window.location.href = "/stalls";
                    //context.setGlobalState('addressSelectModal', false);
                }}  className="btn btn-secondary col-10 col-lg-8 mx-auto">{pageData['ok'].label}</a>
                 <a data-toggle="modal" onClick={()=>{context.setGlobalState('addressSelectModal', false);
                }}  className="btn btn-primary col-10 col-lg-8 mx-auto mt-3">{pageData['home'].label}</a>
            </div>
        </Modal.Body>
    </Modal>);
}
export default AddressSelectModal;