import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useParams, withRouter} from "react-router";
import Context from "../../Utils/Context";
import blogHeader from "../../assets/blogHeader.png";
import blogImage from "../../assets/images/blog-image.png";
import "./Blog.css";


function AllBlogs() {

    const context = useContext(Context);
    const [lBlogs, setLBlogs] = useState([]);

    const history = useHistory();

    useEffect(()=>{
        window.scroll({top: 0, behavior: "smooth"});
        if(!context.blogs || (context.blogs.length && context.blogs.length === 0)){
            init();
        }
    },[context.blogs])

    const init = async () => {
        await context.select("web_content", (data) => {
            console.log("blgos", data);
            setLBlogs(data);
            // setLBlogs([data[0], data[1], data[0], data[1], data[0], data[0], data[0]])
            context.setGlobalState("blogs", data);
        }, "web_content_type:blog&pageLimit=99999");
        //https://mservice.pazardan.app/web_content/web_contentSelect?filterparameters=web_content_type:blog&pageLimit=99999
    }

    useEffect(()=>{
        console.log("gg");
        if(context.blogs && context.blogs.length && context.blogs.length > 0){
            // setLBlogs([context.blogs[0], context.blogs[1], context.blogs[0], context.blogs[1], context.blogs[0], context.blogs[0],context.blogs[0]])
            setLBlogs(context.blogs)
        }
    },[context.blogs]);



    return (
        <div className="container">
            <div className="flex justify-content-center align-items-center top-img-mobile"
                // src={imgUrl}
                // style={{backgroundImage:"url(assets/images/blog-image.png)"}}
                 style={{backgroundImage:"url("+blogHeader+")", backgroundSize: "cover", width:"100%", height:"8rem", marginBottom: "3rem "}}

            >
                <span className=" text-white font-weight-bold " style={{fontSize: "24px"}}>BLOG</span>
            </div>

            <div className="flex all-blogs-desktop" style={{flexWrap: "wrap"}}>
            {
                lBlogs && lBlogs.length && lBlogs.length > 0 ? lBlogs.map((blog, index)=>(
                        <div style={{minWidth: index % 3 === 0 ? "100%" : "50%",
                            maxWidth: index % 3 === 0 ? "100%" : "50%",
                            paddingRight: index % 3 === 1 ? "1rem": "0",
                            paddingLeft: index % 3 === 2 ? "1rem" : "0"
                        }}>
                            <div>
                            <p>
                            {blog && blog.web_content_tagname2 && blog.web_content_tagname2 !== "" ?
                                <div style={{backgroundImage:"url("+blog.web_content_tagname2+")",
                                    backgroundSize: "cover",
                                    width: index % 3 === 0 ? "55%": "100%",
                                    marginRight: index % 3 === 0 ? "2rem": "0rem",
                                    height: index % 3 === 0 ? "24rem" : "16rem",
                                    borderRadius: "1rem",
                                    float: index % 6 === 0 ? "left" : index % 6 === 3 ? "right" : "none",
                                    marginBottom: "3rem "}}>
                                </div>

                                : null }


                                <h1 style={{color:"#46742C", fontSize:"24px", fontWeight:"bold"}}
                                    className={ "mb-8"}>{blog && blog.web_content_tagname1 ? blog.web_content_tagname1 : "-"}</h1>

                            {blog && blog?.web_content_content_text1 ?
                            <span className={index % 3 === 0 ? "first-blog-content mb-8" : "second-blog-content mb-8"} style={{fontSize:"14px"}} dangerouslySetInnerHTML={{__html: blog.web_content_content_text1}} /> : ""}
                            </p>

                            <p className="all-blog-continue mb-8 cursor-pointer" onClick={()=>history.push("/blog/" + blog.web_content_id)}>
                                Devamı
                            </p>
                            </div>
                        </div>
                )
                ) : <div>
                    Yakında bloglar geliyor:)
                </div>
            }
            </div>


            <div className="flex all-blogs-mobile" style={{flexWrap: "wrap"}}>
                {
                    lBlogs && lBlogs.length && lBlogs.length > 0 ? lBlogs.map((blog, index)=>(
                            <div style={{minWidth: index % 3 === 0 ? "100%" : "100%",
                                maxWidth: index % 3 === 0 ? "100%" : "100%",

                            }}>
                                <div>
                                    <p className="all-blogs-content">
                                        {blog && blog.web_content_tagname2 && blog.web_content_tagname2 !== "" ?
                                            <div style={{backgroundImage:"url("+blog.web_content_tagname2+")",
                                                backgroundSize: "cover",
                                                width: index % 3 === 0 ? "100%": "100%",
                                                height: index % 3 === 0 ? "24rem" : "24rem",
                                                borderRadius: "1rem",
                                                float: index % 6 === 0 ? "left" : index % 6 === 3 ? "right" : "none",
                                                marginBottom: "3rem "}}>
                                            </div>

                                            : null }


                                        <h1 style={{color:"#46742C", fontSize:"24px", fontWeight:"bold"}}
                                            className={ "mb-8"}>{blog && blog.web_content_tagname1 ? blog.web_content_tagname1 : "-"}</h1>

                                        {blog && blog?.web_content_content_text1 ?
                                            <span className={index % 3 === 0 ? "first-blog-content mb-8" : "second-blog-content mb-8"} style={{fontSize:"14px"}} dangerouslySetInnerHTML={{__html: blog.web_content_content_text1}} /> : ""}
                                    </p>

                                    <p className="all-blog-continue mb-8 cursor-pointer" onClick={()=>history.push("/blog/" + blog.web_content_id)}>
                                        Devamı
                                    </p>
                                </div>
                            </div>
                        )
                    ) : <div>
                        Yakında bloglar geliyor:)
                    </div>
                }
            </div>

        </div>
    );
}

export default withRouter(AllBlogs);