import React, {useContext, useEffect, useState} from "react";
import {Button, Dropdown, Modal} from "react-bootstrap";
import Context from "../Utils/Context";
import {getItem} from "../Utils/LocalStorage";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const AddAddressModal = () => {
    const { t } = useTranslation();
    const addAddressData = t('modals.addAddressModal', { returnObjects: true })
    const pageData = {
        "title": addAddressData['custom-content'].find(x => x.name === 'add-address-modal')['value-single'],
        "name": addAddressData.inputs.find(x => x.name === 'address-modal-name'),
        "no": addAddressData.inputs.find(x => x.name === 'address-modal-no'),
        "floor": addAddressData.inputs.find(x => x.name === 'address-modal-floor'),
        "apartment": addAddressData.inputs.find(x => x.name === 'address-modal-apartment'),
        "address": addAddressData.inputs.find(x => x.name === 'address-modal'),
        "directions": addAddressData.inputs.find(x => x.name === 'address-modal-directions'),
        "country": addAddressData.dropdowns.find(x => x.name === 'address-modal-country'),
        "county": addAddressData.dropdowns.find(x => x.name === 'address-modal-county'),
        "neighborhood": addAddressData.dropdowns.find(x => x.name === 'address-modal-neighborhood'),
        "cancel": addAddressData.buttons.find(x => x.name === 'address-modal-cancel'),
        "ok": addAddressData.buttons.find(x => x.name === 'address-modal-ok'),
    }
    const context = useContext(Context);
    const [cities, setCities] = useState([]);
    const [counties, setCounties] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [cityPicker, setCityPicker] = useState([]);
    const [countyPicker, setCountyPicker] = useState([]);
    const [update, setUpdate] = useState(false);
    const [neighborhoodPicker, setNeighborhoodPicker] = useState([]);
    const [formData, setFormData] = useState({
        user_address_title: "",
        user_address_city: "",
        user_address_county: "",
        user_address_neighborhood: "",
        user_address_detail: "",
        user_address_building_number: "",
        user_address_floor_number: "",
        user_address_door_number: "",
        user_address_information: "",
        user_address_full_address: "",
        user_address_created_by_user_id: null,
        user_address_is_active: 1,
        user_address_is_deleted: 0,
    });

    useEffect(()=>{
        init();
    },[]);
    const init =async () => {
        const CCN = JSON.parse(await getItem("CCN"));
        if(CCN){
            const f = formData;
            f['user_address_city'] = CCN.city.city_name;
            f['user_address_county'] = CCN.county.county_name;
            f['user_address_neighborhood'] = CCN.neighbourhood.neighborhood_name;
            setFormData(f);
        }
        await context.select(
            'city',
            (data) => {
                context.setGlobalState('showDialog', false);
                //--//--console.warn('NewAddress >> city >> ', data);
                const c = _.map(data, (d) => {
                    return {label: d.city_name, value: d.city_name};
                });
                setCityPicker(c);
                setCities(data);
            },
            '&orderparameters=city_parent_city_id:asc&pageLimit=99999',
        );
    };
    const onNAAddAddressButtonPressed = async () => {
        updateFormData('user_address_created_by_user_id', context.user.member_id);
        updateFormData(
            'user_address_full_address',
            formData.user_address_detail +
            ' Bina No:' +
            formData.user_address_building_number +
            (formData.user_address_floor_number
                ? ' Kat:' + formData.user_address_floor_number
                : '') +
            (formData.user_address_door_number
                ? ' Daire:' + formData.user_address_door_number
                : '') +
            ' ' +
            formData.user_address_neighborhood +
            '/' +
            formData.user_address_county +
            '/' +
            formData.user_address_city,
        );
        context.insert('address', formData, (r) => {
            context.setGlobalState('addAddressModal', false);
        });
    };

    const updateFormData = (key, value) => {
        const f = formData;
        f[key] = value;
        setFormData(f);
        setUpdate(!update);
    }

    const onCitySelected = (value) => {
        if (value) {
            updateFormData('user_address_city', value);
            updateFormData('user_address_county', "");
            updateFormData('user_address_neighborhood', "");
            context.select(
                'county',
                (data) => {
                    //--//--console.warn('NewAddress >> county >> ', data);
                    const c = _.map(data, (d) => {
                        return {label: d.county_name, value: d.county_name};
                    });
                    setCounties(data);
                    setCountyPicker(c);
                },
                'county_city_id:' +
                getId(value, cities, 'city_name').city_id +
                '&pageLimit=99999&orderparameters=county_name:asc',
            );
        }
    };

    const onCountySelected = (value) => {
        if (value) {
            updateFormData('user_address_county', value);
            updateFormData('user_address_neighborhood', "");

            context.select(
                'neighborhood',
                (data) => {
                    //--//--console.warn('NewAddress >> county >> ', data);
                    const n = _.map(data, (d) => {
                        return {label: d.neighborhood_name, value: d.neighborhood_name};
                    });
                    setNeighborhoodPicker(n);
                    setNeighborhoods(data);
                },
                'neighborhood_county_id:' +
                getId(value, counties, 'county_name').county_id +
                '&pageLimit=99999&orderparameters=neighborhood_name:asc',
            );
        }
    };

    const onNeighborhoodSelected = (value) => {
        if (value) {
            updateFormData('user_address_neighborhood', value);
        }
    };

    const getId = (value, data, search) => {
        const f = {};
        f[search] = value;
        if (_.filter(data, f)[0]) return _.filter(data, f)[0];
    };

    return (<Modal show={context.addAddressModal} size={"xl"} onHide={() => console.log("asi")}>
        <div onClick={() => {
            context.setGlobalState('addAddressModal', false)
        }} className="close-modal" data-dismiss="modal"><i/></div>
        <Modal.Body>
            <div className="row">
                <div className="col-10 col-lg-8 m-auto">
                    <h1>{ pageData['title'] }</h1>
                    <div className="address-form">
                        <div className="container">
                            <div className="row">
                            </div>
                            <div className="row">
                                <div className=" col-12">
                                    <div className="address-add needs-validation">
                                        {/*title*/}
                                        <div className="form-row">
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input type="text" value={formData.user_address_title}
                                                           onChange={(e) => updateFormData('user_address_title', e.target.value)}
                                                           className="form-control" id="name"
                                                           placeholder={pageData['name'].placeholder} required/>
                                                    <label htmlFor="name">{pageData['name'].label}</label>
                                                    <div className="invalid-feedback">
                                                        {pageData['name'].error}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*city, county, neighborhood*/}
                                        <div className="form-row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <Dropdown className="col-12 align-self-center"
                                                              style={{padding: "inherit"}}>
                                                        {formData.user_address_city.length !== 0 ? (
                                                            <Dropdown.Toggle role={"button"}
                                                                             className={"select-selected justify-content-start"}
                                                                             style={{
                                                                                 paddingLeft: "15px",
                                                                                 display: "flex"
                                                                             }}
                                                                             as={Button} id="dropdownMenuLink"
                                                            >
                                                                {formData.user_address_city}
                                                            </Dropdown.Toggle>
                                                        ) : (
                                                            <Dropdown.Toggle role={"button"}
                                                                             className={"select-selected justify-content-start"}
                                                                             style={{
                                                                                 paddingLeft: "15px",
                                                                                 display: "flex"
                                                                             }}
                                                                             as={Button} id="dropdownMenuLink">
                                                                {pageData['country'].placeholder}
                                                            </Dropdown.Toggle>
                                                        )}
                                                        <Dropdown.Menu
                                                            className="dropdown-menu scrollable-menu-markets">
                                                            {cityPicker.map((city, index) => (
                                                                <Dropdown.Item className={"dropdown-item"}
                                                                               key={index} onClick={(e) => {
                                                                    onCitySelected(city.value);
                                                                }}>{city.label}</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <div className="invalid-feedback">
                                                        {pageData['country'].error}
                                                    </div>
                                                    <label htmlFor="">{pageData['country'].label}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-group-select">
                                                    <Dropdown className="col-12 align-self-center"
                                                              style={{padding: "inherit"}}>
                                                        {formData.user_address_county.length !== 0 ? (
                                                            <Dropdown.Toggle role={"button"}
                                                                             className={"select-selected justify-content-start"}
                                                                             style={{
                                                                                 paddingLeft: "15px",
                                                                                 display: "flex"
                                                                             }}
                                                                             as={Button} id="dropdownMenuLink"
                                                            >
                                                                {formData.user_address_county}
                                                            </Dropdown.Toggle>
                                                        ) : (
                                                            <Dropdown.Toggle role={"button"}
                                                                             className={"select-selected justify-content-start"}
                                                                             style={{
                                                                                 paddingLeft: "15px",
                                                                                 display: "flex"
                                                                             }}
                                                                             as={Button} id="dropdownMenuLink">
                                                                {pageData['county'].placeholder}
                                                            </Dropdown.Toggle>
                                                        )}
                                                        <Dropdown.Menu
                                                            className="dropdown-menu scrollable-menu-markets">
                                                            {countyPicker.map((county, index) => (
                                                                <Dropdown.Item className={"dropdown-item"} key={index}
                                                                               onClick={(e) => {
                                                                                   onCountySelected(county.value);
                                                                               }}>{county.label}</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <div className="invalid-feedback">
                                                        {pageData['county'].error}
                                                    </div>
                                                    <label htmlFor="">{pageData['county'].label}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group form-group-select ">
                                                    <Dropdown className="col-12 align-self-center"
                                                              style={{padding: "inherit"}}>
                                                        {formData.user_address_neighborhood.length !== 0 ? (
                                                            <Dropdown.Toggle role={"button"}
                                                                             className={"select-selected justify-content-start"}
                                                                             style={{
                                                                                 paddingLeft: "15px",
                                                                                 display: "flex"
                                                                             }}
                                                                             as={Button} id="dropdownMenuLink"
                                                            >
                                                                {formData.user_address_neighborhood}
                                                            </Dropdown.Toggle>
                                                        ) : (
                                                            <Dropdown.Toggle role={"button"}
                                                                             className={"select-selected justify-content-start"}
                                                                             style={{
                                                                                 paddingLeft: "15px",
                                                                                 display: "flex"
                                                                             }}
                                                                             as={Button} id="dropdownMenuLink">
                                                                {pageData['neighborhood'].placeholder}
                                                            </Dropdown.Toggle>
                                                        )}
                                                        <Dropdown.Menu
                                                            className="dropdown-menu scrollable-menu-markets">
                                                            {neighborhoodPicker.map((neighborhood, index) => (
                                                                <Dropdown.Item className={"dropdown-item"} key={index}
                                                                               onClick={(e) => {
                                                                                   onNeighborhoodSelected(neighborhood.value);
                                                                               }}>{neighborhood.label}</Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    <div className="invalid-feedback">
                                                        {pageData['neighborhood'].error}
                                                    </div>
                                                    <label htmlFor="">{pageData['neighborhood'].label}</label>
                                                </div>
                                            </div>
                                        </div>
                                        {/*bina, numara, kat*/}
                                        <div className="form-row">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <input type="text"
                                                           value={formData.user_address_building_number}
                                                           onChange={(e) => {
                                                               updateFormData('user_address_building_number', e.target.value)
                                                           }}
                                                           className="form-control" id="bina"
                                                           placeholder={pageData['no'].placeholder} required/>
                                                    <label htmlFor="inputPassword4">{pageData['no'].label}</label>
                                                    <div className="invalid-feedback">
                                                        {pageData['no'].error}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <input type="text"
                                                           value={formData.user_address_floor_number}
                                                           onChange={(e) => updateFormData('user_address_floor_number', e.target.value)}
                                                           className="form-control" id="kat"
                                                           placeholder={pageData['floor'].placeholder} required/>
                                                    <label htmlFor="inputPassword4">{pageData['floor'].label}</label>
                                                    <div className="invalid-feedback">
                                                        {pageData['floor'].error}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <input type="text"
                                                           value={formData.user_address_door_number}
                                                           onChange={(e) => updateFormData('user_address_door_number', e.target.value)}
                                                           className="form-control" id="daire"
                                                           placeholder={pageData['apartment'].placeholder} required/>
                                                    <label htmlFor="inputEmail4">{pageData['apartment'].label}</label>
                                                    <div className="invalid-feedback">
                                                        {pageData['apartment'].error}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*tam adres*/}
                                        <div className="form-group">
                                                    <textarea id="address"
                                                              value={formData.user_address_detail}
                                                              onChange={(e) => updateFormData('user_address_detail', e.target.value)}
                                                              className="form-control"
                                                              placeholder={pageData['address'].placeholder}
                                                              required/>
                                            <label htmlFor="inputAddress">{pageData['address'].label}</label>
                                            <div className="invalid-feedback">
                                                {pageData['address'].error}
                                            </div>
                                        </div>
                                        {/*adres tarifi*/}
                                        <div className="form-group">
                                                    <textarea id="address-note"
                                                              value={formData.user_address_information}
                                                              onChange={(e) => updateFormData('user_address_information', e.target.value)}
                                                              className="form-control"
                                                              placeholder={pageData['directions'].placeholder} required/>
                                            <label htmlFor="inputAddress">{pageData['directions'].label}</label>
                                            <div className="invalid-feedback">
                                                {pageData['directions'].error}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-6"/>
                        <div className="col-lg-3 col-sm-12">
                            <a data-toggle="modal" onClick={() => {
                                context.setGlobalState('addAddressModal', false);
                            }} className="btn btn-primary col-10 col-lg-8 mx-auto">{pageData['cancel'].label}</a>
                        </div>
                        <div className="col-lg-3 col-sm-12">
                            <a data-toggle="modal" onClick={async () => {
                                await onNAAddAddressButtonPressed();
                            }} className="btn btn-secondary col-10 col-lg-8 mx-auto">{pageData['ok'].label}</a>
                        </div>
                    </div>
                </div>
            </div>
        </Modal.Body>
    </Modal>);
}
export default AddAddressModal;
